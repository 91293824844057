import React, { useEffect, useState } from "react";

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

// file
import WebView from "./WebView";
// import PhoneView from "./PhoneView";

// core Component
import { 
  Container,
  Divider,
  Typography
} from "@material-ui/core";

// Images
import EssentialsBanner from "../assets/BuyNow/banner1000x390-4.jpg";
import BusinessBanner from "../assets/BuyNow/banner1000x390-5.jpg";
import PremiumBanner from "../assets/BuyNow/banner1000x390-6.jpg";
import E1Banner from "../assets/BuyNow/banner1000x390-7.jpg";
import ProplusBanner from "../assets/BuyNow/banner1000x390-8.jpg";
import E3Banner from "../assets/BuyNow/banner1000x390-9.jpg";

import O365_Icon01 from "../assets/BuyNow/icon/o365-1.jpg";
import O365_Icon02 from "../assets/BuyNow/icon/o365-2.jpg";
import O365_Icon03 from "../assets/BuyNow/icon/o365-3.jpg";
import O365_Icon04 from "../assets/BuyNow/icon/o365-4.jpg";
import O365_Icon05 from "../assets/BuyNow/icon/o365-5.jpg";
import O365_Icon06 from "../assets/BuyNow/icon/o365-6.jpg";
import O365_Icon07 from "../assets/BuyNow/icon/o365-7.jpg";
import O365_Icon08 from "../assets/BuyNow/icon/o365-8.jpg";
import O365_Icon09 from "../assets/BuyNow/icon/o365-9.jpg";
import O365_Icon10 from "../assets/BuyNow/icon/o365-10.jpg";
import O365_Icon11 from "../assets/BuyNow/icon/o365-11.jpg";
import O365_Icon12 from "../assets/BuyNow/icon/o365-12.jpg";
import O365_Icon13 from "../assets/BuyNow/icon/o365-13.jpg";
import O365_Icon14 from "../assets/BuyNow/icon/o365-14.jpg";
import O365_Icon15 from "../assets/BuyNow/icon/o365-15.jpg";
import O365_Icon16 from "../assets/BuyNow/icon/o365-16.jpg";
import O365_Icon17 from "../assets/BuyNow/icon/o365-17.jpg";
import O365_Icon18 from "../assets/BuyNow/icon/o365-18.jpg";
import O365_Icon19 from "../assets/BuyNow/icon/o365-19.jpg";
import O365_Icon20 from "../assets/BuyNow/icon/o365-20.jpg";
import O365_Icon21 from "../assets/BuyNow/icon/o365-21.jpg";
import O365_Icon22 from "../assets/BuyNow/icon/o365-22.jpg";
import O365_Icon23 from "../assets/BuyNow/icon/o365-23.jpg";
import O365_Icon24 from "../assets/BuyNow/icon/o365-24.jpg";

import Contain_Icon01 from "../assets/BuyNow/BuyNow1.png";
import Contain_Icon02 from "../assets/BuyNow/BuyNow2.png";
import Contain_Icon03 from "../assets/BuyNow/BuyNow3.png";
import Contain_Icon04 from "../assets/BuyNow/BuyNow4.png";
import Axios from "axios";

// CSS
import Styled from "styled-components";
const StyledTitle = Styled(Typography)`
  && {
    text-align: center;
    margin: 30px 0;
    font-weight: 700;
    font-family: Microsoft JhengHei;
    letter-spacing: 0.1em;
    font-style: italic;
    line-height: 1.5;
    word-break: keep-all;
    color: #424242;
  }
`

export default function BuyNow() {
  const [state, setState] = useState({
    heads: {
      title:
        "個人服務： <br><font color='blue'>企業級電子郵件<br>檔案儲存與共用<br>線上會議</font>",
      contents: {
        essentials: {
          title: "Microsoft 365",
          edition: "商務基本版",
          hint: "(原：Office 365 商務基本版)"
        },
        business: {
          title: "Microsoft 365",
          edition: "Apps 商務版",
          hint: "(原：Office 365 商務版)"
        },
        premium: {
          title: "Microsoft 365",
          edition: "商務標準版",
          hint: "(原：Office 365 商務進階版)"
        },
        E1: {
          title: "Office 365",
          edition: "企業版 E1",
          hint: ""
        },
        proplus: {
          title: "Microsoft 365",
          edition: "Apps 企業版",
          hint: "(原：Office 365 專業增強版)"
        },
        E3: {
          title: "Office 365",
          edition: "企業版 E3",
          hint: ""
        }
      }
    },
    prices: {
      year: {
        title:
          "每位使用者/每年<br>(按年份計的履約承諾)<br><font color='red'><b>價格均含稅 年繳9折!</b></font>",
        contents: {
          essentials: {
            detailSn: 8,
            price: "-"
          },
          business: {
            detailSn: 9,
            price: "-"
          },
          premium: {
            detailSn: 10,
            price: "-"
          },
          E1: {
            detailSn: 11,
            price: "-"
          },
          proplus: {
            detailSn: 12,
            price: "-"
          },
          E3: {
            detailSn: 13,
            price: "-"
          }
        }
      },
      month: {
        title:
          "每位使用者/每個月<br>(按月份計的履約承諾)<br><font color='red'><b>價格均含稅</b></font>",
        contents: {
          essentials: {
            detailSn: 2,
            price: "-"
          },
          business: {
            detailSn: 3,
            price: "-"
          },
          premium: {
            detailSn: 4,
            price: "-"
          },
          E1: {
            detailSn: 5,
            price: "-"
          },
          proplus: {
            detailSn: 6,
            price: "-"
          },
          E3: {
            detailSn: 7,
            price: "-"
          }
        }
      }
    },
    userLimit: {
      title: "使用者上限",
      contents: {
        essentials: "300",
        business: "300",
        premium: "300",
        E1: "無限制",
        proplus: "無限制",
        E3: "無限制"
      }
    },
    rows: [
      {
        title: "完整安裝的 Office 應用程式",
        contents: {
          essentials: false,
          business: true,
          premium: true,
          E1: false,
          proplus: true,
          E3: true
        }
      },
      {
        title: "平板電腦及手機上適用的 Offic",
        contents: {
          essentials: true,
          business: true,
          premium: true,
          E1: true,
          proplus: true,
          E3: true
        }
      },
      {
        title: "網上版 Office 包含Word、Excel 和 PowerPoint",
        contents: {
          essentials: true,
          business: true,
          premium: true,
          E1: true,
          proplus: true,
          E3: true
        }
      },
      {
        title: "檔案儲存和共用配備1TB儲存空間/使用者",
        contents: {
          essentials: true,
          business: true,
          premium: true,
          E1: true,
          proplus: true,
          E3: true
        }
      },
      {
        title: "企業級電子郵件、行事曆與連絡人功能",
        contents: {
          essentials: true,
          business: false,
          premium: true,
          E1: true,
          proplus: false,
          E3: true
        }
      },
      {
        title: "無限制的線上會議、IM 和 HD 視訊會議",
        contents: {
          essentials: true,
          business: false,
          premium: true,
          E1: true,
          proplus: false,
          E3: true
        }
      },
      {
        title: "小組專屬的內部網路網站",
        contents: {
          essentials: true,
          business: false,
          premium: true,
          E1: true,
          proplus: false,
          E3: true
        }
      },
      {
        title: "Yammer有助於員工不受所在地點限制進行跨部門合作",
        contents: {
          essentials: true,
          business: false,
          premium: true,
          E1: true,
          proplus: false,
          E3: true
        }
      },
      {
        title: "企業影片入口網站可上傳企業影片並與各部門分享 ",
        contents: {
          essentials: false,
          business: false,
          premium: false,
          E1: true,
          proplus: false,
          E3: true
        }
      },
      {
        title: "用 Office Graph 在Office 365中進行個人化搜尋與探索",
        contents: {
          essentials: true,
          business: false,
          premium: true,
          E1: true,
          proplus: false,
          E3: true
        }
      },
      {
        title: "使用群組原則、遙測及共用電腦啟用進行應用程式的企業管理",
        contents: {
          essentials: false,
          business: false,
          premium: false,
          E1: false,
          proplus: true,
          E3: true
        }
      },
      {
        title: "自助商業智慧工具可探索、分析並視覺化呈現 Excel 中的資料",
        contents: {
          essentials: false,
          business: false,
          premium: false,
          E1: false,
          proplus: true,
          E3: true
        }
      },
      {
        title:
          "法規遵循和資訊保護措施包括針對電子郵件與檔案採取法務保存、版權管理以及資料外洩防護",
        contents: {
          essentials: false,
          business: false,
          premium: false,
          E1: false,
          proplus: false,
          E3: true
        }
      },
      {
        title: "eDiscovery 中心，提供可支援法規遵循的工具",
        contents: {
          essentials: false,
          business: false,
          premium: false,
          E1: false,
          proplus: false,
          E3: true
        }
      }
    ],
    allIncludes: [
      {
        // icon: <AccessTimeIcon />,
        icon: Contain_Icon01,
        contents: "保證 99.9% 的上線時間，有財務後盾的服務等級協定"
      },
      {
        // icon: <BuildIcon />,
        icon: Contain_Icon02,
        contents: "針對重大問題提供 IT 等級的 Web 支援與全年無休的電話支援"
      },
      {
        // icon: <VerifiedUserIcon />,
        icon: Contain_Icon03,
        contents: "整合 Active Directory，方便管理使用者認證和權限"
      },
      {
        // icon: <VerifiedUserIcon />,
        icon: Contain_Icon04,
        contents: "透過多因素驗證加強資訊存取安全"
      }
    ]
  });

  const [editionDetail] = useState({
    allIncludes: [
      {
        title: "可靠性",
        icon: O365_Icon19,
        content:
          "服務有保證上線時間達 99.9% 且有財務後盾的服務等級協定 (SLA)，您可安心無虞。"
      },
      {
        title: "安全性",
        icon: O365_Icon20,
        content: "您的資料永保機密。我們保障您的資料安全並保護您的隱私權。"
      },
      {
        title: "系統管理",
        icon: O365_Icon21,
        content:
          "在整個公司部署和管理 Office 365，無需 IT 專業知識。您可以在幾分鐘內新增和移除使用者。"
      },
      {
        title: "最新狀態",
        icon: O365_Icon22,
        content:
          "您無需支付版本升級費用；更新已包含在訂閱中。Office 365 客戶會定期獲得新功能。"
      },
      {
        title: "Active Directory 整合",
        icon: O365_Icon23,
        content:
          "管理使用者認證及權限。採用單一登入並可與 Active Directory 同步處理。"
      },
      {
        title: "支援",
        icon: O365_Icon24,
        content:
          "Microsoft 支援部門提供電話和線上解答服務、使用作法資源，並提供與其他 Office 365 客戶交流的機會以瞭解如何設定和快速修正問題。"
      }
    ],
    essentials: {
      banner: EssentialsBanner,
      contents: [
        {
          title: "電子郵件和行事曆",
          icon: O365_Icon02,
          content:
            "透過桌上型電腦或以網路瀏覽器使用 Outlook Web App 取得企業級電子郵件功能，藉以享有豐富且熟悉的 Outlook 體驗。每位使用者皆可取得 50 GB 的信箱空間並可傳送最大 25 MB 的附件。"
        },
        {
          title: "線上會議",
          icon: O365_Icon05,
          content: "透過單鍵螢幕共用與 HD 視訊會議功能主持線上影音會議。"
        },
        {
          title: "立即訊息與Skype連線",
          icon: O365_Icon06,
          content:
            "透過立即訊息、語音通話以及視訊通話與其他商務用 Skype 使用者進行連線，並讓對方透過您的線上狀態瞭解您是否有空。您可以與 Skype 使用者共享顯示狀態、傳送 IM 並且進行音訊通話。"
        },
        {
          title: "企業社交網路",
          icon: O365_Icon07,
          content:
            "Yammer 共同作業軟體和商務應用程式能讓員工與正確的對象聯繫、在團隊之間共用資訊及籌備專案，讓他們更快達成進度。"
        },
        {
          title: "檔案儲存與共用",
          icon: O365_Icon08,
          content:
            "商務用 OneDrive 提供每位使用者 1 TB 的儲存空間，讓他們幾乎能在任何地點存取文件。與組織內外的其他人共用檔案、控制誰可以查看和編輯每個檔案，並且輕鬆使用 PC/Mac 和裝置來同步處理檔案。"
        },
        {
          title: "小組網站",
          icon: O365_Icon09,
          content:
            "使用 10 GB 的基準儲存空間，加上每位使用者 500 MB 的儲存空間，讓您輕鬆存取和共用文件。"
        },
        {
          title: "Online",
          icon: O365_Icon10,
          content:
            "透過瀏覽器建立並編輯 Word、OneNote、PowerPoint 及 Excel 文件。"
        },
        {
          title: "搜尋和探索",
          icon: O365_Icon11,
          content:
            "隨時掌握最新狀態。搜尋和探索 Office 365 中以個人化洞察報告為基礎的所有內容。Office Delve 是第一款由 Office Graph技術提供的服務，能從每位使用者的行為和與內容、主題及連絡人的關係當中分析所衍生的訊號或洞察報告。"
        }
      ]
    },

    business: {
      banner: BusinessBanner,
      contents: [
        {
          title: "Office",
          icon: O365_Icon01,
          content:
            "於 PC、Mac、Windows 平板電腦、iPad® 與 Android™ 平板電腦以及多數的行動裝置上，享受完整安裝的 Office 產品。 每位使用者可在 5 部 PC 或 Mac、5 台平板電腦(Windows、iPad 與 Android) 以及 5 支手機上安裝 Office"
        },
        {
          title: "Online",
          icon: O365_Icon10,
          content:
            "透過瀏覽器建立並編輯 Word、OneNote、PowerPoint 及 Excel 文件。"
        },
        {
          title: "OneDrive",
          icon: O365_Icon08,
          content:
            "商務用 OneDrive 提供每位使用者 1 TB 的儲存空間，讓他們幾乎能在任何地點存取文件。與組織內外的其他人共用檔案、控制誰可以查看和編輯每個檔案，並且輕鬆使用 PC/Mac 和裝置來同步處理檔案。"
        }
      ]
    },

    premium: {
      banner: PremiumBanner,
      contents: [
        {
          title: "電子郵件和行事曆",
          icon: O365_Icon02,
          content:
            "透過桌上型電腦或以網路瀏覽器使用 Outlook Web App 取得企業級電子郵件功能，藉以享有豐富且熟悉的 Outlook 體驗。每位使用者皆可取得 50 GB 的信箱空間並可傳送最大 25 MB 的附件。"
        },
        {
          title: "線上會議",
          icon: O365_Icon05,
          content: "透過單鍵螢幕共用與 HD 視訊會議功能主持線上影音會議。"
        },
        {
          title: "立即訊息與Skype連線",
          icon: O365_Icon06,
          content:
            "透過立即訊息、語音通話以及視訊通話與其他商務用 Skype 使用者進行連線，並讓對方透過您的線上狀態瞭解您是否有空。您可以與 Skype 使用者共享顯示狀態、傳送 IM 並且進行音訊通話。"
        },
        {
          title: "企業社交網路",
          icon: O365_Icon07,
          content:
            "Yammer 共同作業軟體和商務應用程式能讓員工與正確的對象聯繫、在團隊之間共用資訊及籌備專案，讓他們更快達成進度。"
        },
        {
          title: "檔案儲存與共用",
          icon: O365_Icon08,
          content:
            "商務用 OneDrive 提供每位使用者 1 TB 的儲存空間，讓他們幾乎能在任何地點存取文件。與組織內外的其他人共用檔案、控制誰可以查看和編輯每個檔案，並且輕鬆使用 PC/Mac 和裝置來同步處理檔案。"
        },
        {
          title: "小組網站",
          icon: O365_Icon09,
          content:
            "使用 10 GB 的基準儲存空間，加上每位使用者 500 MB 的儲存空間，讓您輕鬆存取和共用文件。"
        },
        {
          title: "搜尋和探索",
          icon: O365_Icon11,
          content:
            "隨時掌握最新狀態。搜尋和探索 Office 365 中以個人化洞察報告為基礎的所有內容。Office Delve 是第一款由 Office Graph 技術提供的服務，能從每位使用者的行為和與內容、主題及連絡人的關係當中分析所衍生的訊號或洞察報告。"
        }
      ]
    },

    E1: {
      banner: E1Banner,
      contents: [
        {
          title: "電子郵件和行事曆",
          icon: O365_Icon02,
          content:
            "透過桌上型電腦或以網路瀏覽器使用 Outlook Web App 取得企業級電子郵件功能，藉以享有豐富且熟悉的 Outlook 體驗。每位使用者皆可取得 50 GB 的信箱空間並可傳送最大 25 MB 的附件。"
        },
        {
          title: "線上會議",
          icon: O365_Icon05,
          content: "透過單鍵螢幕共用與 HD 視訊會議功能主持線上影音會議。"
        },
        {
          title: "立即訊息與Skype連線",
          icon: O365_Icon06,
          content:
            "透過立即訊息、語音通話以及視訊通話與其他商務用 Skype 使用者進行連線，並讓對方透過您的線上狀態瞭解您是否有空。您可以與 Skype 使用者共享顯示狀態、傳送 IM 並且進行音訊通話。"
        },
        {
          title: "企業社交網路",
          icon: O365_Icon07,
          content:
            "Yammer 共同作業軟體和商務應用程式能讓員工與正確的對象聯繫、在團隊之間共用資訊及籌備專案，讓他們更快達成進度。"
        },
        {
          title: "檔案儲存與共用",
          icon: O365_Icon08,
          content:
            "商務用 OneDrive 提供每位使用者 1 TB 的儲存空間，讓他們幾乎能在任何地點存取文件。與組織內外的其他人共用檔案、控制誰可以查看和編輯每個檔案，並且輕鬆使用 PC/Mac 和裝置來同步處理檔案。"
        },
        {
          title: "小組網站",
          icon: O365_Icon09,
          content:
            "使用 10 GB 的基準儲存空間，加上每位使用者 500 MB 的儲存空間，讓您輕鬆存取和共用文件。"
        },
        {
          title: "搜尋和探索",
          icon: O365_Icon11,
          content:
            "隨時掌握最新狀態。搜尋和探索 Office 365 中以個人化洞察報告為基礎的所有內容。Office Delve 是第一款由 Office Graph 技術提供的服務，能從每位使用者的行為和與內容、主題及連絡人的關係當中分析所衍生的訊號或洞察報告。"
        },
        {
          title: "企業影片入口網站",
          icon: O365_Icon14,
          content:
            "在組織內部輕鬆管理影片 Office 365 影片是適用全公司的位置，可供各種裝置上傳、共用及探索影片，並且順暢地播放影片。"
        },
        {
          title: "相關應用程式與SharePoint",
          icon: O365_Icon18,
          content:
            "新推出的第三方應用程式與客戶開發的應用程式皆可與 Office 及 SharePoint 搭配使用，將網路服務直接帶到您的文件與網站中。"
        }
      ]
    },
    proplus: {
      banner: ProplusBanner,
      contents: [
        {
          title: "Office",
          icon: O365_Icon01,
          content:
            "於 PC、Mac、Windows 平板電腦、iPad® 與 Android™ 平板電腦以及多數的行動裝置上，享受完整安裝的 Office 產品。 每位使用者可在 5 部 PC 或 Mac、5 台平板電腦 (Windows、iPad 與 Android) 以及 5 支手機上安裝 Office"
        },
        {
          title: "檔案儲存與共用",
          icon: O365_Icon08,
          content:
            "商務用 OneDrive 提供每位使用者 1 TB 的儲存空間，讓他們幾乎能在任何地點存取文件。與組織內外的其他人共用檔案、控制誰可以查看和編輯每個檔案，並且輕鬆使用 PC/Mac 和裝置來同步處理檔案。"
        },
        {
          title: "Online",
          icon: O365_Icon10,
          content:
            "透過瀏覽器建立並編輯 Word、OneNote、PowerPoint 及 Excel 文件。"
        },
        {
          title: "的自助商業智慧",
          icon: O365_Icon17,
          content:
            "透過您已知的 Excel 工具讓成效加倍。 使用 Power Query 探索和連接資料、使用 Power Pivot 模型化和分析這份資料，以及使用 Power View 和 Power Map 在互動式報告和地圖中視覺化洞察報告。 Power BI for Office 365 (英文) 是個別的服務供應項目，可供共用和管理在 Excel 中所建立的報告和查詢。Power BI for Office 365 是一項獨立的服務，讓您能輕鬆建立線上的 Excel 報表圖庫，好讓您所屬組織中的所有人都能存取報表、共用分析資料，透過各種裝置隨處合作。"
        },
        {
          title: "相關應用程式與SharePoint",
          icon: O365_Icon18,
          content:
            "新推出的第三方應用程式與客戶開發的應用程式皆可與 Office 及 SharePoint 搭配使用，將網路服務直接帶到您的文件與網站中。"
        }
      ]
    },

    E3: {
      banner: E3Banner,
      contents: [
        {
          title: "Office",
          icon: O365_Icon01,
          content:
            "於 PC、Mac、Windows 平板電腦、iPad® 與 Android™ 平板電腦以及多數的行動裝置上，享受完整安裝的 Office 產品。 每位使用者可在 5 部 PC 或 Mac、5 台平板電腦(Windows、iPad 與 Android) 以及 5 支手機上安裝 Office"
        },
        {
          title: "電子郵件和行事曆",
          icon: O365_Icon02,
          content:
            "透過桌上型電腦或以網路瀏覽器使用 Outlook Web App 取得企業級電子郵件功能，藉以享有豐富且熟悉的 Outlook 體驗。每位使用者皆可取得 50 GB 的信箱空間並可傳送最大 25 MB 的附件。"
        },
        {
          title: "進階電子郵件",
          icon: O365_Icon03,
          content:
            "使用封存與法務保存措施的功能，外加無限制的儲存空間，以達到法規遵循的需求。 而且使用資料外洩防護 (DLP) 原則和原則提示，可讓使用者在電子郵件中瞭解其他法規遵循行使措施。"
        },
        {
          title: "文件和電子郵件存取控制",
          icon: O365_Icon04,
          content:
            "Rights Management Services 可讓您限定文件和電子郵件的存取權，僅將存取權提供給特定人員，防止他人檢視或編輯這些資料，即使在組織外部傳送這些資料也不會外洩。"
        },
        {
          title: "線上會議",
          icon: O365_Icon05,
          content: "透過單鍵螢幕共用與 HD 視訊會議功能主持線上影音會議。"
        },
        {
          title: "立即訊息與Skype連線",
          icon: O365_Icon06,
          content:
            "透過立即訊息、語音通話以及視訊通話與其他商務用 Skype 使用者進行連線，並讓對方透過您的線上狀態瞭解您是否有空。您可以與 Skype 使用者共享顯示狀態、傳送 IM 並且進行音訊通話。"
        },
        {
          title: "企業社交網路",
          icon: O365_Icon07,
          content:
            "Yammer 共同作業軟體和商務應用程式能讓員工與正確的對象聯繫、在團隊之間共用資訊及籌備專案，讓他們更快達成進度。"
        },
        {
          title: "檔案儲存與共用",
          icon: O365_Icon08,
          content:
            "商務用 OneDrive 提供每位使用者 1 TB 的儲存空間，讓他們幾乎能在任何地點存取文件。與組織內外的其他人共用檔案、控制誰可以查看和編輯每個檔案，並且輕鬆使用 PC/Mac 和裝置來同步處理檔案。"
        },
        {
          title: "小組網站",
          icon: O365_Icon09,
          content:
            "使用 10 GB 的基準儲存空間，加上每位使用者 500 MB 的儲存空間，讓您輕鬆存取和共用文件。"
        },
        {
          title: "Online",
          icon: O365_Icon10,
          content:
            "透過瀏覽器建立並編輯 Word、OneNote、PowerPoint 及 Excel 文件。"
        },
        {
          title: "搜尋和探索",
          icon: O365_Icon11,
          content:
            "隨時掌握最新狀態。搜尋和探索 Office 365 中以個人化洞察報告為基礎的所有內容。Office Delve 是第一款由 Office Graph 技術提供的服務，能從每位使用者的行為和與內容、主題及連絡人的關係當中分析所衍生的訊號或洞察報告。"
        },
        {
          title: "行動性",
          icon: O365_Icon12,
          content:
            "同步處理電子郵件、行事曆及連絡人；存取 SharePoint 網站；在 Windows Phone、iOS 及 Android 裝置上，透過瀏覽器使用 Office Online 檢視並編輯 Office 文件。"
        },
        {
          title: "應用程式的企業管理",
          icon: O365_Icon13,
          content:
            "使用群組原則、遙測及共用電腦啟用來簡化組織內的應用程式管理作業。"
        },
        {
          title: "企業影片入口網站",
          icon: O365_Icon14,
          content:
            "在組織內部輕鬆管理影片 Office 365 影片是適用全公司的位置，可供各種裝置上傳、共用及探索影片，並且順暢地播放影片。"
        },
        {
          title: "整合通訊",
          icon: O365_Icon15,
          content:
            "託管式語音信箱支援以及自動語音應答功能。 語音信箱的錄製內容會儲存至 Exchange Online，而使用者可透過 Outlook、Outlook Web App 或相容的行動電話加以存取。"
        },
        {
          title: "進階法規遵循工具",
          icon: O365_Icon16,
          content:
            "透過整合的 eDiscovery 中心，您可以橫跨 SharePoint、商務用 Skype 及 Exchange 信箱搜尋郵件。eDiscovery 整合了進階保存和封存功能，可以執行就地法律保存措施和依案件的專案。"
        },
        {
          title: "的自助商業智慧",
          icon: O365_Icon17,
          content:
            "透過您已知的 Excel 工具讓成效加倍。 使用 Power Query 探索和連接資料、使用 Power Pivot 模型化和分析這份資料，以及使用 Power View 和 Power Map 在互動式報告和地圖中視覺化洞察報告。 Power BI for Office 365 (英文) 是個別的服務供應項目，可供共用和管理在 Excel 中所建立的報告和查詢。Power BI for Office 365 是一項獨立的服務，讓您能輕鬆建立線上的 Excel 報表圖庫，好讓您所屬組織中的所有人都能存取報表、共用分析資料，透過各種裝置隨處合作。"
        },
        {
          title: "相關應用程式與SharePoint",
          icon: O365_Icon18,
          content:
            "新推出的第三方應用程式與客戶開發的應用程式皆可與 Office 及 SharePoint 搭配使用，將網路服務直接帶到您的文件與網站中。"
        }
      ]
    }
  });

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  useEffect(() => {
    setOpen(true);
    Axios.get("/api/product/price").then(res => {
      // console.log(res);
      Object.keys(res.data).forEach(key => {
        setState(prevState => ({
          ...prevState,
          prices: {
            ...prevState.prices,
            year: {
              ...prevState.prices.year,
              contents: {
                ...prevState.prices.year.contents,
                [key]: {
                  ...prevState.prices.year.contents[key],
                  detailSn: res.data[key].year.detailSn,
                  price: res.data[key].year.price
                  // test: "test"
                }
              }
            },
            month: {
              ...prevState.prices.month,
              contents: {
                ...prevState.prices.month.contents,
                [key]: {
                  ...prevState.prices.month.contents[key],
                  detailSn: res.data[key].month.detailSn,
                  price: res.data[key].month.price
                }
              }
            }
          }
        }));
      });
    })
    .catch(err => {
      setOpen(false);
      //alert(err);
    });
  }, []);

  useEffect(() => {
    // Use any data from backend to control backfrop effect. So you can also use the others values.
    if(state.prices.year.contents["proplus"].price !== '-') {
      setOpen(false);
    }
  },[state])

  return (
    <Container>
      <StyledTitle variant="h3">選擇方案</StyledTitle>
      <Divider />
      <WebView data={state} editionDetail={editionDetail} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
    
  
  );
}
