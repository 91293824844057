import React from "react";
import { createContext, useState } from "react";

const PageLoadingContext = createContext({});

function PageLoadingProvider({ children }) {
  const [showPageLoading, setShowPageLoading] = useState(false);

  return (
    <PageLoadingContext.Provider
      value={{ showPageLoading, setShowPageLoading }}
    >
      {children}
    </PageLoadingContext.Provider>
  );
}

export { PageLoadingContext, PageLoadingProvider };
