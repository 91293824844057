import React from "react";

// Layout
import Grid from '@material-ui/core/Grid';

// Component
import { Container } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardMedia } from '@material-ui/core';

//Dialog
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

// Icon
import BuildIcon from '@material-ui/icons/Build';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// Style CSS
import { makeStyles } from '@material-ui/core/styles';

// Setup Videos Img
import SetupImg1 from "./assets/Videos/img/tv_300x200_01.jpg";
import SetupImg2 from "./assets/Videos/img/tv_300x200_02.jpg";
import SetupImg3 from "./assets/Videos/img/tv_300x200_03.jpg";
import SetupImg4 from "./assets/Videos/img/tv_300x200_04.jpg";
import SetupImg5 from "./assets/Videos/img/tv_300x200_05.jpg";
import SetupImg6 from "./assets/Videos/img/tv_300x200_06.jpg";

// Deploy Videos Img
import DeployImg1 from "./assets/Videos/img/tv_300x200_07.jpg";
import DeployImg2 from "./assets/Videos/img/tv_300x200_08.jpg";

// Maintain Videos Img
import MaintainImg1 from "./assets/Videos/img/tv_300x200_09.jpg";
import MaintainImg2 from "./assets/Videos/img/tv_300x200_10.jpg";
import MaintainImg3 from "./assets/Videos/img/tv_300x200_11.jpg";

// Configure style
const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 650,
    //height: '100%'
  },
  divider: {
    marginTop: 10,
    marginBottom: 30
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  subtitle: {
    marginBottom: 5
  },
  contexting: {
    marginBottom: 30
  },
  media: {
    height: 140,
  },
  videoDescription: {
    width: 320,
    marginLeft: 10,
    paddingLeft: 20
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

// For dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RenderContent(props) {
  const classes = useStyles();

  // For dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End for dialog

  return (
    <>
      <Grid item justify="center" container="true" style={{ marginBottom: 20 }}>
        <img src={props.row.video} onClick={handleClickOpen} alt=""></img>
      </Grid>
      <Grid item className={classes.videoDescription}>
        <Typography paragraph variant="h5" color="primary" style={{ fontSize: 20 }}>
          {props.row.title}
        </Typography>
        <Typography align="justify">{props.row.lecturer}</Typography>
      </Grid>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.row.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container className={classes.container}>
          <Card className={classes.container}>
            <CardMedia
              id="iframeX"
              className={classes.container}
              component="iframe"
              src={props.row.url}
              allowFullScreen
            />
          </Card>
        </Container>
      </Dialog>
    </>
  );
}

function RenderVideo(props) {
  return (
    <Grid container item xs={12} spacing={3}>
      {props.row.map(context => (
        <Grid item xs={4}>
          <RenderContent row={context} />
        </Grid>
      ))}
    </Grid>
  );
}

export default function TeachingVideo() {
  const classes = useStyles();

  const setup1Contexts = [
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart/o365-setup1/player",
      video: SetupImg1,
      title: "如何透過 Office 365 介面尋求技術支援服務",
      lecturer: "Alice Wang"
    },
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart/o365-setup2/player",
      video: SetupImg2,
      title: "如何大量指派授權給使用者",
      lecturer: "Alice Wang"
    },
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart/o365-setup3/player",
      video: SetupImg3,
      title: "如何大量新增使用者",
      lecturer: "Alice Wang"
    }
  ]

  const setup2Contexts = [
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart/o365-setup5/player",
      video: SetupImg4,
      title: "如何更改 Office 365 的顯示語言 (OWA/UI)",
      lecturer: "Alice Wang"
    },
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart/setup06/player",
      video: SetupImg5,
      title: "如何註冊申請 Office365 方案使用",
      lecturer: "Jammy Lo"
    },
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart/setup07/player",
      video: SetupImg6,
      title: "如何在我的 Office 365 中新增網域",
      lecturer: "Jammy Lo"
    }
  ]

  const deployContexts = [
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart-deploy/deploy01/player",
      video: DeployImg1,
      title: "如何透過簡易的設定(POP)進行舊信轉移_小型企業",
      lecturer: "Derrick Shih"
    },
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart-deploy/deploy02/player",
      video: DeployImg2,
      title: "如何透過簡易的設定大量匯入舊有的 PST 檔案至雲端",
      lecturer: "Derrick Shih"
    }
  ]

  const maintainContexts = [
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart-faq/faq01/player",
      video: MaintainImg1,
      title: "如何列出 30 天未登入的信箱",
      lecturer: "Derrick Shih"
    },
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart-faq/faq02/player",
      video: MaintainImg2,
      title: "無法同步 OneDrive 時該如何處理",
      lecturer: "Derrick Shih"
    },
    {
      url: "https://channel9.msdn.com/Series/Office-365-jumpstart-faq/faq03/player",
      video: MaintainImg3,
      title: "DNS 沒有提供 SRV 紀錄怎麼辦",
      lecturer: "Derrick Shih"
    }
  ]

  return (
    <Container className={classes.container}>
      <Typography mx="auto" variant="h5"><b>教學影片</b></Typography>
      <Divider className={classes.divider} />
      <Grid container className={classes.subtitle} direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <SettingsIcon style={{ fontSize: 30 }}></SettingsIcon>
        </Grid>
        <Grid item style={{ fontSize: 30 }}><b>設定篇</b></Grid>
      </Grid>
      <Grid container spacing={1} className={classes.contexting}>
        <RenderVideo row={setup1Contexts}></RenderVideo>
        <RenderVideo row={setup2Contexts}></RenderVideo>
      </Grid>
      <Grid container className={classes.subtitle} direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <PeopleIcon style={{ fontSize: 30 }}></PeopleIcon>
        </Grid>
        <Grid item style={{ fontSize: 30 }}><b>部屬篇</b></Grid>
      </Grid>
      <Grid container spacing={1} className={classes.contexting}>
        <RenderVideo row={deployContexts}></RenderVideo>
      </Grid>
      <Grid container className={classes.subtitle} direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <BuildIcon style={{ fontSize: 30 }}></BuildIcon>
        </Grid>
        <Grid item style={{ fontSize: 30 }}><b>維運篇</b></Grid>
      </Grid>
      <Grid container spacing={1} className={classes.contexting}>
        <RenderVideo row={maintainContexts}></RenderVideo>
      </Grid>
      <Grid container className={classes.subtitle} direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <OpenInNewIcon style={{ fontSize: 30 }}></OpenInNewIcon>
        </Grid>
        <Grid item style={{ fontSize: 30 }}><b>其他教學資源</b></Grid>
      </Grid>
      <div style={{
        textAlign: "justify",
        textJustify: "inter-word"
      }}>
        <ul class="one">
          <li><a href="https://youtube.com/playlist?list=PLRDHoYNP8eMI558bHo0UbjB07Bjh4j2oB" target="_blank" rel="noopener noreferrer" style={{ fontSize: 20 }}>Microsoft Teams 遠距辦公救星，工作好Easy</a></li><br />
          <li><a href="https://youtube.com/playlist?list=PLRDHoYNP8eMIJ5ADs-dX3HqnokrOfhLjl" target="_blank" rel="noopener noreferrer" style={{ fontSize: 20 }}>SharePoint Online 企業訊息中心，管理神器</a></li><br />
          <li><a href="https://youtube.com/playlist?list=PLRDHoYNP8eMLdIAJyjXapHvuMxhXiP-og" target="_blank" rel="noopener noreferrer" style={{ fontSize: 20 }}>Outlook 郵件辦公寶典，井然有序</a></li><br />
          <li><a href="https://youtube.com/playlist?list=PLRDHoYNP8eMIPosHrE8BNGH0yWarxD5iV" target="_blank" rel="noopener noreferrer" style={{ fontSize: 20 }}>OneDrive for Business 雲端大空間，隨時存取</a></li><br />
        </ul>
      </div>

    </Container>
  );
}



