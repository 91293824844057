import React, { useState, useEffect } from "react";

// Core
import {
  AppBar,
  Toolbar,
  Hidden,
  Button,
  IconButton,
} from "@material-ui/core";

// router
import { Link } from "react-router-dom";

// cookie
import { useCookies } from "react-cookie";

// Style CSS
import styled from 'styled-components';
// import { makeStyles, createStyles } from "@material-ui/core/styles";

// HTTP request tool
import axios from "axios";

// icons
import MenuIcon from "@material-ui/icons/Menu";

// assets
import LogoImg from "./assets/headerLogo.jpg";

// var state = false
var sessionId = "";

// CSS 
const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 15px 0 15px 0;
  }
`
const StyledIconButton = styled(IconButton)`
  && {
    margin-right: 20px;
  }
  & > .MuiIconButton-label > .MuiSvgIcon-root {
    font-size: 2rem;
    width: 1.2em;
    height: 1.2em;
  }
`
const StyledLogo = styled.img`
  max-width: 90%;
  height: auto;
`

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       backgroundColor: "#FFFFFF",
//     },
//   }),
// );

export default function UPHeader(props) {
  const [cookies, setCookie] = useCookies(["sessionId"]);
  const [login, setLogin] = useState(false);
  const [isCheat, setIsCheat] = useState(false);
  // const [sessionID, setSessionID] = useState("None");

  // const classes = useStyles();

  function AAALogin() {
    axios.post("/AAA/login").then((res) => {
      const data = res.data;
      //console.log(data["url"]);
      window.location.assign(data["url"]);
      // AAAresopnse();
    });
  }
  // function AAAresopnse() {
  //   axios
  //     .post("/AAA/loginResponse", {
  //       aaResult: "Success",
  //       aaUid: "74918600",
  //       aaOPTW: "testOPTW",
  //       aaFee: "0",
  //       aaAuthority: "testAuthrity",
  //       aaIp: "::1",
  //       aaOthers: "aaOthers",
  //       aaErrorCode: "aaErrorCode"
  //     })
  //     .then(res => {
  //       const data = res.data;
  //       sessionId = data["sessionId"];
  //       //document.cookie = "sessionId=" + sessionId;
  //       setCookie("sessionId", sessionId);
  //       window.location.assign("/");
  //     });
  // }

  useEffect(() => {
    let isCheatLoggedIn = localStorage.getItem("cheatMode");
    if (isCheatLoggedIn === "true") {
      setIsCheat(true);
      cheatCheckLogin(cookies.sessionId);
    }
  }, [cookies.sessionId]);

  function cheatLogin() {
    let cheatMode = localStorage.getItem("cheatMode");
    if (cookies.sessionId === undefined || cookies.sessionId.length === 0) {
      localStorage.removeItem("cheatMode");
    }
    if (cheatMode === null || cheatMode === "") {
      axios
        .post("/AAA/cheatLogin", {
          Hn: "89869999",
        })
        .then((res) => {
          const data = res.data;
          // console.log("data = ", data);
          sessionId = data["sessionId"];
          console.log(sessionId);
          //document.cookie = "sessionId=" + sessionId;
          setCookie("sessionId", sessionId, { secure: true, maxAge: 15 * 60 });

          // Set login mode
          setIsCheat(true);
          setLogin(true);
          localStorage.setItem("cheatMode", true);
          // window.location.assign("/");
        });
    }
  }

  function cheatCheckLogin(cookie) {
    if (cookie === "") {
      axios
        .post("/AAA/checkLogin", {
          sessionID: cookie,
        })
        .then((res) => {
          const data = res.data;
          // console.log(data)
          setIsCheat(true);
          setLogin(data["Authentication"]);
        });
    } else {
      setLogin(true);
    }
  }

  function checkLogin(cookie) {
    axios
      .post(
        "/AAA/checkLogin",
        {
          sessionID: cookie,
        },
        { withCredentials: true },
      )
      .then((res) => {
        const data = res.data;
        // console.log(data)
        setLogin(data["Authentication"]);
      });
  }

  function logout() {
    if (!isCheat) {
      axios
        .post(
          "/AAA/logout",
          {
            sessionID: cookies.sessionId,
          },
          { withCredentials: true },
        )
        .then((res) => {
          document.cookie = "sessionId=";
          setLogin(false);
          window.location.assign("/");
        }).catch(function (err) {
          document.cookie = "sessionId=";
          setLogin(false);
          window.location.assign("/");
        });
    } else {
      document.cookie = "sessionId=";
      localStorage.removeItem("cheatMode");
      setIsCheat(false);
      setLogin(false);
      window.location.assign("/");
    }
  }

  checkLogin(cookies.sessionId);
  return (
    <StyledAppBar position="static">
      <Toolbar>
        <StyledIconButton onClick={props.handleDrawerOpen}>
          <MenuIcon />
        </StyledIconButton>
        <a href="/">
          <StyledLogo src={LogoImg} alt="Logo" />
        </a>

        <Hidden xsDown>
          <div style={{ marginLeft: "auto", float: "right" }}>
            {process.env.REACT_APP_MODE === "development" ? (
              <React.Fragment>
                <Link to="/backoffice/Login">
                  <Button style={{ textTransform: "none" }}>backoffice</Button>
                </Link>
                <Button
                  style={{ textTransform: "none" }}
                  href=""
                  onClick={cheatLogin}
                >
                  AdminLogin
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            {login === true ? (
              <Button
                style={{ textTransform: "none" }}
                href=""
                onClick={logout}
              >
                HiNet帳號登出
              </Button>
            ) : (
              <Button
                style={{ textTransform: "none" }}
                href=""
                onClick={AAALogin}
              >
                HiNet帳號登入
              </Button>
            )}
            <Button
              style={{ textTransform: "none" }}
              href="http://hicloud.hinet.net/"
            >
              hicloud首頁
            </Button>
            <Button
              style={{ textTransform: "none" }}
              href="https://member.cht.com.tw/"
            >
              中華電信
            </Button>
            <Button
              style={{ textTransform: "none" }}
              href="https://member.cht.com.tw/"
            >
              會員中心
            </Button>
            <Button
              style={{ textTransform: "none" }}
              href="https://www.hinet.net/"
            >
              HiNet首頁
            </Button>
            <Button
              style={{ textTransform: "none" }}
              href="https://domain.hinet.net/"
            >
              HiNet域名註冊
            </Button>
            <Button
              style={{ textTransform: "none" }}
              href="https://forms.office.com/r/Q3TZy0rv4A"
            >
              障礙申告表
            </Button>
          </div>
        </Hidden>
      </Toolbar>
    </StyledAppBar>
  );
}
