import React, { useState, useEffect } from "react";
import WebView from "./WebView";

// Core
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";

// HTTP request tool
import axios from "axios";

export default function Home() {
  const [announcement, setAnnouncement] = useState("");
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);

  const extractTextAndURLs = (text) => {
    const regex = /(?:([^:]+):)?(https?:\/\/[^\s]+)/g;
    const matches = [...text.matchAll(regex)];
    return matches.map(match => ({
      text: match[1] || match[2], // 使用捕獲的前面文字或URL本身
      url: match[2]
    }));
  };

  const links = extractTextAndURLs(announcement);

  // 找到最後一個左括號的索引
  const lastIndex = announcement.lastIndexOf("(");

  // 如果找到左括號，則取得它前面的字串，否則設為空字串
  const beforeParenthesis = lastIndex !== -1 ? announcement.substring(0, lastIndex) : "";

  // 找到第一個右括號的索引
  const firstIndex = announcement.indexOf(")");

  // 如果找到右括號，則取得它後面的字串，否則設為空字串
  const afterParenthesis = firstIndex !== -1 ? announcement.substring(firstIndex + 1) : "";

  useEffect(() => {
    // using api to get announcement
    axios
      .post("/api/v0/getAnnouncement")
      .then(function (response) {
        console.log(response);

        if (response.data.Content !== "") {
          setAnnouncement(response.data.Content);
          setAnnouncementModalOpen(true);
        } else {
          setAnnouncement("");
          setAnnouncementModalOpen(false);
        }
      })
      .catch(function (error) {
        setAnnouncementModalOpen(false);
        setAnnouncement("");
      });
  }, []);

  return (
    <>
      <WebView></WebView>
      <Dialog
        open={announcementModalOpen}
        onClose={() => setAnnouncementModalOpen(false)}
        fullWidth={true}
        maxWidth={"sm"}>
        <DialogTitle>公告</DialogTitle>
        <DialogContent>
          <div style={{ whiteSpace: "pre-wrap" }}>
            {lastIndex !== -1 && (
              <p>{beforeParenthesis}</p>
            )}
            <ul>
              {links.map((link, index) => (
                <li key={index}>
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
            {firstIndex !== -1 && (
              <p>{afterParenthesis}</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAnnouncementModalOpen(false)} color="primary">
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
