import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getCookie,
  handleExpiredCookies,
  handleRefreshLoginCookies,
} from "../../common/session";

import Axios from "axios";
import { format } from "date-fns";
import { CsvBuilder } from "filefy";
import MaterialTable from "material-table";
import NumberFormat from "react-number-format";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
//import BarChart from "@material-ui/icons/BarChart";
// import CloudUpload from "@material-ui/icons/CloudUpload";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import Search from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import ViewColumn from "@material-ui/icons/ViewColumn";
// import MoveIcon from '@material-ui/icons/SubdirectoryArrowRight';
// import ErrorOutline from '@material-ui/icons/ErrorOutline';
// import FilterListRounded from '@material-ui/icons/FilterListRounded';
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import AccountBox from "@material-ui/icons/AccountBox";
//import AssignmentIcon from "@material-ui/icons/Assignment";
// eslint-disable-next-line no-unused-vars
import { Box, Grid, Link, TextField, Typography } from "@material-ui/core";

import DetailModal from "./DetailModal";
import DetailModalOfGoogle from "./DetailModalOfGoogle";
import DetailModalOfMicrosoft from "./DetailModalOfMicrosoft";

import { useSnackbar } from "notistack";
import { useCookies } from "react-cookie";
import CompanyList from "../CompanyList";
import FilterCompanyList from "../FilterCompanyList";
import ChargeModal from "./ChargeModal";
import PurchaseModel from "./PurchaseModel";
import PurchaseModelOfGoogle from "./PurchaseModelOfGoogle";
import PurchaseModelOfMicrosoft from "./PurchaseModelOfMicrosoft";
import QuanitityModal from "./QuantityModal";

import {
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  NativeSelect,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import Swal from "sweetalert2";

// Style CSS
import { makeStyles } from "@material-ui/core/styles";
// import { LaptopWindows } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 500,
    //height: '100%'
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// API
// 受理方式
const orderSourceTypeURL = "/api/order/getOrderSourceTypeList";

// 訂單狀態
const orderStatusURL = "/api/order/getOrderStatusList";
const orderListURL = "/api/order/getOrderList";
const deleteOrderURL = "/api/order/deleteOrder";
const orderStatusMSServiceActivated = 2;
const orderStatusGWSServiceActivated = 9;
const orderStatusUnsubscribe = 4;
//const deleteOrderURL = "/api/order/deleteOrder";
// 特殊事件
const sendMailEventURL = "/api/mailLog/sendNewEventNotificationMail";

// 微軟 API
const MSOrderSuspend = "/api/v0/mpnSuspendSubscription";
// const batchMigrateToNCEOrderURL = "/api/v0/batchUpgradeToNCEOffer";

const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
const orderSourceTypeDSIS = "DSIS";
//const orderChargeTypeYear = "年繳";
// const orderStatusMsStart = "服務啟用(CSP出帳)";
const orderStatusGoogleStart = "GWS服務啟用";
const updateOrderStatus = "/api/order/updateOrderStatus";

// 產品廠牌
const BrandInit = 0;
const BrandMicrosoftSn = 1;
const BrandGoogleSn = 2;
const getProductBrandListAPI = "/api/product/brand/list";

const searchItemDefault = {
  orderDateStart: "",
  orderDateEnd: format(new Date(), "yyyy-MM-dd"),
};

const tableQueryDefault = {
  pageSize: 0,
  page: 0,
  search: "",
  field: "",
  orderDirection: "",
};

export default function Orders(props) {
  // Refreshing cookies mechanism
  let history = useHistory(); // 取得某頁面route name
  const { enqueueSnackbar } = useSnackbar();

  // Table Feature
  const tableRef = React.createRef();
  const handleChangePage = (event) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Modal status
  const [detailModalStatus, setDetailModalStatus] = useState(false);
  const [chargeModalStatus, setChargeModalStatus] = useState(false);
  const [quanitityModalStatus, setQuantityModalStatus] = useState(false);
  const [purchaseModalStatus, setPurchaseModalStatus] = useState(false);
  const [selectTimeModalStatus, setSelectTimeModalStatus] = useState(false);
  const [selectAccountingFileModalStatus, setSelectAccountingFileModalStatus] =
    useState(false);
  const [
    inputSingleAccountingDateModalStatus,
    setInputSingleAccountingDateModalStatus,
  ] = useState(false);
  const [downloadK2ModalStatus, setDownloadK2ModalStatus] = useState(false);
  const [downloadAvenueModalStatus, setDownloadAvenueModalStatus] =
    useState(false);
  const [downloadCostModalStatus, setDownloadCostModalStatus] = useState(false);
  const [companyOptionStatus, setCompanyOptionStatus] = useState(false);
  const [company, setCompany] = useState(BrandInit);
  const [productBrandList, setProductBrandList] = useState([]);
  const [filterCompanyOptionStatus, setFilterCompanyOptionStatus] =
    useState(false);
  const [filterCompanyCB, setFilterCompanyCB] = useState(null);

  // Modal Sn
  const [orderSn, setOrderSn] = useState(0);
  const [aaHnNo, setAaHnNo] = useState(0);

  // status list
  const [orderSourceTypeList, setOrderSourceTypeList] = useState({});
  const [orderStatusList, setOrderStatusList] = useState({});

  const [searchItem, setSearchItem] = useState(searchItemDefault);
  // eslint-disable-next-line no-unused-vars
  const [tableQuery, setTableQuery] = useState(tableQueryDefault);
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState("");

  // data
  const [orderList, setOrderList] = useState([]);

  // File Upload
  const [billingFileName, setBillingFileName] = useState("");
  const [selectedBillingFile, setSelectedBillingFile] = useState("");

  // Single month accounting report related
  const [singleAccYear, setSingleAccYear] = useState(new Date().getFullYear());
  const [singleAccMonth, setSingleAccMonth] = useState(1);
  const [yearList, setYearList] = useState([]);
  // 2024/05/03 K2 Add interval download report
  const [singleAccEndYear, setSingleAccEndYear] = useState(
    new Date().getFullYear()
  );
  const [singleAccEndMonth, setSingleAccEndMonth] = useState(1);

  // Design multi-select mechanism
  const [selectMode, setSelectMode] = useState(false);
  const [chosenOrders, setChosenOrders] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // SendMail Event (關於任何的多選事件都放這裡)
  const [mailEvent, setMailEvent] = useState(false);

  // Time picker
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date(Date.now())
  );
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(Date.now()));

  // Permission
  const [cookies] = useCookies(["op_name", "group_id"]);
  const [permission, setPermission] = useState(0);

  const notPermitted = 1;
  const readOnly = 2;
  // const readWrite = 3;
  // const rwd = 4;

  const columns = [
    {
      title: "訂單時間",
      field: "orderDate",
      render: (rowData) => datetimeStringFormat(rowData.orderDate),
    },
    {
      title: "商品名稱",
      field: "productName",
    },
    {
      title: "訂單編號",
      field: "orderNo",
    },
    {
      title: "商品優惠代碼",
      field: "productDiscode",
    },
    {
      title: "續約狀態",
      field: "autoRenewStatus",
    },
    { title: "HN帳號", field: "aaHnNo" },
    {
      title: "公司/客戶名稱",
      field: "memberName",
    },
    { title: "證號", field: "memberIdNo", hidden: true, searchable: true },
    { title: "統編", field: "memberCompanyNo", hidden: true, searchable: true },
    { title: "週期", field: "billingCycle" },
    { title: "帳號數", field: "quantity" },
    { title: "單價", field: "priceSpecial" },
    {
      title: "累計出帳金額",
      field: "accumulationCharge",
      // sorting: false,
      render: (rowData) => (
        <div align="right">
          <NumberFormat
            value={rowData.accumulationCharge}
            displayType={"text"}
            prefix={"$"}
            thousandSeparator={true}
          />
          <br />
          <Link
            component="button"
            onClick={() => orderCharge(rowData.sn, rowData.productBrandSn)}
          >
            列帳
          </Link>
        </div>
      ),
    },
    // { title: "推廣人", field: "promotionEmployeeNo" },
    {
      title: "NCE",
      field: "isNce",
      render: (rowData) => {
        return rowData.isNce ? "O" : "X";
      },
    },
    {
      title: "訂單狀態",
      field: "orderStatusSn",
      lookup: orderStatusList,
    },
    {
      title: "啟用時間",
      field: "orderStartDate",
      render: (rowData) => {
        if (rowData.orderStartDate) {
          return format(new Date(rowData.orderStartDate), datetimeFormat);
        }
        return null;
      },
    },
    {
      title: "商品更改",
      field: "",
      sorting: false,
      render: (rowData) => {
        //console.log("rowData.orderSourceTypeSn: " + rowData.orderSourceTypeSn);
        //console.log("orderSourceTypeList[rowData.orderSourceTypeSn]: " + orderSourceTypeList[rowData.orderSourceTypeSn]);
        //console.log("rowData.orderStatusSn: " + rowData.orderStatusSn);
        //console.log("orderStatusList[rowData.orderStatusSn]: " + orderStatusList[rowData.orderStatusSn]);
        if (orderSourceTypeList[rowData.orderSourceTypeSn] !== orderSourceTypeDSIS && orderStatusList[rowData.orderStatusSn] === orderStatusGoogleStart) {
          return (
            <Link
              component="button"
              onClick={() => orderQuantity(rowData.sn, rowData.productBrand)}
            >
              數量更改
            </Link>
          );
        }
        if (
          orderSourceTypeList[rowData.orderSourceTypeSn] === orderSourceTypeDSIS
        ) {
          return (
            <Link
              component="button"
              onClick={() =>
                orderPurchase(rowData.aaHnNo, rowData.productBrandSn)
              }
            >
              加購
            </Link>
          );
        }
      },
    },
  ];

  const accountingReportColumns = [
    { title: "訂單編號", field: "orderNo" },
    { title: "廠商", field: "brandName" },
    { title: "Customer Id", field: "customerId" },
    { title: "Customer Name", field: "customerName" },
    { title: "Customer Hn", field: "customerHn" },
    { title: "Domain Name", field: "domainName" },
    { title: "Subscription Description", field: "subscriptionDescription" },
    { title: "Type Of Contract", field: "typeOfContract" },
    { title: "Billing Cycle Type", field: "billingCycleType" },
    {
      title: "Total Number Of Subscription",
      field: "totalNumberOfSubscription",
    },
    { title: "訂單狀態", field: "orderStatus" },
    { title: "列帳金額", field: "accumulatedPrice" },
  ];

  const accountingAvenueReportsColumns = [
    { title: "公雲別", field: "cloudType" },
    { title: "產品代碼", field: "productCode" },
    { title: "公司/客戶名稱", field: "customerName" },
    { title: "服務事業群", field: "businessGroup" },
    { title: "HN帳號", field: "customerHn" },
    { title: "網域", field: "Domain" },
    { title: "專標案與否", field: "accountingNoK2" },
    { title: "合約名稱", field: "cmName" },
    { title: "合約總月數", field: "contractTotalMonths" },
    { title: "每月租費收入(新台幣含稅)", field: "monthlyRevenue" },
    { title: "收入合約總金額(新台幣含稅)", field: "contractTotalRevenue" },
    { title: "租期開始日", field: "contractStartDate" },
    { title: "租期結束日", field: "contractEndDate" },
    { title: "收入出帳系統", field: "revenueAccountSystem" },
    { title: "收款頻率", field: "orderChargeType" },
    { title: "收款方式", field: "chargeType" },
    { title: "收入會計科目(總帳)", field: "revenueItemTotal" },
    { title: "收入會計科目(明細帳)", field: "revenueItemDetail" },
    { title: "當月認列金額(未稅)", field: "monthlyRevenuePretaxed" },
    { title: "實際收款情況(新台幣含稅)", field: "actualChargeCircumstances" },
    {
      title: "累計實際收款金額",
      field: "actualChargeCircumstancesAccumulated",
    },
    // { title: "", field: "licenseLengthInMonth" },
  ];
  const accountingCostReportsColumns = [
    { title: "成本情境分類", field: "costSenarioType" },
    { title: "公雲別", field: "cloudType" },
    { title: "產品代碼", field: "productCode" },
    { title: "公司/客戶名稱", field: "customerName" },
    { title: "統編", field: "CompanyNo" },
    { title: "群別", field: "BusinessGroupName" },
    { title: "HN帳號", field: "customerHn" },
    { title: "網域", field: "Domain" },
    { title: "專標案與否", field: "isTenderingProj" },
    { title: "合約名稱", field: "cmName" },
    { title: "合約總月數", field: "contractTotalMonths" },
    { title: "租期開始日", field: "contractStartDate" },
    { title: "租期結束日", field: "contractEndDate" },
    { title: "授權開始日", field: "licenseStartDate" },
    { title: "授權結束日", field: "licenseEndDate" },
    { title: "授權總月數", field: "licenseLengthInMonth" },
    { title: "每月租費支出(新台幣含稅)", field: "monthlyCostTaxed" },
    {
      title: "授權期間內支出總金額(新台幣含稅)",
      field: "historyCycleCostTotal",
    },
    { title: "付款方式", field: "payType" },
    { title: "付款頻率", field: "orderChargeType" },
    { title: "實際付款情況(新台幣含稅)", field: "historyCycleCost" },
    { title: "當月付款金額(新台幣含稅)", field: "thisMonthCostTaxed" },
    { title: "成本會計科目(總帳)", field: "accountingCostItemTotal" },
    { title: "成本會計科目(明細帳)", field: "accountingCostItemDetail" },
    { title: "列帳機構", field: "documentUnit" },
    { title: "交易機構", field: "serviceOffice" },
    { title: "交易單位", field: "serviceUnit" },
    // { title: "當月認列金額", field: "accountingHistoryWithinContract" },
    {
      title: "當月認列成本金額(新台幣未稅)",
      field: "thisMonthHistoryCycleCostPreTaxed",
    },

    // { title: "", field: "totalCostForCurrentContract" }
  ];

  // const migrationFailedReportColumns = [
  //   { title: "Order Number", field: "orderNo" },
  //   { title: "Hn Number", field: "hn" },
  //   { title: "Failed Message", field: "failedMsg" }
  // ];

  const actions =
    permission === readOnly
      ? []
      : [
        // {
        //   icon: FilterListRounded,
        //   tooltip: "篩選廠牌",
        //   isFreeAction: true,
        //   hidden: (selectMode ? true : false),
        //   onClick: () => {
        // 注意： 此區塊已經在 2023/08/18 註解掉，未來會呼叫的到的 chooseFilterCompany 也被改掉。
        // 9e5c2dc - Update login procedure, modulize filter company, orders, product basic, list, discode
        //     setFilterCompanyOptionStatus(true);
        //   }
        // },
        // {
        //   icon: ErrorOutline,
        //   tooltip: "Export migration failed report",
        //   isFreeAction: true,
        //   hidden: (selectMode ? true : false),
        //   onClick: () => {
        //     onConfirmExportMigrationFailedReport();
        //   }
        // },
        // {
        //   icon: CloudUpload,
        //   tooltip: "Update mpn billing info",//"Upload billing CSV downloaded from partner center",
        //   isFreeAction: true,
        //   hidden: (selectMode ? true : false),
        //   onClick: () => {
        //     // openSelectUploadAccountingFileModal();
        //     updateCostFromMPN();
        //   }
        // },
        /*{
    icon: BarChart,
    tooltip: "Export accounting report to CSV",
    isFreeAction: true,
    hidden: (selectMode ? true : false),
    onClick: () => {
      onConfirmExportAccountingReport();
    }
  },*/
        {
          icon: AccountBalanceWallet,
          tooltip: "匯出單月 k1 報表",
          isFreeAction: true,
          hidden: selectMode ? true : false,
          onClick: () => {
            openInputSingleAccountingDateModalStatus();
          },
        },
        {
          icon: AccountBox,
          tooltip: "匯出單月 k2 報表",
          isFreeAction: true,
          hidden: selectMode ? true : false,
          onClick: () => {
            openDownloadK2ModalStatus();
          },
        },
        {
          icon: AccountBox,
          tooltip: "匯出月份會計收入報表",
          isFreeAction: true,
          hidden: selectMode ? true : false,
          onClick: () => {
            openDownloadAvenueModalStatus();
          },
        },
        {
          icon: AccountBox,
          tooltip: "匯出月份會計支出報表",
          isFreeAction: true,
          hidden: selectMode ? true : false,
          onClick: () => {
            // alert("下載中...需時數分鐘, 請稍候.");
            openDownloadCostModalStatus();
          },
        },
        {
          icon: SaveAlt,
          tooltip: "將訂單列表匯出成 csv",
          isFreeAction: true,
          hidden: selectMode ? true : false,
          onClick: () => {
            openSelectTimeModal();
          },
        },
        {
          icon: AddCircleOutlineIcon,
          tooltip: "新增",
          isFreeAction: true,
          hidden: selectMode ? true : false,
          onClick: (event) => {
            setSearchText("test");
            var brandSn =
              localStorage.getItem("filterCompany") !== null
                ? parseInt(localStorage.getItem("filterCompany"))
                : 0;
            if (brandSn !== 0) {
              chooseCompany(brandSn);
            } else {
              openCompanyList();
            }
          },
        },
        // {
        //   icon: MoveIcon,
        //   tooltip: "Migrate to NCE order",
        //   isFreeAction: true,
        //   onClick: (event) => {
        //     if (window.confirm("微軟會自動判定是否能遷移至 NCE 之訂單, 並會更新 offer id, 需時數分鐘!")) {
        //       doBatchOrderMigrateToNCE();
        //     }
        //   },
        // },
        {
          icon: EmailIcon,
          tooltip: "寄漲價通知信",
          isFreeAction: true,
          hidden:
            selectMode ||
              parseInt(localStorage.getItem("filterCompany")) !== 1
              ? true
              : false,
          onClick: (event) => {
            setMailEvent(true);
            setSelectMode(true);
            Swal.fire({
              icon: "info",
              position: "center",
              width: "800px",
              title: "寄漲價通知信功能說明（目前僅限定微軟）",
              html: "<div style='text-align:left; line-height: 2;'><ul><li>點選訂單對象（左方）之後，再按「送出」按鈕（右上方）寄出。</li><li>系統會自動過濾訂單狀態為「待退訂、退訂失敗、服務啟用(CSP出帳)」且訂單來源為「CSP 前、後台」的非優惠代碼訂單之後才寄出。</li><li>若想離開選取模式，請按「取消選取」按鈕（右上方）。</li><li style='color: red;'>在「離開選取模式」或「寄信」之前，請先清空搜尋欄位（右上方）。</li></ul></div>",
              scrollbarPadding: true,
              showConfirmButton: true,
              confirmButtonColor: "#28a8ea",
            });
          },
        },
        {
          // 未選任何選項時，離開 Select Mode。
          icon: CancelIcon,
          tooltip: "取消選取",
          isFreeAction: true,
          hidden: selectMode && searchValue === "" ? false : true,
          onClick: (event) => {
            clearSelection();
            setMailEvent(false);
            setSelectMode(false);
          },
        },
        {
          // 已選任何選項時，取消所有已選的內容並離開 Select Mode。
          icon: CancelIcon,
          tooltip: "取消選取",
          isFreeAction: false,
          hidden: selectMode && searchValue === "" ? false : true,
          onClick: (event) => {
            clearSelection();
            setMailEvent(false);
            setSelectMode(false);
          },
        },
        {
          icon: SendIcon,
          tooltip: "送出",
          isFreeAction: false,
          hidden:
            mailEvent && selectMode && searchValue === "" ? false : true,
          onClick: (event) => {
            if (mailEvent) {
              handleSendMail();
            } else {
              console.log("Nothing happened.");
            }
          },
        },
        //{
        //  icon: AssignmentIcon,
        //  tooltip: "申請微軟服務",
        //  onClick: (event, rowData) => alert("申請微軟服務"),
        //},
        {
          icon: EditIcon,
          tooltip: "詳細資料",
          isFreeAction: false,
          hidden: selectMode ? true : false,
          onClick: (event, rowData) =>
            orderEdit(rowData.sn, rowData.productBrandSn),
        },
        {
          icon: DeleteIcon,
          tooltip: "退租",
          isFreeAction: false,
          hidden: selectMode ? true : false,
          onClick: (event, rowData) => orderDelete(rowData.sn, rowData),
        },
      ];

  useEffect(() => {
    getData(localStorage.getItem("filterCompany"), cookies.op_name);
    getInitData();
    var gid = isNaN(parseInt(cookies.group_id))
      ? 0
      : parseInt(cookies.group_id);
    if (gid !== 0) {
      getGroupPermission(gid);
    }
    if (
      purchaseModalStatus === false &&
      detailModalStatus === false &&
      quanitityModalStatus === false
    ) {
      setCompany(BrandInit);
    }
    setCompanyOptionStatus(false);
    getProductBrandList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    purchaseModalStatus,
    detailModalStatus,
    quanitityModalStatus,
    searchItem,
    cookies,
    searchItem.orderDateStart,
    searchItem.orderDateEnd,
  ]);

  // Refreshing cookies mechanism
  useEffect(() => {
    if (getCookie("session") !== undefined) {
      // 按下登出按鈕 或是 Session 仍存在的情況下進行換頁。
      handleRefreshLoginCookies();
      var debugMode = true;
      if (debugMode) {
        console.log("Call loginCheck api- " + getCookie("session"));
      }
      Axios.post("/api/v0/loginCheck", {
        session: getCookie("session"),
      })
        .then(function (response) {
          if (response.data.IsLogin !== true) {
            handleExpiredCookies();
            history.replace("/backoffice/Login");
          }
        })
        .catch(function (error) {
          console.log("OPRouter", error);
        });
    } else {
      // Session 過期的情況下進行換頁。
      handleExpiredCookies();
      history.replace("/backoffice/Login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    purchaseModalStatus, //新增訂單時
    detailModalStatus, //編輯訂單時
    quanitityModalStatus, //加購時
    chargeModalStatus, //列帳時
    inputSingleAccountingDateModalStatus, //匯出單月 k1 報表時
    downloadK2ModalStatus, //匯出單月 k2 報表時
    downloadAvenueModalStatus, //匯出月份會計收入報表時
    downloadCostModalStatus, //匯出月份會計支出報表時
    selectTimeModalStatus, //將訂單列表匯出成 csv 時
  ]);

  async function getProductBrandList() {
    var postData = {};
    postData["OpName"] = cookies.op_name;
    Axios.post(getProductBrandListAPI, postData)
      .then(function (res) {
        if (res.data !== undefined) {
          setProductBrandList(res.data);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  async function getData(sn = 0, opName = "") {
    if (sn === null) sn = 0;
    else if (typeof sn === "string" || sn instanceof String) sn = parseInt(sn);

    var postData = {};

    postData.orderDateStart = searchItem.orderDateStart;
    postData.orderDateEnd = searchItem.orderDateEnd;
    postData.brandSn = sn;
    postData.OpName = opName;

    await Axios.post(orderListURL, postData)
      .then((response) => {
        setOrderList(
          response.data.dataList !== null ? response.data.dataList : []
        );
      })
      .catch((result) => {
        // alert("getData 後端異常");
        setOrderList([]);
      });
  }
  // const getData = (query) =>
  //   new Promise((resolve, reject) => {
  //     var postData = {};
  //     // console.log(query)
  //     // if (query) {
  //     //   setTableQuery(query)
  //     //   postData.perPage = query.pageSize;
  //     //   postData.page = query.page;
  //     //   postData.search = query.search;
  //     //   if (query.orderBy) {
  //     //     postData.orderBy = query.orderBy.field;
  //     //     postData.orderDirection = query.orderDirection;
  //     //   }
  //     // }

  //     postData.orderDateStart = searchItem.orderDateStart;
  //     postData.orderDateEnd = searchItem.orderDateEnd;
  //     console.log(postData);
  //     Axios.post(orderListURL, postData)
  //       .then((response) => {
  //         resolve({
  //           data: response.data.dataList !== null ? response.data.dataList : [],
  //           page: query ? query.page : 0,
  //           totalCount: response.data.dataCount,
  //         });
  //         // setAccountNum(response.data.accountNum);
  //         // setBillingAmount(response.data.billingAmount);
  //       })
  //       .catch((result) => {
  //         console.log(result);
  //         alert("後端異常");
  //         resolve({
  //           data: [],
  //           page: 0,
  //           totalCount: 0,
  //         });
  //       });
  //   });

  // const doBatchOrderMigrateToNCE = () => {
  //   var postData = {};
  //   postData.LoginSession = cookies.session;
  //   Axios.post(batchMigrateToNCEOrderURL, postData).then(function () {
  //     alert("Migration done");
  //   }).catch(function (err) {
  //     alert(err.data.message);
  //     console.log(err.data);
  //   });
  // };

  const checkGWSTokenValid = async () => {
    let valid = false;
    // 預先判斷是否具有合法 token
    try {
      await Axios.get("/google/hasTokenInDB")
        .then((response) => {
          if (response.status === 200) {
            valid = true;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            Axios.get("/google/btn/authorize")
              .then((response) => {
                valid = false;
                alert(
                  "目前沒有 GWS token ，稍後將會重新整理或前往登入頁面以更新 token 。"
                );
                window.location.href = response.data.url;
              })
              .catch((error) => {
                valid = false;
                alert(error);
              });
          }
        });
    } catch (err) {
      console.error(err);
      valid = false;
    }
    // console.log("valid: " + valid);
    return valid;
  };
  const getInitData = useCallback(() => {
    // 受理方式
    const getSourceTypeList = Axios.get(orderSourceTypeURL);

    // 訂單狀態
    const getOrderStatusList = Axios.get(orderStatusURL);

    Axios.all([getSourceTypeList, getOrderStatusList])
      .then(
        Axios.spread((sourceTypeResponse, orderStatusResponse) => {
          setOrderSourceTypeList(sourceTypeResponse.data.dataList);
          setOrderStatusList(orderStatusResponse.data.dataList);
          // console.log(orderStatusResponse.data.dataList);
        })
      )
      .catch((errors) => {
        alert("後端異常");
      });
  }, []);

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const handleSendMail = () => {
    let hnList = [];
    let brandSnList = [];
    for (let i = 0; i < chosenOrders.length; i++) {
      /*
        漲價通知信
        ┌Include：訂單狀態為「待退訂」、「退訂失敗」、「服務啟用(CSP出帳)」；訂單來源為「前台」、「後台」的訂單；廠牌為「微軟」。
        └Exclude：商品優惠代碼之訂單。
      */
      if (
        (chosenOrders[i].orderStatusSn === 2 ||
          chosenOrders[i].orderStatusSn === 6 ||
          chosenOrders[i].orderStatusSn === 7) &&
        (chosenOrders[i].orderSourceTypeSn === 1 ||
          chosenOrders[i].orderSourceTypeSn === 2) &&
        chosenOrders[i].productBrandSn === BrandMicrosoftSn &&
        chosenOrders[i].productDiscode === ""
      ) {
        hnList.push(chosenOrders[i].aaHnNo);
        brandSnList.push(chosenOrders[i].productBrandSn);
      } else {
        continue;
      }
    }

    console.log("系統過濾後，漲價通知信最後僅寄給：", hnList);

    Swal.fire({
      icon: "info",
      position: "center",
      width: "800px",
      title: "Are you sure?",
      html: "<p>是否確定寄漲價通知信給客戶們 ?</p>",
      showCancelButton: true,
      showConfirmButton: true,
      focusConfirm: true,
      confirmButtonColor: "#28a8ea",
      confirmButtonText: "確定",
      cancelButtonText: "取消",
      scrollbarPadding: true,
    }).then(function (result) {
      if (result.isConfirmed) {
        Axios.post(sendMailEventURL, {
          HnList: hnList,
          BrandSnList: brandSnList,
          OpName: cookies.op_name,
        })
          .then((res) => {
            Swal.fire("Success", "已成功寄出信件通知客戶。", "success");
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "原因" + error, "error");
          });
        clearSelection();
        setMailEvent(false);
        setSelectMode(false);
      } else {
        Swal.fire("Cancelled", "已取消。", "error");
      }
    });
  };

  const handleSearchValueChange = (text) => {
    setSearchValue(text);
  };

  const handleChangeSearchItem = (event) => {
    var { name, value } = event.target;
    setSearchItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  // onClick handle function
  const orderQuantity = (sn = 0, productBrand = "Microsoft") => {
    // 先判斷此筆 rowData.productBrand 為哪間公司的產品，並 set Company value
    if (productBrand === "Google") {
      setCompany(BrandGoogleSn);
      console.log("productBrand:" + BrandGoogleSn);
    } else if (productBrand === "Microsoft") {
      setCompany(BrandMicrosoftSn);
      console.log("productBrand:" + BrandGoogleSn);
    }
    setOrderSn(sn);
    openQuantityModal();
  };
  const orderPurchase = (aaHnNo = 0, productBrand = BrandMicrosoftSn) => {
    // 先判斷此筆 rowData.productBrand 為哪間公司的產品，並 set Company value
    switch (productBrand) {
      case BrandGoogleSn:
        setCompany(BrandGoogleSn);
        console.log("productBrand:" + BrandGoogleSn);
        break;
      case BrandMicrosoftSn:
        setCompany(BrandMicrosoftSn);
        console.log("productBrand:" + BrandMicrosoftSn);
        break;
      default:
        setCompany(productBrand);
        console.log("productBrand:" + productBrand);
        break;
    }
    setAaHnNo(aaHnNo);
    openPurchaseModal();
  };
  const orderEdit = (sn = 0, productBrand = BrandMicrosoftSn) => {
    // console.log(productBrand);
    // setOrderSn(sn);
    switch (productBrand) {
      case BrandGoogleSn:
        if (checkGWSTokenValid()) {
          console.log("Open DetailModal");
          setCompany(BrandGoogleSn);
          openDetailModal(sn, BrandGoogleSn);
        }
        break;
      case BrandMicrosoftSn:
        setCompany(BrandMicrosoftSn);
        openDetailModal(sn, BrandMicrosoftSn);
        break;
      default:
        setCompany(productBrand);
        openDetailModal(sn, productBrand);
        break;
    }
  };
  const orderDelete = async (sn = 0, rowData) => {
    console.log(rowData);
    if (
      rowData.orderStatusSn === orderStatusMSServiceActivated ||
      (rowData.orderStatusSn === orderStatusGWSServiceActivated &&
        rowData.googleCustomerId !== "" &&
        rowData.subscriptionId !== "")
    ) {
      if (window.confirm("是否確定要退訂此單?")) {
        switch (rowData.productBrandSn) {
          case BrandGoogleSn:
            var postData = {};
            postData.Sn = rowData.sn;
            postData.OrderStatusSn = orderStatusUnsubscribe;
            if (rowData.isGoogleExistingOrder === true) {
              await Axios.post(updateOrderStatus, postData)
                .then((response) => {
                  console.log(response);
                  alert(
                    "此單於 csp 系統之訂單狀態成功改成退訂, 請記得於 gws portal 上退訂該單!"
                  );
                  // window.location.reload();
                })
                .catch((error) => {
                  console.log(error);
                  alert("此單於 csp 系統之訂單狀態更改失敗");
                  // var postErr = showPostError("系統訂單", error);
                  // handleShowMessage(`${postErr}`, "error");
                  props.setKeepModalStatus(false);
                });
            } else {
              let customerId = rowData.googleCustomerId;
              let subscriptionId = rowData.googlesubscriptionId;
              var deleteRequest = {};
              deleteRequest["customerId"] = customerId;
              deleteRequest["subscriptionId"] = subscriptionId;
              deleteRequest["OrderSn"] = sn;
              if (checkGWSTokenValid()) {
                // GWS 中止訂閱
                await Axios.post(
                  `/api/v0/gws/customers/${customerId}/subscriptions/${subscriptionId}/delete`,
                  deleteRequest
                )
                  .then(async (res) => {
                    alert("GWS 訂單退訂成功 (1/2)");
                    // console.log(res);
                    // 修改order
                    await Axios.post(deleteOrderURL, { sn: sn })
                      .then((response) => {
                        alert("CSP 系統訂單退訂成功 (2/2)");
                        // console.log(response);
                        // window.location.reload();
                      })
                      .catch((err) => {
                        alert("GWS 訂單退訂成功但 CSP 系統訂單資訊更新失敗");
                        console.error(err);
                      });
                  })
                  .catch((err) => {
                    alert("GWS 訂單退訂失敗");
                    console.log(err);
                  });
              }
            }
            break;
          case BrandMicrosoftSn:
            await Axios.post(MSOrderSuspend, { sn: sn })
              .then(async (response) => {
                alert("MS 訂單退訂成功 (1/2)");
                alert(response.data.message);
                await Axios.post(deleteOrderURL, { sn: sn })
                  .then((response) => {
                    alert("CSP 系統訂單退訂成功 (2/2)");
                    console.log(response);
                    // window.location.reload();
                  })
                  .catch((err) => {
                    alert("微軟訂單退訂成功但 CSP 系統訂單資訊更新失敗");
                    // window.location.reload();
                    console.error(err);
                  });
              })
              .catch((error) => {
                if (error.response) {
                  // console.log(error.response.data.message);
                  if (error.response.status === 409) {
                    alert(
                      "MS 訂單退訂失敗, 訂單尚未準備完成, 請稍帶樹分鐘後重試, 謝謝!"
                    );
                  } else {
                    alert("MS 訂單退訂失敗\n" + error.response.data.message);
                  }
                  // console.log(error.response.status);
                  // console.log(typeof (error.response.status));
                  // console.log(error.response.headers);
                } else {
                  alert("MS 訂單退訂失敗, 系統會嘗試將狀態改為待退訂");
                }
                console.log(error);
                // window.location.reload();
              });
            break;
          case 0:
            alert("訂單廠牌異常, 廠牌為空!");
            // window.location.reload();
            break;
          default:
            var postDataForDelOrder = {};
            postDataForDelOrder["sn"] = sn;
            await Axios.post(deleteOrderURL, postDataForDelOrder)
              .then(function () {
                alert("訂單退訂成功!");
                // window.location.reload();
              })
              .catch(function (err) {
                alert("訂單退訂失敗!");
                if (err.response !== undefined) {
                  console.log(err.response.data);
                }
                // window.location.reload();
              });
            break;
        }
        getData(localStorage.getItem("filterCompany"), cookies.op_name);
      }
    } else {
      alert(
        "訂單狀態非「服務啟用」或「無訂單資料」。\n請先進行開通，否則無法退訂。"
      );
    }
  };
  const orderCharge = (sn = 0, brandSn) => {
    setOrderSn(sn);
    setCompany(brandSn);
    openChargeModal();
  };

  const openCompanyList = () => {
    setCompanyOptionStatus(true);
  };

  const closeCompanyList = () => {
    setCompanyOptionStatus(false);
  };
  const closeFilterCompanyList = () => {
    setFilterCompanyOptionStatus(false);
    setFilterCompanyCB(null);
  };
  const chooseCompany = (companyName) => {
    // aaHnNo = 0 表示新增一筆 order data
    // 而 productBrand 則根據選擇後丟進去 function orderPurchase 去 set company value.
    switch (companyName) {
      case BrandGoogleSn:
        if (checkGWSTokenValid()) {
          orderPurchase(0, BrandGoogleSn);
        }
        break;
      case BrandMicrosoftSn:
        orderPurchase(0, BrandMicrosoftSn);
        break;
      default:
        orderPurchase(0, companyName);
        break;
    }
  };

  const chooseFilterCompany = (brandSn) => {
    // getData(brandSn, cookies.op_name);
    // localStorage.setItem("filterCompany", brandSn);
    if (filterCompanyCB) {
      filterCompanyCB(brandSn);
    } else {
      console.log("filterCompanyCB is null");
    }
  };

  // permission design
  const getGroupPermission = (sn) => {
    var postData = {};
    postData["Sn"] = sn;
    Axios.post("/OP/group/getGroupData", postData)
      .then(function (response) {
        var res = response.data.data;
        for (var i in res) {
          if (res[i].name === "訂單管理") {
            setPermission(res[i].permission);
            break;
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  // order 列帳
  const openChargeModal = () => {
    setChargeModalStatus(true);
  };
  const closeChargeModal = () => {
    setChargeModalStatus(false);
  };

  // order 詳細資料
  /*
    進入狀況有：
      1. 使用者點擊主頁小鉛筆icon (從 orderEdit 進來)
      2. 新增表單填完後 Domain 還沒驗證成功 (直接回傳 openDetailModal 進來)
  */
  const openDetailModal = (sn, brandSn) => {
    setOrderSn(sn);
    setCompany(brandSn);
    setDetailModalStatus(true);
  };
  const closeDetailModal = () => {
    setDetailModalStatus(false);
    getData(localStorage.getItem("filterCompany"), cookies.op_name);
  };

  // order 新增表單、商品更改 - 加購
  const openPurchaseModal = () => {
    setPurchaseModalStatus(true);
  };
  const closePurchaseModal = () => {
    setPurchaseModalStatus(false);
    getData(localStorage.getItem("filterCompany"), cookies.op_name);
  };

  // order 商品更改 - 數量更改
  const openQuantityModal = () => {
    setQuantityModalStatus(true);
  };
  const closeQuantityModal = () => {
    setQuantityModalStatus(false);
    getData(localStorage.getItem("filterCompany"), cookies.op_name);
  };

  // Open time range query order list file download modal
  const openSelectTimeModal = () => {
    setSelectTimeModalStatus(true);
  };
  const closeSelectTimeModal = () => {
    setSelectTimeModalStatus(false);
  };
  function onConfirmRangeTimeQuery(brandSn) {
    if (selectedStartDate <= selectedEndDate) {
      // Fetch timezone
      var timeOffset = selectedStartDate.getTimezoneOffset() * 60000;

      // Adjust timezone
      var selectedStartDateWithTZ = new Date(
        selectedStartDate.getTime() - timeOffset
      );
      var selectedEndDateWithTZ = new Date(
        selectedEndDate.getTime() - timeOffset
      );

      // Fetch data within query range
      Axios.post("/api/order/getTimeRangeQueryOrder", {
        StartDate: selectedStartDateWithTZ
          .toISOString()
          .split(".")[0]
          .replace("T", " "),
        EndDate: selectedEndDateWithTZ
          .toISOString()
          .split(".")[0]
          .replace("T", " "),
        BrandSn: brandSn,
        // localStorage.getItem("filterCompany") !== null
        //   ? parseInt(localStorage.getItem("filterCompany"))
        //   : 0,
        OpName: cookies.op_name,
      })
        .then((res) => {
          // Store data fetched from backend
          var data = res.data["dataList"];

          console.log(data);

          // Create file name
          var fileName =
            "訂單清單_從_" +
            format(selectedStartDate, datetimeFormat) +
            "_至_" +
            format(selectedEndDate, datetimeFormat) +
            ".csv";

          var fileColumns = columns;
          fileColumns.push(
            {
              title: "推廣人",
              field: "promotionEmployeeNo",
            },
            {
              title: "公司統編",
              field: "companyNo",
            },
            {
              title: "商品價位",
              field: "priceSpecial",
            },
            {
              title: "商品折扣",
              field: "productDiscount",
            },
            {
              title: "受理來源",
              field: "orderSourceTypeSn",
              lookup: orderSourceTypeList,
            },
            {
              title: "營運處",
              field: "operationOffice",
            },
            {
              title: "客戶屬性",
              field: "customerType",
            },
            {
              title: "行業別",
              field: "industry",
            },
            {
              title: "Major服務人員姓名",
              field: "majorServicePersonnel",
            },
            {
              title: "聯絡人（姓）",
              field: "memberContactLastName",
            },
            {
              title: "聯絡人（名）",
              field: "memberContactName",
            },
            {
              title: "聯絡電話",
              field: "memberTel",
            },
            {
              title: "聯絡手機",
              field: "memberCell",
            },
            {
              title: "聯絡email",
              field: "memberEMail",
            },
            {
              title: "網域名稱",
              field: "domain",
            },
            {
              title: "客戶 ID",
              field: "customerId",
            },
            {
              title: "訂閱 ID",
              field: "subscriptionId",
            }
          );

          if (data != null && data.length !== 0) {
            // To prevent Excel automatically convert orderNo with pure digits to int
            // for (var i = 0; i < data.length; ++i) {
            //   data[i]["orderNo"] = data[i]["orderNo"];
            // }

            // Create file
            new CsvBuilder(fileName)
              .setColumns(fileColumns.map((columnDef) => columnDef.title))
              .addRows(
                data.map((rowData) =>
                  columns.map((columnDef) => rowData[columnDef.field])
                )
              )
              .exportFile();
          } else {
            // If there's no data created within queried time range
            new CsvBuilder(fileName)
              .setColumns(fileColumns.map((columnDef) => columnDef.title))
              .exportFile();
          }

          // // Close modal
          // closeSelectTimeModal();
        })
        .catch((error) => {
          alert(error);
          // closeSelectTimeModal();
        });
    } else {
      alert("起始時間不能在結束時間之後!");
    }
  }

  function setChangeBillingFileHandler(e) {
    var value = e.target.value;
    var filename = value.replace(/^.*[\\\\/]/, "");
    setBillingFileName(filename);
    setSelectedBillingFile(e.target.files[0]);
  }
  function setIntDateHandler(value, setFunction) {
    const intValue = parseInt(value);

    setFunction(intValue);
  }
  // function setChangeSingleAccYearHandler(e) {
  //   var value = e.target.value;
  //   setSingleAccYear(parseInt(value));
  // }
  // function setChangeSingleAccMonthHandler(e) {
  //   var value = e.target.value;
  //   setSingleAccMonth(parseInt(value));
  // }

  function onConfirmPostBillingFileToServer() {
    const formData = new FormData();
    formData.append(billingFileName, selectedBillingFile);
    Axios.post("/api/file/billingRecordFileUpload", formData, {
      "Content-Type": "multipart/form-data",
    })
      .then(function (res) {
        console.log(res);
        alert("對帳單上傳成功!");
        closeSelectUploadAccountingFileModal();
      })
      .catch(function (err) {
        alert("對帳單上傳失敗!");
        console.log(err);
        closeSelectUploadAccountingFileModal();
      });
  }

  // function updateCostFromMPN() {
  //   Axios.get("/api/v0/mpnGetAllInvoice").then(function (res) {
  //     alert("更新微軟 billing info 成功!");
  //     console.log(res);
  //   }).catch(function (err) {
  //     alert("更新微軟 billing info 失敗!");
  //     console.log(err);
  //   });
  // }

  // const openSelectUploadAccountingFileModal = () => {
  //   setSelectAccountingFileModalStatus(true);
  // };
  const closeSelectUploadAccountingFileModal = () => {
    setSelectAccountingFileModalStatus(false);
  };
  const openInputSingleAccountingDateModalStatus = () => {
    setYearList(createYearList());
    setInputSingleAccountingDateModalStatus(true);
  };
  const closeInputSingleAccountingDateModalStatus = () => {
    setInputSingleAccountingDateModalStatus(false);
  };
  const openDownloadK2ModalStatus = () => {
    setYearList(createYearList());
    setDownloadK2ModalStatus(true);
  };
  const closeDownloadK2ModalStatus = () => {
    setDownloadK2ModalStatus(false);
  };
  const openDownloadAvenueModalStatus = () => {
    setYearList(createYearList());
    setDownloadAvenueModalStatus(true);
  };
  const closeDownloadAvenueModalStatus = () => {
    setDownloadAvenueModalStatus(false);
  };
  const openDownloadCostModalStatus = () => {
    setYearList(createYearList());
    setDownloadCostModalStatus(true);
  };
  const closeDownloadCostModalStatus = () => {
    setDownloadCostModalStatus(false);
  };
  /*function onConfirmExportAccountingReport() {
    // Fetch data within query range
    Axios.post("/api/order/getOrderAccountingReports", {
      "queryAll": true
    }).then((res) => {
      // Store data fetched from backend
      var data = res.data;
  
      // Create file name
      var fileName = format(new Date(), datetimeFormat) + "_個別客戶出帳清單.csv";
  
      var fileColumns = accountingReportColumns;
  
      if (data != null && data.length !== 0) {
        // To prevent Excel automatically convert orderNo with pure digits to int
        // for (var i = 0; i < data.length; ++i) {
        //   data[i]["orderNo"] = data[i]["orderNo"];
        // }
  
        // Create file
        new CsvBuilder(fileName)
          .setColumns(fileColumns.map((columnDef) => columnDef.title))
          .addRows(
            data.map((rowData) =>
              fileColumns.map((columnDef) => rowData[columnDef.field])
            ),
          )
          .exportFile();
      } else {
        // If there's no data created within queried time range
        new CsvBuilder(fileName)
          .setColumns(fileColumns.map((columnDef) => columnDef.title))
          .exportFile();
      }
    }).catch((error) => {
      alert(error);
    });
  }*/

  function onConfirmExportSingleMonthAccountingReport(brandSn) {
    // Fetch data within query range
    Axios.post("/api/order/getSingleMonthAccountingReports", {
      BrandSn: brandSn,
      // localStorage.getItem("filterCompany") !== null
      //   ? parseInt(localStorage.getItem("filterCompany"))
      //   : 0,
      QueryYear: singleAccYear,
      QueryMonth: singleAccMonth,
      OpName: cookies.op_name,
    })
      .then((res) => {
        // Store data fetched from backend
        var data = res.data;

        // Create file name
        var fileName = format(new Date(), datetimeFormat) + "_";
        fileName += singleAccYear.toString() + "年_";
        fileName +=
          singleAccMonth.toString() + "月_單月個別客戶出帳及成本資訊清單.csv";

        var fileColumns = accountingReportColumns;
        fileColumns.push(
          { title: "Total AAA charge", field: "totalCost" },
          { title: "Total For Customer", field: "totalForCustomer" },
          { title: "Currency", field: "currency" },
          {
            title: "銷售契約編號(K1)",
            field: "ContractNoK1",
          },
          // {
          //   title: "會計契約編號(K2)",
          //   field: "AccountingNoK2"
          // },
          {
            title: "BCRM績效子類別",
            field: "AchItemName",
          },
          {
            title: "BCRM績效類別",
            field: "AchTypeName",
          },
          {
            title: "契約執行人",
            field: "AppEmpName",
          },
          {
            title: "契約執行單位",
            field: "AppUnitName",
          },
          {
            title: "契約名稱",
            field: "CmName",
          },
          {
            title: "契約註冊日期",
            field: "CntDate",
          },
          {
            title: "契約起始日期",
            field: "StartDate",
          },
          {
            title: "契約結束日期",
            field: "EndDate",
          },
          {
            title: "公司/客戶名稱",
            field: "CoName",
          },
          {
            title: "非系統帳出帳",
            field: "HasManualBilling",
          },
          {
            title: "出帳項目",
            field: "TypeOfCharge",
          }
        );

        if (data != null && data.length !== 0) {
          // Create file
          new CsvBuilder(fileName)
            .setColumns(fileColumns.map((columnDef) => columnDef.title))
            .addRows(
              data.map((rowData) =>
                fileColumns.map((columnDef) => rowData[columnDef.field])
              )
            )
            .exportFile();
        } else {
          // If there's no data created within queried time range
          new CsvBuilder(fileName)
            .setColumns(fileColumns.map((columnDef) => columnDef.title))
            .exportFile();
        }
      })
      .catch((error) => {
        alert(error);
      });
    // setInputSingleAccountingDateModalStatus(false);
  }

  function onConfirmExportK2Report(brandSn) {
    // Calculate all the months between the start year and month and the end year and month
    let queryDate = [];
    for (let y = singleAccYear; y <= singleAccEndYear; y++) {
      if (y === singleAccYear && y !== singleAccEndYear) {
        for (let m = singleAccMonth; m <= 12; m++) {
          queryDate.push({ queryYear: y, queryMonth: m });
        }
      } else if (y === singleAccYear && y === singleAccEndYear) {
        for (let m = singleAccMonth; m <= singleAccEndMonth; m++) {
          queryDate.push({ queryYear: y, queryMonth: m });
        }
      } else if (y !== singleAccYear && y === singleAccEndYear) {
        for (let m = 1; m <= singleAccEndMonth; m++) {
          queryDate.push({ queryYear: y, queryMonth: m });
        }
      } else {
        for (let m = 1; m <= 12; m++) {
          queryDate.push({ queryYear: y, queryMonth: m });
        }
      }
    }
    // console.log("queryDate :", queryDate);
    if (queryDate.length > 6) {
      Swal.fire({
        icon: "info",
        position: "center",
        title: "請選擇半年內的日期區間",
        // text: err,
        scrollbarPadding: true,
        showConfirmButton: true,
        confirmButtonColor: "#28a8ea",
      }).then(() => {
        setDownloadK2ModalStatus(true);
      });

      return;
    }

    // parallel fetch getOrdersWithK2CodeReports with different date range
    const fetchPromises = queryDate.map((item) => {
      return Axios.post("/api/order/getOrdersWithK2CodeReports", {
        BrandSn: brandSn,
        QueryYear: item.queryYear,
        QueryMonth: item.queryMonth,
        OpName: cookies.op_name,
      });
    });
    // concat fetchPromises result
    Axios.all(fetchPromises)
      .then((res) => {
        // Store data fetched from backend
        var data = res.map((item) => item.data).flat();

        // // Fetch data within query range
        // Axios.post("/api/order/getOrdersWithK2CodeReports", {
        //   BrandSn: brandSn,
        //   // localStorage.getItem("filterCompany") !== null
        //   //   ? parseInt(localStorage.getItem("filterCompany"))
        //   //   : 0,
        //   QueryYear: singleAccYear,
        //   QueryMonth: singleAccMonth,
        //   OpName: cookies.op_name,
        // })
        // .then((res) => {
        //   // Store data fetched from backend
        //   var data = res.data;

        // Create file name
        // var fileName = format(new Date(), datetimeFormat) + "_";
        // fileName += singleAccYear.toString() + "年_";
        // fileName +=
        //   singleAccMonth.toString() + "月_單月個別客戶訂單及K2碼資訊清單.csv";

        const fileName = `${format(
          new Date(),
          datetimeFormat
        )}_${singleAccYear.toString()}年${singleAccMonth.toString()}月_${singleAccEndYear.toString()}年${singleAccEndMonth.toString()}月_單月個別客戶訂單及K2碼資訊清單.csv`;

        var fileColumns = accountingReportColumns;
        fileColumns.push(
          {
            title: "產品名稱",
            field: "productName",
          },
          {
            title: "銷售契約編號(K1)",
            field: "ContractNoK1",
          },
          {
            title: "會計契約編號(K2)",
            field: "AccountingNoK2",
          },
          {
            title: "BCRM績效子類別",
            field: "AchItemName",
          },
          {
            title: "BCRM績效類別",
            field: "AchTypeName",
          },
          {
            title: "契約執行人",
            field: "AppEmpName",
          },
          {
            title: "契約執行單位",
            field: "AppUnitName",
          },
          {
            title: "契約名稱",
            field: "CmName",
          },
          {
            title: "契約註冊日期",
            field: "CntDate",
          },
          {
            title: "契約起始日期",
            field: "StartDate",
          },
          {
            title: "契約結束日期",
            field: "EndDate",
          },
          {
            title: "公司/客戶名稱",
            field: "CoName",
          },
          {
            title: "網域名稱",
            field: "Domain",
          },
          {
            title: "非系統帳出帳",
            field: "HasManualBilling",
          },
          {
            title: "CSP 出帳",
            field: "isCspBilling",
            render: (rowData) => {
              return rowData.isCspBilling ? "是" : "否";
            },
          },
          {
            title: "一次性出帳項目",
            field: "OneTimeChargeList",
          }
        );

        if (data != null && data.length !== 0) {
          // Create file
          new CsvBuilder(fileName)
            .setColumns(fileColumns.map((columnDef) => columnDef.title))
            .addRows(
              data.map((rowData) =>
                fileColumns.map((columnDef) => rowData[columnDef.field])
              )
            )
            .exportFile();
        } else {
          // If there's no data created within queried time range
          new CsvBuilder(fileName)
            .setColumns(fileColumns.map((columnDef) => columnDef.title))
            .exportFile();
        }
      })
      .catch((error) => {
        alert(error);
      });
    // closeDownloadK2ModalStatus(false);
  }

  function onConfirmExportSingleMonthAccountingAvenueReport() {
    // alert("下載中...需時數分鐘, 請稍候.");
    enqueueSnackbar("下載中...需時數分鐘, 請稍候.", {
      variant: "info",
      autoHideDuration: 2500,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });

    // Fetch data within query range
    Axios.post("/api/order/getSingleMonthAccountingAvenueReports", {
      BrandSn:
        localStorage.getItem("filterCompany") !== null
          ? parseInt(localStorage.getItem("filterCompany"))
          : 0,
      QueryYear: singleAccYear,
      QueryMonth: singleAccMonth,
      OpName: cookies.op_name,
    })
      .then((res) => {
        // Store data fetched from backend
        var data = res.data;

        // Create file name
        var fileName = format(new Date(), datetimeFormat) + "_";
        fileName += singleAccYear.toString() + "年_";
        fileName +=
          singleAccMonth.toString() + "月_單月公雲收入會計資訊清單.csv";

        var fileColumns = accountingAvenueReportsColumns;

        if (data != null && data.length !== 0) {
          // Create file
          new CsvBuilder(fileName)
            .setColumns(fileColumns.map((columnDef) => columnDef.title))
            .addRows(
              data.map((rowData) =>
                fileColumns.map((columnDef) => rowData[columnDef.field])
              )
            )
            .exportFile();
        } else {
          // If there's no data created within queried time range
          new CsvBuilder(fileName)
            .setColumns(fileColumns.map((columnDef) => columnDef.title))
            .exportFile();
        }
      })
      .catch((error) => {
        alert(error);
      });
    closeDownloadAvenueModalStatus(false);
  }

  function onConfirmExportSingleMonthAccountingCostReport() {
    alert("下載中...需時數分鐘, 請稍候.");
    // Fetch data within query range
    Axios.post("/api/order/getSingleMonthAccountingCostReports", {
      BrandSn:
        localStorage.getItem("filterCompany") !== null
          ? parseInt(localStorage.getItem("filterCompany"))
          : 0,
      QueryYear: singleAccYear,
      QueryMonth: singleAccMonth,
      OpName: cookies.op_name,
    })
      .then((res) => {
        // Store data fetched from backend
        var data = res.data;

        // Create file name
        var fileName = format(new Date(), datetimeFormat) + "_";
        fileName += singleAccYear.toString() + "年_";
        fileName +=
          singleAccMonth.toString() + "月_單月公雲支出會計資訊清單.csv";

        var fileColumns = accountingCostReportsColumns;

        if (data != null && data.length !== 0) {
          // Create file
          new CsvBuilder(fileName)
            .setColumns(fileColumns.map((columnDef) => columnDef.title))
            .addRows(
              data.map((rowData) =>
                fileColumns.map((columnDef) => rowData[columnDef.field])
              )
            )
            .exportFile();
        } else {
          // If there's no data created within queried time range
          new CsvBuilder(fileName)
            .setColumns(fileColumns.map((columnDef) => columnDef.title))
            .exportFile();
        }
      })
      .catch((error) => {
        alert(error);
      });

    closeDownloadCostModalStatus(false);
  }

  // function onConfirmExportMigrationFailedReport() {
  //   // Fetch data within query range
  //   Axios.get("/api/order/getAllMigrationErrorMsgs").then((res) => {
  //     // Store data fetched from backend
  //     var data = res.data;

  //     // Create file name
  //     var fileName = format(new Date(), datetimeFormat) + "_訂單遷移至NCE失敗紀錄.csv";

  //     var fileColumns = migrationFailedReportColumns;

  //     if (data != null && data.length !== 0) {
  //       // To prevent Excel automatically convert orderNo with pure digits to int
  //       // for (var i = 0; i < data.length; ++i) {
  //       //   data[i]["orderNo"] = data[i]["orderNo"];
  //       // }

  //       // Create file
  //       new CsvBuilder(fileName)
  //         .setColumns(fileColumns.map((columnDef) => columnDef.title))
  //         .addRows(
  //           data.map((rowData) =>
  //             fileColumns.map((columnDef) => rowData[columnDef.field])
  //           ),
  //         )
  //         .exportFile();
  //     } else {
  //       // If there's no data created within queried time range
  //       new CsvBuilder(fileName)
  //         .setColumns(fileColumns.map((columnDef) => columnDef.title))
  //         .exportFile();
  //     }
  //   }).catch((error) => {
  //     alert(error);
  //   });
  // }

  function datetimeStringFormat(time) {
    // time is string
    if (time === "" || time === null) {
      return "";
    } else {
      return format(new Date(time), datetimeFormat);
    }
  }

  // Render DOM function
  const renderPurchaseModel = (value) => {
    switch (value) {
      case BrandMicrosoftSn:
        return (
          <PurchaseModelOfMicrosoft
            open={purchaseModalStatus}
            closeModal={closePurchaseModal}
            openDetailModal={openDetailModal}
            aaHnNo={aaHnNo}
            brandSn={BrandMicrosoftSn}
          />
        );
      case BrandGoogleSn:
        return (
          <PurchaseModelOfGoogle
            open={purchaseModalStatus}
            closeModal={closePurchaseModal}
            // onClose={closeModalCheckK1}
            openDetailModal={openDetailModal}
            aaHnNo={aaHnNo}
            brandSn={BrandGoogleSn}
          />
        );
      case 0:
        return <></>;
      default:
        return (
          <PurchaseModel
            open={purchaseModalStatus}
            closeModal={closePurchaseModal}
            // onClose={closeModalCheckK1}
            openDetailModal={openDetailModal}
            aaHnNo={aaHnNo}
            brandSn={value}
          />
        );
    }
  };

  // const orderDelete = (sn = 0) => {
  //   //alert("請確定微軟網站已退訂");
  //   if (window.confirm("是否確定要退訂此單?")) {
  //     Axios.post(MSOrderSuspend, { sn: sn })
  //       .then((response) => {
  //         alert(response.data.message);
  //         // Axios.post(deleteOrderURL, { sn: sn })
  //         //   .then((response) => {
  //         //     alert("微軟訂單退訂成功");
  //         //     window.location.reload();
  //         //   })
  //         //   .catch((err) => {
  //         //     alert("微軟訂單退訂成功但 CSP 系統訂單資訊更新失敗");
  //         //     console.error(err);
  //         //   });
  //       })
  //       .catch((err) => {
  //         alert("微軟訂單退訂失敗");
  //       });
  //   }
  // }

  const renderDetailModel = (value) => {
    // console.log(value);
    switch (value) {
      case BrandMicrosoftSn:
        return (
          <DetailModalOfMicrosoft
            open={detailModalStatus}
            closeModal={closeDetailModal}
            orderStatusList={orderStatusList}
            sn={orderSn}
          />
        );
      case BrandGoogleSn:
        return (
          <DetailModalOfGoogle
            open={detailModalStatus}
            closeModal={closeDetailModal}
            orderStatusList={orderStatusList}
            sn={orderSn}
          />
        );
      case 0:
        return <></>;
      default:
        return (
          <DetailModal
            open={detailModalStatus}
            closeModal={closeDetailModal}
            orderStatusList={orderStatusList}
            brand={value}
            sn={orderSn}
          />
        );
    }
  };
  // const orderCharge = (sn = 0) => {
  //   setOrderSn(sn);
  //   openChargeModal();
  // };

  var classes = useStyles();
  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.container}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: "center",
                minHeight: "100vh",
              }}
            >
              <Grid
                item
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 200,
                }}
              >
                <Typography style={{ fontSize: 100 }}>您無此權限</Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }
  // const orderCharge = (sn = 0) => {
  //   setOrderSn(sn);
  //   openChargeModal();
  // };

  function createYearList() {
    var yearList = [];
    for (var i = 1980; i <= 2040; ++i) {
      yearList.push({
        year: i,
        value: i,
      });
    }
    return yearList;
  }

  const monthList = [
    {
      month: "一月",
      value: 1,
    },
    {
      month: "二月",
      value: 2,
    },
    {
      month: "三月",
      value: 3,
    },
    {
      month: "四月",
      value: 4,
    },
    {
      month: "五月",
      value: 5,
    },
    {
      month: "六月",
      value: 6,
    },
    {
      month: "七月",
      value: 7,
    },
    {
      month: "八月",
      value: 8,
    },
    {
      month: "九月",
      value: 9,
    },
    {
      month: "十月",
      value: 10,
    },
    {
      month: "十一月",
      value: 11,
    },
    {
      month: "十二月",
      value: 12,
    },
  ];

  function SelectMonthModal(props) {
    return (
      <>
        {props !== undefined ? (
          <Dialog
            open={props.openModal}
            onClose={props.closeModal}
            maxWidth="lg"
            scroll="paper"
          >
            <Box>
              <DialogTitle>請填入欲下載之帳務資料月份</DialogTitle>
              <Grid
                style={{
                  marginTop: 15,
                  marginBottom: 5,
                }}
              >
                <Divider></Divider>
              </Grid>
              <Grid container alignItems="bottom" style={{ width: 800 }}>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontSize: 20,
                      marginLeft: 40,
                      marginTop: 20,
                    }}
                  >
                    西元年:
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <NativeSelect
                    style={{
                      fontSize: 20,
                      marginLeft: 40,
                      marginTop: 20,
                    }}
                    name="yearInfoSelect"
                    value={singleAccYear}
                    onChange={(e) =>
                      setIntDateHandler(e.target.value, setSingleAccYear)
                    }
                  >
                    <option value=""></option>
                    {yearList.map((year, index) => {
                      return (
                        <option key={index} value={year.value}>
                          {year.year}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontSize: 20,
                      marginLeft: 40,
                      marginTop: 20,
                    }}
                  >
                    月:
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <NativeSelect
                    style={{
                      fontSize: 20,
                      marginLeft: 40,
                      marginTop: 20,
                    }}
                    name="monthInfoSelect"
                    value={singleAccMonth}
                    onChange={(e) =>
                      setIntDateHandler(e.target.value, setSingleAccMonth)
                    }
                  >
                    <option value=""></option>
                    {monthList.map((monthInfo, index) => {
                      return (
                        <option key={index} value={monthInfo.value}>
                          {monthInfo.month}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </Grid>
              </Grid>
              {(props.hasEndDate ?? false) && (
                <Grid container alignItems="bottom" style={{ width: 800 }}>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontSize: 20,
                        marginLeft: 40,
                        marginTop: 20,
                      }}
                    >
                      到:
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <NativeSelect
                      style={{
                        fontSize: 20,
                        marginLeft: 40,
                        marginTop: 20,
                      }}
                      name="yearInfoSelect"
                      value={singleAccEndYear}
                      onChange={(e) =>
                        setIntDateHandler(e.target.value, setSingleAccEndYear)
                      }
                    >
                      <option value=""></option>
                      {yearList.map((year, index) => {
                        return (
                          <option key={index} value={year.value}>
                            {year.year}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontSize: 20,
                        marginLeft: 40,
                        marginTop: 20,
                      }}
                    >
                      月:
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <NativeSelect
                      style={{
                        fontSize: 20,
                        marginLeft: 40,
                        marginTop: 20,
                      }}
                      name="monthInfoSelect"
                      value={singleAccEndMonth}
                      onChange={(e) =>
                        setIntDateHandler(e.target.value, setSingleAccEndMonth)
                      }
                    >
                      <option value=""></option>
                      {monthList.map((monthInfo, index) => {
                        return (
                          <option key={index} value={monthInfo.value}>
                            {monthInfo.month}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </Grid>
                </Grid>
              )}
              {(props.hasEndDate ?? false) && (
                <Grid container alignItems="bottom" style={{ width: 800 }}>
                  <Grid item >
                    <Typography
                      style={{
                        fontSize: 20,
                        marginLeft: 40,
                        marginTop: 20,
                      }}
                    >
                      僅限下載 6 個月區間的資料
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid
                style={{
                  marginTop: 15,
                  marginBottom: 5,
                }}
              >
                <Divider></Divider>
              </Grid>
              <ButtonGroup
                style={{
                  float: "right",
                  padding: 10,
                }}
              >
                <Button onClick={props.downloadReport} color="primary">
                  確定並下載清單
                </Button>
                <Button onClick={props.closeModal} color="primary">
                  取消
                </Button>
              </ButtonGroup>
            </Box>
          </Dialog>
        ) : (
          <></>
        )}
      </>
    );
  }

  function whetherFilterCompany(doAfterFilteringCompany) {
    let brandSn = localStorage.getItem("filterCompany");
    if (brandSn) brandSn = parseInt(brandSn);

    if (brandSn === 0) {
      setFilterCompanyCB(() => doAfterFilteringCompany);
      setFilterCompanyOptionStatus(true);
    } else {
      doAfterFilteringCompany(brandSn);
    }
  }

  return (
    <Box m={2}>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12} md={2}>
            {"搜尋條件："}
          </Grid>
          <Grid item xs={4} md={1}>
            {"訂單起始日："}
          </Grid>
          <Grid item xs={8} md={4}>
            <TextField
              // fullWidth
              type="date"
              name={"orderDateStart"}
              value={searchItem.orderDateStart}
              onChange={handleChangeSearchItem}
            />
          </Grid>
          <Grid item xs={4} md={1}>
            {"訂單結束日："}
          </Grid>
          <Grid item xs={8} md={4}>
            <TextField
              // fullWidth
              type="date"
              name={"orderDateEnd"}
              value={searchItem.orderDateEnd}
              onChange={handleChangeSearchItem}
            />
          </Grid>
        </Grid>
      </Box>
      <MaterialTable
        tableRef={tableRef}
        title={selectMode === true ? "選取模式" : "訂單清單"}
        icons={tableIcons}
        columns={columns}
        // data={getData}
        actions={actions}
        data={orderList}
        onChangePage={handleChangePage}
        options={{
          searchText: searchValue,
          pageSize: 40,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 500],
          padding: "dense",
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#888",
            color: "#FFF",
            textAlign: "center",
          },
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
            textAlign: "center",
          }),
          // exportButton: true,
          // exportCsv: (columns, data) => {
          //   openSelectTimeModal();
          // },
          selection: selectMode === true ? true : false,
          showSelectAllCheckbox: true,
        }}
        onSelectionChange={(data) => setChosenOrders(data)}
        onSearchChange={handleSearchValueChange}
      />
      <ChargeModal
        open={chargeModalStatus}
        closeModal={closeChargeModal}
        sn={orderSn}
        brandSn={company}
      />

      {/* DetailModel */}
      {renderDetailModel(company)}

      <QuanitityModal
        open={quanitityModalStatus}
        closeModal={closeQuantityModal}
        sn={orderSn}
        brandSn={company}
      />

      <CompanyList
        open={companyOptionStatus}
        closeList={closeCompanyList}
        productBrandList={productBrandList}
        chooseCompany={chooseCompany}
      />

      <FilterCompanyList
        open={filterCompanyOptionStatus}
        closeList={closeFilterCompanyList}
        chooseFilterCompany={chooseFilterCompany}
        companyList={productBrandList}
      />

      {/* PurchaseModel */}
      {renderPurchaseModel(company)}

      <Dialog
        open={selectTimeModalStatus}
        onClose={closeSelectTimeModal}
        maxWidth="lg"
        scroll="paper"
      >
        <Box>
          <DialogTitle>選取時段區間資料</DialogTitle>
          <Grid
            style={{
              marginTop: 15,
              marginBottom: 5,
            }}
          >
            <Divider></Divider>
          </Grid>
          <Grid container style={{ width: 800 }}>
            <Grid item xs={2}>
              <Typography
                style={{
                  fontSize: 20,
                  marginLeft: 40,
                  marginTop: 20,
                }}
              >
                起始時間:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container style={{ width: 800 }}>
            <Grid item xs={2}>
              <Typography
                style={{
                  fontSize: 20,
                  marginLeft: 40,
                  marginTop: 20,
                }}
              >
                結束時間:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid
            style={{
              marginTop: 15,
              marginBottom: 5,
            }}
          >
            <Divider></Divider>
          </Grid>
          <ButtonGroup
            style={{
              float: "right",
              padding: 10,
            }}
          >
            <Button
              onClick={() => {
                closeSelectTimeModal();

                whetherFilterCompany(onConfirmRangeTimeQuery);
              }}
              color="primary"
            >
              下載訂單清單
            </Button>
            <Button onClick={closeSelectTimeModal} color="primary">
              取消
            </Button>
          </ButtonGroup>
        </Box>
      </Dialog>

      <Dialog
        open={selectAccountingFileModalStatus}
        onClose={closeSelectUploadAccountingFileModal}
        maxWidth="lg"
        scroll="paper"
      >
        <Box>
          <DialogTitle>選取欲上傳之帳務資料</DialogTitle>
          <Grid
            style={{
              marginTop: 15,
              marginBottom: 5,
            }}
          >
            <Divider></Divider>
          </Grid>
          <Grid container style={{ width: 800 }}>
            <Grid item xs={2}>
              <Typography
                style={{
                  fontSize: 20,
                  marginLeft: 40,
                  marginTop: 20,
                }}
              >
                檔案路徑:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                name="billingFile"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                onChange={setChangeBillingFileHandler}
              />
            </Grid>
          </Grid>
          <Grid
            style={{
              marginTop: 15,
              marginBottom: 5,
            }}
          >
            <Divider></Divider>
          </Grid>
          <ButtonGroup
            style={{
              float: "right",
              padding: 10,
            }}
          >
            <Button onClick={onConfirmPostBillingFileToServer} color="primary">
              確定並上傳清單
            </Button>
            <Button
              onClick={closeSelectUploadAccountingFileModal}
              color="primary"
            >
              取消
            </Button>
          </ButtonGroup>
        </Box>
      </Dialog>

      <SelectMonthModal
        openModal={inputSingleAccountingDateModalStatus}
        closeModal={closeInputSingleAccountingDateModalStatus}
        downloadReport={() => {
          closeInputSingleAccountingDateModalStatus();

          whetherFilterCompany(onConfirmExportSingleMonthAccountingReport);
        }}
      ></SelectMonthModal>

      <SelectMonthModal
        openModal={downloadK2ModalStatus}
        hasEndDate={true}
        closeModal={closeDownloadK2ModalStatus}
        downloadReport={() => {
          closeDownloadK2ModalStatus();

          whetherFilterCompany(onConfirmExportK2Report);
        }}
      ></SelectMonthModal>

      {/* 公雲收入報表 */}
      <SelectMonthModal
        openModal={downloadAvenueModalStatus}
        closeModal={closeDownloadAvenueModalStatus}
        downloadReport={onConfirmExportSingleMonthAccountingAvenueReport}
      ></SelectMonthModal>

      {/* 公雲支出報表 */}
      <SelectMonthModal
        openModal={downloadCostModalStatus}
        closeModal={closeDownloadCostModalStatus}
        downloadReport={onConfirmExportSingleMonthAccountingCostReport}
      ></SelectMonthModal>
    </Box>
  );
}
