import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "../assets/ProductOverview/Product_Safety_Check.png";
// import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Styled from "styled-components";
import SafeImage1 from "../assets/ProductOverview/Product_Safety1.png";
import SafeImage2 from "../assets/ProductOverview/Product_Safety2.png";
import SafeImage3 from "../assets/ProductOverview/Product_Safety3.png";
import SafeImage4 from "../assets/ProductOverview/Product_Safety4.png";

//CSS
const StyledGrid = Styled(Grid)`
  && {
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    transition: all 0.2s ease-in;
  }
`
const StyledAdvantageGrid = Styled(Grid)`
  && {
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 2px solid #ececfc;
    border-radius: 80px;
    cursor: pointer;
    margin-bottom: 60px;
    margin: 20px;
    transition: all 0.2s ease-in;
  }
  &:hover {
    background-color: #fffefe;
    box-shadow: 0px 3px 10px 3px rgba(51, 51, 51, 0.5);
    transform: translate(0px, -6px);
  }
`
const StyledSubGrid = Styled(Grid)`
  && {
    display: flex;
    text-align: center;
    justify-content: left;
    align-items: center;
  }
`
const StyledTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 700;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
    margin-bottom: 40px;
  }    
`
const StyledContextGrid = Styled(Grid)`
  display: grid;
  text-align: left;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: 500;
  font-family: Microsoft JhengHei;
  padding: 0 60px 0 60px;
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 600;
    font-family: Microsoft JhengHei;
    text-align: center;
    color: #28a8ea;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
    padding: 0 15px 0 15px;
  }
`
const StyledAdvantageImage = Styled.img`
  max-width: 100%;
  height: auto;
  margin: auto;
  padding-bottom: 15px;
`
const StyledCheckImage = Styled.img`
  max-width: 100%;
  height: 20px;
  margin: auto;
  margin-right: 10px;
`
const StyledContainer = Styled(Grid)`
  && {
    justify-content: center;
    margin-bottom: 60px;
  }
`

export default function SafetyAndPrivacy(props) {
  const handleSetOption = props.handleSetOption;
  // const goBuiltinSecurity = props.goBuiltinSecurity;
  // const goPrivacyDesign = props.goPrivacyDesign;
  // const goLaw = props.goLaw;
  // const goTransparentOperation = props.goTransparentOperation;

  // Dialog option
  const builtinSecurity = 0;
  const privacyDesign = 1;
  const law = 2;
  const transparentOperation = 3;

  function renderTitle() {
    const context = {
      title: "歡迎使用",
      context:
        "在 Office 365 的世界中，您的資料為您個人所有。 您是資料的主人， 握有完全的掌控權。 如果您決定停用服務，也能將資料一併帶走。 我們值得您持續信賴的核心價值在於："
    };
    return (
      <StyledGrid item sm={12}>
        <StyledTitle paragraph variant="h3">
          {context.title}
        </StyledTitle>
        <StyledContextGrid>{context.context}</StyledContextGrid>
      </StyledGrid>
    );
  }

  function renderContext() {
    const contexts = [
      {
        title: "內建安全性",
        context: [
          {
            subContext: "透過深度防禦達到服務等級的安全性"
          },
          {
            subContext: "服務中的客戶控制"
          },
          {
            subContext: "強化安全性及營運最佳實務作法"
          }
        ],
        option: builtinSecurity,
        img: SafeImage1
      },
      {
        title: "著重隱私權的設計",
        context: [
          {
            subContext: "您的資料不會被拿來作為廣告宣傳之用"
          },
          {
            subContext: "可以全面控制隱私權"
          },
          {
            subContext: "您可以隨時將資料帶走"
          }
        ],
        option: privacyDesign,
        img: SafeImage2
      },
      {
        title: "持續遵循法規",
        context: [
          {
            subContext: "以主動式程序達到您的法規遵循需求"
          },
          {
            subContext: "客戶控制組織的法規遵循"
          },
          {
            subContext: "獨立驗證以滿足日新月異的標準需求"
          }
        ],
        option: law,
        img: SafeImage3
      },
      {
        title: "透明化作業",
        context: [
          {
            subContext: "您知道資料所在的位置，及哪些人能存取資料"
          },
          {
            subContext: "掌握服務可用性及變動性"
          },
          {
            subContext: "有財務後盾並保障 99.9% 的正常運作時間"
          }
        ],
        option: transparentOperation,
        img: SafeImage4
      }
    ];

    return contexts.map(context => (
        <StyledAdvantageGrid item md={5} sm={8} onClick={() => handleSetOption(context.option)}>
          <StyledAdvantageImage src={context.img}></StyledAdvantageImage>
          <StyledContextSubTitle paragraph variant="h5">
            {context.title}
          </StyledContextSubTitle>
          {renderList(context.context)}
        </StyledAdvantageGrid>
    ));
  }

  function renderList(props) {
    const contexts = props;

    return contexts.map(context => (
      <StyledSubGrid>
        <StyledContextSubContent paragraph>
          <StyledCheckImage src={DoneIcon}/>
          {context.subContext}
        </StyledContextSubContent>
      </StyledSubGrid>
    ));
  }

  return (
    <div>
      <StyledContainer container spacing={6}>
        {renderTitle()}
        {renderContext()}
      </StyledContainer>
    </div>
  );
}
