import React from "react";

// Component
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";

// Icon
import BuildIcon from '@material-ui/icons/Build';

// Style CSS
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 500,
    //height: '100%'
  },
}));

export default function DownloadResource() {
  var classes = useStyles();
  return (
    <div>
      <Container className={classes.container}>
        <Grid
          spacing={1}
          direction="row"
          style={{
            textAlign: 'center',
            minHeight: '100vh'
          }}
        >
          <Grid item style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 200
          }}>
            <Typography style={{fontSize: 100}}>
              施工中...
              <BuildIcon style={{fontSize: 100}}></BuildIcon>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
