const BrandMicrosoftSn = 1;
const BrandGoogleSn = 2;

const serviceNamefilterOptions = {
  [BrandMicrosoftSn]: [
    "建置設定",
    "維運服務",
    "教育訓練",
    "專案客製",
    "授權切齊",
    "授權補報",
  ],
  [BrandGoogleSn]: [],
};

function getServiceNamefilterOptions(brandSn) {
  return serviceNamefilterOptions[brandSn] ?? [];
}

export default getServiceNamefilterOptions;
