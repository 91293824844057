import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Styled from 'styled-components';
import DoneIcon from "../assets/ProductOverview/Product_Safety_Check.png";

//CSS
const StyledContextSubGrid = Styled(Grid)`
  display: grid;
  text-align: left;
  justify-content: left;
  align-items: center;
  padding: 0 30px 0 30px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextContent = Styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #f50057;
    margin-bottom: 15px;
  }
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 700;
    font-family: Microsoft JhengHei;
    text-align: left;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContextGrid = Styled(Grid)`
  && {
    margin: 20px 0 40px 0;
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 30px 0 30px;
  }
`
const StyledDoneIcon = Styled.img`
  max-width: 20px;
  height: auto;
  margin-right: 10px;
`

export default function TransparentOperation(props) {
  function renderTitle() {
    const context = {
      title: "透明化作業",
      context:
        "邁向雲端服務，不代表您就失去了知的權利。 Office 365 讓您清楚掌控一切資訊。 我們採取透明化的經營態度，讓您得以監控服務狀態、追蹤問題，並且回顧可用性的歷史記錄。"
    };
    return (
      <StyledContextGrid container sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextContent paragraph>{context.context}</StyledContextContent>
      </StyledContextGrid>
    );
  }
  function renderList(props) {
    const contexts = props;

    return contexts.map(context => (
      <>
        <Typography paragraph>
          <StyledDoneIcon src={DoneIcon}/>
          {context.subContext}
        </Typography>
      </>
    ));
  }

  function renderContext() {
    const contexts = [
      {
        title: "資料位置和存取",
        context: [
          {
            subContext:
              "我們會在不同資料中心為您的資料儲存多個複本以提供備援，並讓您知道 這些資料在什麼地方。"
          },
          {
            subContext:
              "我們會告訴您 誰能存取您的資料 ，而且在什麼情況下存取您的資料。"
          }
        ]
      },
      {
        title: "真人支援",
        context: [
          {
            subContext: "我們提供全年無休的重大問題電話支援。"
          },
          {
            subContext:
              "我們有 DevOps 處理程序，亦即全年無休將問題呈報至實際負責的開發小組，以解決無法單由操作解決的問題。"
          }
        ]
      },
      {
        title: "我們對您負責",
        context: [
          {
            subContext:
              "無論服務事件的影響規模為何，我們都會對所有服務事件進行透徹的審查，如果貴組織受到影響，我們也會與您分享分析報告。"
          },
          {
            subContext:
              "我們致力提供有財務後盾且至少有 99.9% 時間正常運作的系統"
          }
        ]
      }
    ];

    return contexts.map(context => (
      <Grid container>
        <StyledContextSubGrid item sm={12}>
          <StyledContextSubTitle color="secondary" variant="h5">
            {context.title}
          </StyledContextSubTitle>
          <StyledContextDivider/>
          <StyledContextSubContent paragraph>
            {renderList(context.context)}
          </StyledContextSubContent>
        </StyledContextSubGrid>
      </Grid>
    ));
  }

  return (
    <div>
      <Grid container spacing={3}>
        {renderTitle()}
        {renderContext()}
      </Grid>
    </div>
  );
}
