import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  TextField,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";

const productStatusOnline = 2;
const orderSourceTypeBackend = 2;
const planTypeDsis = "企業上網月繳方案";

// Order status
const orderStatusMSServiceActivated = 2;
// 產品廠牌
// const BrandInit = 0;
// const BrandMicrosoftSn = 1;
// const BrandGoogleSn = 2;

const mdSize = 6;
const xsSize = 12;

const memberDefault = {
  aaHnNo: "",
  name: "",
  companyNo: "",
  address: "",
  zip: "",
  telMorning: "",
  email: "",
  contactLastName: "",
  contactName: "",
  cell: "",
};

const orderDefault = {
  orderDate: "",
  domain: "",
  quantity: "",
  promoEmpno: "",
  productDetailSn: "",
  operatorNote: "",
  orderSourceTypeSn: orderSourceTypeBackend, //FIXME
};

// API
const getMemberURL = "/api/order/getSingleHnDataList";
const getProductDetailListURL = "/api/product/detail/list";
const saveNewOrderURL = "/api/order/createNewOrder";
// const updateOrderStatusURL = "/api/order/updateOrderStatus";
const sendRegisterMailURL = "/api/mailLog/sendRegisterMail";

export default function PurchaseModel(props) {
  const [modalStatus, setModalStatus] = useState(false);
  const [member, setMember] = useState(memberDefault);
  const [order, setOrder] = useState(orderDefault);
  const [productDetailList, setProductDetailList] = useState([]);
  const [hnError, setHnError] = useState(false);
  // const [domainError, setDomainError] = useState(false);
  const [keepModal, setKeepModal] = useState(false);

  const closeModal = props.closeModal;
  const openDetailModal = props.openDetailModal;
  const onClose = props.closeModalCheckK1;

  const getProductDetailList = useCallback(async () => {
    var postData = {
      orderBy: "product",
      brandSn: props.brandSn,
    };
    Axios.post(getProductDetailListURL, postData).then((response) => {
      // console.log(response.data.productDetail);
      setProductDetailList(response.data.productDetail);
    });
  }, [props.brandSn]);

  useEffect(() => {
    if (props.open) {
      getProductDetailList();
      getMemberData(props.aaHnNo);
    }
    setModalStatus(props.open);
  }, [props.open, props.aaHnNo, getProductDetailList]);

  // Security Design
  const convertDataToHiddenCode = (val) => {
    let strLength = val.length;
    let data = "";
    if (strLength !== 0 && strLength !== undefined && strLength !== null) {
      for (let i = 0; i < strLength; i++) {
        data += "*";
      }
    }
    return data;
  };

  const getMemberData = (aaHnNo = 0) => {
    if (aaHnNo !== 0) {
      Axios.post(getMemberURL, { Hn: aaHnNo })
        .then((response) => {
          setHnError(false);
          // setOrder(orderDefault);
          setMember(response.data.dataList);
        })
        .catch((error) => {
          setHnError(true);
          console.error(error);
        });
    } else {
      setMember(memberDefault);
      setOrder(orderDefault);
    }
  };

  const handleChangeMember = (event) => {
    var { name, value } = event.target;
    const regexNum = /^[0-9\b]+$/;

    if (value === "" || regexNum.test(value)) {
      setMember((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleChangeOrder = (event) => {
    var { name, value, type } = event.target;

    // check domain
    const regexDomain = /^[A-Za-z0-9_.\-\b]+$/;
    if (name === "domain") {
      if (!regexDomain.test(value) && value !== "") {
        return;
      }
      if (value.length > 27) return;
    }

    if (type === "number") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
    } else if (type === "select-one") {
      value = parseInt(value);
    }
    setOrder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeOrderForAutocomplete = (name, value) => {
    let targetValue;

    if ((name = "productDetailSn")) {
      targetValue = parseInt(value.sn);
      targetValue = targetValue > 2147483647 ? 2147483647 : targetValue;
      targetValue = targetValue < 1 ? 1 : targetValue;
    }
    setOrder((prevState) => ({
      ...prevState,
      [name]: targetValue,
    }));
  };

  // const mpnCheckDomainByDomain = () => {
  //   if (order.domain !== "") {
  //     Axios.post("/api/v0/mpnCheckDomainByDomain", { domain: order.domain })
  //       .then((response) => {
  //         setDomainError(false);
  //       })
  //       .catch((error) => {
  //         setDomainError(true);
  //       });
  //   }
  // };

  const setKeepModalStatus = (isKeep) => {
    setKeepModal(isKeep);
  };

  const renderProductDetailOption = (option) => {
    let optionString = "";

    optionString = optionString + option.product;
    optionString =
      optionString + (option.plan !== "" ? " - " + option.plan : "");
    optionString =
      optionString + (option.discode !== "" ? " - " + option.discode : "");
    optionString =
      optionString +
      (option.price_special !== ""
        ? ", 售價: " + option.price_special + "元"
        : "");

    return optionString;
  };

  var orderDataList = [
    createLabel(
      "HN 帳號:",
      <Input
        fullWidth
        name={"aaHnNo"}
        value={member.aaHnNo}
        error={hnError}
        onChange={handleChangeMember}
        disabled={keepModal}
        autoComplete="off"
        required
        onBlur={() => getMemberData(member.aaHnNo)}
      />
    ),
    createLabel(
      "訂閱商品：",
      <Autocomplete
        noOptionsText={"（沒有任何訂閱商品可選擇）"}
        onChange={(event, value) =>
          handleChangeOrderForAutocomplete("productDetailSn", value)
        }
        disabled={keepModal}
        autoComplete={true}
        disableClearable={true}
        disablePortal={true}
        options={productDetailList}
        getOptionDisabled={(option) =>
          !(
            option.product_reviewed &&
            option.product_status === productStatusOnline &&
            // option.product_msNCEOfferId !== "" &&
            !option.plan.includes(planTypeDsis)
          )
        }
        getOptionLabel={(option) => renderProductDetailOption(option)}
        // renderOption={(props, option) => (
        //   <TextField {...props} name={"productDetailSn"} label={renderProductDetailOption(option)} value={option.sn} />
        // )}
        // getOptionDisabled=
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="（支援文字輸入搜尋）"
            fullWidth
          // inputProps={{
          //   ...params.inputProps,
          //   autoComplete: 'new-password', // disable autocomplete and autofill
          // }}
          />
        )}
      ></Autocomplete>
    ),
    createLabel(
      "使用網域：",
      <Input
        fullWidth
        name={"domain"}
        value={order.domain}
        // error={domainError}
        onChange={handleChangeOrder}
        // endAdornment={".onmicrosoft.com"}
        disabled={keepModal}
        autoComplete="off"
      // onBlur={() => mpnCheckDomainByDomain()}
      />
    ),
    createLabel(
      "訂閱帳號數量：",
      <Input
        fullWidth
        name={"quantity"}
        type="number"
        value={order.quantity}
        onChange={handleChangeOrder}
        disabled={keepModal}
        autoComplete="off"
        endAdornment={"筆"}
      />
    ),
    createLabel(
      "推廣員工代號：",
      <Input
        fullWidth
        name={"promoEmpno"}
        value={order.promoEmpno}
        disabled={keepModal}
        autoComplete="off"
        onChange={handleChangeOrder}
      />
    ),
    createLabel(
      "契約編號 k1 碼：",
      <Input
        fullWidth
        name={"contractNoK1"}
        value={order.contractNoK1}
        disabled={keepModal}
        autoComplete="off"
        onChange={handleChangeOrder}
      />
    ),
  ];

  var memberDataList = [
    createLabel("公司/客戶名稱:", member.name),
    createLabel("統編/證號:", convertDataToHiddenCode(member.companyNo)),
    createLabel("帳單地址:", convertDataToHiddenCode(member.address)),
    createLabel("郵遞區號:", convertDataToHiddenCode(member.zip)),
    createLabel("聯絡電話:", convertDataToHiddenCode(member.telMorning)),
    createLabel("聯絡電話(手機):", convertDataToHiddenCode(member.cell)),
    createLabel("聯絡人（姓）:", member.contactLastName),
    createLabel("聯絡人（名）:", member.contactName),
    createLabel("聯絡email:", member.email),
  ];

  function createLabel(title, data) {
    return (
      <Grid container>
        <Grid item xs={4}>
          <Box m={1}>{title}</Box>
        </Grid>
        <Grid item xs={8}>
          <Box m={1}>{data}</Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog
      open={modalStatus}
      // onClose={closeModal}
      onClose={() => onClose(order.contractNoK1 === "")}
      maxWidth="lg"
      scroll="paper"
    >
      <Box>
        <form>
          <Box m={2}>
            <Typography variant="h6">
              訂購資料
              {/* <IconButton
                  aria-label="toggle personal data visibility"
                  onClick={handleClickShowAllMemberData}
                  onMouseDown={handleMouseDownAllMemberData}
                  edge="end"
                >
                  {showAllMemberData ? <VisibilityOff /> : <Visibility />}
              </IconButton> */}
            </Typography>
            <DialogContent dividers>
              <Grid container>
                {orderDataList.map((data, index) => {
                  return (
                    <Grid item xs={xsSize} md={mdSize} key={index}>
                      {data}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container>
                {memberDataList.map((data, index) => {
                  return (
                    <Grid item xs={xsSize} md={mdSize} key={index}>
                      {data}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Box m={1}>{"備註:"}</Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box m={1}>
                        <TextField
                          fullWidth
                          multiline
                          rows={5}
                          variant="outlined"
                          name="operatorNote"
                          autoComplete="off"
                          onChange={handleChangeOrder}
                          value={order.operatorNote}
                          disabled={keepModal}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SnackbarProvider maxSnack={1}>
                <DialogButtons
                  hn={member.aaHnNo}
                  order={order}
                  closeModal={() => closeModal(order.contractNoK1 === "")}
                  isK1Missing={order.contractNoK1 === ""}
                  openDetailModal={openDetailModal}
                  keepModal={keepModal}
                  setKeepModalStatus={setKeepModalStatus}
                  brandSn={props.brandSn}
                  hnError={hnError}
                />
              </SnackbarProvider>
            </DialogActions>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

function DialogButtons(props) {
  const { enqueueSnackbar } = useSnackbar();

  const handleShowMessage = (
    message,
    variant: VariantType,
    isMoment = true
  ) => {
    var autoHideDuration = 5000;
    if (!isMoment) {
      autoHideDuration = 500000;
    }
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: autoHideDuration,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  // const mpnCheckDomainByDomain = () => {
  //   var message = "(MS)檢查網域";
  //   handleShowMessage(`${message}...`, "info", false);

  //   if (props.order.domain !== "") {
  //     Axios.post("/api/v0/mpnCheckDomainByDomain", {
  //       domain: props.order.domain,
  //     })
  //       .then((response) => {
  //         handleShowMessage(`${message}---Success`, "success");
  //       })
  //       .catch((error) => {
  //         var postErr = showPostError(message, error);
  //         handleShowMessage(`${postErr}`, "error");
  //       });
  //   } else {
  //     handleShowMessage(`網域此欄位不能空白`, "error");
  //   }
  // };

  const showPostError = (message, error) => {
    var errMessage = "";
    if (error.response) {
      if (error.response.data) {
        errMessage = `${error.response.data.message}`;
      } else {
        errMessage = `${error.response}`;
      }
    } else if (error.request) {
      errMessage = `連線異常`;
    } else {
      // 在設定 request 時出錯會進入此
      errMessage = `error`;
      console.error(error);
    }
    return `${message}---${errMessage}`;
  };

  async function saveOrderTest() {
    missingK1Warning();
    // Order Form 防呆判斷
    let message = "";
    if (props.hn === "" || props.hnError) {
      message = message + "【HN 帳號】\n";
    }
    if (
      props.order.productDetailSn === "" ||
      isNaN(props.order.productDetailSn)
    ) {
      message = message + "【訂閱商品】\n";
    }
    if (props.order.domain === "") {
      message = message + "【使用網域】\n";
    }
    if (props.order.quantity === "" || props.order.quantity === 0) {
      message = message + "【訂閱量帳號數量】\n";
    }
    if (message !== "") {
      alert("以下內容未填入或有誤：\n" + message);
      return false;
    }

    // 是否確定要儲存資料
    if (!window.confirm("確定儲存資料？")) {
      return;
    }
    props.setKeepModalStatus(true);
    // var orderSn = 0;
    // Save Order
    var newOrder = props.order;
    newOrder["OrderStartDate"] = new Date().toISOString();
    try {
      await Axios.post(saveNewOrderURL, {
        aaHnNo: props.hn,
        order: newOrder,
        orderStatusSn: orderStatusMSServiceActivated,
      }).then(function (responseData) {
        // orderSn = responseData.data.orderSn;
        handleShowMessage("系統訂單已儲存，開通帳號中...", "info", false);
        // var postData = {};
        // postData["sn"] = order.sn;
        // postData["orderStatusSn"] = orderStatusMSServiceActivated;
        // Axios.post(updateOrderStatusURL, postData).then(function (res) {
        //   handleShowMessage("系統訂單已儲存，開通成功!", "info", false);
        // }).catch(function (error) {
        //   // handleShowMessage("系統訂單已儲存，開通帳號異常, 請在訂單資訊中再次確認...", "info", false);
        //   var message = "系統訂單已儲存，開通帳號異常, 請在訂單資訊中再次確認...";
        //   var postErr = showPostError(message, error);
        //   // handleShowMessage(`${postErr}`, "error");
        //   stateChange(props.order.sn, postErr);
        // });
        // stateChange(props.order.sn, "訂單開通成功!")
        // Send Register Mail
        if (responseData !== undefined && responseData.data !== undefined) {
          try {
            // 寄送開通信
            message = "寄送開通信";
            handleShowMessage(`${message}...`, "info", false);
            Axios.post(sendRegisterMailURL, { orderSn: responseData.data.orderSn });
            handleShowMessage(`${message}---Success`, "success");
          } catch (error) {
            var postErr = showPostError(message, error);
            handleShowMessage(`${postErr}`, "error");
            props.setKeepModalStatus(false);
          }
        }
      });
    } catch (error) {
      alert("系統訂單儲存失敗");
      props.setKeepModalStatus(false);
      return;
    }

    // Activate Microsoft Order
    // try {
    //   message = "(MS)檢查網域";
    //   handleShowMessage(`${message}...`, "info", false);
    //   await Axios.post("/api/v0/mpnCheckDomain", { sn: orderSn });
    //   handleShowMessage(`${message}---Success`, "success");

    //   message = "(MS)建立客戶";
    //   handleShowMessage(`${message}...`, "info", false);
    //   await Axios.post("/api/v0/mpnCreateCustomer", { sn: orderSn });
    //   handleShowMessage(`${message}---Success`, "success");

    //   message = "(MS)Agree Agreements";
    //   handleShowMessage(`${message}...`, "info", false);
    //   await Axios.post("/api/v0/mpnAgreeAgreements", { sn: orderSn });
    //   handleShowMessage(`${message}---Success`, "success");

    //   message = "(MS)建立訂單";
    //   handleShowMessage(`${message}...`, "info", false);
    //   await Axios.post("/api/v0/mpnCreateOrder", { sn: orderSn });
    //   handleShowMessage(`${message}---Success`, "success");

    //   message = "寄送微軟開通信";
    //   handleShowMessage(`${message}...`, "info", false);
    //   await Axios.post(sendRegisterMailURL, { orderSn: orderSn });
    //   handleShowMessage(`${message}---Success`, "success");

    //   window.location.reload();
    // } catch (error) {
    //   var postErr = showPostError(message, error);
    //   // handleShowMessage(`${postErr}`, "error");
    //   stateChange(orderSn, postErr);
    // }

    // window.location.reload();
    props.setKeepModalStatus(false);
    props.closeModal()
  }

  // const showPostError = (message, error) => {
  //   var errMessage = "";
  //   if (error.response) {
  //     if (error.response.data) {
  //       errMessage = `${error.response.data.message}`;
  //     } else {
  //       errMessage = `${error.response}`;
  //     }
  //   } else if (error.request) {
  //     errMessage = `連線異常`;
  //   } else {
  //     // 在設定 request 時出錯會進入此
  //     errMessage = `error`;
  //     console.error(error);
  //   }
  //   return `${message}---${errMessage}`;
  // };

  // function stateChange(orderSn, errMessage) {
  //   console.log("props.brandSn:" + props.brandSn);
  //   if (window.confirm(`${errMessage}\n\n系統是否需要自動轉至編輯頁面`)) {
  //     props.closeModal(props.order.contractNoK1 === "");
  //     props.openDetailModal(orderSn, props.brandSn);
  //   };
  // }

  function missingK1Warning() {
    if (props.isK1Missing) {
      alert("K1 碼尚未填入, 重要合約資料, 請確認是否需填寫!");
    }
  }

  return (
    <React.Fragment>
      {/* <Button
        onClick={mpnCheckDomainByDomain}
        color="primary"
        disabled={props.keepModal}
      >
        檢查網域
      </Button> */}
      <Button
        onClick={saveOrderTest}
        color="primary"
        disabled={props.keepModal}
      >
        確定並開通
      </Button>
      <Button
        onClick={props.closeModal}
        color="primary"
        disabled={props.keepModal}
      >
        離開
      </Button>
    </React.Fragment>
  );
}
