import React, { useState, useEffect } from "react";
import { 
  Dialog, 
  DialogContent, 
  TextField, 
  Button, 
  ButtonGroup, 
  DialogActions, 
  Typography
 } from "@material-ui/core";
// notification
// import { SnackbarProvider } from "notistack";
import { useCookies } from "react-cookie";
import { format } from "date-fns";
import MaterialTable from "material-table";
import Axios from "axios";
import Styled from "styled-components"
import Swal from "sweetalert2";

// CSS 
const StyledTitle = Styled(Typography)`
  && {
    text-align: center;
    font-weight: 700;
    font-family: Microsoft JhengHei;
    margin-bottom: 5px;
    letter-spacing: 0.1em;
    font-style: italic;
    line-height: 1.5;
    word-break: keep-all;
    margin: 28px 0px;
    color: #424242;
  }
`
const StyledTextField = Styled(TextField)`
  && {
    width: 80px;
  }
  & .MuiInputBase-input {
    text-align: center;
  }
`
const StyledSaveBtn = Styled(Button)`
  && {
    background-color: #28a8ea;
    color: #fff;
    border-radius: 10px;
    font-size: 1rem;
    padding: 10px;
  }
  &&:hover {
    background-color: #1794d5;
  }
`
const StyledCancelBtn = Styled(Button)`
  && {
    background-color: #f75c5c;
    color: #fff;
    border-radius: 10px;
    font-size: 1rem;
  }
  &&:hover {
    background-color: #d54b4b;
  }
`

const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
// const getOrderListBySessionURL = "/api/order/getOrderListBySession";
const updateOrderQuantityByCustomerURL = "/api/order/updateOrderQuantityByCustomer";
const orderDomain = ".onmicrosoft.com";

export default function RecordModal(props) {
  const [cookies] = useCookies();
  const [modalStatus, setModalStatus] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const closeModal = props.closeModal;

  useEffect(() => {
    if(props.orderList.length !== 0){
      console.log(props.orderList);
      setOrderList(props.orderList);
    }
    if (props.open) {
      // check seesion ID
      if (cookies.sessionId && cookies.sessionId !== "") {
        setModalStatus(props.open);
      } else {
        AAALogin();
        console.error("No Session Id");
      }
    } else setModalStatus(props.open);
  }, [props.open, cookies.sessionId, props.orderList]);

  const AAALogin = () => {
    Axios.post("/AAA/login", { redirectUrl: "/OrderStatus" }).then((res) => {
      const data = res.data;
      //console.log(data["url"]);
      window.location.assign(data["url"]);
      // AAAresopnse();
    });
  };

  function saveSubscriptionNum() {
    let orderListArr = [...orderList];
    var postData = {};
    postData["orderList"] = [];
    for (var index in orderListArr) {
      let newSubsNum = {};
      newSubsNum["orderNo"] = orderListArr[index].orderNo;
      newSubsNum["orderQuantity"] = orderListArr[index].orderQuantity;
      postData["orderList"].push(newSubsNum);
    }
    console.log(postData);
    Axios.post(updateOrderQuantityByCustomerURL, postData)
    .then((response) => {
      closeModal();
      Swal.fire({
        position: 'center',
        title: '帳號數量變更成功',
        text: '系統會暫時儲存您的更改資訊，將於下個月繳費後才進行變更。',
        icon: 'success',
        scrollbarPadding: true,
        showConfirmButton: true,
        confirmButtonColor:'#28a8ea'
      });
      console.log(response);
    }).catch((err) => {
      closeModal();
      Swal.fire({
        position: 'center',
        title: '帳號數量變更失敗',
        text: err,
        icon: 'error',
        scrollbarPadding: true,
        showConfirmButton: true,
        confirmButtonColor:'#28a8ea'
      });
    });
  }

  const updateFieldChanged = (e) => {//index => e => {
    var { name, value, id } = e.target;

    // check number format
    const regex = /^\d+$/;
    if (name === "orderQuantity") {
      if (!regex.test(value) && value !== "") {
        return;
      }
    }

    var index = parseInt(id);
    value = parseInt(value);
    value = isNaN(value) ? 1 : value;
    value = value > 2147483647 ? 2147483647 : value;
    value = value < 1 ? 1 : value;

    let newArr = [...orderList];
    let entry = newArr[index];
    entry[name] = value;
    newArr[index] = entry;

    setOrderList(newArr);
  }

  function orderTable(orderList) {
    const columns = [
      {
        title: "訂單時間",
        field: "orderDate",
        sorting: false,
        render: (rowData) => format(new Date(rowData.orderDate), datetimeFormat),
      },
      { title: "商品名稱", field: "productName" },
      { title: "訂單編號", field: "orderNo" },
      {
        title: "帳號數量", field: "orderQuantity", render: (rowData) => {
          console.log(rowData.orderQuantity);
          return rowData.orderActivated === false ? (
            <Typography 
              variant="subtitle1" 
              gutterBottom 
              component="div"
            >
              {rowData.orderQuantity.toString()}
            </Typography>
            // <StyledTextField
            //   type="number"
            //   id={rowData.tableData.id.toString()}
            //   name={"orderQuantity"}
            //   value={rowData.orderQuantity.toString()}
            //   variant="outlined"
            //   size="small"
            //   fullWidth
            //   disabled
            // />
          ) : (
            <StyledTextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              id={rowData.tableData.id.toString()}
              name={"orderQuantity"}
              value={rowData.orderQuantity.toString()}
              variant="outlined"
              size="small"
              fullWidth 
              onChange={updateFieldChanged}
            />
          );
        }
      },
      { title: "訂單狀態", field: "orderStatusName", render: (rowData) => { return rowData.orderActivated ? "啟用" : "未啟用"; } },
      { title: "訂單金額", field: "priceSpecial" },
      {
        title: "啟用時間",
        field: "orderStartDate",
        render: (rowData) =>
          format(new Date(rowData.orderStartDate), datetimeFormat),
      },
      { title: "訂單金額", field: "orderChargeType" },
      {
        title: "註冊網域",
        field: "domain",
        render: (rowData) => {
          if (rowData.domain) {
            return rowData.domain + orderDomain;
          }
        },
      },
    ];

    return (
      <MaterialTable
        // icons={tableIcons}
        columns={columns}
        data={orderList}
        options={{
          search: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          sorting: false,
          size: "medium",
          padding: "default",
          draggable: false,
          headerStyle:{ backgroundColor:'#28a8ea', color:'#fff', fontWeight:'600', textAlign:'center' },
          cellStyle:{ fontWeight:'500', textAlign:'center' },
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#eef9ff",
          }),
        }}
      />
    );
  }

  return (
    <Dialog
      open={modalStatus}
      onClose={closeModal}
      maxWidth="xl"
      scroll="paper"
      disableScrollLock={true}
    >
      <StyledTitle variant="h4">你的訂單</StyledTitle>
      <DialogContent>{orderTable(orderList)}</DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth>
          <StyledSaveBtn onClick={saveSubscriptionNum}>更改帳號數量</StyledSaveBtn>
          <StyledCancelBtn onClick={closeModal}>返回</StyledCancelBtn>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}

