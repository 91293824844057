import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Styled from 'styled-components';
import DoneIcon from "../assets/ProductOverview/Product_Safety_Check.png";

//CSS
const StyledContextSubGrid = Styled(Grid)`
  display: grid;
  text-align: left;
  justify-content: left;
  align-items: center;
  padding: 0 30px 0 30px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextContent = Styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #f50057;
    margin-bottom: 15px;
  }
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 700;
    font-family: Microsoft JhengHei;
    text-align: left;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContextGrid = Styled(Grid)`
  && {
    margin: 20px 0 40px 0;
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 30px 0 30px;
  }
`
const StyledDoneIcon = Styled.img`
  max-width: 20px;
  height: auto;
  margin-right: 10px;
`

export default function Law(props) {
  function renderTitle() {
    const context = {
      title: "持續遵循法規",
      context:
        "Office 365 是全球性的服務，而持續遵循法規是指我們致力於讓 Office 365 控制功能不斷進化，持續跟上您所屬產業和地區之標準及法規的變化。 由於各種法規經常會有相同或類似的控制要求，這方便 Microsoft 滿足新法規或組織/產業特定的需求。此外，Office 365 提供管理員和使用者控制功能，包括 eDiscovery、法務保存措施和資料外洩防護，協助您達到內部法規遵循要求。 不需要額外的內部部署基礎結構即可使用這些控制功能。"
    };
    return (
      <StyledContextGrid container sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextContent paragraph>{context.context}</StyledContextContent>
      </StyledContextGrid>
    );
  }
  function renderList(props) {
    const contexts = props;

    return contexts.map(context => (
      <>
        <Typography paragraph>
          <StyledDoneIcon src={DoneIcon}/>
          {context.subContext}
        </Typography>
      </>
    ));
  }

  function renderContext() {
    const contexts = [
      {
        title: "獨立驗證",
        context: [
          {
            subContext:
              "Microsoft 的服務已通過驗證，符合 ISO 27001、歐盟示範條款、HIPAA BAA 以及 FISMA 的要求。"
          },
          {
            subContext:
              "我們的資料處理合約詳述隱私權、安全性以及對於客戶資料的處理，有助您遵循地方法規。"
          }
        ]
      },
      {
        title: "主動遵循法規的作法",
        context: [
          {
            subContext:
              "Office 365 法規遵循架構下有超過 900 個控制項目，讓我們可以跟上不斷演化的產業標準腳步。"
          },
          {
            subContext:
              "我們會透過透明化報告，定期揭露我們所收到的 執法要求報告數。"
          },
          {
            subContext:
              "專業法規遵循小組持續追蹤標準和規範，開發通用控制項組合，讓產品小組可以將其組建到服務中。"
          }
        ]
      },
      {
        title: "客戶控制組織的法規遵循",
        context: [
          {
            subContext:
              "服務內建的法務保存措施和 eDiscovery 能協助您配合執法要求或調查，滿足您尋找、保存、分析和封裝電子內容 (常被稱為電子儲存資訊，簡稱 ESI) 的需求。 隱私權控制讓您設定組織中有誰能存取資料，以及能存取哪些資料。"
          },
          {
            subContext:
              "Office 365 的資料外洩防護透過深度內容分析，協助您辨識、監控及保護組織內的機密資料。"
          }
        ]
      }
    ];

    return contexts.map(context => (
      <Grid container>
        <StyledContextSubGrid item sm={12}>
          <StyledContextSubTitle color="secondary" variant="h5">
            {context.title}
          </StyledContextSubTitle>
          <StyledContextDivider/>
          <StyledContextSubContent paragraph>
            {renderList(context.context)}
          </StyledContextSubContent>
        </StyledContextSubGrid>
      </Grid>
    ));
  }

  return (
    <div>
      <Grid container spacing={3}>
        {renderTitle()}
        {renderContext()}
      </Grid>
    </div>
  );
}
