// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useMemo } from "react";
import { useHistory/*, Link*/ } from "react-router-dom";
import Axios from "axios";
import PropTypes from 'prop-types';
import { useCookies } from "react-cookie";

import {
  Container,
  TextField,
  Grid,
  Button,
  Typography,
  Divider,
  AppBar,
  Tabs,
  Tab,
  Box,
  Checkbox,
  Paper,
  InputAdornment,
  FormControlLabel
  //Dialog,
  //DialogTitle
} from "@material-ui/core";
import {
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';

import NoOTPLoginModal from "./NoOTPLoginModal";
import HiB2BModal from "./HiB2BModal";

// Style CSS
import Styled from "styled-components"

// CSS 
const StyledGrid = Styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-item: center;
    margin-top: 15px;
  }
`
const StyledGridTitle = Styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-item: center;
  }
`
const StyledTitle = Styled(Typography)`
  && {
    font-weight: 800;
    letter-spacing: 0.04em;
    font-family: Microsoft JhengHei;
  }
`
const StyledPlanBtn = Styled(ToggleButton)`
  && {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4);
    font-size: 1.6rem;
    padding: 5px;
  }
  &&.Mui-selected {
    border: 1px solid #28a8ea;
    color: #fff;
    background-color: #28a8ea;
  }
  &&.Mui-selected:hover {
    border: 1px solid #28a8ea;
    color: #fff;
    background-color: #28a8ea;
  }
`
const StyledSelectHint = Styled(Typography)`
  && {
    font-weight: 700;
    color: #28a8ea;
  }
`
const StyledDivider = Styled(Divider)`
  && {
    width: 100%;
    height: 2px;
    background-color: #28a8ea;
    margin: 5px 0px;
  }
`
const StyledSpan = Styled.span`
  && {
    font-size: 0.875rem;
    display: contents;
    align-items: end;
    text-align: end;
  }
`
const StyledproductDescription = Styled(Typography)`
  && {
    letter-spacing: 0.0838em;
  }
`
const StyledformHint = Styled(Typography)`
  && {
    color: red;
    letter-spacing: 0.0838em;
    font-weight: 600;
  }
`

const StyledTextField = Styled(TextField)`
  && {
    width: 100%;
  }
  & .MuiFilledInput-input {
    text-align: center;
  }
  & .MuiFilledInput-root {
    border-radius: 30px;
    letter-spacing: 0.2rem;
  }
  & .MuiFilledInput-input:-autofill {
    border-radius: 30px;
  }
  & .MuiFilledInput-input:-webkit-autofill {
    border-radius: 30px;
  }
  & .MuiInputLabel-formControl {
    position: relative;
  }
  & .MuiFormLabel-root {
    text-align: center;
    transform-origin: center;
    font-size: 1.15rem;
    font-weight: 700;
    letter-spacing: 0.0938em;
  }
  & .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(0px, 23px) scale(0.65);
  }
  & .MuiInputLabel-filled {
    transform: translate(0px, 38px) scale(1);
  }
  & .MuiFormHelperText-root {
    text-align: center;
    margin-top: 5px;
  }
`
const StyledGridTextField = Styled(Grid)`
  && {
    display: flex;
    justify-content: space-evenly;
  }
`
const StyledHelperText = Styled.p`
  && {
    margin: 0px auto;
    letter-spacing: 0.06333rem;
  }
`
const StyledLink = Styled.a`
  && {
    text-decoration: none;
    color: #0072ff;
    font-weight: bold;
  }
`
const StyledDomainTextField = Styled(TextField)`
  && {
    width: 100%;
  }
  & .MuiFilledInput-input {
    text-align: center;
  }
  & .MuiFilledInput-root {
    padding-left: 141.5px;
    border-radius: 30px;
    letter-spacing: 0.2rem;
  }
  @media screen and (max-width: 500px) {
    & .MuiFilledInput-root {
      padding-left: 0px;
      border-radius: 30px;
      letter-spacing: 0.2rem;
    }
  }
  & .MuiFilledInput-input:-autofill {
    border-radius: 30px;
  }
  & .MuiFilledInput-input:-webkit-autofill {
    border-radius: 30px;
  }
  & .MuiInputLabel-formControl {
    position: relative;
  }
  & .MuiFormLabel-root {
    text-align: center;
    transform-origin: center;
    font-size: 1.15rem;
    font-weight: 700;
    letter-spacing: 0.0938em;
  }
  & .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(0px, 23px) scale(0.65);
  }
  & .MuiInputLabel-filled {
    transform: translate(0px, 38px) scale(1);
  }
  & .MuiFormHelperText-root {
    text-align: center;
    margin-top: 5px;
  }
`
const StyledConfirmBtn = Styled(Button)`
  && {
    background-color: #28a8ea;
    color: #fff;
    width: 100%;
    height: 55.990px;
    border-radius: 30px;
    font-size: 1.25rem;
    margin: 20px 0px;
  }
  &&:hover {
    background-color: #1794d5;
  }
`
const StyledAppBar = Styled(AppBar)`
  && {
    background-color: #28a8ea;
    color: white;
    margin: 20px 0px 0px 0px;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  }
`
const StyledTabs = Styled(Tabs)`
  & .Mui-selected {
    background-color: #1794d5;
    color: white;
  }
`
const StyledTabLeft = Styled(Tab)`
  && {
    font-size: 1rem;
    padding: 15px 0px;
    border-radius: 10px 0px 0px 0px;
  }
`
const StyledTabRight = Styled(Tab)`
  && {
    font-size: 1rem;
    padding: 15px 0px;
    border-radius: 0px 10px 0px 0px;
    text-transform:capitalize
  }
`
const StyledTabPanel = Styled(TabPanel)`
  && {
    width: 100%;
    margin-bottom: 20px;
  }
  & .MuiBox-root {
    padding: 0px;
  }
`
const StyledPaper = Styled(Paper)`
  && {
    border-radius: 0px 0px 10px 10px;
    padding: 10px 30px;
    background-color: #fffcfc;
    font-weight: 500;
    letter-spacing: 0.01em;
    word-wrap: break-word;
  }
`
const StyledCheckboxGrid = Styled(Grid)`
  && {
    display: flex;
    justify-content: left;
    align-item: center;
  }
`

const StyledSubmitBtn = Styled(Button)`
  && {
    background-color: #28a8ea;
    color: #fff;
    width: 95%;
    border-radius: 30px;
    padding: 12px;
    font-size: 1.05rem;
    font-weight: bolder;
  }
  &&:hover {
    background-color: #1794d5;
  }
`
const StyledBackBtn = Styled(Button)`
  && {
    background-color: #f75c5c;
    color: #fff;
    width: 95%;
    border-radius: 30px;
    padding: 12px;
    font-size: 1.05rem;
    font-weight: bolder;
  }
  &&:hover {
    background-color: #d54b4b;
  }
`
const StyledButtonGroup = Styled(Grid)`
  && {
    margin-bottom: 20px;
  }
`
const StyledCheckbox = Styled(Checkbox)`
  & .MuiSvgIcon-root {
    font-size: 30px;
  }
  &.Mui-checked {
    color: #d81b60;
  }
  & .MuiCheckbox-colorPrimary {
    color: #d81b60;
  }
`


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Purchase(props) {
  // console.log(props);
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(["sessionId"]);
  const [planOption, setPlanOption] = useState('年繳'); // Control the value from Plan Button
  let data = (props.location.state) ? props.location.state.data : {
    value: [
      {
        plan: "month",
        detailSn: 0,
        price: ""
      },
      {
        plan: "year",
        detailSn: 0,
        price: ""
      }
    ],
    planName: "",
    applyHN: false
  };

  const [memberDetail, setMemberDetail] = React.useState({
    Sn: 0,
    Hn: "",
    TelMorning: "",
    Zip: "",
    Name: "",
    ContactLastName: "",
    ContactName: "",
    EMail: "",
    Address: "",
  });

  const [orderDetail, setOrderDetail] = React.useState({
    Domain: "",
    Quantity: 1,
    PromoEmpno: "",
    OperatorNote: "",
  });

  const [showOrderInput, setShowOrderInput] = React.useState(true);
  const [showContract, setShowContract] = React.useState(false);
  const [contract1Checked, setcontract1Checked] = React.useState(false);
  const [contract2Checked, setcontract2Checked] = React.useState(false);
  const [value, setValue] = React.useState(0);

  // Input order isvalid
  const [inputIsValid, setInputIsValid] = React.useState(true);
  const [domainValid, setDomainValid] = React.useState(false);
  const [domainError, setDomainError] = React.useState(false);
  const [zipValid, setZipValid] = React.useState(true);
  const [domainDisabled, setDomainDisabled] = React.useState(false);
  const [inputError, setInputError] = React.useState(false);
  const [zipInputError, setZipInputError] = React.useState(false);
  const [inputDisabled, setInputDisabled] = React.useState(false);
  // const [checkDomainButtonDisabled, setCheckDomainButtonDisabled] = React.useState(false);
  // const [renameDomainButtonDisabled, setRenameDomainButtonDisabled] = React.useState(false);
  const [confirmOrderButtonDisabled, setConfirmOrderButtonDisabled] = React.useState(false);

  // Domain check result message
  // const [showCheckResultMsg, setShowCheckResultMsg] = React.useState(false);
  const [domainCheckResultMessage, setDomainCheckResultMessage] = React.useState("");

  // No OTP login purchase argument
  const [promoEmpNo, setPromoEmpNo] = React.useState("");

  let planName = localStorage.getItem("planName") === null ? "Microsoft 365 商務標準版" : localStorage.getItem("planName");   // Plan name 需要先初始化，避免 substring function 出問題
  let plan = localStorage.getItem("plan");  // Pay per month or per year
  let price = localStorage.getItem("price"); // Price of plan
  let productDetailSn = parseInt(localStorage.getItem("productDetailSn"));
  let applyHN = localStorage.getItem("applyHN");
  let productDescription = localStorage.getItem("productDescription");

  if (props.location.state && !showContract && showOrderInput) {
    console.log("Initial when finishing your order data.")
    planName = data.productTitle;
    applyHN = data.applyHN;
    plan = getPlanLength(data.value[1].plan);
    price = data.value[1].price.toString();
    productDetailSn = data.value[1].detailSn;
    productDescription = data.value[1].simple;

    localStorage.setItem('planName', planName);
    localStorage.setItem('applyHN', applyHN);
    localStorage.setItem('plan', plan);
    localStorage.setItem('price', price);
    localStorage.setItem('productDetailSn', productDetailSn);
    localStorage.setItem("productDescription", productDescription);
  }

  /* 
    When Initing and Selecting a Plan Option, 
    it will update current arguments and save it in local storage.
  */
  useEffect(() => {
    if (props.location.state) {
      console.log("Update the Plan.");
      if (planOption === '月繳') {
        localStorage.setItem('price', data.value[0].price.toString());
        localStorage.setItem('productDetailSn', data.value[0].detailSn);
        localStorage.setItem("productDescription", data.value[0].simple);
      } else {
        localStorage.setItem('price', data.value[1].price.toString());
        localStorage.setItem('productDetailSn', data.value[1].detailSn);
        localStorage.setItem("productDescription", data.value[1].simple);
      }
      localStorage.setItem('planName', data.productTitle);
      localStorage.setItem('applyHN', data.applyHN);
      localStorage.setItem('plan', planOption);
    }
  }, [planOption, data, props.location.state])

  useEffect(() => {
    // console.log(props.location.state);
    // Back top side of the web view
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    if ((props.location.state && props.location.state.data.applyHN === true) || (applyHN !== undefined && (applyHN === true || applyHN === "true"))) {
      // Show OTP Dialog
      openOTPLogin();
    } else {
      // check seesion ID
      if (cookies.sessionId === undefined || cookies.sessionId === "") {
        AAALogin();
        console.error("No such Session, user haven't logged in");
      } else {
        getMemberData(cookies.sessionId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.sessionId]);

  function clearLocalStorage() {
    // If cheat mode is on
    var cheatMode = false;
    if (localStorage.getItem("cheatMode") !== null) {
      cheatMode = true;
    }
    localStorage.clear();
    if (cheatMode) {
      localStorage.setItem('cheatMode', true);
    }
  }

  // Clear localStorage before close window
  // window.onbeforeunload = function () {
  //   var clearLS = setTimeout(function () { clearLocalStorage(); }, 5000);
  //   window.onreload = function () {
  //     clearTimeout(clearLS);
  //   }
  // }

  const AAALogin = () => {
    Axios.post("/AAA/login", { redirectUrl: "/BuyNow" }).then((res) => {
      const data = res.data;
      //console.log(data["url"]);
      window.location.assign(data["url"]);
      // AAAresopnse();
    }).catch(error => {
      console.log(error);
    });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // Save plan value
  function getPlanLength(planName) {
    switch (planName) {
      case "year":
        return "年繳";
      default:
        return "月繳";
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setMemberDetail(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function handleOrderChange(event) {
    const { name, value } = event.target;

    // 避免使用者突然更新 Domain 欄位內容而未驗證就 submit 表單。
    if (name === "Domain") initialDomainValidStatus();

    setOrderDetail(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function initialInputCheck(initialData) {
    if (/[0-9]{5}/.test(initialData.Zip)) {
      setZipValid(true);
      setZipInputError(false);
    } else {
      setZipValid(false);
      setZipInputError(true);
    }
    if (isNaN(orderDetail.Quantity) || orderDetail.Quantity <= 0) {
      setInputIsValid(false);
      setInputError(true);
    } else {
      setInputIsValid(true);
      setInputError(false);
    }
  }

  function handleOrderChangeZip(event) {
    const { name, value } = event.target;
    if (/[0-9]{5}/.test(value)) {
      setZipValid(true);
      setZipInputError(false);
    } else {
      setZipValid(false);
      setZipInputError(true);
    }
    setMemberDetail(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function handleOrderChangeQuantity(event) {
    const { name, value } = event.target;
    if (isNaN(value) || value <= 0) {
      setInputIsValid(false);
      setInputError(true);
    } else {
      setInputIsValid(true);
      setInputError(false);
    }
    setOrderDetail(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function getMemberData(sessionID) {
    Axios.post("/api/member/getSingleMember", {
      SessionId: sessionID
    }).then(res => {
      setMemberDetail(res.data);
      initialInputCheck(res.data);
    })
  }

  function initialDomainValidStatus() {
    setDomainValid(false);
    setDomainCheckResultMessage("網域更改後，需重新驗證。");
    // setShowCheckResultMsg(true);
    setDomainError(false);
  }

  function checkDomainAvailability() {
    if (orderDetail.Domain !== "") {
      setDomainCheckResultMessage("正在重新驗證...");
      // setShowCheckResultMsg(true);
      setDomainError(false);
      // setCheckDomainButtonDisabled(true);
      // setRenameDomainButtonDisabled(true);
      var message = "主要網域名稱 ";
      Axios.post("/api/v0/mpnCheckDomainByDomain", {
        domain: orderDetail.Domain
      }).then((res) => {
        setDomainValid(true);
        // setDomainDisabled(true);
        //alert("這個網域名稱可以使用");
        // setRenameDomainButtonDisabled(false);
        setDomainCheckResultMessage("這個網域名稱可以使用");
        // setShowCheckResultMsg(true);
        setDomainError(false);
      }).catch((error) => {
        setDomainValid(false);
        // setDomainDisabled(false);
        // setCheckDomainButtonDisabled(false);
        // setRenameDomainButtonDisabled(false);
        var errMessage = "";
        if (error.response) {
          console.log(error.response);
          if (error.response.data) {
            errMessage = error.response.data["message"] + "---Fail";
          } else {
            errMessage = `${message}---${error.response}`;
          }
        } else if (error.request) {
          errMessage = `${message}---連線異常`;
        } else {
          // 在設定 request 時出錯會進入此
          errMessage = `${message}---error`;
          console.error(error);
        }
        //alert(errMessage);
        setDomainCheckResultMessage(errMessage);
        // setShowCheckResultMsg(true);
        setDomainError(true);
      });
    } else {
      //alert("Domain 不能為空!");
      setDomainCheckResultMessage("Domain 不能為空!");
      setDomainError(true);
      // setShowCheckResultMsg(true);
    }
  }

  function showPurchaseContract() {
    // If any required field is not filled, show error message
    let errMsg = "";
    if (memberDetail.ContactLastName === "") {
      if (errMsg !== "") {
        errMsg += "\n";
      }
      errMsg += " - 聯絡人（姓）";
    }
    if (memberDetail.ContactName === "") {
      if (errMsg !== "") {
        errMsg += "\n";
      }
      errMsg += " - 聯絡人（名）";
    }
    if (memberDetail.City === "") {
      if (errMsg !== "") {
        errMsg += "\n";
      }
      errMsg += " - 城市";
    }
    if (memberDetail.Address === "") {
      if (errMsg !== "") {
        errMsg += "\n";
      }
      errMsg += " - 使用服務之位置地址";
    }
    if (memberDetail.EMail === "") {
      if (errMsg !== "") {
        errMsg += "\n";
      }
      errMsg += " - 聯絡email";
    }

    if (errMsg !== "") {
      errMsg = "以下欄位:\n\n" + errMsg + "\n\n為必填!";
      alert(errMsg);
      return;
    }

    if (!zipValid) {
      alert("郵遞區號須為五碼!");
      return;
    }
    if (domainValid) {
      if (inputIsValid) {
        setShowOrderInput(false);
        setInputDisabled(true);
        setDomainDisabled(true);
        setShowContract(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      } else {
        setInputDisabled(false);
        alert("輸入授權個數須為數字且為一個以上之整數");
      }
    } else {
      setDomainError(true);
      setInputDisabled(false);
      setDomainDisabled(false);
      alert("請確認網域名稱是否合法或已驗證!");
    }
  }

  function hidePurchaseContract() {
    setInputDisabled(false);
    setDomainDisabled(false);
    setShowContract(false);
    setShowOrderInput(true);
    // Back top side of the web view
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  function handleFirstContractChecked(event) {
    setcontract1Checked(event.target.checked);
  }

  function handleSecondContractChecked(event) {
    setcontract2Checked(event.target.checked);
  }

  function onConfirmOrder() {
    console.log("confirm pressed");
    setConfirmOrderButtonDisabled(true);
    if (contract1Checked && contract2Checked) {
      if (window.confirm("確定儲存變更資料？")) {
        Axios.post("/api/member/alterMember", {
          Sn: memberDetail.Sn,//1,
          TelMorning: memberDetail.TelMorning,
          Cell: memberDetail.Cell,
          Zip: memberDetail.Zip,
          Name: memberDetail.Name,
          ContactLastName: memberDetail.ContactLastName,
          ContactName: memberDetail.ContactName,
          EMail: memberDetail.EMail,
          Address: memberDetail.Address,
        }).then(res => {
          Axios.post("/api/order/createNewOrder", {
            AaHnNo: memberDetail.Hn,//"74918600",//"12341",
            Order: {
              Domain: orderDetail.Domain,
              ProductDetailSn: productDetailSn, //1,
              Quantity: parseInt(orderDetail.Quantity),
              PromoEmpno: promoEmpNo, // orderDetail.PromoEmpno,
              OperatorNote: "", // orderDetail.OperatorNote,
              DsisNo: "",
              //ProductDiscodeSn  : 2,
              OrderChargeTypeSn: (plan === "年繳") ? 1 : 2,
              OrderSourceTypeSn: 1,
              IsApplyingHN: applyHN,
            }
          }).then(res => {
            //history.replace("/PurchaseComplete");
            let PaySuccess = true;
            if (plan === "年繳") {
              let orderStatus = res.data["statusCode"];
              // If pay success
              PaySuccess = (orderStatus === 200);
            }
            //if(PaySuccess) {
            // Clear browser's localstorage
            clearLocalStorage();
            history.push({
              pathname: "/PurchaseComplete",
              state: {
                planName: planName,
                plan: plan,
                price: price,
                TelMorning: memberDetail.TelMorning,
                Cell: memberDetail.Cell,
                Zip: memberDetail.Zip,
                Name: memberDetail.Name,
                ContactLastName: memberDetail.ContactLastName,
                ContactName: memberDetail.ContactName,
                EMail: memberDetail.EMail,
                Address: memberDetail.Address,
                Domain: orderDetail.Domain,
                Quantity: orderDetail.Quantity,
                PromoEmpno: "", // orderDetail.PromoEmpno,
                OperatorNote: "", // orderDetail.OperatorNote,
                PaySuccess: PaySuccess,
                OrderDate: res.data["orderDate"],
                OrderNo: res.data["orderNo"],
                PurchaseStatus: PaySuccess,
                productDescription: productDescription
              }
            });
            //}
          }).catch(error => {
            console.log("Error");
            console.log(error);
            history.push({
              pathname: "/PurchaseComplete",
              state: {
                PaySuccess: false,
              }
            });
            //history.replace("/BuyNow");
          });
        }).catch(error => {
          //let PaySuccess = true;
          console.log(error);
          history.push({
            pathname: "/PurchaseComplete",
            state: {
              PaySuccess: false,
            }
          });
          //history.replace("/BuyNow");
        });
      }
      setConfirmOrderButtonDisabled(false);
    } else {
      var UnCheckMsg = "";
      UnCheckMsg += (contract1Checked) ? "" : "請詳閱並同意本業務服務契約";
      if (!contract2Checked) {
        if (UnCheckMsg.length !== 0) {
          UnCheckMsg += "及 Microsoft 雲端合約";
        } else {
          UnCheckMsg += "請詳閱並同意 Microsoft 雲端合約";
        }
      }
      alert(UnCheckMsg);
      setConfirmOrderButtonDisabled(false);
    }
    // } else {
    //   history.replace("/BuyNow");
    // }
  }

  // No Hn login OTP modal status
  const [openOTPInputModal, setOpenOTPInputModal] = React.useState(false);
  const [otpCell, setOTPCell] = React.useState("");

  // HiB2B modal status
  const [statusHiB2BModal, setStatusHiB2BModal] = React.useState(false);

  const openOTPLogin = () => {
    setOpenOTPInputModal(true);
  };

  const openHiB2BModal = () => {
    setStatusHiB2BModal(true);
  };

  const closeHiB2BModal = () => {
    setStatusHiB2BModal(false);
  };

  const setOTPCellHandler = (otp) => {
    setOTPCell(otp);
  };

  const setPromoEmpNoHandler = (no) => {
    setPromoEmpNo(no);
  }

  const setNewMemberHNHandler = () => {
    getMemberDataFromNewHN();
  };

  const handleChangePlanOption = (e) => {
    console.log('Your Plan is : ' + e.target.value);
    if (e.target.value !== undefined) {
      setPlanOption(e.target.value);
    }
  }

  function getMemberDataFromNewHN() {
    Axios.post("/api/member/getSingleMember", {
      Hn: otpCell
    }).then(res => {
      setMemberDetail(res.data);
      initialInputCheck(res.data);
    });
  }

  return (
    <Container>
      {/*otpLogin()*/}
      <Grid container spacing={2}>

        {showOrderInput && (
          <>
            <StyledGridTitle item xs={12}>
              <StyledTitle variant="h4">{planName.substring(0, 13) === "Microsoft 365" ? planName.substring(0, 13) : planName.substring(0, 10)}</StyledTitle>
            </StyledGridTitle>
            <StyledGridTitle item xs={12}>
              <StyledTitle variant="h4">{planName.substring(0, 13) === "Microsoft 365" ? planName.substring(14, planName.length) : planName.substring(11, planName.length)}</StyledTitle>
            </StyledGridTitle>
            <StyledGrid item xs={12}>
              <StyledSelectHint variant="h6">選擇方案</StyledSelectHint>
            </StyledGrid>
            <StyledDivider />

            <ToggleButtonGroup
              value={planOption}
              exclusive
              fullWidth
              onChange={handleChangePlanOption}
            >
              <StyledPlanBtn value="月繳" variant="outlined" xs={12} sm={6}>
                {"NT$ " + data.value[0].price.toString()}
                <StyledSpan>&ensp;使用者/月</StyledSpan>
              </StyledPlanBtn>
              <StyledPlanBtn value="年繳" variant="outlined" xs={12} sm={6}>
                {"NT$ " + data.value[1].price.toString()}
                <StyledSpan>&ensp;使用者/年</StyledSpan>
              </StyledPlanBtn>
            </ToggleButtonGroup>
            <StyledDivider />
            <StyledGrid item xs={12}>
              <StyledproductDescription>{productDescription}</StyledproductDescription>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <StyledformHint>請確認帳號資料並填妥必要欄位資訊。</StyledformHint>
            </StyledGrid>
            <StyledGridTextField item xs={12}>
              <StyledTextField
                variant="filled"
                label="公司/客戶名稱"
                value={memberDetail.Name}
                disabled
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGridTextField item xs={12}>
              <StyledTextField
                variant="filled"
                label="聯絡人（姓）"
                name="ContactLastName"
                value={memberDetail.ContactLastName}
                required
                disabled={inputDisabled}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGridTextField item xs={12}>
              <StyledTextField
                variant="filled"
                label="聯絡人（名）"
                name="ContactName"
                value={memberDetail.ContactName}
                required
                disabled={inputDisabled}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGridTextField item xs={12} md={6}>
              <StyledTextField
                variant="filled"
                label="郵遞區號"
                name="Zip"
                value={memberDetail.Zip}
                required
                disabled={inputDisabled}
                onChange={handleOrderChangeZip}
                error={zipInputError}
                InputProps={{ disableUnderline: true }}
                helperText={<StyledHelperText>3+2郵遞區號查詢請點擊<StyledLink href="https://www.post.gov.tw/post/internet/SearchZone/index.jsp?ID=130107" rel="noopener noreferrer" target="_blank"> 這裡 </StyledLink></StyledHelperText>}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGridTextField item xs={12} md={6}>
              <StyledTextField
                variant="filled"
                label="城市"
                name="City"
                value={memberDetail.City}
                required
                disabled={inputDisabled}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGridTextField item xs={12}>
              <StyledTextField
                variant="filled"
                label="使用服務之位置地址"
                name="Address"
                value={memberDetail.Address}
                required
                disabled={inputDisabled}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGridTextField item xs={12}>
              <StyledTextField
                variant="filled"
                label="聯絡電話"
                name="TelMorning"
                value={memberDetail.TelMorning}
                disabled={inputDisabled}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGridTextField item xs={12}>
              <StyledTextField
                variant="filled"
                label="手機號碼"
                name="Cell"
                value={memberDetail.Cell}
                disabled={inputDisabled || applyHN}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGridTextField item xs={12}>
              <StyledDomainTextField
                variant="filled"
                label="主要網域名稱"
                name="Domain"
                value={orderDetail.Domain}
                required
                disabled={domainDisabled}
                onChange={handleOrderChange}
                error={domainError}
                InputProps={window.matchMedia("(max-width: 500px)").matches ? { disableUnderline: true } : { endAdornment: <InputAdornment position="end">.onmicrosoft.com</InputAdornment>, disableUnderline: true }}
                helperText={`${domainCheckResultMessage}`}
                onBlur={checkDomainAvailability}
              ></StyledDomainTextField>
            </StyledGridTextField>

            <StyledGridTextField item xs={12}>
              <StyledTextField
                variant="filled"
                label="聯絡 Email"
                name="EMail"
                value={memberDetail.EMail}
                required
                disabled={inputDisabled}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGridTextField item xs={12}>
              <StyledTextField
                variant="filled"
                label="您想要幾個使用者授權"
                name="Quantity"
                value={orderDetail.Quantity}
                disabled={inputDisabled}
                required
                onChange={handleOrderChangeQuantity}
                InputProps={{ disableUnderline: true }}
                error={inputError}
              ></StyledTextField>
            </StyledGridTextField>
            <StyledGrid item xs={12}>
              <StyledConfirmBtn
                color="primary"
                onClick={showPurchaseContract}
              >確認訂單</StyledConfirmBtn>
            </StyledGrid>

          </>
        )}

        {showContract && (
          <>
            <StyledAppBar position="static">
              <StyledTabs value={value} onChange={handleTabChange} aria-label="simple tabs example" variant="fullWidth" centered>
                <StyledTabLeft label="本業務服務契約" {...a11yProps(0)} />
                <StyledTabRight label="Microsoft 雲端合約" {...a11yProps(1)} />
              </StyledTabs>
            </StyledAppBar>
            <StyledTabPanel value={value} index={0}>
              <StyledPaper elevation={3} overflow="auto">
                {/* <div style={{ fontWeight: "bold", fontSize: 30, color: "red", marginBottom: 10 }}>「微軟雲服務」服務契約</div> */}
                <p>1.	中華電信股份有限公司係依據本服務契約提供客戶微軟雲端軟體服務，客戶簽署同意使用本服務時，除代表已完全瞭解及接受本服務契約及Microsoft雲端合約之所有約定外，並同意本公司開通申裝服務，得蒐集、傳遞、利用上開 貴客戶個人予台灣微軟股份有限公司。簽署人應為公司代表人或有權代表公司簽署之人，如有虛假偽冒，簽署人應負全部法律責任。</p>
                <p>2.	本服務係由台灣微軟股份有限公司提供之雲端軟體服務，貴客戶簽署即同意遵守該公司之相關規定。</p>
                <p>3.	本公司僅為開立帳單、收費及協助向台灣微軟股份有限公司提供  貴客戶之申請手續及相關使用上之問題。如使用上或有任何問題請洽0800-080-365。</p>
                <p>4.	本服務依  貴客戶租用服務之項目及數量，計算總價，並向  貴客戶收取； 貴客戶應於本服務通知繳費之期限前繳清，逾期未繳清者，本服務得註銷其申請或通知定期停止本服務；如經再限期催繳，貴客戶逾期仍未繳納者，本服務得逕行終止服務。  貴客戶所積欠之費用，仍須繳納且不得拒絕給付。</p>
                <p>5.	貴客戶申請租用本服務竣工日以  貴客戶開通使用完成之日為準，以E-Mail方式通知  貴客戶。</p>
                <p>6.	本服務之繳費方式按下列規定辦理：<br />甲、	月繳制：最短租期為1個月，租期未滿1個月以1個月（30天）計收。其費用以開通日按日計算，起租日當日開始計費。<br />乙、	年繳制：最短租期為1年，租期未滿1年以1年計收。如於未滿最短租期  貴客戶單方面終止租用或違反規定經本服務終止租用者，貴客戶已繳納費用概不退還，並需支付1500元手續費。每期到期後以E-Mail方式通知  貴客戶，並自動按年繳制計收，如  貴客戶不欲繼續採年繳時，應於年繳到期前辦理異動手續。</p>
                <p>7.	貴客戶應確保其自行租用專線電路、HiLink VPN、寬頻上網之電路、網路或電話電路等設備之正常運作，本服務無法就前述電路或網路負責，就因該電路或網路之障礙、阻斷、遲滯、中斷或不能傳遞等因素，而致本服務無法使用之情形，本分公司無租費扣減租費及損害賠償之責任。</p>
                <p>8.	本服務硬體、軟體、程式及內容（包括但不限於文字、說明、圖畫、圖片、圖形、檔案、頁面設計、網站規劃與安排等）之專利權、著作權、商標權、營業秘密、專門技術及其他智慧財產權均屬本服務或其他權利人所有，非經本服務或其他權利人事先書面授權同意， 貴客戶不得重製、公開傳播、公開播送、公開上映、改作、編輯、出租、散布、進行還原工程、解編、反向組譯、拆解及藉由本服務提供衍生產品或服務，如有違反，除應自行負法律責任外，如因而對本服務造成損害或損失， 貴客戶應對本服務負損害賠償責任。</p>
                <p>9.	因本契約涉訟時，系爭金額超過民事訴訟法規定之小額訴訟金額者，雙方合意以本分公司所在地所屬之地方法院為第一審管轄法院。</p>
              </StyledPaper>
            </StyledTabPanel>
            <StyledTabPanel value={value} index={1}>
              <StyledPaper elevation={3} overflow="auto">
                {/* <div style={{ fontWeight: "bold", fontSize: 30, color: "red", marginBottom: 10 }}>Microsoft 雲端合約</div> */}
                <p>
                  本 Microsoft 雲端合約係由 Microsoft Taiwan (以下稱「Microsoft」或「本公司」) 與 貴用戶所代表之實體間所共用成立之合約，或若 貴用戶就訂閱相關事宜並未指定實體，則本合約係由 Microsoft 與 貴用戶個人 (無論何種情況以下均稱「客戶」或「 貴用戶」) 間所共用成立之合約。本合約包含以下條款及條件、可接受的使用原則、線上服務條款及 SLA。合約生效日期為本公司對於 貴用戶之最初訂單提供訂單確認書之日期 (以下稱「生效日期」)。重要名詞將於第 11 節中加以定義。
                </p>

                <div style={{ fontWeight: "bold" }}>1. 一般規定。</div>
                <p>a. 使用之權利。本公司將授與 貴用戶存取並使用線上服務以及安裝和使用訂閱內所包含之軟體的權利，如本合約中所詳細描述者。本公司保留所有其他權利。
                  <br />b. 可接受的使用。 貴用戶將僅依據可接受的使用原則來使用產品。 貴用戶不得對產品進行還原工程、反向組譯、解編或規避技術限制，但若相關法律允許此項限制者，不在此限。 貴用戶不得停用、竄改或以其他方式試圖規避針對 貴用戶對產品之使用而制定的任何付款機制。 貴用戶不得將任何產品出租、租賃、出借、轉售、移轉或以主機服務形式提供予第三人。
                  <br />c. 使用權利。在生效日期當日生效之線上服務條款將適用於 貴用戶於訂閱最初期間對產品之使用。在續約日期當日生效之線上服務條款則適用於任何續約期間。使用權利及 Microsoft 更新線上服務之相關程序將詳細載明於線上服務條款中。
                  <br />d. 選擇轉銷商。 貴用戶必須選擇及維持在其區域內獲授權之轉銷商。
                  <br />e. 轉銷商管理者存取及客戶資料。 貴用戶認知且同意 (i) 除非 Microsoft 另行許可，否則 貴用戶一旦選擇了轉銷商，該轉銷商便成為期間內線上服務之額外管理者，且 貴用戶將無法再於期間內選擇其他線上服務之轉銷商；(ii) 轉銷商對客戶資料將具備與 貴用戶本身的管理者相同的管理權限和存取權；(iii) 轉銷商就客戶資料或轉銷商所提供之任何服務所採取的相關隱私權措施可能與 Microsoft 的隱私權措施不同；以及 (iv) 轉銷商可能蒐集、使用、移轉、揭露及以其他方式處理客戶資料，包括個人資料。 貴用戶同意 Microsoft 得為了訂購、提供及管理線上服務之目的，將 貴用戶提供給 Microsoft 之資訊與客戶資料提供給轉銷商。
                  <br />f. Academic 和 Government 版本的資格。 貴用戶同意若購買學術專用或政府專用的優惠方案，表示 貴用戶符合 http://www.microsoftvolumelicensing.com 上所列的合格教育使用者或合格政府機構的個別資格要件。Microsoft 保留隨時確認此類資格，並於不符合資格要件時暫停線上服務的權利。
                </p>

                <div style={{ fontWeight: "bold" }}>2. 保密條款。</div>
                <p>在相關法律許可的範圍之內，本合約之條款及條件均屬機密性質。除非另行同意， 貴用戶不得將這些條款及條件，或是相關討論之實質內容，向非 貴用戶之關係企業或代理人外之任何第三人揭露，但可向符合下列條件之指定或潛在轉銷商揭露：(a) 為協助履行本合約而有知悉該等資訊之需要；且 (b) 已受指示所有該等資訊必須視為絕對機密處理。</p>

                <div style={{ fontWeight: "bold" }}>3. 訂單、價格、期間、終止及中止。</div>
                <p>a. 訂單。
                  <ul>
                    <li>(i) 訂單必須透過 貴用戶指定之轉銷商遞交。</li>
                    <li>(ii) 貴用戶得增加訂閱期間訂購的產品數量。新增至訂閱的其他產品數量會在該訂閱結束時屆滿。</li>
                  </ul>
                  <br />b. 可用的訂閱優惠方案。
                  <ul>
                    <li>(i) 預付方案。 貴用戶預先承諾購買特定數量的服務以在期間內使用。關於 Microsoft Azure 服務，額外或其他使用 (例如，使用超出 貴用戶的預付數量) 可能會視同消費方案。期間內未使用之預付數量將在期間結束時屆滿。 </li>
                    <li>(ii) 消費方案 (又稱為隨收隨付)。若是 Microsoft Azure 服務， 貴用戶之轉銷商將根據 貴用戶線上服務的實際使用情形向 貴用戶請款。</li>
                    <li>(iii) 貴用戶之轉銷商將建立可供 貴用戶使用之訂閱優惠方案。 </li>
                  </ul>
                  <br />c. 價格及付款。 貴用戶認知其每項產品的價格，以及開立發票和付款的條款及條件係由 貴用戶之轉銷商所建立。
                  <br />d. 續約。除非 貴用戶在訂閱期間屆滿之前提供 貴用戶不再續約之意願通知給轉銷商，否則 貴用戶現有訂閱之期間將自動續約。在處理續約之前，Microsoft 得要求 貴用戶簽署新合約、增補合約或增修條款。
                  <br />e. 稅賦。任一方當事人對於他方當事人依法有義務支付的任何稅賦，無須承擔任何責任，前述稅賦係因依本合約所完成交易所產生或與其相關而引起，而且所有此等稅賦應屬依法律作業有義務支付此等稅賦之當事人之財務責任。
                  <br />f. 合約有效期限及終止。本合約於依此訂購任何訂閱期間將繼續有效。 貴用戶得隨時透過連絡指定轉銷商之方式終止本合約。本合約之屆滿或終止只會終止 貴用戶依本合約遞交新訂單以取得額外產品的權利。
                  <br />g. 違反合約之終止。任一方當事人得於他方當事人重大違反本合約時，透過以書面通知他方當事人之方式終止本合約。除該違約之性質無法於 30 日內改正者外，終止方應給予他方當事人 30 日之事先書面通知以及改正之機會。
                </p>

                <div style={{ fontWeight: "bold" }}>4. 安全性、隱私權及資料保護。 </div>
                <p>a. 貴用戶同意將個人資訊交由 Microsoft 及其代理人處理，以協助履行本合約標的。依據本合約之規定， 貴用戶得選擇代表第三人 (包括 貴用戶之連絡人、轉銷商、代理商、管理者及員工) 提供個人資訊給 Microsoft。 貴用戶在提供個人資訊給 Microsoft 之前，將會依據適用之隱私權及資料保護法律，向第三人取得所有必要的同意。
                  <br />b. 其他隱私權及安全性詳細資料，將會載明於線上服務條款中。線上服務條款中之承諾僅適用於依本合約訂購之線上服務，不適用於 貴用戶之轉銷商所提供之任何服務或產品。
                  <br />c. 貴用戶同意且授權 Microsoft (及其服務提供者及次承攬人) 依轉銷商之指示或依法律要求，存取及揭露執法機構或其他政府機構取自 貴用戶或與 貴用戶相關之資料，包括通訊內容 (或提供執法機構或其他政府機構此等資料之存取權)。
                  <br />d. 在法律規定的範圍內， 貴用戶應通知線上服務之個別使用者，其得為了揭露給執行機構或其他政府機構之目的，依轉銷商之指示或依法律要求而處理其資料，且其同樣應取得使用者之同意。
                  <br />e. 貴用戶將轉銷商任命為其代理人，使其可為了針對本文第 4 節之目的而與 Microsoft 接洽及提供指示。
                </p>

                <div style={{ fontWeight: "bold" }}>5. 瑕疵擔保。</div>
                <p>a. 有限瑕疵擔保責任。
                  <ul>
                    <li>(i) 線上服務。本公司擔保每項線上服務將符合適用期間內適用之 SLA 的條款。 貴用戶對於違反本瑕疵擔保之唯一救濟權係指 SLA 中之該等救濟權。 </li>
                    <li>(ii) 軟體。本公司擔保，自 貴用戶第一次使用軟體起一年內將會實質依照適用的使用者文件中描述之方式執行。如果軟體無法符合本瑕疵擔保，本公司將選擇 (1) 退還所支付之軟體價格或 (2) 修復或更換軟體。 </li>
                  </ul>
                  <br />b. 有限瑕疵擔保之排除。本有限瑕疵擔保責任有以下限制：
                  <ul>
                    <li>(i) 任何默示擔保、保證或因法律因素無法排除之條件將自有限瑕疵擔保當日起算，適用一年； </li>
                    <li>(ii) 凡因意外、濫用或使用產品之方式與本合約規定不符所產生的問題，或因超出本公司合理控制範圍之外的事件所引發的問題，均不在本有限瑕疵擔保責任涵蓋範圍內； </li>
                    <li>(iii) 本有限瑕疵擔保責任不適用於因未能符合系統最低規格需求所產生的問題；且 </li>
                    <li>(iv) 本有限瑕疵擔保不適用於免費、試用版、預先發行、預覽版或搶鮮版 (Beta) 之產品。 </li>
                  </ul>
                  <br />c. 免責聲明。除本瑕疵擔保外，本公司不提供一切明示、默示、法定或其他之瑕疵擔保，包括適售性或符合特定目的之瑕疵擔保。除與相關法律不允許之情事不在此限，本免責聲明將適用本條款無其他瑕疵擔保責任之規定。
                </p>

                <div style={{ fontWeight: "bold" }}>6. 索賠之辯護。</div>
                <p>a. 辯護。如果非關係企業之第三人指控產品侵害其專利權、著作權或商標權，或是非法使用其營業秘密，而對 貴用戶提出索賠請求，則本公司將會為 貴用戶辯護。若因任何客戶資料 (i) 侵害第三人之專利權、著作權或商標權，或是故意非法使用其營業秘密；或 (ii) 因違反可接受的使用原則所引起，致使本公司遭非關係企業之第三人索賠， 貴用戶將會為本公司辯護。
                  <br />b. 限制規定。對於第 5a 節中之本公司的義務，將不適用於以下列事實為依據之索賠或裁定：(i) 客戶資料、非 Microsoft 軟體、 貴用戶對產品進行之修改，或是 貴用戶視同使用產品之一部分而提供或可供使用的資料；(ii) 可歸因於 貴用戶將產品與非 Microsoft 產品、客戶資料或商業流程合併使用之價值而造成之損害賠償；(iii) 貴用戶在未取得明示之書面同意的情況下使用 Microsoft 之商標；或本公司因第三人請求索賠而通知 貴用戶停止使用產品後， 貴用戶仍繼續使用；或者 (iv) 貴用戶將產品轉散布予非關係企業之第三人，或 貴用戶之使用係為非關係企業之第三人的利益。
                  <br />c. 救濟權。若本公司合理相信依第 5a 節中之請求索賠可能中止 貴用戶對產品之使用，本公司將尋求：(i) 取得 貴用戶繼續使用產品之權利；或 (ii) 修改產品或以功能相同產品取代之，並通知 貴用戶停止使用產品的較早版本。如果這些並非商業合理的選項，本公司得終止 貴用戶使用產品之權利，然後針對未使用的訂閱權利之任何預先付款退款給 貴用戶之轉銷商。
                  <br />d. 義務。每一方當事人必須立即通知他方當事人有關依本文第 6 節規定所提出之索賠事項。尋求保護的當事人必須 (i) 交由他方當事人全權決定此等索賠之辯護或和解方式；以及 (ii) 進行索賠辯護時，提供合理之協助。提供保護的當事人將 (1) 合理補償他方當事人在提供協助時所支付的費用，以及 (2) 必須支付不利之終局判決所判定應承擔的賠償費用 (或 貴用戶同意的和解費用)。各方當事人依第 6 節所擁有之各自辯護權利及支付判決或和解之權利，應以任何慣例或強制賠償權利或類似權利代替，且每一方當事人放棄此等慣例或法定權利。
                </p>

                <div style={{ fontWeight: "bold" }}>7. 責任限制。</div>
                <p>a. 限制規定。每一方當事人依據本合約所產生之所有索賠之總計賠償責任僅限於直接損害，賠償上限為發生訴訟情事之前 12 個月期間就線上服務所支付之金額；前提是任一方當事人對任何線上服務之總累計賠償責任，均不得超過針對該線上服務已支付之金額。若為免費提供之產品，Microsoft 的賠償責任僅限於直接損害，上限為 $5,000 美元。
                  <br />b. 排除。對於收入損失或間接、特殊性、附隨性、衍生性、懲罰性或懲戒性損害，或是因利潤損失、收入、營業中斷或商業資訊遺失所導致的損害，即使當事人已獲知該等損害賠償之可能性或可合理預見，任一方當事人均不負任何賠償責任。
                  <br />c. 限制之例外。本節中之賠償責任限制適用於相關法律許可的最完整範圍，但不適用於：(i) 依第 6 節之當事人的義務；或 (ii) 侵害他方當事人之智慧財產權。
                </p>

                <div style={{ fontWeight: "bold" }}>8. 軟體。</div>
                <p>a. 已訂購之授權。本公司授與 貴用戶訂購軟體拷貝數量之使用權。
                  <br />b. 授權確認。 貴用戶軟體使用權之證明係指 (i) 本合約，(ii) 訂單確認，(iii) 可證明使用權移轉之文件 (針對任何允許之移轉)，以及 (iv) 付款證明。
                  <br />c. 使用權與軟體媒體提供無關。 貴用戶於軟體媒體之取得或對網路來源之存取權，並不會影響 貴用戶依本合約取得之軟體的使用權。本公司係將授權軟體予 貴用戶，並非販售該軟體。
                  <br />d. 移轉及轉讓使用權。不得進行使用權移轉。
                </p>

                <div style={{ fontWeight: "bold" }}>9. 支援。</div>
                <p>依本合約訂購之產品的支援服務將由轉銷商提供。</p>

                <div style={{ fontWeight: "bold" }}>10. 合約機制。</div>
                <p>a. 聲明。 貴用戶必須透過郵件將通知寄送至以下地址。 <br />
                  <ul>
                    <li>通知應寄至：Microsoft Taiwan Corporation 8th Floor, 7 Sungren Rd. Shinyi Chiu Taipei 110 Taiwan</li>
                    <li>副本應寄至：Microsoft Corporation Legal and Corporate Affairs Volume Licensing Group One Microsoft Way Redmond, WA 98052 USA 傳真號碼：(425) 936-7329 </li>
                  </ul>
                  本公司得將通知以電子郵件方式寄予 貴用戶認定的帳戶管理者。郵寄回執聯上之日期或寄送電子郵件之日期，視同通知生效之日期。

                  <br />a. 聲明。 貴用戶必須透過郵件將通知寄送至以下地址。
                  <br />b. 轉讓。 貴用戶不得將本合約之全部或一部分進行轉讓。
                  <br />c. 分別性。若本合約之任何部分經裁定為無法執行時，合約之其餘條款仍具有完整之效果及效力。
                  <br />d. 拋棄。無法執行本合約之任何條款不應視為拋棄。
                  <br />e. 無第三方受益人。對本合約而言並沒有第三方受益人。
                  <br />f. 準據法及法庭地。本合約將以台灣法律為其準據法。為執行本合約而提起之任何訴訟均必須於台灣台北地方法院提出，指派其為本合約相關爭端之第一審法院進行裁決。此管轄法院之選擇，並不禁止任一方當事人就侵害智慧財產權時，於適當管轄地尋求暫時禁制令之救濟。
                  <br />g. 整份合約。本合約係指雙方當事人間就其合約標的之整份合約，且取代任何先前或同時存在之溝通內容。
                  <br />h. 存續。下列條款於本合約終止或屆滿後仍然有效：第 5 節 (瑕疵擔保)、第 6 節 (索賠之辯護)、第 7 節 (責任限制)、第 10f 節 (準據法及法庭地)、第 10g 節 (整份合約)、第 10h 節 (存續)、第 10i 節 (美國出口法規) 及第 11 節 (名詞定義)。
                  <br />i. 美國出口法規。產品受美國出口管轄地法規之規範。 貴用戶必須遵守所有相關法律，包括美國出口管制規定、國際武器交易規則 (International Traffic in Arms Regulation)，以及美國與其他政府所發布之使用者、使用與目的地之限制。如需詳細資訊，請參閱http://www.microsoft.com/exporting/。
                  <br />j. 不可抗力。若發生非任一方當事人可合理控制之原因 (例如火災、爆炸、停電、地震、洪水、暴風雨、罷工、禁運、勞資爭議、民間或軍事機構之行動、戰爭、恐怖攻擊 (包括網路恐怖攻擊)、天災、網路流量業者之行為或不作為、任何管理或政府機構之行為或不作為 (包括通過法律、或規則或其他會影響線上服務提供之政府行為)，而導致該方當事人無法履行義務時，任一方當事人均無須向他方當事人負責。惟本節不適用於 貴用戶依照本合約必須承擔的付款義務。
                  <br />k. 資訊之公開。Microsoft 得在包括 Microsoft 的客戶清單和其他宣傳資料中，公開揭露 (不論以口頭或書面) 貴用戶為 Microsoft 的客戶，和產品的購買者。
                  <br />l. 優先次序。若本合約中提及之任何文件間有相互牴觸且未於該等文件中明示表示其適用之情形，則其條款適用優先順序 (由高至低) 如下：(1) 本合約，(2) SLA，以及 (3) 線上服務條款。就相同合約標的而言，增修條款中條款之效力將超越增修文件及任何先前的增修條款。
                </p>

                <div style={{ fontWeight: "bold" }}>11. 名詞定義。</div>
                <p>本合約中提及之任何「天」係指一個日曆天。
                  <br />「可接受的使用原則」已列在線上服務條款中，或由 Microsoft 另行顯目提供。
                  <br />「關係企業」係指一方當事人所擁有之任何法律實體，或擁有一方當事人之法律實體，或擁有某實體 50% 或以上之利益的法律實體。
                  <br />「預付方案」及「消費方案」係描述訂閱方案的類別，定義詳見第 3 節。
                  <br />「客戶資料」已定義在線上服務條款中。
                  <br />「線上服務」係指依本合約訂閱之任何 Microsoft 線上服務。
                  <br />「線上服務條款」係 貴用戶對 http://www.microsoft.com/licensing/onlineuserights 上可取得之產品的使用可適用之條款。線上服務條款包含除本合約指定之條款外，其他規範 貴用戶對產品之使用的條款。
                  <br />「產品」係指任何線上服務 (包括任何軟體)。
                  <br />「轉銷商」係指經 Microsoft 授權且依據本方案轉銷軟體使用權及線上服務訂閱，並由 貴用戶雇用，負責提供交易前與交易後協助之實體。
                  <br />「SLA」係指本公司針對線上服務之提供及/或效能所進行的服務等級協定承諾，詳如下列網站所公佈：http://www.microsoft.com/licensing/contracts、http://www.windowsazure.com/en-us/support/legal/sla/ 或本公司指定之替代網站上。
                  <br />「軟體」係指本公司視同 貴用戶訂閱的一部分而提供給 貴用戶安裝在裝置上的軟體，或可供搭配線上服務使用，以啟動特定功能的軟體。
                  <br />「訂閱」係指定義期間 (例如，30 天或 12 個月) 內某產品數量之訂單。
                  <br />「期間」係指 貴用戶之轉銷商針對特定產品所建立之訂閱期間
                </p>
              </StyledPaper>
            </StyledTabPanel>
            <StyledCheckboxGrid item xs={12}>
              <FormControlLabel control={
                <StyledCheckbox
                  color="primary"
                  inputProps={{ 'aria-label': 'first checkbox' }}
                  onChange={handleFirstContractChecked} />
              }
                label="申請人已詳閱並同意本業務服務契約。"
              />
            </StyledCheckboxGrid>
            <StyledCheckboxGrid item xs={12}>
              <FormControlLabel control={
                <StyledCheckbox
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={handleSecondContractChecked}
                />
              }
                label="申請人已詳閱並同意 Microsoft 雲端合約。"
              />
            </StyledCheckboxGrid>


            <StyledButtonGroup container>
              <StyledGrid item xs={6}>
                <StyledBackBtn
                  variant="contained"
                  color="primary"
                  onClick={hidePurchaseContract}
                >
                  重新輸入
                </StyledBackBtn>
              </StyledGrid>
              <StyledGrid item xs={6}>
                <StyledSubmitBtn
                  variant="contained"
                  color="primary"
                  onClick={onConfirmOrder}
                  disabled={confirmOrderButtonDisabled}
                >
                  立即結帳
                </StyledSubmitBtn>
              </StyledGrid>
            </StyledButtonGroup>
          </>
        )}
      </Grid>

      <NoOTPLoginModal
        open={openOTPInputModal}
        openHiB2BModal={openHiB2BModal}
        setOTPCellHandler={setOTPCellHandler}
      ></NoOTPLoginModal>

      <HiB2BModal
        open={statusHiB2BModal}
        setNewMemberHN={setNewMemberHNHandler}
        setPromoEmpNoHandler={setPromoEmpNoHandler}
        closeModal={closeHiB2BModal}
        otpCell={otpCell}
      ></HiB2BModal>
    </Container>
  );
}

