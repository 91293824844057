import React, { forwardRef, useEffect } from "react";

// Table
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import Save from "@material-ui/icons/Save";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DetailsIcon from '@material-ui/icons/Details';

// Restful API
import Axios from "axios";
//import Qs from "qs";

// Component
import {
  Container,
  Paper
} from "@material-ui/core";

// Style CSS
import { makeStyles } from '@material-ui/core/styles';
import { useCookies } from "react-cookie";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  containerNoPermission: {
    minHeight: 500,
    //height: '100%'
  },
  container: {
    marginTop: 10,
    height: '100%',
    width: '110%'
  },
  button: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

// Define table Icons
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Details: forwardRef((props, ref) => (<DetailsIcon {...props} ref={ref} />)),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <Save {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function Logs() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [smsLog, setSmsLog] = React.useState({
    Sn: 0,
    Msgtype: "",
    Smstype: "",
    Cell: "",
    RequestMsg: "",
    RequestDate: "",
    ReturnCode: "",
    ResponseMsg: "",
    ResponseDate: "",
  });

  const [cookies] = useCookies();
  const [permission, setPermission] = React.useState(0);
  const notPermitted = 1;

  // Fetch data before render
  useEffect(() => {
    var gid = isNaN(parseInt(cookies.group_id)) ? 0 : parseInt(cookies.group_id);
    if (gid !== 0) {
      getGroupPermission(gid);
    }
    getmemberLogList();
    //getMemberData();
  }, [cookies]);

  const getGroupPermission = (sn) => {
    var postData = {};
    postData["Sn"] = sn;
    Axios.post("/OP/group/getGroupData", postData).then(function (response) {
      var res = response.data.data;
      for (var i in res) {
        if (res[i].name === '簡訊寄送紀錄') {
          setPermission(res[i].permission);
          break;
        }
      }
    }).catch(function (err) {
      console.log(err);
    });
  };

  // Get table data
  function getmemberLogList() {
    Axios.get("/api/sms/getSmsLogData").then(res => {
      for (var i = 0; i < res.data.length; ++i) {
        res.data[i]["RequestDate"] = res.data[i]["RequestDate"].split(".")[0].replace("T", " ").replace("Z", "")
        res.data[i]["ResponseDate"] = res.data[i]["ResponseDate"].split(".")[0].replace("T", " ").replace("Z", "")
      }
      res.data.sort((a, b) => (a.Sn < b.Sn) ? 1 : -1);
      setRows(res.data);
    });
  }
  
  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.containerNoPermission}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: 'center',
                minHeight: '100vh'
              }}
            >
              <Grid item style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 200
              }}>
                <Typography style={{ fontSize: 100 }}>
                  您無此權限
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }

  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.containerNoPermission}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: 'center',
                minHeight: '100vh'
              }}
            >
              <Grid item style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 200
              }}>
                <Typography style={{ fontSize: 100 }}>
                  您無此權限
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }

  return (
    <Container className={classes.container}>
      <Paper>
        <MaterialTable
          columns={[
            { title: '訊息類型', field: 'Msgtype' },
            { title: '簡訊類別', field: 'Smstype' },
            { title: '會員手機號碼', field: 'Cell' },
            { title: '簡訊傳送內容', field: 'RequestMsg' },
            { title: '簡訊傳送時間', field: 'RequestDate' },
            { title: '回傳訊息', field: 'ReturnCode' },
            { title: '存取來源網站	', field: 'ResponseMsg' },
            { title: '回傳時間	', field: 'ResponseDate' },
          ]}
          data={rows}
          title="簡訊發送紀錄管理"
          icons={tableIcons}
          options={{
            searchText:"",
            headerStyle: {
              backgroundColor: "#888",
              color: "#FFF"
            },
            rowStyle: (rowData) => ({
              backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
            }),
          }}
        />
      </Paper>
    </Container>
  );
}



