import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Dialog,
  DialogTitle,
  Grid,
  Box,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";

var mailLogDetailDefault = {
  mailTo: "",
  mailSubject: "",
  mailBody: "",
  sendStatus: "",
  errMessage: "",
  sendTime: "2020-03-23T05:45:08.6942786Z",
  functionDescription: "",
};
export default function ReviewModal(props) {
  useEffect(() => {
    setModalStatus(props.openModal);
    setMailLogDetail(props.mailLogDetail);
  }, [props.openModal, props.mailLogDetail]);

  const [modalStatus, setModalStatus] = useState(false);
  const [mailLogDetail, setMailLogDetail] = useState(mailLogDetailDefault);
  const closeModal = props.closeModal;

  const dataFieldList = [
    createLabel(
      "寄送時間: ",
      mailLogDetail.sendTime != null
        ? format(new Date(mailLogDetail.sendTime), "yyyy/MM/dd HH:mm:ss")
        : ""
    ),
    createLabel("收件人：", mailLogDetail.mailTo),
    createLabel("副本：", ""),
    createLabel("密件副本：", ""),
    createLabel("主旨：", mailLogDetail.mailSubject),
    createLabel("使用功能：", mailLogDetail.functionDescription),
    createLabel(
      "錯誤訊息：",
      mailLogDetail.errMessage !== "" ? mailLogDetail.errMessage : "NULL"
    ),
    createLabel(
      "信件內容：",
      <Typography
        variant="body2"
        component={"span"}
        dangerouslySetInnerHTML={{
          __html: mailLogDetail.mailBody,
        }}
      ></Typography>
    ),
  ];
  return (
    <Dialog open={modalStatus} onClose={closeModal} maxWidth="lg">
      <DialogTitle>記錄明細</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          {dataFieldList.map((data, index) => {
            return (
              <Grid item xs={12} key={index}>
                {data}
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>確定</Button>
      </DialogActions>
    </Dialog>
  );
}

function createLabel(title, text) {
  return (
    <Grid container>
      <Grid item xs={2}>
        <Box m={1}>{title}</Box>
      </Grid>
      <Grid item xs={10}>
        <Box m={1}>{text}</Box>
      </Grid>
    </Grid>
  );
}
