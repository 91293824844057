import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import VideoConference1 from "../assets/ProductOverview/all-17.jpg";
import VideoConference2 from "../assets/ProductOverview/all-18.jpg";
import VideoConference3 from "../assets/ProductOverview/all-19.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

// CSS
const StyledContainer = Styled(Grid)`
  && {
    margin-bottom: 120px;
  }
`
const StyledGrid = Styled(Grid)`
  && {
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 30px 0 30px;
  }
`
const StyledContextImage = Styled.img`
  max-width: 100%;
  border: 2px solid #28a8ea;
  border-radius: 10px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #28a8ea;
    margin: 0px;
    margin-bottom: 10px;
  }
`
const StyledContextContent = Styled(Typography)`
  && {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`

function renderContext() {
  const contexts = [
    {
      title: "易得好用的視訊影片",
      context:
        "從現代且直覺的影片位置輕鬆發掘影片。Office 365 影片可讓員工不受裝置限制，在公司上下找尋並檢視重要主題和想法，隨時齊頭並進掌握最新資訊。",
      img: VideoConference1
    },
    {
      title: "廣泛分享想法",
      context:
        "拖放即可輕鬆上傳影片。接著輸入標籤和描述項， 在 Yammer 交談中分享影片。輕鬆分享訓練課程、行政溝通、服務台操作指導和更多豐富影片。",
      img: VideoConference2
    },
    {
      title: "內建安全和簡易管理功能",
      context:
        "Office 365 影片提供可自由擴充的企業級視訊影片解決方案。管理員可輕鬆管理權限、新增或移除頻道，並且突顯重要內容。所有功能均受 Office 365 和 Azure Media Services 支援，提供經加密處理、領先業界的平台環境。",
      img: VideoConference3
    }
  ];

  return contexts.map(context => (
    <StyledContainer container>
      <StyledGrid item md={5} sm={12}>
        <StyledContextImage src={context.img}></StyledContextImage>
      </StyledGrid>
      <StyledGrid item md={7} sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextDivider/>
        <StyledContextContent>{context.context}</StyledContextContent>
      </StyledGrid>
    </StyledContainer>
  ));
}

export default function CenteredGrid(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {renderContext()}
      </Grid>
    </div>
  );
}
