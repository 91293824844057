import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

import { 
  Typography,
  Container,
  Grid,
  Divider,
  Button,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions
 } from "@material-ui/core";

import MuiDialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 8,
      padding: theme.spacing(2)
    }
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography color="primary" variant="h6">
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

export default function DialogProductInfo(props) {

  return (
    <>
      <Dialog open={props.open} onClose={props.handleDialogClose} maxWidth="sm">
        <div>
          <img src={props.support.banner} alt="" width="100%"></img>
        </div>
        <DialogContent dividers scroll="paper">
          <DialogTitle>
            {props.title.title + " " + props.title.edition + " 包含："}
          </DialogTitle>
          {props.support.contents.map((item, i) => {
            return createRow(item.title, item.icon, item.content, i);
          })}
          <br />
          <br />
          <Divider/>
          <br />
          <br />
          <DialogTitle>所有的 Office 365 企業版方案包含：</DialogTitle>
          {props.supportAll.map((item, i) => {
            return createRow(item.title, item.icon, item.content, i);
          })}
        </DialogContent>
        <DialogActions>
          <Button size="medium" variant="outlined" color="primary" onClick={props.handleDialogClose} >關閉</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function createRow(subtitle, icon, content, key) {
  return (
    <Container key={key}>
      <Grid container spacing={1}>
        <Grid item xs={2} md={1}>
          <Avatar src={icon} />
        </Grid>
        <Grid item xs={10} md={2}>
          <Typography color="primary" variant="subtitle1">
            {subtitle}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography gutterBottom>{content}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
