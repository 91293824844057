import React from "react";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Styled from "styled-components";
// import { makeStyles } from "@material-ui/core/styles";
import DiscountInfo01 from "./assets/DiscountInfo/892964.png";

// const useStyles = makeStyles({
//   root: {
//     maxWidth: 345
//   },
//   media: {
//     maxWidth: 345,
//     height: 160
//   }
// });
const StyledContainer = Styled(Grid)`
  && {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`
const StyledGrid = Styled(Grid)`
  && {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`
const StyledCard = Styled(Card)`
  && {
  }
`
const StyledCardContent = Styled(CardContent)`
  && {
    font-size: 1rem;
    text-align: center;
  }
`
const StyledCardMedia = Styled(CardMedia)`
  && {
    width: 25vw;
    height: 20vh;
    min-width: 240px;
    min-height: 108px;
    background-size: cover;
    max-width: 100%;
  }
`

export default function DiscountInfo() {
  const discountTitle = "O365 Teams通信防疫包";

  return (
    <StyledContainer container spacing={3}>
      <StyledGrid item md={4} sm={12}>
        <a href="https://www.cht.com.tw/home/enterprise/cloud-idc/cloud-service/hinet-microsoft-cloud" target="_blank" rel="noreferrer noopener">
          <StyledCard>
            <CardActionArea>
              <StyledCardMedia
                image={DiscountInfo01}
                title={discountTitle}
                // className={classes.media}
              />
            </CardActionArea>
            <StyledCardContent>{discountTitle}</StyledCardContent>
          </StyledCard>
        </a>
      </StyledGrid>
    </StyledContainer>
  );
}
