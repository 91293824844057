import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  Grid,
  Divider,
  Button,
  Dialog,
  DialogTitle
} from "@material-ui/core";
import { Redirect, Link } from "react-router-dom";

import Axios from "axios";
import Styled from "styled-components";

// CSS
const StyledDialog = Styled(Dialog)`
.MuiDialog-paper {
  padding: 8px 20px;
  border-radius: 14px;
}
`
const StyledDialogTitle = Styled(DialogTitle)`
&& {
  display: flex;
  justify-content: center;
}
.MuiTypography-root {
  font-weight: 800;
  font-size: 1.28rem;
  letter-spacing: 0.04em;
}
`
const StyledDialogContent = Styled(Grid)`
&& {
  display: flex;
  justify-content: space-around;
  padding: 16px 24px;
}
`
const StyledDivider = Styled(Divider)`
&& {
  margin-left: 25px;
  margin-right: 25px;
}
`
const StyledIsHnButton = Styled(Button)`
&& {
  width: 150px;
  color: #fff;
  background-color: #28a8ea;
  border-radius: 20px;
  padding: 6px 20px;
  margin-right: 3px;
  box-shadow: none;
  border: 2px solid #28a8ea;
}
&&:hover {
  border: 2px solid #28a8ea;
  background-color: #008cd5;
  box-shadow: none;
}
`
const StyledNonHnButton = Styled(Button)`
&& {
  width: 150px;
  color: #28a8ea;
  background-color: #fff;
  border: 2px solid #28a8ea;
  border-radius: 20px;
  padding: 6px 20px;
  margin-left: 3px;
  box-shadow: none;
}
&&:hover {
  border: 2px solid #28a8ea;
  box-shadow: none;
}
`
const StyledLink = Styled(Link)`
&& {
  text-decoration: none;
}
`
const StyledGrid = Styled(Grid)`
&& {
  display: flex;
  justify-content: center;
  align-item: center;
  margin: 10px 0px;
}
`

export default function BeforePurchase(props) {
  // check has props
  //    Yes -> continue
  //    No  -> redirect to /
  // check sessionId is valid
  //    Yes -> redirect to /Purchase with applyHN = false
  //    No  -> continue
  // button hasHN -> redirect to AAALogin
  // button noHN -> redirect to /Purchase with applyHN = true

  const history = useHistory();
  const [cookies] = useCookies(["session", "op_name"]);
  const [redirectPurchaseToggle, setRedirectPurchaseToggle] = useState(false); // Control the Redirect Component Rendering
  const [open, setOpen] = useState(false); // Control the Dialog Component open or close
  const handleDialogClose = () => {
    props.handleDialogClose(false);
    setOpen(false);
  }
  useEffect(() => {
    if (props.open === true) { // 表示使用者點擊 立刻購買 button
      // 在 cookies.sessionId 為空（表示未登入）、data 也傳進來情況下，props.open set true 才會渲染 Dialog
      if ((cookies.sessionId === undefined || cookies.sessionId === "") && (props.data !== undefined)) {
        setRedirectPurchaseToggle(false);
        setOpen(props.open);
        console.log("同步 props.open from ProductCard.js and open from BeforePurchase.js 參數.");
      } else if ((cookies.sessionId !== undefined || cookies.sessionId !== "") && (props.data !== undefined)) {
        // 在 cookies.sessionId 不為空（表示已登入）、data 也傳進來情況下，props.open set true 才直接渲染 Redirect
        setRedirectPurchaseToggle(true);
        props.handleDialogClose(false);
        setOpen(false);
        console.log("已經登入過，可直接購買。");
      }
    } else {
      return
    }
  }, [props.open, cookies.sessionId, props.data, props]);

  let data = {};

  // check data
  if (props.data === undefined) { // no props redirect to /
    history.push("/");
  } else {
    data = props.data;
    if (data.key.length && data.productTitle.length) {
      //month
      let planMonth = 0;
      let monthProdDetailSn = data.value[planMonth].detailSn;
      getProductInfo(monthProdDetailSn, planMonth);

      //year
      let planYear = 1;
      let yearProdDetailSn = data.value[planYear].detailSn;
      getProductInfo(yearProdDetailSn, planYear);
    }
    // console.log(data);
  }

  // check seesion ID
  // console.log(cookies.sessionId);
  if (cookies.sessionId !== undefined && cookies.sessionId !== "") {
    // already login
    data.applyHN = false;
  } else {
    data.applyHN = true;
  }

  // Get product simple description
  function getProductInfo(prodDetailSn, plan) {
    /* Axios.post("/api/product/detail", {
      Sn: prodDetailSn
    }).then(res => {
      data.value[plan].simple = res.data.simple;
      // console.log(data);
    }).catch(err => {
      //console.log(err);
    }); */
  }

  const AAALogin = () => {
    Axios.post("/AAA/login", { redirectUrl: "/BuyNow" })
      .then((res) => {
        const data = res.data;
        //console.log(data["url"]);
        window.location.assign(data["url"]);
        // AAAresopnse();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {redirectPurchaseToggle && (
        <Redirect to={{ pathname: "Purchase", state: { data: data, }, }} />
      )
      }
      <StyledDialog open={open} onClose={handleDialogClose} maxWidth="md">
        <StyledDialogTitle>
          <Grid item xs={12}>
            選擇登入方式
          </Grid>
        </StyledDialogTitle>
        <StyledDivider />
        <StyledDialogContent container>
          <StyledGrid item xs={12} md={6}>
            <StyledIsHnButton variant="contained" color="primary" onClick={AAALogin}>
              有 HN 號碼登入
            </StyledIsHnButton>
          </StyledGrid>
          <StyledGrid item xs={12} md={6}>
            <StyledLink to={{ pathname: "Purchase", state: { data: data } }}>
              <StyledNonHnButton variant="outlined" color="primary">
                無 HN 購買
              </StyledNonHnButton>
            </StyledLink>
          </StyledGrid>
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
}
