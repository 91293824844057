import React, {useState} from "react";
import Cookies from 'js-cookie';
import md5 from "md5";

// core
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid
} from "@material-ui/core";

// API
import Axios from "axios";

export default function LoginDialogForCheckID(props) {
    const [pwd, setPwd] = useState('');
    const {open, handleToggleShowAllMemberData, handleCloseLoginDialog} = props;

    const handleChange = (e) => {
        setPwd(e.target.value);
    }

    const loginAdmin = () => {
        Axios
            .post("/api/v0/cheatLogin", {})
            .then(function (response) {
                handleToggleShowAllMemberData(); // 隱碼解除
                handleCloseLoginDialog(); // 關掉login Dialog
            })
            .catch(function (error) {
                alert(error.response.data);
            });
    };

    const handleConfirmPwd = () => {
        Axios
          .post("/api/v0/loginForDataSecurity", {
            id: Cookies.get('op_name'),
            pwd: md5(pwd),
          })
          .then(function (response) {
              console.log(response.data.message);
              // if it is success, it will return true(Boolean)
              handleToggleShowAllMemberData();    // 隱碼解除
              handleCloseLoginDialog();   // 關掉login Dialog
              setPwd("");   // 移除所輸入之密碼
          })
          .catch(function (error) {
            setPwd("");   // 移除所輸入之密碼
            alert(error.response.data);
          });
    }

  return (
    <>
        <Dialog 
            open={open}
            onClose={handleCloseLoginDialog}
        >
            <DialogTitle>身分驗證</DialogTitle>
            <DialogContent>
            <DialogContentText>
                請輸入您的 <b>使用者密碼</b> 以確保是本人操作：
            </DialogContentText>
            <TextField
                fullWidth
                name="password"
                label="Password"
                type="password"
                variant="outlined"
                value={pwd}
                onChange={handleChange}
            />
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleCloseLoginDialog}
                            >
                                取消
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmPwd}
                            >
                                確認
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {(process.env.REACT_APP_MODE === "development") && (
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={loginAdmin}
                                >
                                    Admin
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    </>
  );
}
