import React, { forwardRef, useEffect } from "react";
import MaterialTable from "material-table";

// icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";

import Axios from "axios";

// Component
import { Box } from "@material-ui/core";

import EditDialog from "./EditDialog";

import { useCookies } from "react-cookie";

import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";

// Style CSS
import { makeStyles } from "@material-ui/core/styles";

// Refreshing cookies mechanism
import { useHistory } from "react-router-dom";
import {
  getCookie,
  handleRefreshLoginCookies,
  handleExpiredCookies,
} from "../../common/session";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 500,
    //height: '100%'
  },
}));

const tableIcons = {
  // CRUD
  Add: forwardRef((props, ref) => (
    <AddCircleOutlineIcon {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  // page
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  // search
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
};

export default function Members() {
  const [rows, setRows] = React.useState([]);

  //Dialog parameters
  const [dialogEnable, setDialogEnable] = React.useState(false);

  const [cookies] = useCookies();

  // Refreshing cookies mechanism
  let history = useHistory(); // 取得某頁面route name

  const [permission, setPermission] = React.useState(0);
  const notPermitted = 1;
  const readOnly = 2;
  const debugMode = true;

  var [memberDetail, setMemberDetail] = React.useState({
    hib2bsn: 0,
    sn: 0,
    hn: "",
    hd: "",
    //Id: "",
    idno: "",
    companyno: "",
    contactname: "",
    contactlastname: "",
    telmorning: "",
    cell: "",
    zip: "",
    registerstatus: 0,
    registerdate: "",
    lastdate: "",
    name: "",
    email: "",
    city: "",
    address: "",
    //AccountType: 0,
    //LevelSn: 0,
    //HinetSn: 0,
    isdeleted: false,
  });

  // Is creating new membership
  const [isCreateNewMember, setIsCreateNewMember] = React.useState(false);

  useEffect(() => {
    var gid = isNaN(parseInt(cookies.group_id))
      ? 0
      : parseInt(cookies.group_id);
    if (gid !== 0) {
      getGroupPermission(gid);
    }

    getData();
  }, [cookies.group_id]);

  // Refreshing cookies mechanism
  useEffect(() => {
    if (getCookie("session") !== undefined) {
      // 按下登出按鈕 或是 Session 仍存在的情況下進行換頁。
      handleRefreshLoginCookies();
      if (debugMode) {
        console.log("Call loginCheck api- " + getCookie("session"));
      }
      Axios.post("/api/v0/loginCheck", {
        session: getCookie("session"),
      })
        .then(function (response) {
          if (response.data.IsLogin !== true) {
            handleExpiredCookies();
            history.replace("/backoffice/Login");
          }
        })
        .catch(function (error) {
          console.log("OPRouter", error);
        });
    } else {
      // Session 過期的情況下進行換頁。
      handleExpiredCookies();
      history.replace("/backoffice/Login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dialogEnable, // 新增、編輯會員時。
  ]);

  function handleDelete(selected) {
    if (window.confirm("確定刪除此用戶？")) {
      var SnToDelete = parseInt(selected.sn);
      Axios.post("/api/member/deleteMember", { Sn: SnToDelete })
        .then(
          (res) => {
            console.log(res.data);
            // window.location.reload();
          },
          (error) => {
            console.log(error);
            // window.location.reload();
          }
        )
        .finally(() => {
          getData();
        });
    }
  }

  const getGroupPermission = (sn) => {
    var postData = {};
    postData["Sn"] = sn;
    Axios.post("/OP/group/getGroupData", postData)
      .then(function (response) {
        var res = response.data.data;
        for (var i in res) {
          if (res[i].name === "會員管理") {
            setPermission(res[i].permission);
            break;
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getData = () => {
    Axios.get("/api/member/readMember").then((res) => {
      if (!(res.data === undefined || res.data === null)) {
        for (var i = 0; i < res.data.length; ++i) {
          res.data[i]["registerdate"] = res.data[i]["registerdate"]
            .split(".")[0]
            .replace("T", " ")
            .replace("Z", "");
          res.data[i]["lastdate"] = res.data[i]["lastdate"]
            .split(".")[0]
            .replace("T", " ")
            .replace("Z", "");
        }

        res.data.sort((a, b) => (a.sn < b.sn ? 1 : -1));

        setRows(res.data);
      }
    });
  };

  const handleClose = () => {
    setDialogEnable(false);
    getData();
  };

  function handleCreateNewMember() {
    setIsCreateNewMember(true);
    setMemberDetail({
      hib2bsn: 0,
      sn: 0,
      hn: "",
      email: "",
      registerstatus: 0,
      registerdate: new Date().toString(),
      lastdate: new Date().toString(),
    });
    setDialogEnable(true);
  }

  const actions =
    permission === readOnly
      ? []
      : [
          {
            icon: tableIcons.Add,
            tooltip: "Add",
            isFreeAction: true, // this flag will show action at top-right
            onClick: (event) => {
              handleCreateNewMember();
            },
          },
          {
            icon: tableIcons.Edit,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setIsCreateNewMember(false);
              setDialogEnable(true);
              setMemberDetail(rowData);
            },
          },
          {
            icon: tableIcons.Delete,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              handleDelete(rowData);
            },
          },
        ];

  var classes = useStyles();
  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.container}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: "center",
                minHeight: "100vh",
              }}
            >
              <Grid
                item
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 200,
                }}
              >
                <Typography style={{ fontSize: 100 }}>您無此權限</Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }
  return (
    <Box m={2}>
      <MaterialTable
        title="會員管理"
        columns={[
          { title: "用戶HN", field: "hn" },
          { title: "公司/客戶名稱", field: "name" },
          { title: "聯絡人（姓）", field: "contactlastname" },
          { title: "聯絡人（名）", field: "contactname" },
          // { title: "公司統編", field: "companyno" },
          { title: "用戶信箱", field: "email" },
          { title: "註冊日期", field: "registerdate" },
          { title: "最後登入日", field: "lastdate" },
        ]}
        data={rows}
        options={{
          searchText: "",
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 500],
          actionsColumnIndex: -1,
          showTextRowsSelected: true,
          headerStyle: {
            backgroundColor: "#888",
            color: "#FFF",
          },
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
          }),
        }}
        icons={tableIcons}
        actions={actions}
      />
      <EditDialog
        dialogEnable={dialogEnable}
        handleClose={handleClose}
        memberDetail={memberDetail}
        setMemberDetail={setMemberDetail}
        isCreateNewMember={isCreateNewMember}
        style={{ zIndex: -1 }}
      />
    </Box>
  );
}
