// core
import React, { useState } from "react";
import { useEffect } from "react";
import Container from "@material-ui/core/Container";

// icon
import AssignmentIcon from "@material-ui/icons/Assignment";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

// router
import UPRouter from "./Router.js";

// components
import UPHeader from "./Header.js";
// import UPNavigation from "./Navigation.js";
import UPDrawer from "./Drawer.js";
import UPFooter from "./Footer.js";

export default function UPIndex(props) {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  // for company to collect data, only for production
  useEffect(() => {
    if (process.env.REACT_APP_MODE === "production" || process.env.REACT_APP_MODE === "intranet") {
      const script = document.createElement("script");
      script.src = "//t.ssp.hinet.net/campaign/event.js";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const pages = [
    { icon: <AssignmentIcon />, name: "產品總覽", path: "/ProductOverview" },
    { icon: <TrendingDownIcon />, name: "優惠訊息", path: "/DiscountInfo" },
    { icon: <ShoppingBasketIcon />, name: "立刻購買", path: "/BuyNow" },
    {
      icon: <LibraryBooksIcon />,
      name: "教學中心",
      subPages: [
        { name: "教學影片", path: "/TeachingVideo" },
        { name: "資源下載", path: "/DownloadResource" },
        { name: "購買Office 365 10大理由", path: "/Buying10Reasons" }
      ]
    },
    {
      icon: <AccountCircleIcon />,
      name: "客戶專區",
      subPages: [
        { name: "訂單查詢", path: "/OrderStatus" }
        //{ name: "客戶聯絡信箱", path: "/CustomerContactEmail" }
      ]
    },
    { icon: <HelpOutlineIcon />, name: "線上QA", path: "/OnlineQA" }
  ];

  return (
    <>
      <UPHeader handleDrawerOpen={e => setDrawerIsOpen(true)} />

      <UPDrawer
        pages={pages}
        drawerIsOpen={drawerIsOpen}
        handleDrawerOpen={e => setDrawerIsOpen(true)}
        handleDrawerClose={e => setDrawerIsOpen(false)}
      />

      <Container>
        {/* <UPNavigation pages={pages} /> */}
        <UPRouter />
      </Container>

      <UPFooter />
    </>
  );
}
