import React, { useState } from "react";

// core
import {
    List,
    Collapse,
    Drawer,
    SwipeableDrawer,
    ListItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery, 
    Divider
  } from '@material-ui/core';

// HTTP request tool
import axios from "axios";

// Style CSS
import styled from 'styled-components';

// icons
  import {
    ExpandLess,
    ExpandMore,
    MeetingRoom
  } from '@material-ui/icons';

// router
import { Link } from "react-router-dom";

// CSS
const StyledDrawerPaper = styled.div`
  margin: 10px;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`
const StyledListItem = styled(ListItem)`
  && {
    border-radius: 15px;
    margin: 7px 0px 7px 0px;
  }
  &&:hover {
    color: #1E90FF;
    background-color: #ebf0fe;
    .MuiSvgIcon-root {
      color: #1E90FF;
    }
  }
`

export default function UPDrawer(props) {
  // 分別控制 nested drawer TeachingCenter CustomerCenter item 的狀態

  const [openDrawerListOfTeachingCenter, setOpenDrawerListOfTeachingCenter] = useState(false);
  const [openDrawerListOfCustomerCenter, setOpenDrawerListOfCustomerCenter] = useState(false);
  const drawerList = [];
  const toggleView = useMediaQuery('(min-width:600px)');
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  function AAALogin() {
    axios.post("/AAA/login").then((res) => {
      const data = res.data;
      //console.log(data["url"]);
      window.location.assign(data["url"]);
      // AAAresopnse();
    });
  }

  const renderDrawerComponent = (value) => {
    switch(value){
      case true:
        // Web View
        return(
          <Drawer open={props.drawerIsOpen} onClose={props.handleDrawerClose}>
            <StyledDrawerPaper role="presentation">
                {drawerList}
            </StyledDrawerPaper>
          </Drawer>
        )
      case false:
        // Phone View (Include iOS)
        return(
          <SwipeableDrawer open={props.drawerIsOpen} disableBackdropTransition={!iOS} disableDiscovery={iOS} onOpen={props.handleDrawerOpen} onClose={props.handleDrawerClose}>
            <StyledDrawerPaper role="presentation">
              {drawerList}
            </StyledDrawerPaper>
          </SwipeableDrawer>
        )
      default:
        return(
          <></>
        )
    }
  }

  // Login AAA on Drawer
  drawerList.push(
    <StyledListItem key="登入" button onClick={AAALogin}>
      <ListItemIcon>
        <MeetingRoom />
      </ListItemIcon>
      <ListItemText primary="登入"/>
    </StyledListItem>
  );

  // The others on Drawer
  for (var page of props.pages) {
    if (page.subPages) {
      // SubPages in ListItems of Drawer is NOT NULL
      const subPageList = [];
      for (var subPage of page.subPages) {
        subPageList.push(
          <StyledLink key={subPage.toString()} to={subPage.path}>
            <StyledListItem key={subPage.toString()} button onClick={props.handleDrawerClose}>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={subPage.name} />
            </StyledListItem>
          </StyledLink>
        );
      }
      if(page.name === "教學中心") {
        // Control Expanding State of Teaching Center ListItem on Drawer.
        drawerList.push(
          <StyledListItem
            key={page.subPages.toString()}
            button
            onClick={() => setOpenDrawerListOfTeachingCenter((prevState) => !prevState)}
          >
            <ListItemIcon>{page.icon}</ListItemIcon>
            <ListItemText primary={page.name} />
            {openDrawerListOfTeachingCenter ? <ExpandLess /> : <ExpandMore />}
          </StyledListItem>
        )
        drawerList.push(
          <Collapse
            key={page.subPages.toString()}
            in={openDrawerListOfTeachingCenter}
            timeout="auto"
            unmountOnExit
          >
            <Divider></Divider>
            <List component="div">
              {subPageList}
            </List>
          </Collapse>
        )
      } else if (page.name === "客戶專區") {
      // Control Expanding State of Customer Center ListItem on Drawer.
        drawerList.push(
          <StyledListItem
            key={page.subPages.toString()}
            button
            onClick={() => setOpenDrawerListOfCustomerCenter((prevState) => !prevState)}
          >
            <ListItemIcon>{page.icon}</ListItemIcon>
            <ListItemText primary={page.name} />
            {openDrawerListOfCustomerCenter ? <ExpandLess /> : <ExpandMore />}
          </StyledListItem>
        )
        drawerList.push(
          <Collapse
            key={page.subPages.toString()}
            in={openDrawerListOfCustomerCenter}
            timeout="auto"
            unmountOnExit
          >
            <Divider></Divider>
            <List component="div">
              {subPageList}
            </List>
          </Collapse>
        )
      }
    } else {
      // SubPages in ListItems of Drawer is NULL
      drawerList.push(
        <StyledLink key={page.toString()} to={page.path}>
          <StyledListItem button onClick={props.handleDrawerClose}>
            <ListItemIcon>{page.icon}</ListItemIcon>
            <ListItemText primary={page.name}/>
          </StyledListItem>
        </StyledLink>
      );
    }
  }

  return (
    <>
      {renderDrawerComponent(toggleView)}
    </>
  );
}
