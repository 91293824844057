import React from "react";
import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Overview from "./Overview";
import Office from "./Office";
import EmailAndCalendar from "./EmailAndCalendar";
import OnlineMeeting from "./OnlineMeeting";
import FileSharing from "./FileSharing";
import VideoConference from "./VideoConference";
import SearchAndExploration from "./SearchAndExploration";
import SafetyAndPrivacy from "./SafetyAndPrivacy";
import Styled from 'styled-components';

// MUI icon
import PublicIcon from '@mui/icons-material/Public';
import AppsIcon from '@mui/icons-material/Apps';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SearchIcon from '@mui/icons-material/Search';
import GppGoodIcon from '@mui/icons-material/GppGood';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// Auto Enter others Props
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

// CSS
const StyledRoot = Styled.div`
  && {
    background-color: #f9f9ff;
    padding-bottom: 20px;
    border-radius: 0 0 8px 8px;
  }
`
const StyledAppBar = Styled(AppBar)`
  && {
    margin-bottom: 20px;
    width: auto;
  }
`
const StyledTabs = Styled(Tabs)`
  && {
    background-color: #28a8ea;
  }
  .MuiTabScrollButton-root {
    padding: 7px;
    background-color: #28a8ea;
    border: 1px solid #0078ff;
  }
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.2;
  }
  .PrivateTabIndicator-colorSecondary-3 {
    background-color: #28a8ea;
  }
`
const StyledTab = Styled(Tab)`
  && {
    min-width: 150px;
    min-height: 95px;
    font: normal 0.95rem/1.5 Arial, Helvetica, "微軟正黑體";
    background-color: #28a8ea;
  }
  &:hover {
    background-color: #2eb8ff;
  }
`

function renderTabs() {
  const tabList = [];
  const labels = [
    "總覽",
    "Office應用程式",
    "電子郵件及行事曆",
    "線上會議",
    "檔案共用",
    "視訊管理",
    "搜尋與探索",
    "安全性與隱私"
  ];
  const icons =[
    <PublicIcon />,
    <AppsIcon />,
    <AttachEmailIcon/>,
    <GroupsIcon/>,
    <FolderSharedIcon/>,
    <OndemandVideoIcon/>,
    <SearchIcon/>,
    <GppGoodIcon/>
  ];

  for (let index = 0; index < labels.length; index++) {
    tabList.push(
      <StyledTab
        iconPosition="start"
        icon={icons[index]}
        label={labels[index]}
        {...a11yProps(index)}
      />
    );
  }
  return tabList;
}

export default function SimpleTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledRoot>
      <StyledAppBar position="static">
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="desktop"
          aria-label="simple tabs example"
          // centered
        >
          {renderTabs()}
        </StyledTabs>
      </StyledAppBar>
      <TabPanel value={value} index={0}>
        <Overview />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Office />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EmailAndCalendar />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <OnlineMeeting />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <FileSharing />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <VideoConference />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <SearchAndExploration />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <SafetyAndPrivacy />
      </TabPanel>
    </StyledRoot>
  );
}
