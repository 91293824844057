import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import { format } from "date-fns";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import getServiceNamefilterOptions from "./serviceNamefilterOptions";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const timeNow = new Date().toString();
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
const orderDefault = {
  aaHnNo: "",
  orderNo: "",
  orderDate: timeNow,
  orderStartDate: timeNow,
  chargeTotal: "",
  chargeLog: [],
};

// API
const orderChargeURL = "/api/order/getOrderChargeInfoBySn";

export default function ChargeModal(props) {
  const [modalStatus, setModalStatus] = useState(false);
  const closeModal = props.closeModal;
  const [order, setOrder] = useState(orderDefault);
  const [orderChargeLog, setOrderChargeLog] = useState([]);
  const [filterChargeTableText, setFilterChargeTableText] = useState("");

  useEffect(() => {
    if (props.open) {
      // console.log("ChargeModal effect");
      getOrderCharge(props.sn);
    }
    setModalStatus(props.open);
  }, [props.open, props.sn]);

  const getOrderCharge = (sn) => {
    Axios.post(orderChargeURL, { sn: sn }).then((response) => {
      setOrder(response.data);
      setOrderChargeLog(response.data.chargeLog ? response.data.chargeLog : []);
    });
  };

  return (
    <Dialog
      open={modalStatus}
      onClose={closeModal}
      maxWidth="lg"
      scroll="paper"
    >
      <Box>
        <DialogTitle>訂單出帳紀錄</DialogTitle>
        <Typography variant="h5"></Typography>
        <DialogContent dividers>
          <Typography>
            訂單管理人員透過下列表單修改該訂單的狀態與付款情形，由於商城提供消費者透過前端頁面查詢自己的購買紀錄，因此請管理人員必須依照實際情況限期處理。
          </Typography>
          <Typography>
            特別提醒：訂單狀態與付款情形也與會員的紅利點數計算有關。
          </Typography>
        </DialogContent>
        <DialogTitle>付款狀態</DialogTitle>
        {/* <Typography variant="h5">付款狀態</Typography> */}
        <DialogContent dividers>
          <Grid container>
            {tableCell("HN帳號：", order.aaHnNo)}
            {tableCell("訂單編號：", order.orderNo)}
            {tableCell(
              "訂單時間：",
              format(new Date(order.orderDate), datetimeFormat)
            )}
            {tableCell(
              "啟用時間：",
              format(new Date(order.orderStartDate), datetimeFormat)
            )}
          </Grid>
        </DialogContent>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>出帳紀錄</div>
            <Box display="flex" alignItems="center">
              {getServiceNamefilterOptions(props.brandSn).length > 0 && (
                <NativeSelect
                  style={{ marginRight: "15px" }}
                  value={""}
                  onChange={(e) => setFilterChargeTableText(e.target.value)}
                >
                  <option value="" disabled>
                    常用品項
                  </option>
                  <option disabled>========</option>
                  {getServiceNamefilterOptions(props.brandSn).map(
                    (filterOption, index) => {
                      return (
                        <option
                          key={`serviceNamefilter-${index}`}
                          value={filterOption}
                          // disabled={false}
                        >
                          {filterOption}
                        </option>
                      );
                    }
                  )}
                </NativeSelect>
              )}
              <TextField
                placeholder="關鍵字過濾..."
                value={filterChargeTableText}
                onChange={(e) => setFilterChargeTableText(e.target.value)}
              />
            </Box>
          </Box>
        </DialogTitle>
        {/* <Typography variant="h5">出帳紀錄</Typography> */}
        <DialogContent dividers>
          {chargeTable(orderChargeLog, filterChargeTableText)}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            離開
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

function tableCell(title, value) {
  return (
    <Grid item xs={12} md={6}>
      <Grid container>
        <Grid item xs={4}>
          {title}
        </Grid>
        <Grid item xs={8}>
          {value}
        </Grid>
      </Grid>
    </Grid>
  );
}

function chargeTable(chargeLog, filterChargeTableText = "") {
  const columns = [
    {
      title: "出帳時間",
      field: "chargeDate",
      render: (rowData) => format(new Date(rowData.chargeDate), datetimeFormat),
    },
    { title: "帳號數", field: "quantity" },
    { title: "出帳金額", field: "chargeAmount" },
    { title: "註記aaa_tag", field: "aaaTag" },
    { title: "報帳狀態", field: "aaaStatus" },
    { title: "OTPW", field: "otpw" },
  ];

  const filteredChargeLog = chargeLog.filter((item) => {
    if (item.aaaTag) {
      return item.aaaTag.includes(filterChargeTableText);
    } else {
      if (item.otpw) { 
        return (item.otpw.substr(0, 4) === "報帳錯誤")
      }
    }
    
    return false;
  });

  return (
    <MaterialTable
      icons={tableIcons}
      columns={columns}
      data={filteredChargeLog}
      options={{
        search: false,
        paging: false,
        showTitle: false,
        toolbar: false,
        padding: "dense",
        rowStyle: (rowData) => ({
          backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
        }),
      }}
    />
  );
}
