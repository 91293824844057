import React, { useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Styled from "styled-components"

import {
  TextField,
  Grid,
  Button,
  Typography,
  Divider,
  Box,
  Dialog,
  DialogTitle
} from "@material-ui/core";

// CSS
const StyledTextField = Styled(TextField)`
  && {
    width: 70vw;
  }
  & .MuiFilledInput-input {
    text-align: center;
  }
  & .MuiFilledInput-root {
    border-radius: 30px;
    letter-spacing: 0.2rem;
  }
  & .MuiFilledInput-input:-autofill {
    border-radius: 30px;
  }
  & .MuiFilledInput-input:-webkit-autofill {
    border-radius: 30px;
  }
  & .MuiInputLabel-formControl {
    position: relative;
  }
  & .MuiFormLabel-root {
    text-align: center;
    transform-origin: center;
    font-size: 1.15rem;
  }
  & .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(0px, 23px) scale(0.65);
  }
  & .MuiInputLabel-filled {
    transform: translate(0px, 38px) scale(1);
  }
  & .MuiFormHelperText-root {
    text-align: center;
    margin-top: 5px;
  }
`
const StyledGrid = Styled(Grid)`
  && {
    display: grid;
    justify-content: center;
  }
`
const StyledDialogTitle = Styled(DialogTitle)`
  && {
    text-align: center;
  }
  & .MuiTypography-root {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2;
    letter-spacing: 0.075em;
  }
`
const StyledConfirmBtn = Styled(Button)`
  && {
    background-color: #28a8ea;
    color: #fff;
    border-radius: 30px;
    width: 50vw;
    font-size: 1rem;
    margin: 12px 0px 6px 0px;
    padding: 10px 0px;
  }
  &&:hover {
    background-color: #1794d5;
  }
`
const StyledCancelBtn = Styled(Button)`
  && {
    background-color: #f75c5c;
    color: #fff;
    border-radius: 30px;
    width: 50vw;
    font-size: 1rem;
    margin: 6px 0px 20px 0px;
    padding: 10px 0px;
  }
  &&:hover {
    background-color: #d54b4b;
  }
`
const StyledHint = Styled(Typography)`
  && {
    font-size: 1rem;
    color: red;
    line-height: 2;
    letter-spacing: 0.0938em;
    font-weight: 600;
  }
`
const StyledTextFieldGroup = Styled(Grid)`
  && {
    margin: 20px 0px;
  }
`
const StyledBtnGroup = Styled(Grid)`
  && {
    margin: 20px 0px;
  }
`

export default function HiB2BModal(props) {
  const history = useHistory();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [sendingHiB2BReq, setSendingHiB2BReq] = React.useState(false);
  const [hiB2BInfo, setHiB2BInfo] = React.useState({
    rpt: "",
    rptid: "",
    cname: "",
    usrid: "",
    rcp_cname: "",
    rcp_custmno: "",
    bilzip: "",
    biladdr: "",
    bilcity: "",
    cln_man: "",
    cn_man: "",
    ctel_area_code: "",
    ctel: "",
    ctel_extension: "",
    usrfax_area_code: "",
    usrfax: "",
    wktel: "",
    email: "",
		promo_emp_no: ""
  });

  const closeModal = props.closeModal;
  var setNewMemberHN = props.setNewMemberHN;
	var setPromoEmpNoHandler = props.setPromoEmpNoHandler;

  useEffect(() => {
    if (props.open) {
      setHiB2BInfo(prevState => ({
        ...prevState,
        wktel: props.otpCell
      }));
    }
    setModalOpen(props.open);
  }, [props.open, props.otpCell]);

  function onHiB2BInfoChange(event) {
    const { name, value } = event.target;
    setHiB2BInfo(prevState => ({
      ...prevState,
      [name]: value.trim()
    }));
  }

  function createEntryData(label, name, value, required, disabled) {
    return {
      label: label,
      name: name,
      value: value,
      required: required,
      disabled: disabled
    };
  }

  const entries = [
    createEntryData("代表人", "rpt", "", true, false),
    createEntryData("代表人統編", "rptid", "", true, false),
    createEntryData("公司/客戶名稱", "cname", "", true, false),
    createEntryData("客戶統編", "usrid", "", true, false),
    createEntryData("收據用戶名稱", "rcp_cname", "", true, false),
    createEntryData("收據證號", "rcp_custmno", "", true, false),
    createEntryData("帳寄郵遞區號(5碼)", "bilzip", "", true, false),
    createEntryData("帳單寄送地址", "biladdr", "", true, false),
    createEntryData("帳單寄送城市", "bilcity", "", true, false),
    createEntryData("聯絡人（姓）", "cln_man", "", true, false),
    createEntryData("聯絡人（名）", "cn_man", "", true, false),
    createEntryData("電話區碼(2-3碼)", "ctel_area_code", "", true, false),
    createEntryData("電話(8碼)", "ctel", "", true, false),
    createEntryData("分機(4碼)", "ctel_extension", "", true, false),
    createEntryData("傳真區碼(2-3碼)", "usrfax_area_code", "", false, false),
    createEntryData("傳真(8碼)", "usrfax", "", false, false),
    createEntryData("手機", "wktel", hiB2BInfo.wktel, false, true),
    createEntryData("電子信箱", "email", "", true, false),
    createEntryData("推廣人", "promo_emp_no", "", false, false)
  ]

  function createDivider() {
    return (
      <Grid>
        <Divider></Divider>
      </Grid>
    );
  }

  function createEntry(label, name, value, required, disabled) {
    return (
      <StyledGrid item xs={12}>
        <StyledTextField
          variant="filled"
          label={label}
          name={name}
          defaultValue={value}
          required={required}
          disabled={disabled}
          onChange={onHiB2BInfoChange}
          InputProps={{ disableUnderline: true }}
        ></StyledTextField>
      </StyledGrid>
    );
  }

  function createModalContent() {
    return (
      <StyledTextFieldGroup container spacing={1}>
        <StyledGrid item xs={12}>
          <StyledHint> 星號 (*) 為必填</StyledHint>
        </StyledGrid>
        {entries.map((entry) => { return createEntry(entry.label, entry.name, entry.value, entry.required, entry.disabled); })}
      </StyledTextFieldGroup>
    );
  }

  function createModalActionButtonGroup() {
    return (
      <StyledBtnGroup container>
        <StyledGrid item xs={12}>
          <StyledConfirmBtn onClick={submitHiB2BInfo} name="save" color="primary">
            確認送出
          </StyledConfirmBtn>
        </StyledGrid>
        <StyledGrid item xs={12}>
          <StyledCancelBtn
            color="primary"
            onClick={() => {
              history.push({ pathname: "BuyNow" });
            }}
          >
            取消
          </StyledCancelBtn>
        </StyledGrid>
      </StyledBtnGroup>
    );
  }

  function submitHiB2BInfo() {
    if (!sendingHiB2BReq) {
      setSendingHiB2BReq(true);
      // Assemble the entire wktel
      var fmtErrorMsg = formInputCheck();
      if (fmtErrorMsg !== "") {
        alert(fmtErrorMsg);
        setSendingHiB2BReq(false);
      } else {
        var phoneNum =
          hiB2BInfo.ctel_area_code +
          hiB2BInfo.ctel +
          hiB2BInfo.ctel_extension;

        // Assemble the entire usrfax
        var faxNum = "";
        if (hiB2BInfo.usrfax_area_code !== "" && hiB2BInfo.usrfax !== "") {
          faxNum =
            hiB2BInfo.usrfax_area_code +
            hiB2BInfo.usrfax;
        }
				setPromoEmpNoHandler(hiB2BInfo.promo_emp_no);
        Axios.post("/api/v0/hib2bCreateMember", {
          Rpt: hiB2BInfo.rpt,
          Rptid: hiB2BInfo.rptid,
          Cname: hiB2BInfo.cname,
          Usrid: hiB2BInfo.usrid,
          Rcp_cname: hiB2BInfo.rcp_cname,
          Rcp_custmno: hiB2BInfo.rcp_custmno,
          Bilzip: hiB2BInfo.bilzip,
          Biladdr: hiB2BInfo.biladdr,
          BilCity: hiB2BInfo.bilcity,
          Cln_man: hiB2BInfo.cln_man,
          Cn_man: hiB2BInfo.cn_man,
          Ctel: phoneNum,
          Usrfax: faxNum,
          Wktel: hiB2BInfo.wktel,
          Email: hiB2BInfo.email
					//PromoEmpNo: hiB2BInfo.promo_emp_no
        }).then(() => {
          setSendingHiB2BReq(false);
          alert("申請送出成功!");
          setNewMemberHN();
          closeModal();
        }).catch(err => {
          setSendingHiB2BReq(false);
          alert("後端錯誤!\n\n" + err.response.data.Message + "\n\n請稍後重新送出表單!");
        });
      }
    } else {
      alert("表單傳送中, 請稍後!");
    }
  }

  function checkRequired(ResMsg, entry, entName) {
    if (entry === "") {
      ResMsg += (ResMsg === "") ? "" : "\n";
      ResMsg += "    - " + entName + "不可為空";
    }
    return ResMsg;
  }

  function checkEntryFormat(ResMsg, isCorrect, msg) {
    if (!isCorrect) {
      ResMsg += (ResMsg === "") ? "" : "\n";
      ResMsg += "    - " + msg;
    }
    return ResMsg;
  }

  function formInputCheck() {
    var Msg = "";
    Msg = checkRequired(Msg, hiB2BInfo.rpt, "代表人");
    Msg = checkRequired(Msg, hiB2BInfo.rptid, "代表人證號");
    Msg = checkRequired(Msg, hiB2BInfo.cname, "公司/客戶名稱");
    Msg = checkRequired(Msg, hiB2BInfo.usrid, "客戶證號");
    Msg = checkRequired(Msg, hiB2BInfo.rcp_cname, "收據用戶名稱");
    Msg = checkRequired(Msg, hiB2BInfo.rcp_custmno, "收據證號");
    Msg = checkRequired(Msg, hiB2BInfo.bilzip, "帳寄郵遞區號");
    Msg = checkRequired(Msg, hiB2BInfo.biladdr, "帳單寄送地址");
    Msg = checkRequired(Msg, hiB2BInfo.bilcity, "帳單寄送城市");
    Msg = checkRequired(Msg, hiB2BInfo.cln_man, "聯絡人（姓）");
    Msg = checkRequired(Msg, hiB2BInfo.cn_man, "聯絡人（名）");
    Msg = checkRequired(Msg, hiB2BInfo.ctel_area_code, "電話區碼");
    Msg = checkRequired(Msg, hiB2BInfo.ctel, "電話");
    Msg = checkRequired(Msg, hiB2BInfo.email, "電子信箱");

    // Check telephone format
    var areaCodeCorrect = /^[0-9]{2,3}$/.test(hiB2BInfo.ctel_area_code);
    var ctelCorrect = /^[0-9]{8}$/.test(hiB2BInfo.ctel);

    Msg = checkEntryFormat(Msg, areaCodeCorrect, "電話區碼需為 2 至 3 碼");
    Msg = checkEntryFormat(Msg, ctelCorrect, "電話須為 8 碼");

    // Check zip code format
    var bilzipCorrect = /^[0-9]{5}$/.test(hiB2BInfo.bilzip);
    Msg = checkEntryFormat(Msg, bilzipCorrect, "帳寄郵遞區號為 5 碼");

    // Check fax format
    if (hiB2BInfo.usrfax_area_code !== "" || hiB2BInfo.usrfax !== "") {
      var faxAreaCodeCorrect = /^[0-9]{2,3}$/.test(hiB2BInfo.usrfax_area_code);
      var usrfaxCorrect = /^[0-9]{8}$/.test(hiB2BInfo.usrfax);

      Msg = checkEntryFormat(Msg, faxAreaCodeCorrect, "傳真區碼須為2至3碼");
      Msg = checkEntryFormat(Msg, usrfaxCorrect, "傳真須為 8 碼");
    }

    if (Msg !== "") {
      Msg = "以下項目需修正格式:\n\n" + Msg;
    }
    return Msg;
  }

  return (
    <Dialog
      open={modalOpen}
      maxWidth="lg"
      scroll="paper"
      backdrop="static"
    >
      <Box>
        <StyledDialogTitle>HiB2B 申請資料</StyledDialogTitle>
        {createDivider()}
        {createModalContent()}
        {createDivider()}
        {createModalActionButtonGroup()}
      </Box>
    </Dialog>
  );
}
