import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Office1 from "../assets/ProductOverview/all-5.jpg";
import Office2 from "../assets/ProductOverview/all-6.jpg";
import Office3 from "../assets/ProductOverview/all-7.jpg";



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

//CSS
const StyledContextGrid = Styled(Grid)`
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 30px;
`
const StyledContextImage = Styled.img`
  max-width: 100%;
  border: 2px solid #28a8ea;
  border-radius: 10px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #28a8ea;
    margin-bottom: 20px;
  }
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 700;
    font-family: Microsoft JhengHei;
    text-align: left;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContainer = Styled(Grid)`
  && {
    margin-bottom: 120px;
  }
`

function renderSubContext(props) {
  const contexts = props;

  return contexts.map(context => (
    <>
      <StyledContextSubTitle color="secondary" variant="h5">
        {context.subTitle}
      </StyledContextSubTitle>
      <StyledContextSubContent paragraph>
        {context.subContext}
      </StyledContextSubContent>
    </>
  ));
}

function renderContext() {
  const contexts = [
    {
      title: "Office 隨身跟著您",
      context: [
        {
          subTitle: "隨處都能使用熟悉的工具",
          subContext:
            "Office 365 提供您所熟悉的應用程式和可隨時存取、永遠保持最新狀態的檔案。不論是在線上還是離線，在辦公桌還是出門在外，只要透過 PC、Mac、iPad®、Android™ 平板電腦或手機，就能隨時隨地獲得所需的內容。"
        },
        {
          subTitle: "取得完整的 Office 桌上型電腦版",
          subContext:
            "透過各種 Office 365 方案，您可以在裝置上安裝最新版的完整 Office 應用程式，讓您能夠離線或線上作業。"
        },
        {
          subTitle: "輕鬆管理",
          subContext:
            "Office 365 應用程式不僅會保持最新狀態，而且易於使用和管理，讓您有更多時間專注在工作上。"
        }
      ],
      img: Office1
    },
    {
      title: "依進度工作",
      context: [
        {
          subTitle: "任何裝置皆可作業",
          subContext:
            "部分方案可讓您在多達五部 PC/Mac 與五部平板電腦 (iPad、Windows 或 Android) 上安裝 Office，您也能隨心所欲將安裝內容傳送到您需要的新裝置上。"
        },
        {
          subTitle: "輕鬆共同作業",
          subContext:
            "您可以在需要的時候，在任何地點以您所選的任何裝置進行作業。 共用檔案。 加入會議。 與小組保持同步。 在需要完成工作時，Office 隨侍在側。"
        },
        {
          subTitle: "任何地點皆可完成工作",
          subContext:
            "運用 Office Online 於任何瀏覽器中同步及共用檔案。 於 Windows Phone、iPhone® 與 Android 手機上使用 Office Mobile，檢視並編輯檔案。"
        }
      ],
      img: Office2
    },
    {
      title: "永遠取得最新動態",
      context: [
        {
          subTitle: "更少的維護作業，更多的工作時間",
          subContext:
            "把時間花費在工作上，而非安裝、更新及確認所有人是否保持同步。 Office 365 會接手所有的幕後工作，讓您直接處理正事。"
        },
        {
          subTitle: "永遠維持最新版本",
          subContext:
            "Office 365 會保持更新，讓您永遠使用最新版本的最愛應用程式，例如 Word、Excel、PowerPoint 及 Outlook。"
        },
        {
          subTitle: "隨時提供您所需的檔案",
          subContext:
            "由於檔案會自動儲存在線上，因此可讓您在外出時存取、編輯及共用最新版本的檔案。"
        }
      ],
      img: Office3
    }
  ];

  return contexts.map(context => (
    <StyledContainer container>
      <StyledContextGrid item md={5} sm={12}>
        <StyledContextImage src={context.img}></StyledContextImage>
      </StyledContextGrid>
      <StyledContextGrid item md={7} sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextDivider/>
        {renderSubContext(context.context)}
      </StyledContextGrid>
    </StyledContainer>
  ));
}

export default function CenteredGrid(props) {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {renderContext()}
      </Grid>
    </div>
  );
}
