import React from "react";

// Core
import {
  Divider,
  Grid
} from "@material-ui/core";
// import Skeleton from '@mui/material/Skeleton';

// router
import { Link } from "react-router-dom";

// Style CSS
import styled from 'styled-components';

// Pictures

// import main1 from "../assets/Home/IndexBanner1_modified.jpg";
// import main2 from "../assets/Home/1000x390final.jpg";
import main1 from "../assets/Home/M365_OF_1000X390.jpg"; // Top main banner
import main2 from "../assets/Home/M365_PLUS_1000X390.jpg"; // Buttom left banner
// import main3 from "../assets/Home/banner1000x390_4.jpg"; // Buttom right banner
import main3 from "../assets/Home/M365_1000X390.jpg"; // Buttom right banner
import checkImg from "../assets/Home/IndexCheckButton.png"

// CSS
const Image = styled.img`
    width: 100%;
    border: 1px solid white;
    transition: all 0.3s linear;
    border-radius: 3px;
    &:hover {
        transform: scale(1.05);
        border: 1px solid black;
    }
`
// const StyledSkeleton = styled(Skeleton)`
//     && {
//         max-width: 100vw;
//         max-height: 390px;
//         height: 25vh;
//     }
// `

const ReasonLink = styled(Link)`
    font-size: 32px;
    color: white;
    font-weight: 800;
    text-decoration: none;
    transition: all 0.2s linear;
    &:hover {
        color: #222;
    }
`
const ReasonTitle = styled.p`
    font-size: 35px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
`
const ReasonDivider = styled(Divider)`
    && {
        background-color: #fff;
        border-top: 1px solid white;
        margin-top: 20px;
        margin-bottom: 30px;
    }
`
const ReasonDescription = styled(Grid)`
    margin: 10px;
    display: flex;
    padding-left: 15%;
    border-radius: 10px;
    align-items: center;
    transition: all 0.2s linear;
    &:hover {
        background-color: #1E90FF;
        color: #222;
    }
`
const CheckIcon = styled.img`
    width: 41px;
    height: 36px;
    margin: 25px;
`

const Reasons = [
  {
    title: "隱私權很重要",
    reason: "0"
  }, {
    title: "嚴肅正視資訊安全",
    reason: "1"
  }, {
    title: "法務遵循領導地位",
    reason: "2"
  }, {
    title: "讓使用者可以隨時隨地存取其內容",
    reason: "3"
  }, {
    title: "豐富的功能集",
    reason: "4"
  }, {
    title: "需要的訓練較少",
    reason: "5"
  }, {
    title: "以您的方式部署",
    reason: "6"
  }, {
    title: "倚賴受財務支援的 99.9% SLA",
    reason: "7"
  }, {
    title: "知道將來可能發生的狀況以進行規劃",
    reason: "8"
  }, {
    title: "企業等級支援",
    reason: "9"
  }
];

function RenderList(props) {
  return (
    <Grid item xs={12}>
      {props.row
        .map((context) => (
          <ReasonDescription item xs={12}>
            <CheckIcon src={checkImg} alt="Check Image" />
            <ReasonLink id={"Buying10Reasons" + context.reason}
              to={{
                pathname: "Buying10Reasons",
                state: {
                  reason: context.reason
                }
              }}>
              {context.title}
            </ReasonLink>
          </ReasonDescription>
        ))
      }
    </Grid>
  );
}

export default function WebView() {
  // // To improve user experience, so Adding Skeleton components when web is loading images.
  // // eslint-disable-next-line
  // const [loadedSrc1, setLoadedSrc1] = useState(false);
  // // eslint-disable-next-line
  // const [loadedSrc2, setLoadedSrc2] = useState(false);
  // // eslint-disable-next-line
  // const [loadedSrc3, setLoadedSrc3] = useState(false);

  // const updateLoadedSrc1 = (loadStatus) => {
  //   setLoadedSrc1(loadStatus);
  // };

  // const updateLoadedSrc2 = (loadStatus) => {
  //   loadStatus = DES3(loadStatus);
  //   setLoadedSrc2(loadStatus);
  // };

  // const updateLoadedSrc3 = (loadStatus) => {
  //   setLoadedSrc3(loadStatus);
  // };

  return (
    <Grid container>
      <Grid
        continer
        item
        xs={12}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      // alignContent="right"
      >
        {/* Main top banner */}

        <Grid item xs={9}>
          <iframe width="1232" height="595" src="https://www.youtube.com/embed/RV4l45pAcYE" title="Microsoft 365 Copilot超人無所不能" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <a href="https://cloud.hinet.net/discount.html?id=12" target="_blank" rel="noreferrer noopener">
          {/* {loadedSrc2 ? null : <StyledSkeleton></StyledSkeleton>} */}
          <Image
            // style={loadedSrc2 ? {} : { display: 'none' }}
            src={main2} alt="Second Office365 Slide"
          // onLoad={updateLoadedSrc2}
          />
        </a>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <a href="https://www.tcloud.gov.tw/solution/F03FE160398F0DAEE0531512620AC1A1" target="_blank" rel="noreferrer noopener">
          {/* {loadedSrc3 ? null : <StyledSkeleton></StyledSkeleton>} */}
          <Image
            // style={loadedSrc3 ? {} : { display: 'none' }}
            src={main3} alt="Third Office365 Slide"
          // onLoad={() => updateLoadedSrc3(true)}
          />
        </a>
      </Grid>
      {/* Waiting until all images are loaded before rendering Buying10Reason component. */}
      {/* {(loadedSrc1 && loadedSrc2 && loadedSrc3) && (
        <Grid item xs={12}
          style={{
            padding: 15,
            backgroundColor: "#28a8ea",
            color: 'white'
          }}>
          <Grid item xs={12}
            style={{
              marginTop: 30,
              marginBottom: 30
            }}>
            <ReasonTitle>
              <b>
                企業選擇 &ensp;
                <span
                  style={{
                    fontSize: 60
                  }}>Microsoft Office 365</span>&ensp;
                的十大原因
              </b>
            </ReasonTitle>
            <ReasonDivider variant="middle" />
          </Grid>
          <RenderList row={Reasons}></RenderList>
        </Grid>
      )} */}
      <Grid item xs={12}
        style={{
          padding: 15,
          backgroundColor: "#28a8ea",
          color: 'white'
        }}>
        <Grid item xs={12}
          style={{
            marginTop: 30,
            marginBottom: 30
          }}>
          <ReasonTitle>
            <b>
              企業選擇 &ensp;
              <span
                style={{
                  fontSize: 60
                }}>Microsoft Office 365</span>&ensp;
              的十大原因
            </b>
          </ReasonTitle>
          <ReasonDivider variant="middle" />
        </Grid>
        <RenderList row={Reasons}></RenderList>
      </Grid>
    </Grid >
  );
}
