import React, { useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { PageLoadingContext } from "../context/PageLoadingContext";

export default function PageLoading() {
  const { showPageLoading, setShowPageLoading } =
    useContext(PageLoadingContext);

  return (
    <Backdrop
      sx={{ color: "#8d60db", zIndex: "9999" }}
      open={showPageLoading}
      onClick={() => {
        setShowPageLoading(false);
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
