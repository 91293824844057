import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Styled from 'styled-components';
import DoneIcon from "../assets/ProductOverview/Product_Safety_Check.png";

//CSS
const StyledContextSubGrid = Styled(Grid)`
  display: grid;
  text-align: left;
  justify-content: left;
  align-items: center;
  padding: 0 30px 0 30px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextContent = Styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #f50057;
    margin-bottom: 15px;
  }
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 700;
    font-family: Microsoft JhengHei;
    text-align: left;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContextGrid = Styled(Grid)`
  && {
    margin: 20px 0 40px 0;
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 30px 0 30px;
  }
`
const StyledDoneIcon = Styled.img`
  max-width: 20px;
  height: auto;
  margin-right: 10px;
`

export default function BuiltinSecurity(props) {
  function renderTitle() {
    const context = {
      title: "內建安全性",
      context:
        "Office 365 是強化安全性的服務，設計依據 Microsoft 安全性開發週期。 集我們二十年打造企業軟體及管理線上服務的最佳作法，為您提供整合的軟體即服務解決方案。在服務等級方面，Office 365 透過深度防禦的方式，提供實體、邏輯和資料層的資安功能及最佳營運實務作法。 此外，Office 365 更提供企業等級的使用者和管理員控制功能，可更進一步保護您的環境。"
    };
    return (
      <StyledContextGrid container sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextContent paragraph>{context.context}</StyledContextContent>
      </StyledContextGrid>
    );
  }
  function renderList(props) {
    const contexts = props;

    return contexts.map(context => (
      <>
        <Typography paragraph>
          <StyledDoneIcon src={DoneIcon}/>
          {context.subContext}
        </Typography>
      </>
    ));
  }

  function renderContext() {
    const contexts = [
      {
        title: "實體安全性",
        context: [
          {
            subContext: "24 小時監視資料中心。"
          },
          {
            subContext:
              "多重因素驗證，包含針對存取資料中心的人士進行生物識別掃描。"
          },
          {
            subContext: "將內部資料中心網路與外部網路隔離。"
          },
          {
            subContext:
              "角色分隔讓能取得資料實體的人無法判斷出特定客戶資料的位置。"
          },
          {
            subContext: "有問題的磁碟機和硬體會消磁摧毀。"
          }
        ]
      },
      {
        title: "邏輯安全性",
        context: [
          {
            subContext: "鎖箱程序 嚴格監控呈報步驟，限制人為存取資料。"
          },
          {
            subContext:
              "伺服器只執行安全清單上的程序，將惡意程式碼的風險降到最低。"
          },
          {
            subContext: "專職的威脅管理小組會主動預測、預防和減少惡意存取。"
          },
          {
            subContext:
              "掃描連接埠、掃描周邊安全風險，及預防入侵偵測或偵測所有惡意存取。"
          }
        ]
      },
      {
        title: "資料安全性",
        context: [
          {
            subContext: "為伺服器上的靜態資料加密以提供保護。"
          },
          {
            subContext:
              "以 SSL/TLS 為傳輸的資料加密，保護您與 Microsoft 間傳輸的資料。"
          },
          {
            subContext:
              "威脅管理、安全性監視，以及檔案/資料整合可預防或偵測任何資料竄改行為。"
          }
        ]
      },
      {
        title: "管理員和使用者控制",
        context: [
          {
            subContext:
              "Rights Management Services 可預防在沒有正確使用者認證的情況下進行檔案等級存取。"
          },
          {
            subContext: "多重因素驗證透過第二因素 (例如電話) 保護服務存取。"
          },
          {
            subContext: "S/MIME 提供以憑證為基礎的安全電子郵件存取。"
          },
          {
            subContext: "Office 365 郵件加密讓您可傳送加密電子郵件給任何人。"
          },
          {
            subContext: "資料外洩防護可防止機密資料在組織內或組織外洩漏。"
          },
          {
            subContext:
              "資料外洩防護可與版權管理及 Office 365 郵件加密結合，給予管理員更強大的控制能力，為機密資料套用適合的原則以提供保護。"
          }
        ]
      }
    ];

    return contexts.map(context => (
      <Grid container>
        <StyledContextSubGrid item sm={12}>
          <StyledContextSubTitle color="secondary" variant="h5">
            {context.title}
          </StyledContextSubTitle>
          <StyledContextDivider/>
          <StyledContextSubContent paragraph>
            {renderList(context.context)}
          </StyledContextSubContent>
        </StyledContextSubGrid>
      </Grid>
    ));
  }

  return (
    <div>
      <Grid container spacing={3}>
        {renderTitle()}
        {renderContext()}
      </Grid>
    </div>
  );
}
