import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  DialogContent,
  FormControl,
  NativeSelect,
} from "@material-ui/core";
import Axios from "axios";
import MaterialTable from "material-table";
import { useCookies } from "react-cookie";

// const getTermDurationListUrl = "/api/product/termDuration/list";

const BrandMicrosoftSn = 1;
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
const productDefault = {
  sn: 0,
  name: "",
  brandSn: BrandMicrosoftSn,
  planTypeSn: "",
  pid: "",
  price: 0,
  priceCost: 0,
  msOfferName: "",
  msOfferId: "",
  msNCEOfferId: "",
  msOfferRenewDate: "",
  termDuration: ""
};

const productFieldList = {
  Name: "商品名稱",
  Plan: "方案類型",
  TermDuration: "合約期限",
  Pid: "小額付款商品編號",
  Price: "定價",
  PriceCost: "成本",
  Reviewed: "審核狀態",
  MsOfferName: "微軟商品名稱(英文)",
};

function logTable(data) {
  // console.log()
  const columns = [
    {
      title: "時間",
      field: "modifyTime",
      render: (rowData) => format(new Date(rowData.modifyTime), datetimeFormat),
    },
    { title: "帳號", field: "operatorName" },
    { title: "欄位", field: "field", lookup: productFieldList },
    { title: "修改前資料", field: "beforeValue" },
    { title: "修改後資料", field: "afterValue" },
    { title: "IP位址", field: "modifyIP" },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      options={{
        search: false,
        paging: false,
        showTitle: false,
        toolbar: false,
        padding: "dense",
        sorting: false,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
        }),
      }}
    />
  );
}

export default function ProductFormOfMicrosoft(props) {
  const [cookies] = useCookies(["session"]);
  const [modalStatus, setModalStatus] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [product, setProduct] = useState(productDefault);
  const [productSn, setProductSn] = useState(0);
  const [modifyLog, setModifyLog] = useState([]);
  const [planTypeList, setPlanTypeList] = useState([]);
  const [termDurationList, setTermDurationList] = useState([]);

  const closeModal = props.closeModal;

  useEffect(() => {
    if (props.open) {
      setReviewed(props.review);
      setPlanTypeList(props.planTypeList);
      setTermDurationList(props.termDurationList);
      getProduct(props.sn);
      // getTermDuration();
    }
    setModalStatus(props.open);
  }, [props.open, props.planTypeList, props.termDurationList, props.review, props.sn]);

  const getProduct = (sn) => {
    // console.log("MS sn:"+sn);
    setProductSn(sn);
    if (sn && sn !== 0) {
      const getProduct = Axios.post("/api/product", { sn: sn });
      const getModifyLog = Axios.post("/api/product/log/list", { sn: sn }); //FIXME

      Axios.all([getProduct, getModifyLog])
        .then(
          Axios.spread((productResponse, modifyLogResponse) => {
            console.log(productResponse.data);
            setProduct(productResponse.data);
            setModifyLog(modifyLogResponse.data ? modifyLogResponse.data : []);
          })
        )
        .catch(() => {
          alert("MS getProduct 後端異常");
          setProduct(productDefault);
          setModifyLog([]);
        });
    } else {
      setProduct(productDefault);
      setModifyLog([]);
    }
  };

  // const getTermDuration = () => {
  //   Axios.get(getTermDurationListUrl).then(function (response) {
  //     setTermDurationList(response.data);
  //   }).catch(function (err) {
  //     alert("後端異常");
  //     console.log(err);
  //   });
  // };

  const handleChange = (event) => {
    var { name, value, type } = event.target;
    if (type === "number") {
      value = value > 2147483647 ? 2147483647 : parseFloat(value);
      value = value < 0 ? 0 : parseFloat(value);
    } else if (type === "select-one") {
      if (name !== "termDuration") {
        value = parseInt(value);
      }
    }

    console.log(name, value);

    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const productReview = () => {
    if (window.confirm("確定審核此筆資料？")) {
      Axios.post("/api/product/review", {
        sn: product.sn,
        OpName: cookies.op_name,
      })
        .then((res) => {
          alert("審核成功");
          closeModal();
          window.location.reload();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const productSubmit = (e) => {
    e.preventDefault();
    if (window.confirm("確定儲存此筆資料？")) {
      Axios.post("/api/product/insertOrUpdate", {
        withCredentials: true,
        product: product,
        OpName: cookies.op_name,
        // ReviewedAndOnline: false
      })
        .then((res) => {
          alert("儲存成功");
          console.log(product);
          closeModal(); // Refreash data after closeModal
          // window.location.reload();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  // const productSubmitWithReviewAndOnline = () => {
  //   if (window.confirm("確定儲存此筆資料？")) {
  //     Axios.post("/api/product/insertOrUpdate", {
  //       withCredentials: true,
  //       product: product,
  //       OpName: cookies.op_name,
  //       ReviewedAndOnline: true
  //     })
  //       .then((res) => {
  //         alert("儲存成功");
  //         console.log(product);
  //         closeModal(); // Refreash data after closeModal
  //         // window.location.reload();
  //       })
  //       .catch((err) => {
  //         alert(err);
  //       });
  //   }
  // };


  const productDataList = [
    createLabel(
      "商品名稱",
      <TextField
        fullWidth
        margin="dense"
        value={product.name || ""}
        disabled={reviewed}
        name="name"
        onChange={handleChange}
        autoComplete="off"
        required
      />
    ),
    // createLabel(
    //   "方案類型",
    //   <TextField
    //     fullWidth
    //     margin="dense"
    //     helperText="月繳、年繳..."
    //     value={product.plan || ""}
    //     disabled={reviewed}
    //     name="plan"
    //     onChange={handleChange}
    //     required
    //   />
    // ),
    createLabel(
      "方案類型",
      <FormControl fullWidth>
        <NativeSelect
          name="planTypeSn"
          value={product.planTypeSn}
          disabled={reviewed}
          onChange={handleChange}
          required
        >
          <option value=""></option>
          {planTypeList.map((planType, index) => {
            return (
              <option key={index} value={planType.Sn}>
                {planType.Name}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
    ),
    createLabel(
      "PID",
      <TextField
        fullWidth
        margin="dense"
        value={product.pid || ""}
        disabled={reviewed}
        name="pid"
        onChange={handleChange}
        autoComplete="off"
        required
      />
    ),
    createLabel(
      "合約期限",
      <FormControl fullWidth>
        <NativeSelect
          name="termDuration"
          value={product.termDuration}
          disabled={reviewed}
          onChange={handleChange}
          required
        >
          <option value=""></option>
          {termDurationList.map((termDuration, index) => {
            return (
              <option key={index} value={termDuration.Value}>
                {termDuration.Name}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
    ),
    createLabel(
      "定價",
      <TextField
        fullWidth
        margin="dense"
        value={product.price || ""}
        disabled={reviewed}
        name="price"
        type="number"
        max="2,147,483,647"
        onChange={handleChange}
        autoComplete="off"
        required
      />
    ),
    createLabel(
      "成本",
      <TextField
        fullWidth
        margin="dense"
        value={product.priceCost || ""}
        disabled={reviewed}
        name="priceCost"
        type="number"
        onChange={handleChange}
        autoComplete="off"
        required
      />
    ),
  ];

  var msOfferDataList = [
    createLabel(
      "微軟商品名稱(英文)",
      <TextField
        fullWidth
        margin="dense"
        helperText="需要與微軟提供的商品名稱相同，用來取得微軟商品碼。"
        value={product.msOfferName || ""}
        disabled={reviewed}
        name="msOfferName"
        onChange={handleChange}
        autoComplete="off"
      // required
      />
    ),
    createLabel(
      "微軟商品碼",
      <TextField
        fullWidth
        margin="dense"
        helperText="微軟商品碼"
        value={product.msNCEOfferId}
        disabled={reviewed}
        name="msNCEOfferId"
        onChange={handleChange}
        autoComplete="off"
      // required
      />),
    createLabel(
      "微軟商品更新時間",
      datetimeStringFormat(product.msOfferRenewDate)
    ),
  ];

  return (
    <Dialog
      open={modalStatus}
      onClose={closeModal}
      maxWidth="lg"
      scroll="paper"
    >
      <Box>
        <form onSubmit={productSubmit}>
          <DialogTitle>Microsoft 商品重要資訊設定：</DialogTitle>
          <DialogContent dividers>
            <Grid container>
              {productDataList.map((item) => {
                return item;
              })}
            </Grid>
          </DialogContent>
          <DialogTitle>微軟相關設定：</DialogTitle>
          <DialogContent dividers>
            <Grid container>
              {msOfferDataList.map((item) => {
                return item;
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Typography hidden={!reviewed}>
              <Button onClick={productReview} name="review" color="primary">
                審核
              </Button>
            </Typography>
            {/* <Typography hidden={reviewed}>
              <Button onClick={productSubmitWithReviewAndOnline} name="saveAndReviewAndOnline" color="primary">
                儲存後審核並上架
              </Button>
            </Typography> */}
            <Typography hidden={reviewed}>
              <Button type="submit" name="save" color="primary">
                儲存
              </Button>
            </Typography>
            <Button onClick={closeModal} color="primary">
              取消
            </Button>
          </DialogActions>
          <Typography component="div" hidden={productSn === 0}>
            <DialogTitle>商品種類更改紀錄：</DialogTitle>
            <DialogContent dividers>{logTable(modifyLog)}</DialogContent>
          </Typography>
        </form>
      </Box>
    </Dialog>
  )
}

function createLabel(title, data, mdSize = 6) {
  return (
    <Grid item xs={12} md={mdSize}>
      <Grid container>
        <Grid item xs={4}>
          <Box m={1}>{title}</Box>
        </Grid>
        <Grid item xs={8}>
          <Box m={1}>{data}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
function datetimeStringFormat(time) {
  // time is string
  if (time === "" || time === null || time === undefined) {
    return "";
  } else {
    return format(new Date(time), datetimeFormat);
  }
}
