import React, { useState, useEffect } from "react";
import Axios from "axios";
import { format } from "date-fns";

import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { useCookies } from "react-cookie";

const datetimeFormat = "yyyy/MM/dd HH:mm:ss";

// const BrandMicrosoftSn = 1;

const productDetailDefault = {
  sn: 0,
  productNo: "",
  hib2bProductCode: "",
  msProductNo: "",
  productSn: "",
  pid: "",
  description: "",
  simple: "",
  price: "",
  priceCost: "",
  priceSpecial: "",
  buyLimit: "",
  isUpVisible: false,
};

const productDetailFieldList = {
  ProductSn: "商品名稱",
  Description: "商品折扣",
  Simple: "產品簡介",
  Context: "產品描述",
  PriceSpecial: "優惠價",
  ProductStatusSn: "商品狀態",
  BuyLimit: "購買數量限制",
  Reviewed: "審核狀態",
  CreateDate: "建檔時間",
};

function logTable(data) {
  // console.log(data)
  const columns = [
    {
      title: "時間",
      field: "modifyTime",
      render: (rowData) => format(new Date(rowData.modifyTime), datetimeFormat),
    },
    { title: "帳號", field: "operatorName" },
    { title: "欄位", field: "field", lookup: productDetailFieldList },
    { title: "修改前資料", field: "beforeValue" },
    { title: "修改後資料", field: "afterValue" },
    { title: "狀態", field: "productStatusSn" },
    { title: "IP位址", field: "modifyIP" },
  ];
  return (
    <MaterialTable
      columns={columns}
      data={data}
      options={{
        search: false,
        paging: false,
        showTitle: false,
        toolbar: false,
        padding: "dense",
        sorting: false,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
        }),
      }}
    />
  );
}

// closeModal
export default function ProductDetailModal(props) {
  const [cookies] = useCookies(["session"]);
  const [modalStatus, setModalStatus] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productDetailSn, setProductDetailSn] = useState(0);
  const [productDetail, setProductDetail] = useState(productDetailDefault);
  const [modifyLog, setModifyLog] = useState([]);

  const closeModal = props.closeModal;
  const brandSn = props.brand;

  /* 用於抓 Microsoft 商品的清單 */
  useEffect(() => {
    const getListData = () => {
      Axios
        .post("/api/product/list", {
          PerPage: 0,
          OrderBy: "name",
          BrandSn: brandSn
        })
        .then((res) => {
          console.log("ProductList:", res.data.data);
          setProductList(res.data.data);
        })
        .catch((err) => {
          console.log("getAllListData:" + err);
        });
    };
    if (props.openModal) {
      getListData();
      setReviewed(props.review);
      getProductDetail(props.productDetailSn);
    }
    setModalStatus(props.openModal);
  }, [
    props.review,
    props.openModal,
    props.productDetailSn,
    brandSn
  ]);

  const handleChange = (event) => {
    var { name, value, type } = event.target;
    if (type === "number") {
      value = value > 2147483647 ? 2147483647 : parseFloat(value);
      value = value < 0 ? 0 : parseFloat(value);
    } else if (type === "select-one") {
      value = parseInt(value);
    }
    setProductDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getProductDetail = (sn) => {
    setProductDetailSn(sn);
    if (sn && sn !== 0) {
      const getProductDetail = Axios.post("/api/product/detail", { sn: sn });
      const getModifyLog = Axios.post("/api/product/detail/log/list", {
        sn: sn,
      });

      Axios.all([getProductDetail, getModifyLog])
        .then(
          Axios.spread((productDetailResponse, modifyLogResponse) => {
            console.table(productDetailResponse.data);
            setProductDetail(productDetailResponse.data);
            setModifyLog(modifyLogResponse.data ? modifyLogResponse.data : []);
          })
        )
        .catch(() => {
          alert("後端異常");
          setProductDetail(productDetailDefault);
          setModifyLog([]);
        });
    } else {
      setProductDetail(productDetailDefault);
      setModifyLog([]);
    }
  };

  const productDetailSubmit = (e) => {
    e.preventDefault();
    // console.table(productDetail);
    if (window.confirm("確定儲存此筆資料？")) {
      Axios.post("/api/product/detail/insertOrUpdate", {
        productDetail: productDetail,
        OpName: cookies.op_name,
      })
        .then((res) => {
          alert("儲存成功");
          closeModal();
          // window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          alert("後端異常");
        });
    }
  };

  const productReview = () => {
    if (window.confirm("確定審核此筆資料？")) {
      Axios.post("/api/product/detail/review", {
        sn: productDetail.sn,
        OpName: cookies.op_name
      })
        .then((res) => {
          alert("審核完成");
          closeModal();
          // window.location.reload(true);
        })
        .catch((error) => {
          console.log(error);
          alert("後端異常");
        });
    }
  };

  const productNameChange = (event) => {
    if (event.target.value !== "") {
      var sn = parseInt(event.target.value);
      // console.log(sn);
      // console.log(sn !== null);
      var product = productList.find((product) => {
        if (product.sn === sn) return product;
        return null;
      });
      setProductDetail((prevState) => ({
        ...prevState,
        productSn: product.sn,
        pid: product.pid,
        price: product.price,
        priceCost: product.priceCost,
      }));
    } else {
      setProductDetail((prevState) => ({
        ...prevState,
        productSn: 0,
        pid: "",
        price: "",
        priceCost: "",
      }));
    }
  };

  return (
    <Dialog
      open={modalStatus}
      onClose={closeModal}
      maxWidth="md"
      scroll="paper"
    >
      <Box>
        <form onSubmit={productDetailSubmit}>
          <DialogTitle>商品重要資訊設定：</DialogTitle>
          <DialogContent dividers>
            <TextField
              fullWidth
              margin="dense"
              label="商品編號（系統自動產生）"
              name="productNo"
              value={productDetail.productNo}
              disabled
              required
              onChange={handleChange}
            />
            {/* <TextField
              fullWidth
              autoFocus
              margin="dense"
              label="HiB2B商品編號"
              name="hib2bProductCode"
              value={productDetail.hib2bProductCode}
              disabled={reviewed}
              onChange={handleChange}
            /> */}
            <FormControl fullWidth>
              <InputLabel htmlFor="demo-customized-select-native">
                商品名稱
                (如需修改商品名稱、方案、定價、成本及PID，請至商品種類修改，謝謝！)
              </InputLabel>
              <NativeSelect
                value={productDetail.productSn}
                disabled={reviewed}
                onChange={productNameChange}
                required
              >
                <option></option>
                {productList.map((product, index) => {
                  return (
                    <option
                      key={index}
                      value={product.sn}
                      disabled={!product.reviewed}
                    >
                      {product.name}
                      {product.plan !== "" ? " - " + product.plan : ""}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
            <TextField
              fullWidth
              margin="dense"
              label="小額付款編號(PID)"
              name="pid"
              value={productDetail.pid}
              disabled
            />
            <TextField
              fullWidth
              margin="dense"
              label="定價"
              value={productDetail.price}
              disabled
            />
            <TextField
              fullWidth
              margin="dense"
              label="成本"
              value={productDetail.priceCost}
              disabled
            />
            <TextField
              fullWidth
              margin="dense"
              label="優惠價"
              name="priceSpecial"
              type="number"
              helperText={
                productDetail.isUpVisible
                  ? "與官網的優惠價連動，請小心更改"
                  : ""
              }
              error={productDetail.isUpVisible}
              value={productDetail.priceSpecial}
              onChange={handleChange}
              autoComplete="off"
              disabled={reviewed}
              required
            />
            <TextField
              fullWidth
              margin="dense"
              label="銷售限額"
              name="buyLimit"
              type="number"
              value={productDetail.buyLimit}
              onChange={handleChange}
              autoComplete="off"
              disabled={reviewed}
              required
            />
            <TextField
              fullWidth
              margin="dense"
              label="商品折扣"
              name="description"
              value={productDetail.description}
              disabled={reviewed}
              autoComplete="off"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="dense"
              label="商品簡介"
              name="simple"
              autoComplete="off"
              multiline
              value={productDetail.simple}
              disabled={reviewed}
              onChange={handleChange}
            />
            {/* <TextField fullWidth margin="dense" label="後台受理建單設定" /> */}
          </DialogContent>
          <DialogActions>
            <Typography hidden={!reviewed}>
              <Button onClick={productReview} name="review" color="primary">
                審核
              </Button>
            </Typography>
            <Typography hidden={reviewed}>
              <Button type="submit" name="save" color="primary">
                儲存
              </Button>
            </Typography>
            <Button onClick={closeModal} color="primary">
              取消
            </Button>
          </DialogActions>
          <Typography component="div" hidden={productDetailSn === 0}>
            <DialogTitle>商品更改紀錄：</DialogTitle>
            <DialogContent dividers>{logTable(modifyLog)}</DialogContent>
          </Typography>
        </form>
      </Box>
    </Dialog>
  );
}
