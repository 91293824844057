import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import OverviewImg1 from "../assets/ProductOverview/all-1.jpg";
import OverviewImg2 from "../assets/ProductOverview/all-2.jpg";
import OverviewImg3 from "../assets/ProductOverview/all-3.jpg";
import OverviewImg4 from "../assets/ProductOverview/all-4.jpg";

import Advantage1 from "../assets/ProductOverview/Advantage01.png";
import Advantage2 from "../assets/ProductOverview/Advantage02.png";
import Advantage3 from "../assets/ProductOverview/Advantage03.png";


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '20px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

// CSS
const StyledContainer = Styled(Grid)`
  && {
    margin-bottom: 120px;
  }
`
const StyledGrid = Styled(Grid)`
  && {
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 30px 0 30px;
  }
`
const StyledContextImage = Styled.img`
  max-width: 100%;
  border: 2px solid #28a8ea;
  border-radius: 10px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #28a8ea;
    margin: 0px;
    margin-bottom: 10px;
  }
`
const StyledContextContent = Styled(Typography)`
  && {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledAdvantageGrid = Styled(Grid)`
  display: grid;
  justify-content: center;
  text-align: center;
`
const StyledAdvantageImage = Styled.img`
  max-width: 100%;
  height: 120px;
  margin: auto;
  padding-bottom: 15px;
`
const StyledAdvantageTitle = Styled(Typography)`
  && {
    font-size: 2rem;
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-bottom: 25px;
  }    
`
const StyledAdvantageContent = Styled(Typography)`
  && {
    font-size: 1.25rem;
    font-family: Microsoft JhengHei;
    margin-bottom: 100px;
  } 
`

function renderContext() {
  const contexts = [
    {
      title: "您的隨身 Office",
      context:
        "不論是在辦公室還是外出工作，都能使用自己熟悉的一流生產力工具。Office 應用程式永遠保持最新版本，讓您在PC/Mac、 iOS、Android 或 Windows  裝置上建立、編輯內容並即時與任何人員共用。",
      img: OverviewImg1
    },
    {
      title: "行銷推廣的最佳利器",
      context:
        "將公司名稱加到企業級電子郵件位址以建立公司知名度，並且使用易於建立的客製化行銷資料來推廣自家的業務。透過從電子郵件和 IM 到社交網路和視訊會議等各種通訊工具，與客戶和同事更密切地交流。",
      img: OverviewImg2
    },
    {
      title: "適合團隊合作的工具",
      context:
        "每位使用者都有 1 TB 的儲存空間，您將擁有充足的足以容納所有檔案的空間。除此之外，由於您的檔案儲存在線上，因此不論在何處工作，只要有需要就能將檔案與公司內外的人員共用。加上具備多方 HD 視訊、內容共用以及共用行事曆，您將能隨時掌握團隊進度。",
      img: OverviewImg3
    },
    {
      title: "設定與管理作業輕鬆又簡單",
      context:
        "透過逐步引導，您可輕鬆設定並快速開始使用服務 您可隨時隨地存取輕鬆易用的系統管理中心，管理您的全部裝置。 Office 365 也為您貼心打理 IT 事務，讓裝置運作順暢並維持在最新狀態。",
      img: OverviewImg4
    }
  ];

  return contexts.map(context => (
    <StyledContainer container>
      <StyledGrid item md={5} sm={12}>
        <StyledContextImage src={context.img}></StyledContextImage>
      </StyledGrid>
      <StyledGrid item md={7} sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextDivider/>
        <StyledContextContent>{context.context}</StyledContextContent>
      </StyledGrid>
    </StyledContainer>
  ));
}

function renderAdvantage() {
  const contexts = [
    {
      title: "符合經濟效益",
      context: "無需負擔軟體的前期成本，而且軟體永遠保持更新。",
      img: Advantage1
    },
    {
      title: "內建安全性",
      context: "內建安全性加上法規遵循與隱私權控制，協助保障資料的安全。",
      img: Advantage2
    },
    {
      title: "永遠整裝待命",
      context: "您永遠都有以財務為後盾保證的 99.9% 上線時間。",
      img: Advantage3
    }
  ];

  return contexts.map(context => (
    <>
      <StyledAdvantageGrid item md={4} sm={12}>
        <StyledAdvantageImage src={context.img}></StyledAdvantageImage>
        <StyledAdvantageTitle variant="h5">
          {/* <Avatar alt="Remy Sharp" src={context.img} /> */}
          {context.title}
        </StyledAdvantageTitle>
        <StyledAdvantageContent>{context.context}</StyledAdvantageContent>
      </StyledAdvantageGrid>
    </>
  ));
}

export default function CenteredGrid(props) {
  const classes = useStyles();



  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {renderContext()}
        {renderAdvantage()}
      </Grid>
    </div>
  );
}
