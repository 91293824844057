import React from "react";

import Group from "./group";
import Operator from "./Operator";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function Operators() {
  const [cookies] = useCookies();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    }
  }));
  const tabStyles = makeStyles(theme => ({
    tab: {
      minWidth: 150
    }
  }));

  function isAdminGrp() {
    var gid = cookies.group_id === undefined || isNaN(cookies.group_id) ? 0 : parseInt(cookies.group_id);
    if (process.env.REACT_APP_MODE === "production" || process.env.REACT_APP_MODE === "intranet") {
      return gid === 1;
    } else {
      return gid === 1 || gid === 2;
    }
  }

  function renderTabs() {
    const tabList = [];
    const classes = tabStyles();
    const labels = isAdminGrp() ? ["使用者管理", "群組管理"] : ["使用者管理"];

    for (let index = 0; index < labels.length; index++) {
      tabList.push(
        <Tab
          label={labels[index]}
          {...a11yProps(index)}
          className={classes.tab}
        />
      );
    }
    return tabList;
  }

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label="simple tabs example"
        >
          {renderTabs()}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Operator />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Group />
      </TabPanel>
    </div>
  );
}
