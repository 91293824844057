import React from "react";
import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";

import axios from "axios";
import Qs from "qs";

export default function GroupAddDialog(props) {
  const closeModal = props.closeModal;
  const modalStatus = props.modalStatus;
  const [functionLists, setFunctionLists] = useState([]);
  const editPermissions = props.editPermissions;
  const enablePermissions = props.enablePermissions;

  const [name, setName] = useState("name");
  const [description, setDescription] = useState("desciption");
  const [state, setState] = useState(false);
  const [form, setForm] = useState({});

  function addGroup() {
    axios
      .post(
        "/OP/group/addGroup",
        {
          form: form,
          name: name,
          description: description,
          state: state
        }
      )
      .then(res => {
        alert(res.data.msg);

        closeModal();
      })
      .catch(error => {
        if (error.response) {
          // 當狀態碼不在 validateStatus 設定的範圍時進入
          // 有 data / status / headers 參數可用
          alert(error.response.data.msg);
        } else if (error.request) {
          // 發送請求，但沒有接到回應
          // error.request is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // 在設定 request 時出錯會進入此
          console.log("Error", error.message);
        }
      });
  }

  function permissionChange(event) {
    var sn = parseInt(event.target.value);
    var index = event.target.name;
    console.log(sn);
    console.log(sn !== null);
    if (isNaN(sn)) return null;
    setForm(prevState => ({
      ...prevState,
      [index]: { sn: prevState[index].sn, value: sn }
    }));
  }

  function changeName(event) {
    setName(event.target.value);
  }
  function changeDescription(event) {
    setDescription(event.target.value);
  }

  function changeState(event) {
    if (state === true) {
      setState(false);
    } else {
      setState(true);
    }
  }
  useEffect(() => {
    getAllShowFunction();
  }, []);

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(3)
    }
  }));

  const classes = useStyles();

  function getAllShowFunction() {
    axios
      .post(
        "/OP/function/getAllShowFunction",
        Qs.stringify({
          test: "test"
        })
      )
      .then(res => {
        const list = res.data.data;
        setFunctionLists(list);

        var relations = {};

        for (var i = 0; i < list.length; i++) {
          var relation = {};
          relation["sn"] = list[i].sn;
          relation["value"] = 1;
          relations[i] = relation;
        }
        // console.log(relations);

        setForm(relations);
      })
      .catch(error => {
        if (error.response) {
          // 當狀態碼不在 validateStatus 設定的範圍時進入
          // 有 data / status / headers 參數可用
          alert(error.response.data.msg);
        } else if (error.request) {
          // 發送請求，但沒有接到回應
          // error.request is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // 在設定 request 時出錯會進入此
          console.log("Error", error.message);
        }
      });
  }
  function close() {
    setForm({});
    closeModal();
  }

  return (
    <div>
      <Dialog
        open={modalStatus}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">新增群組</DialogTitle>
        <DialogContent>
          <DialogContentText>
        基本資料
          </DialogContentText>
          <TextField
            value={name}
            label="群組名稱"
            name="name"
            required
            onChange={changeName}
            style={{ margin: 8 }}
            placeholder="Name"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            value={description}
            label="說明"
            name="name"
            required
            onChange={changeDescription}
            style={{ margin: 8 }}
            placeholder="Name"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          {functionLists.map((functionList, funcIndex) => {
            // console.log(functionList);
            funcIndex = funcIndex.toString()
            if (functionList.permissionType === 1) {
              return (
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                  row
                >
                  <FormLabel component="legend">{functionList.name}</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name={funcIndex}
                    value={form[funcIndex] ? form[funcIndex].value : 1}
                    onChange={permissionChange}
                    row
                  >
                    {editPermissions.map((editPermission, index) => {
                      return (
                        <FormControlLabel
                          name={funcIndex}
                          value={editPermission.sn}
                          control={<Radio />}
                          label={editPermission.name}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              );
            } else {
              return (
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                  row
                >
                  <FormLabel component="legend">{functionList.name}</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name={funcIndex}
                    value={form[funcIndex] ? form[funcIndex].value : 1}
                    onChange={permissionChange}
                    row
                  >
                    <FormControlLabel
                      name={funcIndex}
                      value={1}
                      control={<Radio />}
                      label={"不開啟功能"}
                    />
                    {enablePermissions.map((enablePermission, index) => {
                      return (
                        <FormControlLabel
                          name={funcIndex}
                          value={enablePermission.sn}
                          control={<Radio />}
                          label={enablePermission.name}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              );
            }
          })}
          <InputLabel style={{ margin: 8 }}>群組是否啟用</InputLabel>
          <Checkbox
            // checked={state}
            onChange={changeState}
            value={state}
            style={{ margin: 8 }}
            name="state"
            color="primary"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button onClick={addGroup} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
