import React, { forwardRef, useEffect } from "react";
import MaterialTable from "material-table";

// icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import NumberFormat from "react-number-format";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import ChargeModalByHn from "./ChargeModalByHn";
import SaveAlt from "@material-ui/icons/SaveAlt";

import Axios from "axios";
// Component
import { Box, Link } from "@material-ui/core";
import { CsvBuilder } from "filefy";
import { format } from "date-fns";

import { useCookies } from "react-cookie";

import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid, FormControl, NativeSelect } from "@material-ui/core";

// Style CSS
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from "@date-io/date-fns";

// Component
import {
  Dialog,
  DialogTitle,
  ButtonGroup,
  Button,
  Divider,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 500,
    //height: '100%'
  },
}));

const tableIcons = {
  // CRUD
  Add: forwardRef((props, ref) => (
    <AddCircleOutlineIcon {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  // page
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  // search
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
};

/* const searchItemDefault = {
  orderDateStart: "",
  orderDateEnd: format(new Date(), "yyyy-MM-dd"),
}; */


export default function UsingManage() {
  const [rows, setRows] = React.useState([]);

  const getOrderStatusURL = "/api/order/getOrderStatusList";
  const [orderStatusList, setOrderStatusList] = React.useState({});
  const BrandSnMicrosoft = 1;

  const [chargeModalStatusByHn, setChargeModalStatusByHn] = React.useState(false);
  // Modal Sn
  const [memberHn, setMemberHn] = React.useState(0);

  const [cookies] = useCookies();
  const [permission, setPermission] = React.useState(0);

  //const [searchItem, setSearchItem] = React.useState(searchItemDefault);

  const [keepModal, setKeepModal] = React.useState(false);


  // Time picker
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    new Date(Date.now())
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date(Date.now()));
  const datetimeFormat = "yyyy/MM/dd HH:mm:ss";

  const notPermitted = 1;
  const readOnly = 2;

  const [selectTimeModalStatus, setSelectTimeModalStatus] = React.useState(false);

  // Open time range query order list file download modal
  const openSelectTimeModal = () => {
    setSelectTimeModalStatus(true);
  };
  const closeSelectTimeModal = () => {
    setSelectTimeModalStatus(false);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  /* const handleChangeSearchItem = (event) => {
    var { name, value } = event.target;
    setSearchItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }; */

  const setKeepModalStatus = (isKeep) => {
    setKeepModal(isKeep);
  };

  const orderDetailDefault = {
    orderNo: "",
    orderDate: "",
    dsisNo: "",
    orderChargeTypeSn: "",
    orderSourceTypeSn: "",
    dsisCounter: "",
    orderStatusSn: "2",
    quantity: "1",
    accumulatedPrice: "",
    orderDisSubscribeDate: "",
    promotionEmployeeNo: "",
    operatorNote: "",
    contractNoK1: "",
    billingCycle: "",
    isEligibleMigrateNCE: false,
    autoRenewStatus: true,
    hd: "",
  };

  const [orderDetail, setOrderDetail] = React.useState(orderDetailDefault);

  const handleChangeOrderDetail = (event) => {
    var { name, value, type } = event.target;
    console.log(name, value);

    getData(value, "");

    if (type === "select-one") {
      if (name === "autoRenewStatus") {
        value = value === "true" ? true : false;
      } else {
        value = parseInt(value);
      }
    }
    if (type === "number") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
    }
    if (name === "orderDisSubscribeDate") {
      // convert local datetime to string with timezone
      try {
        value = new Date(value).toISOString();
      } catch (e) {
        value = "";
      }
    }
    setOrderDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {

    var gid = isNaN(parseInt(cookies.group_id)) ? 0 : parseInt(cookies.group_id);
    if (gid !== 0) {
      getGroupPermission(gid);
    }
    getData("2", "");
    getOrderStatusList();
    //setOrderDetail(orderDetailDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  const getGroupPermission = (sn) => {
    var postData = {};
    postData["Sn"] = sn;
    Axios.post("/OP/group/getGroupData", postData).then(function (response) {
      var res = response.data.data;
      for (var i in res) {
        if (res[i].name === '會員管理') {
          setPermission(res[i].permission);
          break;
        }
      }
    }).catch(function (err) {
      console.log(err);
    });
  };

  const actions = (permission === readOnly) ?
    [] : [
      {
        icon: SaveAlt,
        tooltip: "將訂單列表匯出成 csv",
        isFreeAction: true,
        hidden: false,
        onClick: () => {
          openSelectTimeModal();
        },
      },
    ];

  const orderChargeByHn = (hn = 0) => {
    setMemberHn(hn);
    console.log(hn);
    openChargeModalByHn();
  };
  const openChargeModalByHn = () => {
    setChargeModalStatusByHn(true);
  };
  const closeChargeModalByHn = () => {
    setChargeModalStatusByHn(false);
  };

  const columns = [
    {
      title: "用戶證號",
      field: "idNo",
    },
    {
      title: "公司統編",
      field: "companyNo",
    },
    {
      title: "公司/客戶名稱",
      field: "clientName",
    },
    {
      title: "各授權版本",
      field: "productName",
    },
    {
      title: "數量",
      field: "quantity",
    },
    { title: "HN帳號", field: "aaHnNo" },
    {
      title: "公司/客戶名稱",
      field: "memberName",
    },
    { title: "證號", field: "memberIdNo", hidden: true, searchable: true },
    {
      title: "累計出帳金額",
      field: "accumulationCharge",
      // sorting: false,
      render: (rowData) => (
        <div align="right">
          <NumberFormat
            value={rowData.accumulationCharge}
            displayType={"text"}
            prefix={"$"}
            thousandSeparator={true}
          />
          <br />
          <Link component="button" onClick={() => orderChargeByHn(rowData.hn)}>
            列帳
          </Link>
        </div>
      ),
    },
    {
      title: "推廣人",
      field: "promotionEmployeeNo"
    },
    {
      title: "註冊日期",
      field: "registerdate"
    },
    {
      title: "最後登入日",
      field: "lastdate"
    },
    {
      title: "啟用時間",
      field: "orderStartDate",
      render: (rowData) => {
        if (rowData.orderStartDate) {
          return format(new Date(rowData.orderStartDate), datetimeFormat);
        }
        return null;
      },
    },
  ];

  // Microsoft 的訂單狀態
  async function getOrderStatusList() {
    Axios.post(getOrderStatusURL, {
      brandSn: BrandSnMicrosoft,
    }).then((res) => {
      // Microsoft 的訂單狀態清單
      // console.table(orderStatusResponse.data.dataList);
      setOrderStatusList(res.data.dataList);
    });
  }

  async function getData(orderStatusSn, opName = "") {
    var postData = {};

    //postData.OrderDateStart = searchItem.orderDateStart;
    //postData.OrderDateEnd = searchItem.orderDateEnd;
    postData.OrderStatusSn = orderStatusSn;
    postData.OpName = "";

    await Axios.post("/api/order/getOrderHnByOrderStatusSn", postData).then((res) => {

      if (!(res.data === undefined || res.data === null)) {
        for (var i = 0; i < res.data.length; ++i) {
          if (res.data[i]["registerdate"] != null) {
            res.data[i]["registerdate"] = res.data[i]["registerdate"]
              .split(".")[0]
              .replace("T", " ")
              .replace("Z", "");
          }

          if (res.data[i]["lastdate"] != null) {
            res.data[i]["lastdate"] = res.data[i]["lastdate"]
              .split(".")[0]
              .replace("T", " ")
              .replace("Z", "");
          }
        }
        res.data.sort((a, b) => (a.hn > b.hn ? 1 : -1));
        setRows(res.data);
      }
    });
  }

  function onConfirmRangeTimeQuery() {
    if (selectedStartDate <= selectedEndDate) {
      // Fetch timezone
      var timeOffset = selectedStartDate.getTimezoneOffset() * 60000;

      // Adjust timezone
      var selectedStartDateWithTZ = new Date(
        selectedStartDate.getTime() - timeOffset
      );
      var selectedEndDateWithTZ = new Date(
        selectedEndDate.getTime() - timeOffset
      );

      console.log("orderDetail.orderStatusSn=" + orderDetail.orderStatusSn);
      // Fetch data within query range
      Axios.post("/api/order/getTimeRangeQueryOrder", {
        StartDate: selectedStartDateWithTZ
          .toISOString()
          .split(".")[0]
          .replace("T", " "),
        EndDate: selectedEndDateWithTZ
          .toISOString()
          .split(".")[0]
          .replace("T", " "),
        BrandSn:
          localStorage.getItem("filterCompany") !== null
            ? parseInt(localStorage.getItem("filterCompany"))
            : 0,
        OpName: cookies.op_name,
        OrderStatusSn: orderDetail.orderStatusSn + "",
      })
        .then((res) => {
          // Store data fetched from backend
          var data = res.data["dataList"];

          console.log(data);

          // Create file name
          var fileName =
            "訂單清單_從_" +
            format(selectedStartDate, datetimeFormat) +
            "_至_" +
            format(selectedEndDate, datetimeFormat) +
            ".csv";

          var fileColumns = columns;
          fileColumns.push(
            {
              title: "推廣人",
              field: "promotionEmployeeNo",
            },
            {
              title: "公司統編",
              field: "companyNo",
            },
            {
              title: "商品價位",
              field: "priceSpecial",
            },
            {
              title: "商品折扣",
              field: "productDiscount",
            },
            {
              title: "聯絡人（姓）",
              field: "memberContactLastName",
            },
            {
              title: "聯絡人（名）",
              field: "memberContactName",
            },
            {
              title: "聯絡電話",
              field: "memberTel",
            },
            {
              title: "聯絡手機",
              field: "memberCell",
            },
            {
              title: "聯絡email",
              field: "memberEMail",
            },
            {
              title: "網域名稱",
              field: "domain",
            },
            {
              title: "客戶 ID",
              field: "customerId",
            },
            {
              title: "訂閱 ID",
              field: "subscriptionId",
            }
          );

          if (data != null && data.length !== 0) {
            // To prevent Excel automatically convert orderNo with pure digits to int
            // for (var i = 0; i < data.length; ++i) {
            //   data[i]["orderNo"] = data[i]["orderNo"];
            // }

            // Create file
            new CsvBuilder(fileName)
              .setColumns(fileColumns.map((columnDef) => columnDef.title))
              .addRows(
                data.map((rowData) =>
                  columns.map((columnDef) => rowData[columnDef.field])
                )
              )
              .exportFile();
          } else {
            // If there's no data created within queried time range
            new CsvBuilder(fileName)
              .setColumns(fileColumns.map((columnDef) => columnDef.title))
              .exportFile();
          }

          // Close modal
          closeSelectTimeModal();
        })
        .catch((error) => {
          alert(error);
          closeSelectTimeModal();
        });
    } else {
      alert("起始時間不能在結束時間之後!");
    }
  }

  var classes = useStyles();
  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.container}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: 'center',
                minHeight: '100vh'
              }}
            >
              <Grid item style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 200
              }}>
                <Typography style={{ fontSize: 100 }}>
                  您無此權限
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }
  return (
    <Box m={2}>
      <Box m={2}>
        <Grid container>
          <Grid item xs={2} md={2}>
            {"搜尋條件："}
          </Grid>
          {/* <Grid item xs={2} md={1}>
            {"訂單起始日："}
          </Grid>
          <Grid item xs={2} md={2}>
            <TextField
              // fullWidth
              type="date"
              name={"orderDateStart"}
              value={searchItem.orderDateStart}
              onChange={handleChangeSearchItem}
            />
          </Grid>
          <Grid item xs={2} md={1}>
            {"訂單結束日："}
          </Grid>
          <Grid item xs={2} md={2}>
            <TextField
              // fullWidth
              type="date"
              name={"orderDateEnd"}
              value={searchItem.orderDateEnd}
              onChange={handleChangeSearchItem}
            />
          </Grid> */}
          <Grid item xs={2} md={4}>
            <FormControl>
              <NativeSelect
                id="demo-customized-select-native"
                value={orderDetail.orderStatusSn}
                name={"orderStatusSn"}
                onChange={handleChangeOrderDetail}
                disabled={keepModal}
                required
              >
                {Object.keys(orderStatusList).map((orderStatusKey, index) => {
                  return (
                    <option key={index} value={orderStatusKey} disabled={false}>
                      {orderStatusList[orderStatusKey]}
                    </option>
                  );
                })}
              </NativeSelect>
              <Typography variant="caption" display="block" gutterBottom>
                選擇顯示的訂單狀態
              </Typography>
            </FormControl>
          </Grid>

        </Grid>
        <Grid container>

        </Grid>
      </Box>
      <MaterialTable
        title="版本用量查詢"
        columns={[
          { title: "用戶HN", field: "hn" },
          { title: "用戶證號", field: "idNo" },
          { title: "公司統編", field: "companyNo" },
          { title: "公司/客戶名稱", field: "clientName" },
          { title: "各授權版本", field: "productName" },
          { title: "數量", field: "quantity" },
          {
            title: "累計出帳金額", field: "accumulationCharge",
            //render: (rowData) =>console.log(rowData),
            // sorting: false,rowData
            render: (rowData) => (
              <div align="right">
                <NumberFormat
                  value={rowData.accumulationCharge}
                  displayType={"text"}
                  prefix={"$"}
                  thousandSeparator={true}
                />
                <br />
                <Link component="button" onClick={() => orderChargeByHn(rowData.hn)}>
                  列帳
                </Link>
              </div>
            ),
          },
          { title: "註冊日期", field: "registerdate" },
          { title: "最後登入日", field: "lastdate" },
        ]}
        actions={actions}
        data={rows}
        options={{
          searchText: "",
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 500],
          actionsColumnIndex: -1,
          showTextRowsSelected: true,
          headerStyle: {
            backgroundColor: "#888",
            color: "#FFF"
          },
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2
              ? "#FFFFFF"
              : "#EEEEEE"
          })
        }}
        icons={tableIcons}
      />

      <Dialog
        open={selectTimeModalStatus}
        onClose={closeSelectTimeModal}
        maxWidth="lg"
        scroll="paper"
      >
        <Box>
          <DialogTitle>選取時段區間資料</DialogTitle>
          <Grid
            style={{
              marginTop: 15,
              marginBottom: 5,
            }}
          >
            <Divider></Divider>
          </Grid>
          <Grid container style={{ width: 800 }}>
            <Grid item xs={2}>
              <Typography
                style={{
                  fontSize: 20,
                  marginLeft: 40,
                  marginTop: 20,
                }}
              >
                起始時間:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container style={{ width: 800 }}>
            <Grid item xs={2}>
              <Typography
                style={{
                  fontSize: 20,
                  marginLeft: 40,
                  marginTop: 20,
                }}
              >
                結束時間:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    setKeepModalStatus={setKeepModalStatus}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid
            style={{
              marginTop: 15,
              marginBottom: 5,
            }}
          >
            <Divider></Divider>
          </Grid>
          <ButtonGroup
            style={{
              float: "right",
              padding: 10,
            }}
          >
            <Button onClick={onConfirmRangeTimeQuery} color="primary">
              下載訂單清單
            </Button>
            <Button onClick={closeSelectTimeModal} color="primary">
              取消
            </Button>
          </ButtonGroup>
        </Box>
      </Dialog>

      <ChargeModalByHn
        open={chargeModalStatusByHn}
        closeModal={closeChargeModalByHn}
        hn={memberHn}
      />
    </Box>
  );
}
