import React, {useState, useEffect} from "react";

// Core
import {
    Typography,
    Button,
    Grid,
    Divider,
    Card,
    CardContent
  } from "@material-ui/core";

import Styled from "styled-components";
import DialogProductInfo from "./DialogProductInfo";
import BeforePurchase from "../BeforePurchase";

// Icon materials
import ProductIcon0 from "../assets/BuyNow/icon/ProductIcon_Cloud.png"
import ProductIcon1 from "../assets/BuyNow/icon/ProductIcon_O.png"
import ProductIcon2 from "../assets/BuyNow/icon/ProductIcon_E.png"
import ProductIcon3 from "../assets/BuyNow/icon/ProductIcon_P2.png"
import ProductIcon4 from "../assets/BuyNow/icon/ProductIcon_P.png"
import ProductIcon5 from "../assets/BuyNow/icon/ProductIcon_X.png"
import ProductIcon6 from "../assets/BuyNow/icon/ProductIcon_T.png"
import ProductIcon7 from "../assets/BuyNow/icon/ProductIcon_W.png"
import ProductIcon8 from "../assets/BuyNow/icon/ProductIcon_N.png"

// CSS
const StyledGrid = Styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-witdh: 100%;
        margin: 35px 0 20px 0;
    }
`
const StyledButtonGrid = Styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-witdh: 100%;
    }
`
const StyledCard = Styled(Card)`
    && {
        border-radius: 15px;
        padding: 15px;
        width: 61vw;
        max-width: 293px;
        border: 1px solid #ddd;
        box-shadow: 2px 2px 10px #ccc;
    }
`
const StyledBuyNowButton = Styled(Button)`
    && {
        display: none;
        margin: 10px 0;
        background-color: #28a8ea;
        color: #fff;
        width: 80%;
        font-family: Microsoft JhengHei;
        border-radius: 30px;
        border: 2px solid #28a8ea;
        transition: all .3s ease-in;
        letter-spacing: 2.5px;
        line-height: 1.5;
        word-break: break-all;
        &:hover {
            background-color: #fff;
            border: 2px solid #28a8ea;
            color: #28a8ea;
        }
    }
`
const StyledMoreInfoButton = Styled(Button)`
    && {
        margin: 10px 0;
        background-color: #00b167;
        color: #fff;
        width: 80%;
        font-family: Microsoft JhengHei;
        border-radius: 30px;
        border: 2px solid #00b167;
        transition: all .3s ease-in;
        letter-spacing: 2.5px;
        line-height: 1.5;
        word-break: break-all;
        &:hover {
            background-color: #fff;
            border: 2px solid #00b167;
            color: #00b167;
        }
    }
`
const StyledProductIcon = Styled.img`
    && {
        max-width: 100%;
        height: auto;
        max-height: 20px;
        margin: 3px;
    }
`
const StyledDivider = Styled(Divider)`
    && {
        margin: 10px 0px;
    }
`
const StyledIconGrid = Styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 51px;
    }
`
const StyledTitle = Styled(Typography)`
    && {
        text-align: center;
        font-weight: 700;
        font-family: Microsoft JhengHei;
        margin: 20px 0 40px 0;
        font-size: 1.7rem;
        letter-spacing: 2.5px;
        line-height: 1.5;
        word-break: keep-all;
    }
`
const StyledPriceContent = Styled(Typography)`
    && {
        text-align: center;
        font-weight: 700;
        font-family: Microsoft JhengHei;
        margin-bottom: 5px;
        letter-spacing: 2.5px;
        line-height: 1.5;
        word-break: keep-all;
    }
`
const StyledPrice = Styled.span`
    && {
        color: black;
        font-size: 1.5rem;
    }

`
const StyledPer = Styled.span`
    && {
        color: black;
        font-size: 0.9rem;
    }

`
// const StyledTip = Styled.p`
//     && {
//         color: red;
//         font-size: 0.8rem;
//         font-weight: 800;
//         margin: 0px;
//     }
// `
const StyledTitleHint = Styled.p`
    && {
        color: black;
        font-size: 0.8rem;
        margin: 0px;
    }
`

// Product Icon List
const productIconList = [
    ProductIcon0,
    ProductIcon1,
    ProductIcon2,
    ProductIcon3,
    ProductIcon4,
    ProductIcon5,
    ProductIcon6,
    ProductIcon7,
    ProductIcon8
];

// For data of DialogPurchase
const defaultPurchaseData = {
    key: "",
    productTitle: "",
    value: [
        {
            plan: "month",
            detailSn: 0,
            price: ""
        },
        {
            plan: "year",
            detailSn: 0,
            price: ""
        }
    ]
}

// Render Product Icons function
const renderProductIcon = (iconArr) => {
    let rows = [];
    for (var i = 0; i < iconArr.length; i++) {
        rows.push(<StyledProductIcon src={productIconList[iconArr[i]]}/>);
    }
    return (
        <>{rows}</>
    )
}

export default function ProductCard(props) {
    const [openInfo, setOpenInfo] = useState(false);
    const [openPurchase, setOpenPurchase] = useState(false);
    const [purchaseData, setPurchaseData] = useState(defaultPurchaseData);
    const {productKey, serviceList, data, editionDetail} = props;

    useEffect(() => {
        if( data && productKey ){
            let tmpData = {
                key: productKey,
                productTitle: data.heads.contents[productKey].title+" "+data.heads.contents[productKey].edition,
                value: [
                    {
                        plan: "month",
                        detailSn: data.prices.month.contents[productKey].detailSn,
                        price: data.prices.month.contents[productKey].price
                    },
                    {
                        plan: "year",
                        detailSn: data.prices.year.contents[productKey].detailSn,
                        price: data.prices.year.contents[productKey].price
                    }
                ]
            }
            // console.log(tmpData);
            setPurchaseData(tmpData);
        }
    },[productKey, data]);

    // To avoid that props is not undefined
    if( !data || !editionDetail ){
        return
    }

    // Control ProductInfo Dialog
    const handleDialogProductInfoOpen = () => {
        setOpenInfo(true);
    };
    const handleDialogProductInfoClose = () => {
        setOpenInfo(false);
      };

    // Control Purchase Dialog
    const handleDialogProductPurchaseOpen = () => {
        setOpenPurchase(true);
    };
    const handleDialogProductPurchaseClose = () => {
        setOpenPurchase(false);
      };

    return (
        <>
            <StyledGrid item md={4} sm={6} xs={12}>
                <StyledCard>
                    <CardContent>
                        <StyledIconGrid container spacing={1}>
                            {renderProductIcon(serviceList)}
                        </StyledIconGrid>
                        <StyledTitle variant="h5">
                            {data.heads.contents[productKey].title}
                            <br />
                            {data.heads.contents[productKey].edition}
                            <StyledTitleHint>
                                {data.heads.contents[productKey].hint ? data.heads.contents[productKey].hint : '-'}
                            </StyledTitleHint>
                        </StyledTitle>
                        <StyledPriceContent>
                            {/* <StyledPrice>NT$ {data.prices.month.contents[productKey].price}</StyledPrice> */}
                            <StyledPrice>NT$ {"-"}</StyledPrice>
                            <StyledPer> /月</StyledPer>
                            {/* <StyledTip>價格均含稅</StyledTip> */}
                        </StyledPriceContent>
                        <StyledPriceContent>
                            {/* <StyledPrice>NT$ {data.prices.year.contents[productKey].price}</StyledPrice> */}
                            <StyledPrice>NT$ {"-"}</StyledPrice>
                            <StyledPer> /年</StyledPer>
                            {/* <StyledTip>價格均含稅，月繳9折!</StyledTip> */}
                        </StyledPriceContent>
                    </CardContent>
                    <StyledDivider/>
                    <StyledButtonGrid item sm={12}>
                        <StyledMoreInfoButton variant="contained" size="large" onClick={handleDialogProductInfoOpen}>了解更多</StyledMoreInfoButton>
                        <DialogProductInfo
                            open={openInfo}
                            title={data.heads.contents[productKey]}
                            support={editionDetail[productKey]}
                            supportAll={editionDetail.allIncludes}
                            handleDialogClose={handleDialogProductInfoClose}
                        />
                    </StyledButtonGrid>
                    <StyledButtonGrid item sm={12}>
                        <StyledBuyNowButton variant="contained" size="large" onClick={handleDialogProductPurchaseOpen}>立刻購買</StyledBuyNowButton>
                        <BeforePurchase
                            open={openPurchase}
                            data={purchaseData}
                            handleDialogOpen={handleDialogProductPurchaseOpen}
                            handleDialogClose={handleDialogProductPurchaseClose}
                        />
                    </StyledButtonGrid>
                </StyledCard>
            </StyledGrid>
        </>
    );
}

