import React, { useEffect } from "react";

import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import { getCookie, handleRefreshLoginCookies, handleExpiredCookies, ignoredPaths } from "../common/session"
import axios from "axios";

// sub page
import Home from "./Home";
import ProductList from "./ProductList";
import ProductBasic from "./ProductBasic";
import Orders from "./Orders";

import OrderLogs from "./OrderLogs";
import MemberLogs from "./MemberLogs";
import ProductLogs from "./ProductLogs";
import MailLogs from "./MailLogs";
import SmsLogs from "./SmsLogs";
import OperatorLogs from "./OperatorLogs";

import Members from "./Members";
import CouponCodes from "./CouponCodes";
import Operators from "./Operators";
import MPN from "./MPN";
import HEAD from "./HEAD";
import Announcement from "./Announcement";
import DiscodeManagement from "./DiscodeManagement";
import DsisProblematicOrders from "./DsisProblematicOrders";
import UsingManage from "./UsingManage";
import EditableMail from "./EditableMail";

export default function OPRouter(props) {
  // Refresh Login session or Back Login page
  let history = useHistory() // 取得某頁面route name
  let match = useRouteMatch() //偵測同頁轉換
  let debugMode = true;

  useEffect(() => {
    console.log("目前在：" + history.location.pathname)
    if (history.location.pathname === ignoredPaths) {// 避免在backoffice登入頁面偵測cookie。
      return
    } else if (getCookie("session") !== undefined) {// 按下登出按鈕 或是 Session 仍存在的情況下進行換頁
      handleRefreshLoginCookies()
      if (debugMode) {
        console.log("Call loginCheck api- " + getCookie("session"));
      }
      axios
        .post("/api/v0/loginCheck", {
          session: getCookie("session"),
        })
        .then(function (response) {
          if (response.data.IsLogin !== true) {
            handleExpiredCookies()
            history.replace("/backoffice/Login")
          }
        })
        .catch(function (error) {
          console.log("OPRouter", error);
        });
    } else {// Session 過期的情況下進行換頁。
      handleExpiredCookies()
      history.replace("/backoffice/Login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match])

  return (
    <Switch>
      <Route path={`${match.url}/ProductList`} component={ProductList} />
      <Route path={`${match.url}/ProductBasic`} component={ProductBasic} />
      <Route path={`${match.url}/Orders`} component={Orders} />
      <Route path={`${match.url}/UsingManage`} component={UsingManage} />
      <Route path={`${match.url}/Members`} component={Members} />

      <Route path={`${match.url}/OrderLogs`} component={OrderLogs} />
      <Route path={`${match.url}/MemberLogs`} component={MemberLogs} />
      <Route path={`${match.url}/ProductLogs`} component={ProductLogs} />
      <Route path={`${match.url}/MailLogs`} component={MailLogs} />
      <Route path={`${match.url}/SmsLogs`} component={SmsLogs} />
      <Route path={`${match.url}/OperatorLogs`} component={OperatorLogs} />
      <Route path={`${match.url}/Announcement`} component={Announcement} />
      <Route path={`${match.url}/DiscodeManagement`} component={DiscodeManagement} />
      <Route path={`${match.url}/DsisProblematicOrders`} component={DsisProblematicOrders} />
      <Route path={`${match.url}/EditableMail`} component={EditableMail} />

      <Route path={`${match.url}/CouponCodes`} component={CouponCodes} />
      <Route path={`${match.url}/Operators`} component={Operators} />
      <Route path={`${match.url}/MPN`} component={MPN} />
      <Route path={`${match.url}/HEAD`} component={HEAD} />
      <Route path={`${match.url}/`} component={Home} />
    </Switch>
  );
}
