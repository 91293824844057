import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  DialogContent,
  FormControl,
  NativeSelect,
} from "@material-ui/core";
import Axios from "axios";
import MaterialTable from "material-table";
import { useCookies } from "react-cookie";

// const getTermDurationListUrl = "/api/product/termDuration/list";

const BrandMicrosoftSn = 1;
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
const productDefault = {
  sn: 0,
  name: "",
  brandSn: BrandMicrosoftSn,
  planTypeSn: "",
  pid: "",
  price: 0,
  priceCost: 0,
  msOfferName: "",
  msOfferId: "",
  msNCEOfferId: "",
  msOfferRenewDate: "",
  termDuration: ""
};

const productFieldList = {
  Name: "商品名稱",
  Plan: "方案類型",
  TermDuration: "合約期限",
  Pid: "小額付款商品編號",
  Price: "定價",
  PriceCost: "成本",
  Reviewed: "審核狀態",
  MsOfferName: "商品名稱(英文)",
};
const getPlanTypeListUrl = "/api/product/planType/list";

function logTable(data) {
  // console.log()
  const columns = [
    {
      title: "時間",
      field: "modifyTime",
      render: (rowData) => format(new Date(rowData.modifyTime), datetimeFormat),
    },
    { title: "帳號", field: "operatorName" },
    { title: "欄位", field: "field", lookup: productFieldList },
    { title: "修改前資料", field: "beforeValue" },
    { title: "修改後資料", field: "afterValue" },
    { title: "IP位址", field: "modifyIP" },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      options={{
        search: false,
        paging: false,
        showTitle: false,
        toolbar: false,
        padding: "dense",
        sorting: false,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
        }),
      }}
    />
  );
}

export default function ProductForm(props) {
  const [cookies] = useCookies(["session"]);
  const [modalStatus, setModalStatus] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [product, setProduct] = useState(productDefault);
  const [productSn, setProductSn] = useState(0);
  const [modifyLog, setModifyLog] = useState([]);
  const [planTypeList, setPlanTypeList] = useState([]);
  const [productOfferDataList, setProductOfferDataList] = useState([]);
  // const [termDurationList, setTermDurationList] = useState([]);

  const closeModal = props.closeModal;
  const brandSn = props.brand;
  const productOfferDataField = props.productOfferDataField;

  useEffect(() => {
    const getProduct = (sn) => {
      // console.log("MS sn:"+sn);
      setProductSn(sn);
      if (sn && sn !== 0) {
        const getProduct = Axios.post("/api/product", { sn: sn });
        const getModifyLog = Axios.post("/api/product/log/list", { sn: sn }); //FIXME

        Axios.all([getProduct, getModifyLog])
          .then(
            Axios.spread((productResponse, modifyLogResponse) => {
              console.log(productResponse.data);
              setProduct(productResponse.data);
              setModifyLog(modifyLogResponse.data ? modifyLogResponse.data : []);
            })
          )
          .catch(() => {
            alert("撈取商品後端異常");
            // Set brand sn while creating new product
            var newProduct = productDefault;
            newProduct.brandSn = brandSn;
            setProduct(newProduct);
            setModifyLog([]);
          });
      } else {
        var newProduct = productDefault;
        newProduct.brandSn = brandSn;
        setProduct(newProduct);
        setModifyLog([]);
      }
    };
    const getPlanTypeList = () => {
      var postData = {};
      postData["brandSn"] = brandSn;
      console.log(postData["brandSn"]);
      Axios
        .post(getPlanTypeListUrl, postData)
        .then((response) => {
          console.log(response);
          setPlanTypeList(response.data);
        })
        .catch((error) => {
          alert("撈取 plan type 後端異常");
          console.log(error);
        });
    };
    if (props.open) {
      setReviewed(props.review);
      getPlanTypeList();
      // setTermDurationList(props.termDurationList);
      if (productOfferDataField !== undefined) {
        setProductOfferDataList(productOfferDataField);
      }
      getProduct(props.sn);
      // getTermDuration();
    }
    setModalStatus(props.open);
  }, [props.open, props.planTypeList, props.termDurationList, props.review, props.sn, brandSn, productOfferDataField]);

  const handleChange = (event) => {
    var { name, value, type } = event.target;
    if (type === "number") {
      value = value > 2147483647 ? 2147483647 : parseFloat(value);
      value = value < 0 ? 0 : parseFloat(value);
    } else if (type === "select-one") {
      if (name !== "termDuration") {
        value = parseInt(value);
      }
    }

    console.log(name, value);

    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const productReview = () => {
    if (window.confirm("確定審核此筆資料？")) {
      Axios.post("/api/product/review", {
        sn: product.sn,
        OpName: cookies.op_name,
      })
        .then((res) => {
          alert("審核成功");
          closeModal();
          window.location.reload();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const productSubmit = (e) => {
    e.preventDefault();
    if (window.confirm("確定儲存此筆資料？")) {
      Axios.post("/api/product/insertOrUpdate", {
        withCredentials: true,
        product: product,
        OpName: cookies.op_name,
      })
        .then((res) => {
          alert("儲存成功");
          console.log(product);
          closeModal(); // Refreash data after closeModal
          // window.location.reload();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };


  const productDataList = [
    createLabel(
      "商品名稱",
      <TextField
        fullWidth
        margin="dense"
        value={product.name || ""}
        disabled={reviewed}
        name="name"
        onChange={handleChange}
        autoComplete="off"
        required
      />
    ),
    createLabel(
      "方案類型",
      <FormControl fullWidth>
        <NativeSelect
          name="planTypeSn"
          value={product.planTypeSn}
          disabled={reviewed}
          onChange={handleChange}
          required
        >
          <option value=""></option>
          {planTypeList.map((planType, index) => {
            return (
              <option key={index} value={planType.Sn}>
                {planType.Name}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
    ),
    createLabel(
      "PID",
      <TextField
        fullWidth
        margin="dense"
        value={product.pid || ""}
        disabled={reviewed}
        name="pid"
        onChange={handleChange}
        autoComplete="off"
        required
      />
    ),
    createLabel(
      "定價",
      <TextField
        fullWidth
        margin="dense"
        value={product.price || ""}
        disabled={reviewed}
        name="price"
        type="number"
        max="2,147,483,647"
        onChange={handleChange}
        autoComplete="off"
        required
      />
    ),
    createLabel(
      "成本",
      <TextField
        fullWidth
        margin="dense"
        value={product.priceCost || ""}
        disabled={reviewed}
        name="priceCost"
        type="number"
        onChange={handleChange}
        autoComplete="off"
        required
      />
    ),
  ];

  // var productOfferDataList = [
  // ];

  return (
    <Dialog
      open={modalStatus}
      onClose={closeModal}
      maxWidth="lg"
      scroll="paper"
    >
      <Box>
        <form onSubmit={productSubmit}>
          <DialogTitle>商品重要資訊設定：</DialogTitle>
          <DialogContent dividers>
            <Grid container>
              {productDataList.map((item) => {
                return item;
              })}
            </Grid>
          </DialogContent>
          <DialogTitle>商品相關設定：</DialogTitle>
          <DialogContent dividers>
            <Grid container>
              {productOfferDataList.map((item) => {
                return item;
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Typography hidden={!reviewed}>
              <Button onClick={productReview} name="review" color="primary">
                審核
              </Button>
            </Typography>
            <Typography hidden={reviewed}>
              <Button type="submit" name="save" color="primary">
                儲存
              </Button>
            </Typography>
            <Button onClick={closeModal} color="primary">
              取消
            </Button>
          </DialogActions>
          <Typography component="div" hidden={productSn === 0}>
            <DialogTitle>商品種類更改紀錄：</DialogTitle>
            <DialogContent dividers>{logTable(modifyLog)}</DialogContent>
          </Typography>
        </form>
      </Box>
    </Dialog>
  )
}

function createLabel(title, data, mdSize = 6) {
  return (
    <Grid item xs={12} md={mdSize}>
      <Grid container>
        <Grid item xs={4}>
          <Box m={1}>{title}</Box>
        </Grid>
        <Grid item xs={8}>
          <Box m={1}>{data}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
// function datetimeStringFormat(time) {
//   // time is string
//   if (time === "" || time === null || time === undefined) {
//     return "";
//   } else {
//     return format(new Date(time), datetimeFormat);
//   }
// }
