
export function ConvertStr2DatetimeLocal(dateStr) {
  // convert datetime string with time zone to datetime string with time zone

  function ten(i) {
    // pandding "0" while digital is 0 ~ 9
    return (i < 10 ? "0" : "") + i;
  }

  const dateObj = new Date(dateStr);

  // those are get local time value
  const YYYY = dateObj.getFullYear();
  const MM = ten(dateObj.getMonth() + 1);
  const DD = ten(dateObj.getDate());
  const HH = ten(dateObj.getHours());
  const II = ten(dateObj.getMinutes());
  const SS = ten(dateObj.getSeconds());
  return YYYY + "-" + MM + "-" + DD + "T" + HH + ":" + II + ":" + SS;
}

