import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import FileSharing1 from "../assets/ProductOverview/all-14.jpg";
import FileSharing2 from "../assets/ProductOverview/all-15.jpg";
import FileSharing3 from "../assets/ProductOverview/all-16.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));


//CSS
const StyledContextGrid = Styled(Grid)`
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 30px;
`
const StyledContextImage = Styled.img`
  max-width: 100%;
  border: 2px solid #28a8ea;
  border-radius: 10px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #28a8ea;
    margin-bottom: 20px;
  }
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 700;
    font-family: Microsoft JhengHei;
    text-align: left;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContainer = Styled(Grid)`
  && {
    margin-bottom: 120px;
  }
`

function renderSubContext(props) {
  const contexts = props;

  return contexts.map(context => (
    <>
      <StyledContextSubTitle color="secondary" variant="h5">
        {context.subTitle}
      </StyledContextSubTitle>
      <StyledContextSubContent paragraph>
        {context.subContext}
      </StyledContextSubContent>
    </>
  ));
}

function renderContext() {
  const contexts = [
    {
      title: "輕鬆儲存、同步以及共用檔案",
      context: [
        {
          subTitle: "永遠擁有最新版本的檔案",
          subContext:
            "將檔案儲存在線上即可輕鬆儲存、組織及共用檔案，方便與小組成員一起處理文件、與業務合作夥伴共用報告或與客戶聯繫。 您的檔案會永遠保持更新，讓所有人都能存取到最新的版本。"
        },
        {
          subTitle: "取得足以容納所有檔案的空間",
          subContext:
            "有了 1 TB 的個人文件儲存空間，就能在線上儲存所有檔案，並能隨處從 PC/Mac、Android™ 平板電腦、iPad® 或手機存取這些檔案。"
        },
        {
          subTitle: "與自選對象共用",
          subContext: "您可以在公司內外共用檔案。 由您掌控存取權。"
        }
      ],
      img: FileSharing1
    },
    {
      title: "與內部及外部人員共用",
      context: [
        {
          subTitle: "由您掌控檔案存取權",
          subContext:
            "在我們的網路世界中，檔案共用不會只侷限在公司內部人員。 不論是公司內部或外部，由您掌控誰可以查看和編輯每個檔案。 此外，您還可以與他人一起建立、編輯及檢閱檔案，免除檔案往返的麻煩，節省程序中所花費的時間。"
        },
        {
          subTitle: "共同處理同一個檔案",
          subContext:
            "不用再以電子郵件通知相關人員處理檔案，也不會遺失最新修訂的檔案。 只在文件的一個版本上輕鬆新增、回覆及追蹤註解和狀態更新。"
        },
        {
          subTitle: "社交網路的強大力量",
          subContext:
            "社交網路能增強共同作業效果，激盪出創新的火花。 文件與網站將可自動取得更新。"
        }
      ],
      img: FileSharing2
    },
    {
      title: "讓專案保持一致",
      context: [
        {
          subTitle: "協助小組保持同步",
          subContext:
            "讓所有人掌握最新動態，並以小組形式共同合作。 Office 365 提供多種不同的方式，協助確保所有人都能快速找到確切所需的檔案，而且所有小組成員都能同時並進。"
        },
        {
          subTitle: "建立小組文件庫",
          subContext:
            "透過小組成員才能存取的專用專案信箱，將專案電子郵件與共用文件存放於一處，不必浪費時間尋找資訊。"
        },
        {
          subTitle: "建立小組網站",
          subContext:
            "由您掌控自己的小組網站。 加上自動化原則、版權保護及封存功能，您可以保障小組資產的安全。"
        }
      ],
      img: FileSharing3
    }
  ];

  return contexts.map(context => (
    <StyledContainer container>
      <StyledContextGrid item md={5} sm={12}>
        <StyledContextImage src={context.img}></StyledContextImage>
      </StyledContextGrid>
      <StyledContextGrid item md={7} sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextDivider/>
        {renderSubContext(context.context)}
      </StyledContextGrid>
    </StyledContainer>
  ));
}

export default function CenteredGrid(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {renderContext()}
      </Grid>
    </div>
  );
}
