import {
  Grid,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Box,
  Typography,
  DialogContent,
  Tooltip,
  IconButton
} from '@material-ui/core';
import DeleteIcon from "@mui/icons-material/Delete";
import Axios from 'axios';
import React, { useState } from 'react';
import Editor from 'react-simple-wysiwyg';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const getProductDetailListForMailURL = "/api/product/detail/list/forMail";
const getMemberListByProductDetailSnURL = "/api/member/getMemberListByProductDetailSn";

export default function EditableMail(props) {
  // Receiver category
  const unselectedCategoryId = 0;
  const customerCategoryId = 1;
  const productCategoryId = 2;
  const categoryIdNameMap = {};
  categoryIdNameMap[unselectedCategoryId] = "請選擇寄信類別";
  categoryIdNameMap[customerCategoryId] = "客戶";
  categoryIdNameMap[productCategoryId] = "產品";
  const filterOptions = [
    { id: unselectedCategoryId, name: categoryIdNameMap[unselectedCategoryId] },
    { id: customerCategoryId, name: categoryIdNameMap[customerCategoryId] },
    { id: productCategoryId, name: categoryIdNameMap[productCategoryId] }
  ];
  const [filterBy, setFilterBy] = useState(0);

  const [customerEmailList, setCustomerEmailList] = useState([]);

  const [selectedCustomerList, setSelectedCustomerList] = useState([]);
  const [targetProductDetailMemberList, setTargetProductDetailMemberList] = useState([
    {
      id: 0,
      name: "",
      hn: "",
      email: ""
    }
  ]);
  // const [selectedProductDetail, setSelectedProductDetail] = useState(1);
  const [productDetailList, setProducDetailList] = useState([
    {
      id: 0,
      name: "",
      data: {}
    }
  ]);
  const [selectedProductDetailList, setSelectedProductDetailList] = useState([]);
  const [selectedCustomerListBlock, setSelectedCustomerListBlock] = useState("");

  // function getMemberListByProductDetailSn(sn) {
  //   if (Number.isInteger(sn)) {
  //     var postData = {};
  //     postData["Sn"] = sn;
  //     Axios.post(getMemberListByProductDetailSnURL, postData).then(function (res) {
  //       if (res.data !== undefined && res.data !== null && res.data.length > 0) {
  //         setTargetProductDetailMemberList(res.data);
  //       }
  //     }).catch(function (err) {
  //       console.log(err);
  //     });
  //   } else {
  //     console.log(sn, " is not a number but a ", typeof (sn))
  //   }
  // }

  function getMemberListBySelectProductDetailList() {
    if (selectedCustomerList.length === 0) {
      var snList = [];
      selectedProductDetailList.forEach((productDetail) => {
        snList.push(productDetail.id);
      });
      if (snList.length > 0) {
        var postData = {};
        postData["SnList"] = snList;
        Axios.post(getMemberListByProductDetailSnURL, postData).then(function (res) {
          if (res.data !== undefined && res.data !== null && res.data.length > 0) {
            setTargetProductDetailMemberList(res.data);
          }
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        console.log(snList, " is empty");
        setTargetProductDetailMemberList([]);
        setSelectedCustomerListBlock("");
      }
    }
  }

  const [mailTitle, setMailTitle] = useState("");
  const [html, setHtml] = useState('Edit here...');
  const isAllSelected = selectedProductDetailList.length > 0 && selectedProductDetailList.length === productDetailList.length;
  const isAllSelectedCustomer = selectedCustomerList.length > 0 && selectedCustomerList.length === targetProductDetailMemberList.length;
  function onChangeMailTitle(e) {
    setMailTitle(e.target.value);
  }
  function onChange(e) {
    setHtml(e.target.value);
  }

  function handleCatagoryChange(e) {
    const value = e.target.value;
    if (value === productCategoryId) {
      Axios.get(getProductDetailListForMailURL).then(function (res) {
        setProducDetailList(res.data);
      }).catch(function (err) { });
    }
    setFilterBy(value);
  }

  // function handleProductDetailChange(e) {
  //   const value = e.target.value;
  //   setSelectedProductDetail(value);
  //   getMemberListByProductDetailSn(value);
  // }

  const handleSeletedCustomerChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value);

    let duplicateRemoved = [];
    var selectedCstLstBlk = ""

    if (value[value.length - 1] === "all") {
      const isUnSelectAll = selectedCustomerList.length === targetProductDetailMemberList.length;
      setSelectedCustomerList(isUnSelectAll ? [] : targetProductDetailMemberList);
      if (isUnSelectAll) {
        setSelectedCustomerListBlock("");
      } else {
        targetProductDetailMemberList.forEach((item) => {
          selectedCstLstBlk += item.email + '\n';
        });
        setSelectedCustomerListBlock(selectedCstLstBlk);
      }
      return;
    }


    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        selectedCstLstBlk += item.email + '\n';
        duplicateRemoved.push(item);
      }
    });
    setSelectedCustomerListBlock(selectedCstLstBlk);
    setSelectedCustomerList(duplicateRemoved);
  };

  const handleSeletedProductDetailListChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "all") {
      setSelectedProductDetailList(selectedProductDetailList.length === productDetailList.length ? [] : productDetailList);
      return;
    }

    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setSelectedProductDetailList(duplicateRemoved);
  };

  const classes = useStyles();

  function confirmAndSendMail() {
    if (window.confirm("確定已完成編輯並送出信件?")) {
      if (mailTitle === "") {
        alert("請輸入信件標題!");
        return;
      }
      var emailList = [];
      if (filterBy === customerCategoryId) {
        for (var i = 0; i < customerEmailList.length; ++i) {
          emailList.push(customerEmailList[i]["customerEmail"]);
        }
      } else if (filterBy === productCategoryId) {
        for (var j = 0; j < selectedCustomerList.length; ++j) {
          emailList.push(selectedCustomerList[j]["email"]);
        }
      }
      if (emailList.length !== 0) {
        var postData = {};
        postData["EmailList"] = emailList;
        postData["Title"] = mailTitle;
        postData["MailBody"] = html;
        Axios.post("/api/mailLog/sendCustomizedMail", postData).then(function (res) {
          alert("信件寄送成功!");
        }).catch(function (err) {
          alert("信件寄送過程異常");
          console.log(err);
        });
      }
    }
  }

  function createDropDownList(title, value, options, handler) {
    return (
      <FormControl className={classes.formControl} style={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={handler}
        >
          {(options !== undefined && options !== null && options.length > 0) ? (options.map((opt) => (
            <MenuItem value={opt.id} key={opt.id}>{opt.name}</MenuItem>
          ))) : (<></>)}
        </Select>
      </FormControl>
    );
  }

  const handleChangeCustomerEmailList = (i, e) => {
    var { name, value, type } = e.target;
    let newItemValues = [...customerEmailList];

    if (type === "number") {
      value = parseInt(value);
      value = isNaN(value) ? 0 : value;
      value = value > 2147483647 ? 2147483647 : value;
    }

    newItemValues[i][name] = value;
    setCustomerEmailList(newItemValues);
  };

  let handleRemoveRowOfCustomerEmailList = (i) => {
    if (window.confirm("確定刪除資料？")) {
      console.log("Come handleRemoveRow.");
      let newItemValues = [...customerEmailList];
      newItemValues.splice(i, 1);
      setCustomerEmailList(newItemValues);
    }
  };

  let handleAddRowOfCustomerEmailList = () => {
    if (window.confirm("確定新增資料？")) {
      console.log("Handling Add Row Of Email List.");
      setCustomerEmailList([...customerEmailList, { customerEmail: "" }]);
    }
  };

  function createCustomerEmailListTable(title, detailFieldList) {
    return (
      <Box m={2} style={{ width: "100%" }}>
        <Typography variant="h6">{title}</Typography>
        <DialogContent dividers>
          <Grid container>
            {detailFieldList.map((data, index) => (
              <Grid container key={index} alignItems="center">
                <Grid item xs={2}>
                  <Box m={1}>{"Email - " + (index + 1) + "："}</Box>
                </Grid>
                <Grid item xs={4}>
                  <Box m={1}>
                    <TextField
                      fullWidth
                      type="text"
                      name={"customerEmail"}
                      value={data.customerEmail}
                      onChange={(e) => handleChangeCustomerEmailList(index, e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box m={1}>
                    <Tooltip title="Remove a Service" placement="bottom">
                      <IconButton
                        onClick={() => handleRemoveRowOfCustomerEmailList(index)}
                        aria-label="Remove a Service"
                      >
                        <DeleteIcon></DeleteIcon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Tooltip title="Add a Service" placement="bottom">
                <Button
                  onClick={handleAddRowOfCustomerEmailList}
                  aria-label="Add a Service"
                  variant="outlined"
                  color="secondary"
                >
                  新增 Customer Email
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    );
  }
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justify="center"
      style={{
        paddingTop: 20,
      }}
    >
      <Grid container item style={{ width: "100%" }} xs={8} spacing={3} justifyContent="left">
        <Grid item xs={4}>
          {createDropDownList("寄信類別", filterBy, filterOptions, handleCatagoryChange)}
        </Grid>
      </Grid>
      {(filterBy !== unselectedCategoryId) ? (
        (filterBy === customerCategoryId) ? (
          <Grid container item style={{ width: "100%" }} xs={8} spacing={3} justifyContent="center">
            {createCustomerEmailListTable("客戶 Email 清單", customerEmailList)}
          </Grid>
        ) : (
          <Grid container item style={{ width: "100%" }} xs={8} spacing={3} justifyContent="center">
            <Grid item xs={4}>
              {/* {createDropDownList("商品清單", selectedProductDetail, productDetailList, handleProductDetailChange)} */}
              <FormControl className={classes.formControl} style={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">商品清單</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox-product"
                  multiple
                  value={selectedProductDetailList}
                  onChange={handleSeletedProductDetailListChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.map((x) => x.name).join(', ')}
                // MenuProps={MenuProps}
                >
                  {(productDetailList !== undefined && productDetailList !== null && productDetailList.length > 0) ? (
                    <MenuItem value="all">
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          selectedProductDetailList.length > 0 && selectedProductDetailList.length < productDetailList.length
                        }
                      />
                      <ListItemText style={{ color: "red" }} primary={"全選"} />
                    </MenuItem>
                  ) : (<></>)}
                  {(productDetailList !== undefined && productDetailList !== null && productDetailList.length > 0) ? (productDetailList.map((productDetail) => (
                    < MenuItem key={productDetail.id} value={productDetail} >
                      <Checkbox
                        checked={
                          selectedProductDetailList.findIndex((item) => item.id === productDetail.id) >= 0
                        }
                      />
                      <ListItemText primary={productDetail.name} />
                    </MenuItem>
                  ))) : (<></>)}
                </Select>
              </FormControl>
            </Grid>
            {(filterBy === productCategoryId) ? (
              <Grid item xs={4}>
                <FormControl className={classes.formControl} style={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">顧客清單</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedCustomerList}
                    onChange={handleSeletedCustomerChange}
                    onOpen={getMemberListBySelectProductDetailList}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.map((x) => x.name).join(', ')}
                  // MenuProps={MenuProps}
                  >
                    {(targetProductDetailMemberList !== undefined && targetProductDetailMemberList !== null && targetProductDetailMemberList.length > 0) ? (
                      <MenuItem value="all">
                        <Checkbox
                          checked={isAllSelectedCustomer}
                          indeterminate={
                            selectedCustomerList.length > 0 && selectedCustomerList.length < targetProductDetailMemberList.length
                          }
                        />
                        <ListItemText style={{ color: "red" }} primary={"全選"} />
                      </MenuItem>
                    ) : (<></>)}
                    {(targetProductDetailMemberList !== undefined && targetProductDetailMemberList !== null && targetProductDetailMemberList.length > 0) ? (targetProductDetailMemberList.map((customer) => (
                      < MenuItem key={customer.id} value={customer} >
                        <Checkbox
                          checked={
                            selectedCustomerList.findIndex((item) => item.id === customer.id) >= 0
                          }
                        />
                        <ListItemText primary={customer.id} />
                      </MenuItem>
                    ))) : (<></>)}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={4} />
            )}
            <Grid item xs={4} style={{ height: 200, overflow: 'auto' }}>
              <TextField
                name="selectedCustomerListBlock"
                label="選取結果"
                value={selectedCustomerListBlock}
                multiline
                style={{ width: "100%" }}
                disabled
              ></TextField>
            </Grid>
          </Grid>
        )
      ) : (<Grid container item style={{ width: "100%" }} xs={8} spacing={3} justifyContent="center" />)
      }

      <Grid container item style={{ width: "100%" }} xs={8}>
        <Grid container item xs={2} style={{
          alignItems: "center"
        }}>
          <Typography style={{ fontSize: 20 }}>信件標題:</Typography>
        </Grid>
        <Grid container item xs={6}
          style={{
            alignItems: "center"
          }}
        >
          <TextField id="standard-basic" label="標題" onChange={onChangeMailTitle} />
        </Grid>
      </Grid>

      <Grid item style={{ width: "100%" }} xs={8}>
        <Editor value={html} onChange={onChange} />
      </Grid>
      <Grid
        container
        item
        style={{ width: "100%" }}
        justify="flex-end"
        xs={8}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={confirmAndSendMail}
        >完成編輯並寄送</Button>
      </Grid>
    </Grid >
  );
}