import React, { useState, useEffect } from "react";

import UPIndex from "./UP/index.js";
import OPIndex from "./OP/index.js";
import OPLogin from "./OP/Login.js";
//import { useEffect } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

import { handleClearLoginCookies } from "./common/session.js"

const fakeAuth = {
  isAuthenticated: false,
};

export const fakeAuthContext = React.createContext(fakeAuth);

function PrivateRoute({ children, isLoading, ...rest }) {
  const fakeAuth1 = React.useContext(fakeAuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoading || fakeAuth1.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/backoffice/Login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function RootRouter() {
  //eslint-disable-next-line
  const [cookies] = useCookies(["session"]);
  const fakeAuth1 = React.useContext(fakeAuthContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if(cookies.session !== undefined){
      axios
        .post("/api/v0/loginCheck", {
          session: cookies.session,
        })
        .then(function(response) {
          if (response.data.IsLogin === true) {
            fakeAuth1.isAuthenticated = true;
          } else {
            fakeAuth1.isAuthenticated = false;
            handleClearLoginCookies()
          }
          setIsLoading(false);
        })
        .catch(function(error) {
          console.log("RootRouter", error);
          handleClearLoginCookies()
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.session]);

  return (
    <fakeAuthContext.Provider value={fakeAuth}>
      <Switch>
        <Route path="/backoffice/Login">
          <OPLogin />
        </Route>
        <PrivateRoute path="/backoffice/" isLoading={isLoading}>
          <OPIndex />
        </PrivateRoute>
        <Route path="/">
          <UPIndex />
        </Route>
      </Switch>
    </fakeAuthContext.Provider>
  );
}
