import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Email1 from "../assets/ProductOverview/all-8.jpg";
import Email2 from "../assets/ProductOverview/all-9.jpg";
import Email3 from "../assets/ProductOverview/all-10.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

//CSS
const StyledContextGrid = Styled(Grid)`
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 30px;
`
const StyledContextImage = Styled.img`
  max-width: 100%;
  border: 2px solid #28a8ea;
  border-radius: 10px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #28a8ea;
    margin-bottom: 20px;
  }
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 700;
    font-family: Microsoft JhengHei;
    text-align: left;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContainer = Styled(Grid)`
  && {
    margin-bottom: 120px;
  }
`

function renderSubContext(props) {
  const contexts = props;

  return contexts.map(context => (
    <>
      <StyledContextSubTitle color="secondary" variant="h5">
        {context.subTitle}
      </StyledContextSubTitle>
      <StyledContextSubContent paragraph>
        {context.subContext}
      </StyledContextSubContent>
    </>
  ));
}

function renderContext() {
  const contexts = [
    {
      title: "不論身在何處都能保持聯繫",
      context: [
        {
          subTitle: "不論在什麼裝置上存取，都會永遠保持更新",
          subContext:
            "Office 365 會即時在您的各種裝置上同步處理電子郵件、行事曆及連絡人資訊。因此，不論您手上拿著什麼裝置，都會是最新的內容。"
        },
        {
          subTitle: "輕鬆安排行程",
          subContext:
            "共用行事曆能讓您查看誰何時有空或忙碌。排程就是這麼簡單，一點都不麻煩， 而且隨處可得。"
        },
        {
          subTitle: "保護行動資料的安全",
          subContext:
            "如果手機不慎遺失，請先別慌張。有了 Office 365，您可以從遠端清除資料，防止有人未授權存取您的資訊。"
        }
      ],
      img: Email1
    },
    {
      title: "讓您的訊息更加出色 ",
      context: [
        {
          subTitle: "為個人訊息建立獨一無二的風格",
          subContext:
            "您可以建立自訂格式讓電子郵件別具特色，並在其中加入影像，使郵件內容令人印象更加深刻。Office 365 能提供這兩項功能，因為它提供您最高 25 MB 的寄件容量。再加上每位使用者都有 50 GB 的儲存容量，您可以保留所需的訊息和檔案。"
        },
        {
          subTitle: "建立您的品牌",
          subContext:
            "在您的電子郵件帳戶中使用您專屬的網域名稱，如此每次傳送郵件時，均可讓您的企業名稱獲得注意。"
        },
        {
          subTitle: "取得進階電子郵件功能",
          subContext:
            "在某些方案中，您可以封存法律探索和法規遵循，並可使用 eDiscovery 來識別、保留及分析整個企業的資料。"
        }
      ],
      img: Email2
    },
    {
      title: "電子郵件安全無虞",
      context: [
        {
          subTitle: "更少的維護作業，更多的工作時間",
          subContext:
            "將寶貴的時間花在通訊上，而不是花時間在管理電子郵件帳戶、苦惱不知如何保護電子郵件，或苦尋被廣告塞滿的收件匣。Office 365 具備簡化的系統管理中心和內建安全性，能夠執行管理電子郵件的工作。"
        },
        {
          subTitle: "便於管理的內建工具",
          subContext:
            "您可以隨處存取系統管理中心以設定新的使用者電子郵件、還原最近刪除的帳戶、建立自訂指令碼等等。"
        },
        {
          subTitle: "內建防禦功能，安全無虞",
          subContext:
            "您將擁有業界領先的防惡意程式碼保護和防垃圾郵件篩選功能，可保障電子郵件免於遭受無所不在的郵件威脅。"
        }
      ],
      img: Email3
    }
  ];

  return contexts.map(context => (
    <StyledContainer container>
      <StyledContextGrid item md={5} sm={12}>
        <StyledContextImage src={context.img}></StyledContextImage>
      </StyledContextGrid>
      <StyledContextGrid item md={7} sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextDivider/>
        {renderSubContext(context.context)}
      </StyledContextGrid>
    </StyledContainer>
  ));
}

export default function CenteredGrid(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {renderContext()}
      </Grid>
    </div>
  );
}
