import React, { useState, useEffect } from "react";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { useCookies } from "react-cookie";
import { format } from "date-fns";
import Axios from "axios";
import MaterialTable from "material-table";
import {
  Dialog,
  Box,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  DialogContent,
  Input,
  IconButton,
  Typography
} from "@material-ui/core";

// common
import LoginDialogForCheckID from "../common/LoginDialogForCheckID.js";

// icon
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import { LegendToggleRounded } from "@mui/icons-material";

// 產品廠牌
// const BrandInit = 0;
const BrandMicrosoftSn = 1;
const BrandGoogleSn = 2;

const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
const orderDefault = {
  orderDetail: {
    orderNo: "",
    aaHnNo: "",
    orderDate: "",
    domain: "",
    customerId: "",
    subscriptionId: "",
    // changeDate: ""
  },
  member: {
    name: "",
    contactLastName: "",
    contactName: "",
    telMorning: "",
    email: "",
  },
  product: {
    name: "",
    pid: "",
    quantity: "",
  },
};

// API
const orderDataURL = "/api/order/getOrderInfoForQuantityModifyBySn";
const saveOrderChangeURL = "/api/order/editOrderQuantity";
// const updateOrCancelQCAccountingPriceChangeURL = "/api/order/updateOrCancelQCAccountingPriceChange";

// Security Design
const getOrderWatcherLogURL = "/api/order/getOrderWatcherLogBySn";
const insertOrderWatcherLogURL = "/api/order/insertOrderWatcherLog";

export default function QuantityModal(props) {
  const [cookies] = useCookies(["op_name", "session"]);
  const [modalStatus, setModalStatus] = useState(false);
  const [order, setOrder] = useState(orderDefault);
  const [orderSn, setOrderSn] = useState();
  const [orderTmp, setOrderTmp] = useState();
  const [keepModal, setKeepModal] = useState(false);
  const [brandSn, setBrandSn] = useState(BrandMicrosoftSn);
  const closeModal = props.closeModal;

  // Security Design
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showAllMemberData, setShowAllMemberData] = useState(false);
  const [orderWatcherLog, setOrderWatcherLog] = useState([]);

  useEffect(() => {
    if (props.open) {
      // console.log("QuantityModal effect");
      // getOrderQuantity(props.sn);
      getOrderCharge(props.sn);
      setOrderSn(props.sn);
      setBrandSn(props.brandSn);
      getOrderWatcherLogList(props.sn);
    }
    setModalStatus(props.open);
  }, [props.open, props.sn, props.brandSn]);

  // Security Design
  useEffect(() => {
    if (showAllMemberData && props.sn !== undefined && cookies.op_name !== undefined) {
      Axios.post(insertOrderWatcherLogURL, { orderSn: props.sn, operatorId: cookies.op_name })
        .then(function (response) {
          console.log(response);
          getOrderWatcherLogList(props.sn);
        }).catch(function (err) {
          console.log(err);
        });
    }
  }, [showAllMemberData, props.sn, cookies.op_name])
  const getOrderWatcherLogList = (sn) => {
    Axios.post(getOrderWatcherLogURL, { Sn: sn })
      .then(function (response) {
        setOrderWatcherLog(response.data.log != null ? response.data.log : [])
      }).catch(function (err) {
        console.log(err);
      });
  }

  const handleChange = (event) => {
    var { id, name, value, type } = event.target;
    if (type === "number") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
    }

    setOrder((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [name]: value,
      },
    }));
  };
  const getOrderCharge = (sn) => {
    Axios.post(orderDataURL, { sn: sn }).then((response) => {
      // console.table(response.data);
      setOrder(response.data);
      setOrderTmp(response.data);
    });
  };

  const setKeepModalStatus = (isKeep) => {
    setKeepModal(isKeep);
  };

  // Security Design
  const convertDataToHiddenCode = (val) => {
    let strLength = val.length;
    let data = '';
    if (strLength !== 0 && strLength !== undefined && strLength !== null) {
      for (let i = 0; i < strLength; i++) {
        data += '*';
      }
    }
    return data;
  }
  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  }
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  }
  const handleToggleShowAllMemberData = () => {
    setShowAllMemberData(!showAllMemberData);
  }
  const handleMouseDownAllMemberData = (e) => {
    e.preventDefault();
  }
  const handleClickShowAllMemberData = () => {
    if (showAllMemberData) { // 正常已開啟狀態。
      handleToggleShowAllMemberData();
    } else {  // 未開啟，故要先行登入。
      handleOpenLoginDialog();
    }
  }
  const handleCloseDialog = () => {
    closeModal();
    if (showAllMemberData) {
      handleToggleShowAllMemberData();
    }
  }

  // // API
  // const updateQcPrice = (set = false) => {
  //   var postData = {};

  //   var orderNo = order.orderDetail.orderNo;
  //   postData["orderNo"] = orderNo;
  //   postData["set"] = set;
  //   alert("更新成功!");
  //   Axios.post(updateOrCancelQCAccountingPriceChangeURL, postData).then(function (res) {
  //     console.log(res);
  //   }).catch(function (err) {
  //     console.log(err);
  //     alert("更新失敗!");
  //   });
  // }

  function createTable(columns, datas) {
    return (
      <MaterialTable
        columns={columns}
        data={datas}
        options={{
          search: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          padding: "dense",
          sorting: false,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
          }),
        }}
      />
    );
  }
  function watcherLogTable(watcherLog) {
    const columns = [
      {
        title: "時間",
        field: "watchTime",
        render: (rowData) => datetimeStringFormat(rowData.watchTime),
      },
      {
        title: "查看人員",
        field: "operator",
        render: (rowData) => showAllMemberData ? rowData.operator : convertDataToHiddenCode(rowData.operator)
      },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          機敏資料查看紀錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, watcherLog)}</DialogContent>
      </Box>
    );
  }

  return (
    <Dialog
      open={modalStatus}
      maxWidth="lg"
      scroll="paper"
    >
      <Box m={2}>
        <form>
          <DialogTitle>修改數量資料</DialogTitle>
          <DialogContent dividers>
            <Box m={2}>
              <Typography variant="h6">
                訂單資訊
                <IconButton
                  aria-label="toggle personal data visibility"
                  onClick={handleClickShowAllMemberData}
                  onMouseDown={handleMouseDownAllMemberData}
                  edge="end"
                >
                  {showAllMemberData ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Typography>
              <DialogContent dividers>
                <Grid container>
                  {createLabel("訂單編號:", order.orderDetail.orderNo)}
                  {createLabel(
                    "交易時間:",
                    datetimeStringFormat(order.orderDetail.orderDate)
                  )}
                  {createLabel("HN帳號:", order.orderDetail.aaHnNo)}
                  {createLabel(
                    "使用網域:",
                    <>{showAllMemberData ? order.orderDetail.domain : convertDataToHiddenCode(order.orderDetail.domain)}</>
                  )}
                  {createLabel("訂購商品:", order.product.name)}
                  {createLabel(
                    "訂閱帳號數量:",
                    <Box m={1}>
                      <Input
                        fullWidth
                        id={"product"}
                        name={"quantity"}
                        type="number"
                        value={order.product.quantity}
                        onChange={handleChange}
                        disabled={keepModal}
                      ></Input>
                    </Box>
                  )}
                  {createLabel("公司/客戶名稱:", order.member.name)}
                  {createLabel("聯絡人（姓）:", order.member.contactLastName)}
                  {createLabel("聯絡人（名）:", order.member.contactName)}
                  {createLabel("連絡電話:", showAllMemberData ? order.member.telMorning : convertDataToHiddenCode(order.member.telMorning))}
                  {createLabel("聯絡email:", order.member.email)}
                  {/* {createLabel("更新品管金額:", <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#007A33"
                        }}
                        onClick={() => updateQcPrice(true)}
                      >設定</Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#FF0000"
                        }}
                        onClick={() => updateQcPrice(false)}
                      >取消</Button>
                    </Grid>
                  </Grid >)} */}

                </Grid>
              </DialogContent>
            </Box>
            {watcherLogTable(orderWatcherLog)}
          </DialogContent>
          <DialogActions>
            <SnackbarProvider maxSnack={1}>
              <DialogButtons
                orderSn={orderSn}
                order={order}
                orderTmp={orderTmp}
                handleCloseDialog={handleCloseDialog}
                session={cookies.session}
                opname={cookies.op_name}
                keepModal={keepModal}
                setKeepModalStatus={setKeepModalStatus}
                brandSn={brandSn}
              />
            </SnackbarProvider>
          </DialogActions>
        </form>
      </Box>

      <LoginDialogForCheckID
        open={openLoginDialog}
        handleToggleShowAllMemberData={handleToggleShowAllMemberData}
        handleCloseLoginDialog={handleCloseLoginDialog}
      >
      </LoginDialogForCheckID>

    </Dialog>
  );
}

function createLabel(title, text, splitPart = 2) {
  return (
    <Grid item xs={12} md={12 / splitPart}>
      <Grid container>
        <Grid item xs={3}>
          <Box m={1}>{title}</Box>
        </Grid>
        <Grid item xs={9}>
          <Box m={1}>{text}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

function DialogButtons(props) {
  const { enqueueSnackbar } = useSnackbar();
  const handleShowMessage = (
    message,
    variant: VariantType,
    isMoment = true
  ) => {
    var autoHideDuration = 5000;
    if (!isMoment) {
      autoHideDuration = 500000;
    }
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: autoHideDuration,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const saveOrderChange = () => {
    var message = "(MS)更改數量";
    var messageOfGoogle = "(Google)修改訂單的實際數量";

    if (window.confirm("是否要儲存？")) {
      // check quantity unchange
      if (props.order.product.quantity === props.orderTmp.product.quantity) {
        alert("數量沒有修改");
        // window.location.reload();
        props.handleCloseDialog();
        return;
      } else if (props.order.product.quantity < props.orderTmp.product.quantity) {
        alert("數量不能比原來的少");
        // window.location.reload();
        props.handleCloseDialog();
        return;
      }

      props.setKeepModalStatus(true);
      // Post API
      if (props.brandSn === BrandGoogleSn) {
        handleShowMessage(`${messageOfGoogle}...`, "info", false);
        console.log("props.order.product.quantity:" + props.order.product.quantity);
        // Change GWS subscription
        Axios.post("/api/v0/gws/customers/" + props.order.orderDetail.customerId + "/subscriptions/" + props.order.orderDetail.subscriptionId + "/changeSeats", {
          // Axios.post("/api/v0/gws/customers/30000/subscriptions/2000/changeSeats", {
          orderSn: props.orderSn,
          seats: props.order.product.quantity
        })
          .then((res) => {
            handleShowMessage(`${messageOfGoogle}---Success`, "success");
            // window.location.reload();
            if (res !== undefined && res.data !== undefined) {
              console.log(res.data["ResultPrice"]);
              if (window.confirm("新增授權價格共計 " + res.data["ResultPrice"] + " 元, 確定出帳? 如金額有誤可按取消並改執行一次性出帳")) {
                var postData = {};
                postData["orderSn"] = props.orderSn;
                postData["seats"] = parseInt(res.data["NewSeatsNum"]);
                Axios.post("/api/v0/gws/chargeForAddSeats", postData).then(function (res) {
                  if (res.data !== undefined) {
                    console.log(res.data);
                  } else {
                    console.log(res);
                  }
                  alert("出帳成功!");
                  props.handleCloseDialog();
                }).catch(function (err) {
                  console.log(err);
                  alert("出帳失敗!");
                  props.handleCloseDialog();
                });
              } else {
                props.handleCloseDialog();
              }
            } else {
              props.handleCloseDialog();
            }
          })
          .catch((error) => {
            var postErr = showPostError(message, error);
            handleShowMessage(`${postErr}`, "error");
            props.setKeepModalStatus(false);
          });
      } else if (props.brandSn === BrandMicrosoftSn) {
        handleShowMessage(`${message}...`, "info", false);
        Axios.post(saveOrderChangeURL, {
          Sn: props.orderSn,
          Quantity: props.order.product.quantity,
          session: props.session,
          OpName: props.opname
        })
          .then((res) => {
            handleShowMessage(`${message}---Success`, "success");
            // window.location.reload();
            if (res !== undefined && res.data !== undefined) {
              console.log(res.data["ResultPrice"]);
              if (window.confirm("新增授權價格共計 " + res.data["ResultPrice"] + " 元, 確定出帳? 如金額有誤可按取消並改執行一次性出帳")) {
                var postData = {};
                postData["orderSn"] = props.orderSn;
                postData["seats"] = parseInt(res.data["NewSeatsNum"]);
                Axios.post("/api/v0/chargeForAddQuantity", postData).then(function (res) {
                  if (res.data !== undefined) {
                    console.log(res.data);
                  } else {
                    console.log(res);
                  }
                  alert("出帳成功!");
                  props.handleCloseDialog();
                }).catch(function (err) {
                  console.log(err);
                  alert("出帳失敗!");
                  props.handleCloseDialog();
                });
              } else {
                props.handleCloseDialog();
              }
            } else {
              props.handleCloseDialog();
            }
          })
          .catch((error) => {
            var postErr = showPostError(message, error);
            handleShowMessage(`${postErr}`, "error");
            props.setKeepModalStatus(false);
          });
      }

    }
  };

  const showPostError = (message, error) => {
    var errMessage = "";
    console.log(error.response);
    if (error.response) {
      if (error.response.data) {
        errMessage = `${error.response.data}`;
      } else {
        errMessage = `${error.response}`;
      }
    } else if (error.request) {
      errMessage = `連線異常`;
    } else {
      // 在設定 request 時出錯會進入此
      errMessage = `error`;
      console.error(error);
    }
    return `${message} --- ${errMessage}`;
  };

  return (
    <React.Fragment>
      <Button
        onClick={saveOrderChange}
        color="primary"
        disabled={props.keepModal}
      >
        確定
      </Button>
      <Button
        onClick={props.handleCloseDialog}
        color="primary"
        disabled={props.keepModal}
      >
        取消
      </Button>
    </React.Fragment>
  );
}

function datetimeStringFormat(time) {
  // time is string
  if (time === "" || time === null) {
    return "";
  } else {
    return format(new Date(time), datetimeFormat);
  }
}

