import React from "react";

// core
import {
  Divider
} from '@material-ui/core';

// assets
import LogoImg from "./assets/footerLogo.png";

// Style CSS
import styled from 'styled-components';

const StyledFooter = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  background-color: #eaeaea;
`
const StyledColumn = styled.p`
  font: normal 1rem/1.5 Arial, Helvetica, "微軟正黑體";
  text-align: center;
  margin: 13px;
  a {
    text-decoration: none;
  }
`
const StyledLogo = styled.img`
  max-width: 60%;
  height: auto;
`

// other reference
// https://material-ui.com/getting-started/templates/sticky-footer/
// https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/sticky-footer

export default function UPHeader(props) {
  return (
    <StyledFooter>
      <Divider></Divider>
      <StyledColumn>
        <StyledLogo src={LogoImg} alt="Footer Logo" />
      </StyledColumn>
      <StyledColumn>
        <a href="https://www.hinet.net/copyright.html" rel="noopener noreferrer" target="_blank">著作權保護</a>
        &nbsp;{' | '}&nbsp;
        <a href="https://www.hinet.net/privacy.html" rel="noopener noreferrer" target="_blank">隱私權保護</a>
        &nbsp;{' | '}&nbsp;
        <a href="https://www.cht.com.tw/zh-tw/home/cht/sustainability/csr-report-download" rel="noopener noreferrer" target="_blank">企業社會責任</a>
        &nbsp;{' | '}&nbsp;
        <a href="http://www.hinet.net/kid.html" rel="noopener noreferrer" target="_blank">兒童網路安全</a>
      </StyledColumn>
      <StyledColumn>
        24 小時免費服務電話 : 0800-080-365
      </StyledColumn>
      <StyledColumn>
        中華電信股份有限公司版權所有© Chunghwa Telecom Co., Ltd. All Rights Reserved
      </StyledColumn>
    </StyledFooter>
  )
}

