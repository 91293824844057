import React, {useState, useCallback, useEffect} from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

import Styled from "styled-components";

// file
import ProductCard from "./ProductCard";

// Core
import {
  Container,
  Typography,
  Grid,
  Box,
  Divider,
  Card
} from "@material-ui/core";

// Picture
import ProductInfoTable from "../assets/BuyNow/BuyNowTableBorder.jpg";
import ProductInfoTableMobile from "../assets/BuyNow/M_BuyNowTable.png";

// CSS 
const StyledContainer = Styled(Container)`
  && {
    padding: 0px;
  }
`
const StyledGrid = Styled(Grid)`
  && {
    justify-content: space-around;
    margin: 20px 0px;
  }
`
const StyledIncludeTitle = Styled(Typography)`
  && {
    text-align: center;
    font-weight: 700;
    font-family: Microsoft JhengHei;
    margin-bottom: 5px;
    letter-spacing: 2.5px;
    line-height: 1.5;
    word-break: keep-all;
  }
`
const StyledIncludeContent = Styled(Typography)`
  && {
    text-align: center;
    font-weight: 500;
    font-family: Microsoft JhengHei;
    margin-bottom: 5px;
    letter-spacing: 2.5px;
    line-height: 1.5;
    word-break: break-all;
  }
`
const StyledIncludeContentGrid = Styled(Grid)`
  && {
    justify-content: space-around;
    margin: 30px 0px;
  }
`
const StyledProductTableImg = Styled.img`
  && {
    width: 80vw;
    padding: 20px 0px;
  }
`
const StyledIncludeImage = Styled(Typography)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
  }
`

// Main Function
export default function WebView(props) {
  const [imageList, setImageList] = useState([ProductInfoTable, ProductInfoTableMobile]);
  const [OneDrive, OutLook, Exchange, Publisher, PowerPoint, Excel, Teams, Word, OneNote] = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const productInfo = [
    {
      name: "essentials",
      service: [OneDrive, Exchange, Teams]
    },
    {
      name: "business",
      service: [OneDrive, OutLook, Publisher, PowerPoint, Excel, Word]
    },
    {
      name: "premium",
      service: [OneDrive, OutLook, Exchange, Publisher, PowerPoint, Excel, Teams, Word]
    },
    {
      name: "E1",
      service: [OneDrive, OutLook, Teams]
    },
    {
      name: "proplus",
      service: [OneDrive, OutLook, PowerPoint, Excel, Teams, Word]
    },
    {
      name: "E3",
      service: [OneDrive, OutLook, PowerPoint, Excel, Teams, Word, OneNote]
    }
  ];

  // Preload Image
  const preloadImage = useCallback(() =>{
    console.log("preloadImage");
    imageList.forEach((image) => {
      new Image().src = image;
    });
    setImageList(imageList);
  },[imageList]);

  useEffect(() => {
    preloadImage()
  }, [preloadImage]);

  // props.data: All informations about products
  return (
    <StyledContainer>
      <Card style={{ backgroundColor: "cyan" }}>
        <Typography>
          <Typography
            style={{
              textAlign: "center"
              , fontFamily: ["Arial"]
              , padding: 20
              , fontSize: 23
              , color: "red"
            }}
          >
            <b>
              因應微軟漲價策略於3/1實施，中華電信微軟雲服務即日起暫停"網路"下單！<br />其他管道正常受理，亦可聯繫業務經理諮詢；<br />
              貴賓如需了解優惠方案請填寫：<a href="https://forms.office.com/r/EUJgWEqLSP" rel="noopener noreferrer" target="_blank">此份表單</a>，將有專人聯繫！<br />我們致力盡快恢復下單服務。
            </b>
          </Typography>
        </Typography>
      </Card>
      <StyledGrid container spacing={1}>
        {productInfo.map((key) => {
          return (
            <ProductCard
              productKey={key.name}
              serviceList={key.service}
              data={props.data}
              editionDetail={props.editionDetail}
            />
          );
        })}
      </StyledGrid>

      <Divider />

      <StyledGrid container>
        {pageFooter(props.data.allIncludes)}
      </StyledGrid>

      <Divider />
      
      <StyledGrid container>
        {useMediaQuery('(min-width:1200px)') ? (<StyledProductTableImg src={ProductInfoTable} alt="Product Table" />) : (<StyledProductTableImg src={ProductInfoTableMobile} alt="Product Table" />) }
      </StyledGrid>
      
    </StyledContainer>
  );
}

function pageFooter(allIncludes) {
  return (
    <Box my={2}>
      <StyledGrid container spacing={2}>
        <StyledIncludeTitle variant="h5">
          上述所有 Office 365 方案包含
        </StyledIncludeTitle>
      </StyledGrid>
      <StyledGrid container spacing={2}>
        {allIncludes.map((item, i) => {
          return (
            <StyledIncludeContentGrid key={i} item xs={12} sm={6} md={3}>
              <StyledIncludeImage>
                <img src={item.icon} alt=""></img>
              </StyledIncludeImage>
              <StyledIncludeContent variant="h6">
                {item.contents}
              </StyledIncludeContent>
            </StyledIncludeContentGrid>
          );
        })}
      </StyledGrid>
    </Box>
  );
}