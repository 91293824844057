// react
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { format } from "date-fns";
import MaterialTable from "material-table";
import {
  IconButton
} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LoginDialogForCheckID from "./common/LoginDialogForCheckID.js";

// material-ui core
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";

// API
import axios from "axios";

// notification
import { useSnackbar } from "notistack";

// common
import { ConvertStr2DatetimeLocal } from "../common/timeParsing.js";

// Security Design
const getAnnouncementWatcherLogURL = "/api/v0/getAnnouncementWatcherLogBySn";
const insertAnnouncementWatcherLogURL = "/api/v0/insertAnnouncementWatcherLog";

// Log
const getAnnouncementLogURL = "/api/v0/announcement/log/list";
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";

function AnnouncementModal(props) {

  const extractTextAndURLs = (text) => {
    const regex = /(?:([^:]+):)?(https?:\/\/[^\s]+)/g;
    const matches = [...text.matchAll(regex)];
    return matches.map(match => ({
      text: match[1] || match[2], // 使用捕獲的前面文字或URL本身
      url: match[2]
    }));
  };

  const links = extractTextAndURLs(props.announcement);

  // 找到最後一個左括號的索引
  const lastIndex = props.announcement.lastIndexOf("(");

  // 如果找到左括號，則取得它前面的字串，否則設為空字串
  const beforeParenthesis = lastIndex !== -1 ? props.announcement.substring(0, lastIndex) : "";

  // 找到第一個右括號的索引
  const firstIndex = props.announcement.indexOf(")");

  // 如果找到右括號，則取得它後面的字串，否則設為空字串
  const afterParenthesis = firstIndex !== -1 ? props.announcement.substring(firstIndex + 1) : "";

  // using whiteSpace to prevent convert whitespace to single blank
  return (
    <Dialog
      open={props.announcementModalOpen}
      onClose={() => props.setAnnouncementModalOpen(false)}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>公告</DialogTitle>
      <DialogContent>
        <div style={{ whiteSpace: "pre-wrap" }}>
          {lastIndex !== -1 && (
            <p>{beforeParenthesis}</p>
          )}
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
          {firstIndex !== -1 && (
            <p>{afterParenthesis}</p>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.setAnnouncementModalOpen(false)}
          color="primary"
        >
          確定
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Announcement(props) {
  const [announcement, setAnnouncement] = useState("");
  const [announcementStartDate, setAnnouncementStartDate] = useState("");
  const [announcementEndDate, setAnnouncementEndDate] = useState("");
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
  const [isAnnouncementChanged, setIsAnnouncementChanged] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies(["op_name"]);

  const [announcementWatcherLog, setAnnouncementWatcherLog] = useState([]);
  const [showAllMemberData, setShowAllMemberData] = useState(false);
  const [announcementLog, setAnnouncementLog] = useState([]);

  // Security Design
  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  useEffect(() => {
    // using api to get announcement
    axios
      .post("/api/v0/getAnnouncementDetail")
      .then(function (response) {
        setAnnouncement(response.data.Content);
        setAnnouncementStartDate(
          response.data.StartDate === null ? "" : response.data.StartDate,
        );
        setAnnouncementEndDate(
          response.data.EndDate === null ? "" : response.data.EndDate,
        );
      })
      .catch(function (error) {
        alert("後端異常");
      });
    axios.get(getAnnouncementLogURL).then(function (modifyLogResponse) {
      if (modifyLogResponse.data !== undefined) {
        setAnnouncementLog(modifyLogResponse.data ? modifyLogResponse.data : [])
      }
    }).catch(function (err) {
      console.log(err);
      alert("撈取公告資料操作紀錄後端異常");
      setAnnouncementLog([]);
    });
  }, []);

  // Security Design
  useEffect(() => {
    // console.log(props.memberDetail.sn);
    if (showAllMemberData && cookies.op_name !== undefined) {
      axios.post(insertAnnouncementWatcherLogURL, { operatorId: cookies.op_name })
        .then(function (response) {
          console.log(response);
          getAnnouncementWatcherLogList();
        }).catch(function (err) {
          console.log(err);
        });
    }
  }, [showAllMemberData, cookies.op_name])
  const getAnnouncementWatcherLogList = () => {
    axios.get(getAnnouncementWatcherLogURL)
      .then(function (response) {
        console.log(response);
        setAnnouncementWatcherLog(response.data.log != null ? response.data.log : [])
      }).catch(function (err) {
        console.log(err);
      });
  }

  const convertDataToHiddenCode = (val) => {
    let strLength = val.length;
    let data = '';
    if (strLength !== 0 && strLength !== undefined && strLength !== null) {
      for (let i = 0; i < strLength; i++) {
        data += '*';
      }
    }
    return data;
  }

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  }
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  }
  const handleToggleShowAllMemberData = () => {
    setShowAllMemberData(!showAllMemberData);
  }
  const handleMouseDownAllMemberData = (e) => {
    e.preventDefault();
  }
  const handleClickShowAllMemberData = () => {
    if (showAllMemberData) { // 正常已開啟狀態。
      handleToggleShowAllMemberData();
    } else if (showAllMemberData === false) { // 新增會員時。
      handleToggleShowAllMemberData();
    } else {  // 未開啟，故要先行登入。
      handleOpenLoginDialog();
    }
  }

  function createTable(columns, datas) {
    return (
      <MaterialTable
        columns={columns}
        data={datas}
        options={{
          search: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          padding: "dense",
          sorting: false,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
          }),
        }}
      />
    );
  }

  function watcherLogTable(watcherLog) {
    const columns = [
      {
        title: "時間",
        field: "watchTime",
        render: (rowData) => datetimeStringFormat(rowData.watchTime),
      },
      {
        title: "查看人員",
        field: "operator",
        render: (rowData) => showAllMemberData ? rowData.operator : convertDataToHiddenCode(rowData.operator)
      },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          機敏資料查看紀錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, watcherLog)}</DialogContent>
      </Box>
    );
  }
  function logTable(memberLog) {
    const columns = [
      {
        title: "時間",
        field: "modifyDate",
        render: (rowData) => datetimeStringFormat(rowData.ModifyTime),
      },
      {
        title: "修改者",
        field: "operator",
        render: (rowData) => {
          // rowData.OperatorSn !== undefined && rowData.OperatorSn.Name !== undefined ? rowData.OperatorSn.Name : convertDataToHiddenCode(rowData.operator)
          if (rowData.OperatorSn !== undefined && rowData.OperatorSn.Name !== undefined) {
            return showAllMemberData ? rowData.OperatorSn.Name : convertDataToHiddenCode(rowData.OperatorSn.Name);
          } else {
            return "";
          }
        }
      },
      {
        title: "欄位",
        field: "field",
        render: (rowData) => {
          if (!fieldMapingList[rowData.Field]) return showAllMemberData ? rowData.Field : convertDataToHiddenCode(rowData.Field);
          return showAllMemberData ? fieldMapingList[rowData.Field] : convertDataToHiddenCode(fieldMapingList[rowData.Field]);
        },
      },
      {
        title: "修改前資料",
        field: "beforeValue",
        render: (rowData) => showAllMemberData ? rowData.BeforeValue : convertDataToHiddenCode(rowData.BeforeValue)
      },
      {
        title: "修改後資料",
        field: "afterValue",
        render: (rowData) => showAllMemberData ? rowData.AfterValue : convertDataToHiddenCode(rowData.AfterValue)
      },
      // { title: "動作", field: "action" },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          會員資料更改記錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, memberLog)}</DialogContent>
      </Box>
    );
  }
  const fieldMapingList = {
    Content: "公告內容",
    StartDate: "公告起始時間",
    EndDate: "公告結束時間"
  };
  function datetimeStringFormat(time) {
    // time is string
    if (time === "" || time === null) {
      return "";
    } else {
      return format(new Date(time), datetimeFormat);
    }
  }

  function saveAnnouncement() {
    // save API
    axios
      .post("/api/v0/saveAnnouncementDetail", {
        Content: announcement,
        StartDate: announcementStartDate === "" ? null : announcementStartDate,
        EndDate: announcementEndDate === "" ? null : announcementEndDate,
        OpName: cookies.op_name
      })
      .then(function (response) {
        enqueueSnackbar("變更已儲存", {
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setIsAnnouncementChanged(false);
      })
      .catch(function (error) {
        if (error.response !== undefined && error.response.status === 400) {
          enqueueSnackbar("輸入錯誤", {
            variant: "Error",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        } else {
          alert("後端異常");
        }
      });
  }

  function previewAnnouncement() {

    axios
      .post("/api/v0/checkAnnouncement", {
        Content: announcement,
        StartDate: announcementStartDate === "" ? null : announcementStartDate,
        EndDate: announcementEndDate === "" ? null : announcementEndDate,
      })
      .then(function (response) {
        if (response.data.Content !== "") {
          setAnnouncementModalOpen(true);
        } else {
          setAnnouncementModalOpen(false);
          enqueueSnackbar("沒有公告可以顯示", {
            variant: "info",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        }
      })
      .catch(function (error) {
        setAnnouncementModalOpen(false);
        if (error.response !== undefined && error.response.status === 400) {
          enqueueSnackbar("輸入錯誤", {
            variant: "Error",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        } else {
          alert("後端異常");
        }
      });
  }

  function showIsChanged() {
    if (isAnnouncementChanged) {
      return (
        <Typography variant="button" display="block" gutterBottom color="error">
          請記得儲存變更
        </Typography>
      );
    } else {
      return "";
    }
  }

  return (
    <Container>
      {/* header: title, start date, end date */}
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Typography variant="h5" gutterBottom>
              公告編輯
            </Typography>
          </Grid>
          <Grid item sm={4}>
            起始日期：
            <br />
            <TextField
              type="datetime-local"
              value={ConvertStr2DatetimeLocal(announcementStartDate)}
              onChange={(e) => {
                setIsAnnouncementChanged(true);
                setAnnouncementStartDate(
                  new Date(e.target.value).toISOString(),
                );
              }}
              inputProps={{ step: 1 }} // for datetime to show second
            />
            <Button
              variant="outlined"
              size="small"
              onClick={() => setAnnouncementStartDate("")}
              color="secondary"
            >
              reset
            </Button>
          </Grid>
          <Grid item sm={4}>
            結束日期：
            <br />
            <TextField
              type="datetime-local"
              value={ConvertStr2DatetimeLocal(announcementEndDate)}
              onChange={(e) => {
                setIsAnnouncementChanged(true);
                setAnnouncementEndDate(new Date(e.target.value).toISOString());
              }}
              inputProps={{ step: 1 }} // for datetime to show second
            />
            <Button
              variant="outlined"
              size="small"
              onClick={() => setAnnouncementEndDate("")}
              color="secondary"
            >
              reset
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* textarea: announcement content */}
      <Box m={2}>
        <TextField
          fullWidth
          multiline
          rows={10}
          variant="outlined"
          name={"operatorNote"}
          defaultValue={announcement}
          onChange={(e) => {
            setIsAnnouncementChanged(true);
            setAnnouncement(e.target.value);
          }}
          placeholder="• 系統將於 2020/08/13(四) 12:30 更版"
        />
      </Box>
      {/* button: preview, save */}
      <Box m={2}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={2}
        >
          {showIsChanged()}
          <Grid item>
            <Button variant="contained" onClick={previewAnnouncement}>
              預覽
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={saveAnnouncement}
              color="primary"
            >
              儲存
            </Button>
          </Grid>
        </Grid>
      </Box>
      {logTable(announcementLog)}
      {watcherLogTable(announcementWatcherLog)}
      <LoginDialogForCheckID
        open={openLoginDialog}
        handleToggleShowAllMemberData={handleToggleShowAllMemberData}
        handleCloseLoginDialog={handleCloseLoginDialog}
      ></LoginDialogForCheckID>
      {/* announcement modal */}
      <AnnouncementModal
        announcement={announcement}
        announcementModalOpen={announcementModalOpen}
        setAnnouncementModalOpen={setAnnouncementModalOpen}
      />
    </Container>
  );
}
