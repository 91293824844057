import React from "react";

import { Switch, Route } from "react-router-dom";

// sub page
import Home from "./Home";
import ProductOverview from "./ProductOverview";
import DiscountInfo from "./DiscountInfo";
import BuyNow from "./BuyNow";
import TeachingVideo from "./TeachingVideo";
import DownloadResource from "./DownloadResource";
import Buying10Reasons from "./Buying10Reasons";
import OrderStatus from "./OrderStatus";
import CustomerContactEmail from "./CustomerContactEmail";
import OnlineQA from "./OnlineQA";
import Purchase from "./Purchase";
import BeforePurchase from "./BeforePurchase"
import PurchaseComplete from "./PurchaseComplete";

export default function UPRouter(props) {
  return (
    <Switch>
      <Route path="/ProductOverview" component={ProductOverview} />
      <Route path="/DiscountInfo" component={DiscountInfo} />
      <Route path="/BuyNow" component={BuyNow} />
      <Route path="/TeachingVideo" component={TeachingVideo} />
      <Route path="/DownloadResource" component={DownloadResource} />
      <Route path="/Buying10Reasons" component={Buying10Reasons} />
      <Route path="/OrderStatus" component={OrderStatus} />
      <Route path="/CustomerContactEmail" component={CustomerContactEmail} />
      <Route path="/OnlineQA" component={OnlineQA} />
      <Route path="/Purchase" component={Purchase} />
      <Route path="/PurchaseComplete" component={PurchaseComplete} />
      <Route path="/BeforePurchase" component={BeforePurchase} />
      <Route path="" component={Home} />
    </Switch>
  );
}
