import React from "react";

import PropTypes from 'prop-types';

// Layout
import Grid from '@material-ui/core/Grid';

// Component
import { Container } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core";

// Style CSS
import { makeStyles } from '@material-ui/core/styles';

// Tabs
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

// Configure style
const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 650,
  },
  divider: {
    marginTop: 10,
    marginBottom: 30
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

// var parseURLParams = function() {
//   var Url = window.location.search;
//   var params = {};

//   Url.replace(
//     new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
//     function( $0, $1, $2, $3 ) {
//       params[ $1 ] = $3;
//     }
//   );
//   return params;
// };


export default function Buying10Reasons(props) {
  const classes = useStyles();

  console.log(props.location.state);

  let HomeReason = (props.location.state)? parseInt(props.location.state.reason): 0;

  // let reason = localStorage.getItem("reason");

  // if(props.location.state) {
  //   reason = HomeReason;

  //   localStorage.setItem('reason', reason);
  // }

  // Set initial tab
  //let params = parseURLParams();

  //var reason = (props.reason == undefined)? 0 : props.reason;
  //var reason = (params['reason'] === undefined || isNaN(params['reason']))? 0 : parseInt(params['reason']);
  //var Reason = (props.location.state === undefined)? 0 : HomeReason;
  const [value, setValue] = React.useState(HomeReason);

  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container className={classes.container}>
      <Typography mx="auto" variant="h5">企業選擇 Microsoft Office 365 的十大原因</Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab label="隱私權很重要" {...a11yProps(0)} />
            <Tab label="嚴肅正視資訊安全" {...a11yProps(1)} />
            <Tab label="法務遵循領導地位" {...a11yProps(2)} />
            <Tab label="讓使用者可以隨時隨地存取其內容" {...a11yProps(3)} />
            <Tab label="豐富的功能集" {...a11yProps(4)} />
            <Tab label="需要的訓練較少" {...a11yProps(5)} />
            <Tab label="以您的方式部署" {...a11yProps(6)} />
            <Tab label="倚賴受財務支援的 99.9% SLA" {...a11yProps(7)} />
            <Tab label="知道將來可能發生的狀況以進行規劃" {...a11yProps(8)} />
            <Tab label="企業等級支援" {...a11yProps(9)} />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={9}>
        <TabPanel value={value} index={0}>
          <div>
            <div class="open"><b>隱私權很重要</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>Office 365 以最先進存取控制的資料中心和安全的使用者行動存取，保護客戶的資料安全。Office 365 客戶知道資料存放的位置、能夠存取資料的人員，以及 Microsoft 對資料所做的處理。Microsoft 著重於提供功能最豐富的企業級產能解決方案，以及我們業務核心的企業軟體和服務。 </p>
            <h4>Office 365</h4>
            <p>您可放心保有完整的隱私權且機密資料很安全，絕不會用於廣告中。</p>
            <h4>Office 365 不會「回收」使用者的資訊用於廣告。資料僅用於提供服務給使用者。</h4>
            <ul class="one">
              <li>Office 365 不會「回收」使用者的資訊用於廣告。資料僅用於提供服務給使用者。</li>
              <li>企業與消費者服務及其需求之間清楚區隔。</li>
              <li>隱私權原則清楚說明客戶資料的使用情形。</li>
              <li>由於提供精細的權限設定，客戶能確切掌握，誰能存取什麼資料，以及在什麼狀況下存取資料。</li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <div class="open"><b>嚴肅正視資訊安全</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>Office 365 經過精心設計符合企業級資訊安全需求，透過在每個階段強固的控管和感知資訊安全的工程設計達成。我們領先業界的「資訊安全開發生命週期」確保在服務開發和交付的每個階段都遵循資訊安全最佳作法。我們採取主動監控我們的服務，保護資訊免受已知和未知的威脅，我們是以企業業務為優先考量來保護資訊安全。Office 365 是託管於 Microsoft 高可用資料中心的網路中，而以人員、程序和技術的深度防護機制建置及運作。Microsoft Forefront Online Protection for Exchange 包含於 Office 365 之中，協助保護您的組織免受病毒和垃圾郵件的攻擊，同時設定性極高可符合企業需求。使用者的服務連線是使用 128 位元安全通訊端層 (SSL) / 傳輸層級安全性 (SSL/TLS) 加密進行保護。Office 365 本身就支援額外的資訊安全措施，如安全多用途網際網路郵件延伸 (S/MIME) 用於公開金鑰加密和數位簽名。若要進一步了解為 Office 365 啟用的資訊安全功能，請<a href="http://www.microsoft.com/en-us/download/details.aspx?id=26552" target="_blank" rel="noopener noreferrer">閱讀本白皮書</a> (英文)。</p>
            <h4>OFFICE 365 </h4>
            <p>Office 365 是專為企業設計，提供強固的資訊安全功能和原則，讓客戶能夠完全掌控。</p>
            <ul class="one">
              <li>Office 365 配合資訊版權管理功能運作，讓客戶能夠防止文件共用、編輯或列印，並管理內容到期的情形。</li>
              <li>Office 365 搭配國際武器交易管理條例 (ITAR) 支援，提供聯邦機構最高層級的資料隔離。</li>
              <li>Office 365 提供強式密碼原則，包括長度、複雜度，以及密碼過期並結合帳戶鎖定。</li>
              <li>Office 365 能夠緊密整合業界最主要的多重要素驗證系統。</li>
              <li>客戶控制哪項裝置能夠存取 Office 365，以及在哪個網路下存取。</li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
        <div>
            <div><b>法務遵循領導地位</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>有了 Office 365，法務遵循不需要變成企業與雲端之間的障礙。這是第一種重大的雲端產能服務，運用根據國際標準組織 (ISO/IEC 27001:2005) 標準建立的資訊安全架構，且由獨立稽核人員認證通過 ISO 27001。Office 365 也透過契約承諾遵守歐盟模型條款和 HIPAA 商業夥伴合約。而且，Exchange Online 包含全面整合的封存和電子資料探索 
              (e-discovery) 工具，讓很多組織都能夠在單一基礎架構中滿足法務遵循和監督控管需求。</p>
            <h4>OFFICE 365 </h4>
            <p>客戶能夠充滿信心，將資料託付給雲端服務法務遵循和業界標準的領導者。</p>
            <ul class="one">
              <li>Office 365 是第一套以雲端為基礎的產能套件，可達到下列目標：
                <ol class="secend">
                  <li>ISO 27001 認證。</li>
                  <li>與所有客戶簽定歐盟模型條款和資料處理協定。</li>
                  <li>歐盟安全港條文。</li>
                  <li>與 Enterprise Agreement 大量授權客戶簽定 HIPAA/BAA 合約。</li>
                </ol>
              </li>
              <li>Office 365 獲得 FISMA 認證，以滿足美國政府機構的需求。</li>
              <li>Office 365 有 SSAE 16 類型 I 和類型 II (先前稱為 SAS-70 類型 I 和類型 II)。</li>
              <li>Office 365 遵循 FERPA 教育客戶的指導方針。</li>
              <li>Office 365 為金融服務業客戶遵循 GLBA (金融服務法現代化服務法案)。</li>
              <li>電子郵件保存和文件記錄管理功能讓客戶能夠滿足法務遵循需求。</li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div class="tit octit">
            <div class="open"><b>讓使用者可以隨時隨地存取其內容</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>工作人員不管在何處，都必須保持生產力。Office 365 透過跨 PC、行動裝置和瀏覽器，提供一致且最佳化的體驗，讓企業能夠為員工達成這項承諾。桌面上有 Office，使用者就能在離線時完成工作。員工可存取封存的電子郵件和文件，讓他們在沒有網際網路連線能力時有效率地工作。</p>
            <h4>OFFICE 365 </h4>
            <ul class="one">
              <li>Office 365 提供產能彈性。使用者預期並獲得跨平台和裝置的一致性使用者經驗。使用者在線上不需要網際網路就能以電子郵件執行的任何作業，都能在離線時執行相同作業，例如存取 Active Directory 通訊錄，查看不限數目電子郵件以供檢閱、設定會議邀請，以及使用 RTF 格式設定來撰寫電子郵件。</li>
              <li>由於桌面上有 Office 用戶端，即使有網路問題，Office 365 使用者仍可以處理試算表、投影片組及其他更多項目。</li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div class="tit octit">
            <div class="open"><b>豐富的功能集</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>沒有其他雲端產能服務提供 Office 365 的豐富功能，它包括由 Exchange Online 所提供領先業界的電子郵件、Lync Online 的一鍵語音通話和視訊會議，以及 SharePoint Online 彈性和功能強大的小組協同合作。這些線上 Office 伺服器與使用者每天依賴的 Microsoft Office 桌面應用程式密切整合。Microsoft 數十年來一直致力於讓 Office 應用程式臻於至善，而 Office 365 是這整個過程最自然的延伸。</p>
            <h4>OFFICE 365 </h4>
            <p>以千錘百鍊的方式增強熟悉的 Office 產能經驗，執行建立、協同合作與溝通作業。</p>
            <ul class="one">
              <li>使用者絕對能夠放心，知道他們在電子郵件與 IM 中的個人狀態是整合的，其他人可從行事曆擷取的資料看到他們的空閒/忙碌時間，而多個群組可一同參加視訊會議並即時共用桌面和應用程式。</li>
              <li>使用者從 Office 桌面切換到 Office Web Apps，或者反向切換時，都能保持檔案相容性，所以企業能夠維持員工的生產力，而且在客戶與合作夥伴前表現出專業水準。</li>
              <li>Office 365 的所有特性和功能都由 SLA 和企業支援在背後支撐。</li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <div class="tit octit">
            <div class="open"><b>需要的訓練較少</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>當客戶轉移到雲端時，他們要提供使用者產能解決方案，將逐步上手的時間減到最低。Office 365 提供這種經驗，因為行動、線上和桌面應用程式都與熟悉的 Office 應用程式具有一致的經驗，讓使用者立即熟練使用解決方案。員工幾乎是可以立即使用 Office 365，因為 Office 365 是建立於內部部署版本的 Exchange、SharePoint、Lync 和 Office 之上。公司獲得雲端的簡易性和靈活彈性，同時改進了使用者預期且需要的功能。</p>
            <h4>OFFICE 365 </h4>
            <p>Office 365 部署了使用者以最低上手時間和訓練而能立刻運用的解決方案。</p>
            <ul class="one">
              <li>減少訓練成本和產能中斷，改善投資報酬率 (ROI)。</li>
              <li>使用者可以使用 Lync Online 和 SharePoint Online 即時通訊 (IM)、通話或上傳文件，就跟內部部署 Lync 和 SharePoint 一樣簡單。</li>
              <li>Outlook Web Access 保持桌面用戶端版的熟悉 Outlook UI 經驗。</li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div class="tit octit">
            <div class="open"><b>以您的方式部署</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>企業可能無法準備就緒，一次就將所有資訊移到雲端。有了 Office 365，客戶可以選擇純粹的雲端環境、內部部署解決方案，或是混合式環境，將雲端整合到內部部署 IT 基礎架構之中。基於法務遵循或營運考量，客戶可以將一些使用者移到雲端，而讓其他人保留在內部部署中。這可以讓客戶以對本身企業最合理的步調移到雲端。如果他們選擇混合式基礎架構，使用者很可能永遠不知道兩者之間的差異，而且客戶可以跨線上和內部部署伺服器使用相同的管理工具。</p>
            <h4>OFFICE 365 </h4>
            <p>只有 Office 365 提供只有雲端、只有內部部署和混合式部署模型等不同選項。</p>
            <ul class="one">
              <li>符合特屬企業或業界的法務遵循、部署和營運需求。</li>
              <li>在 Exchange 中以整合式系統管理工具，同時輕鬆管理內部部署和線上環境。</li>
              <li>跨內部部署和線上使用者群組，提供順暢無阻的使用者經驗。</li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <div class="tit octit">
            <div class="open"><b>倚賴受財務支援的 99.9% SLA</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>客戶需要有保證的解決方案，若無法履行該保證則應另以有價值的其他項目來交換。客戶了解可能會發生中斷現象，但是當企業損失產能時，負責任的企業廠商應該負起責任並協助客戶。Office 365 是針對可靠性、可用性及效能而建置的服務。它備受肯定的服務，是由企業已使用數十年而完全符合企業需求的電子郵件和商務協同合作產品所提供。由於對企業的這項承諾，Microsoft 是少數雲端服務提供者之一，可在任何 Office 365 服務可用性降到 99.9% 以下時，提供有財務支援的服務等級協定 (SLA)。</p>
            <h4>OFFICE 365 </h4>
            <p>Office 365 服務等級是使用特別為讓客戶放心而設計的透明方法來量測。</p>
            <ul class="one">
              <li>Office 365 SLA 涵蓋所有服務，不管客戶使用哪種服務都提供保證。</li>
              <li>SLA 涵蓋每一個使用者和套件中每一個元件。</li>
              <li>Office 365 服務健康儀表板提供客戶最新的服務健康狀況監控能力。</li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={8}>
          <div class="tit octit">
            <div class="open"><b>知道將來可能發生的狀況以進行規劃</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>企業不能因為新功能忽然消失或提供時不一致而遭受阻礙。使用 Office 365 時，客戶會在重大變更和清除提供項目時接到事先通知，以便有效進行規劃。藉由協助企業設定其技術策略，Microsoft 協助客戶了解公司的願景和創新。</p>
            <h4>OFFICE 365 </h4>
            <p>Microsoft 有超過 20 年以上的企業就緒經驗，並採取以企業為中心的雲端手法，確保客戶能夠適當進行規劃。</p>
            <ul class="one">
              <li>客戶獲得簽約時預期的項目，而沒有任何隱藏的成本或意外。</li>
              <li>Office 365 會依定期時程進行更新，若有重大變革，會在 12 個月前接到事先通知。</li>
              <li>Office 365 提供客戶靈活彈性，可視需要變更服務和授權的數量。</li>
              <li>透過公開提供的 Service Update Wiki，客戶能避免不愉快的意外，獲得深入了解 Office 365 技術藍圖的能力。 <br />
              註：<a href="http://community.office365.com/zh-tw/w/office_365_service_updates/default.aspx" target="_blank" rel="noopener noreferrer" class="url">http://community.office365.com/zh-tw/w/office_365_service_updates/default.aspx</a>
              </li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={9}>
          <div class="tit octit">
            <div class="open"><b>企業等級支援</b></div>
          </div>
          <div style={{
            textAlign: "justify",
            textJustify: "inter-word"
          }}>
            <p>中華電信微軟雲服務銷售之 Office 365 商務/企業方案，提供 24 小時全年無休專人客服窗口。於工作日接收到之 Office 365 服務等問題，提供專業諮詢協助，非工作日則紀錄後，於工作日回覆，如客戶有進階問題亦可代客請求微軟支援，滿足各種企業客戶使用情境。</p>
            <h4>OFFICE 365 </h4>
            <p>Office 365 經企業測試的支援具有經實證方法，可在服務中斷時讓客戶仍然具有情報資訊。</p>
            <ul class="one">
              <li>每天 24 小時全年無休的電話支援甚至在只有一個使用者服務中斷時都提供，沒有警告或最低受影響使用者人數限制，直接就開始考慮問題。</li>
              <li>Office 365 支援服務會嘗試立即解決問題，但必要時可更向上層階級呈報。</li>
              <li>控管型社群論壇有助客戶取得一般常見問題的解答。</li>
              <li>Microsoft <a href="http://www.microsoft.com/microsoftservices/professionaldirect/faq.htm" target="_blank" rel="noopener noreferrer">Professional Direct</a> (英文) 和 <a href="http://www.microsoft.com/microsoftservices/en/us/scenario_cloud_vantage.aspx" target="_blank" rel="noopener noreferrer">Cloud Vantage</a> (英文) 提供無可比擬的支援經驗。</li>
            </ul>
            <p>進一步了解 Office 365 企業版，請閱讀<a href="http://www.microsoft.com/en-us/download/details.aspx?id=13602" target="_blank" rel="noopener noreferrer">服務說明</a> (英文) </p>
          </div>
        </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
}
