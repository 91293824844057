import React, { useState } from "react";
import MaterialTable from "material-table";
import ReviewModal from "./ReviewModal";
import { format } from "date-fns";
import Axios from "axios";

import { forwardRef, useEffect } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import { Box } from "@material-ui/core";

import { useCookies } from "react-cookie";

import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";

// Style CSS
import { makeStyles } from '@material-ui/core/styles';

// Refreshing cookies mechanism
import { useHistory } from "react-router-dom";
import { getCookie, handleRefreshLoginCookies, handleExpiredCookies } from "../../common/session"

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 500,
    //height: '100%'
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// const headerURL = "http://localhost:8888";
const deleteMailLogURL = "/api/mailLog/delete";
const resendEmailURL = "/api/mailLog/resendEmail";
const getDataURL = "/api/mailLog/list";

export default function Logs() {
  const [reviewModalStatus, setReviewModalStatus] = useState(false);
  const [mailLogDetail, setMailLogDetail] = useState(0);

  const [cookies] = useCookies(["group_id"]);

  // Refreshing cookies mechanism
  let history = useHistory() // 取得某頁面 route name

  const [permission, setPermission] = React.useState(0);

  const notPermitted = 1;
  const readOnly = 2;
  const debugMode = true;

  // Fetch data before render
  useEffect(() => {
    var gid = isNaN(parseInt(cookies.group_id)) ? 0 : parseInt(cookies.group_id);
    if (gid !== 0) {
      getGroupPermission(gid);
    }
  }, [cookies.group_id]);

  // Refreshing cookies mechanism
  useEffect(() => {
    if(getCookie("session") !== undefined) {// 按下登出按鈕 或是 Session 仍存在的情況下進行換頁。
      handleRefreshLoginCookies()
      if (debugMode) {
        console.log("Call loginCheck api- " + getCookie("session"));
      }
      Axios
        .post("/api/v0/loginCheck", {
          session: getCookie("session"),
        })
        .then(function(response) {
          if (response.data.IsLogin !== true) {
            handleExpiredCookies()
            history.replace("/backoffice/Login")
          }
        })
        .catch(function(error) {
          console.log("OPRouter", error);
        });
    } else {// Session 過期的情況下進行換頁。
      handleExpiredCookies()
      history.replace("/backoffice/Login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    reviewModalStatus, // 查看紀錄時
  ])

  const columns = [
    { title: "收信人", field: "mailTo" },
    { title: "主旨", field: "mailSubject" },
    { title: "使用功能", field: "functionDescription" },
    { title: "寄送狀態", field: "sendMailStatus" },

    {
      title: "寄送時間",
      field: "sendTime",
      render: (rowData) =>
        format(new Date(rowData.sendTime), "yyyy/MM/dd HH:mm:ss"),
    },
  ];

  const actions = (permission === readOnly) ?
    [] : [
      {
        icon: VisibilityIcon,
        tooltip: "檢視",
        onClick: (event, rowData) => reviewMail(rowData),
      },
      {
        icon: DeleteIcon,
        tooltip: "刪除",
        onClick: (event, rowData) => {
          if (permission === readOnly) {
            alert("您無此權限");
          } else {
            deleteMailLog(rowData.sn);
          }
        },
      },
      {
        icon: EmailIcon,
        tooltip: "補寄",
        onClick: (event, rowData) => {
          if (permission === readOnly) {
            alert("您無此權限");
          } else {
            resendEmail(rowData.sn);
          }
        },
      },
    ];
  const data = (query) =>
    new Promise((resolve, reject) => {
      var postData = {};
      postData.perPage = query.pageSize;
      postData.page = query.page;
      postData.search = query.search;
      if (query.orderBy) {
        postData.orderBy = query.orderBy.field;
        postData.orderDirection = query.orderDirection;
      }
      Axios.post(getDataURL, postData)
        .then((result) => {
          resolve({
            data: result.data.data !== null ? result.data.data : [],
            page: query.page,
            totalCount: result.data.total,
          });
        })
        .catch((result) => {
          alert("後端異常");
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
        });
    });

  const options = {
    searchText: "",
    pageSize: 20,
    pageSizeOptions: [10, 20, 30, 40, 50],
    padding: "dense",
    actionsColumnIndex: -1,
    headerStyle: {
      backgroundColor: "#888",
      color: "#FFF"
    },
    rowStyle: (rowData) => ({
      backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
    }),
  };

  const getGroupPermission = (sn) => {
    var postData = {};
    postData["Sn"] = sn;
    Axios.post("/OP/group/getGroupData", postData).then(function (response) {
      var res = response.data.data;
      for (var i in res) {
        if (res[i].name === '信件寄送紀錄') {
          setPermission(res[i].permission);
          break;
        }
      }
    }).catch(function (err) {
      console.log(err);
    });
  };

  const openReviewModal = () => {
    setReviewModalStatus(true);
  };

  const closeReviewModal = () => {
    setReviewModalStatus(false);
  };

  const resendEmail = (sn) => {
    if (window.confirm("確定要補寄此封信件？")) {
      Axios.post(resendEmailURL, { sn: sn, opName: cookies.op_name })
        .then(() => {
          alert("補寄成功！");
          window.location.reload();
        })
        .catch((err) => {
          alert("補寄失敗");
          console.log(err);
        });
    }
  };

  const deleteMailLog = (sn) => {
    if (window.confirm("確定刪除？")) {
      Axios.post(deleteMailLogURL, { sn: sn })
        .then(() => {
          alert("刪除成功");
          window.location.reload();
        })
        .catch((error) => {
          alert("刪除失敗");
          console.log(error);
        });
    }
  };

  const reviewMail = (rowData) => {
    setMailLogDetail(rowData);
    openReviewModal();
  };
  var classes = useStyles();
  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.container}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: 'center',
                minHeight: '100vh'
              }}
            >
              <Grid item style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 200
              }}>
                <Typography style={{ fontSize: 100 }}>
                  您無此權限
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }
  return (
    <Box m={2}>
      <MaterialTable
        title="信件寄送紀錄"
        icons={tableIcons}
        columns={columns}
        data={data}
        actions={actions}
        options={options}
      />
      <ReviewModal
        mailLogDetail={mailLogDetail}
        openModal={reviewModalStatus}
        closeModal={closeReviewModal}
      />
    </Box>
  );
}
