import React, { useState, useEffect, useCallback } from "react";
import styled from 'styled-components';
import MsLogo from './assets/CompanyList/msLogo.png';
import GoogleLogo from './assets/CompanyList/googleLogo.png';
import {
  Dialog,
  Slide,
  Paper,
  Grid,
  Button
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// 商品品牌
// const BrandMicrosoftSn = 1;
// const BrandGoogleSn = 2;

const DialogTitle = styled.p`
    text-align: center;
    font-weight: bolder;
    font-size: 40px;
    margin-top: 35px;
  `

const StyledPaper = styled(Paper)`
    border: 1px outset #ddd;
    border-radius: 20px;
    padding: 0px 45px 30px 20px;
  `
const buttonStyle = {
  backgroundColor: "#45b6fe",
  marginLeft: 10,
  marginRight: 10,
  width: "100%"
};

export default function FilterCompanyList(props) {
  const [imageList, setImageList] = useState([MsLogo, GoogleLogo]);
  const open = props.open;
  const closeList = props.closeList;
  const companyList = props.companyList;
  const redirect = props.redirect;

  // Preload Icon
  const preloadImage = useCallback(() => {
    // console.log("preloadImage");
    imageList.forEach((image) => {
      new Image().src = image;
    });
    setImageList(imageList);
  }, [imageList]);

  useEffect(() => {
    preloadImage()
  }, [preloadImage]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeList}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        PaperComponent={StyledPaper}
      >
        <DialogTitle>選擇品牌</DialogTitle>
        <Grid container spacing={1}>
          {companyList.map((company, index) => {
            return (
              <Grid item xs={4}>
                <Button style={buttonStyle} variant="contained" onClick={() => {
                  props.chooseFilterCompany(company.Sn);
                  props.closeList();
                  if (redirect !== undefined) {
                    redirect();
                  }
                }}>{company.Name}</Button>
              </Grid>
            );
          })}

          <Grid item xs={4}>
            <Button style={buttonStyle} variant="contained" onClick={() => {
              props.chooseFilterCompany(0);
              props.closeList();
              if (redirect !== undefined) {
                redirect();
              }
            }}>全部品牌</Button>
          </Grid>
        </Grid>
      </Dialog>
    </div >
  );
}