import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
} from "@material-ui/core";
import React, { useState, useCallback } from "react";
import ProductDetailModalOfMicrosoft from "../ProductList/ProductDetailModalOfMicrosoft.js";
import { ProductListAutoComplete } from "./PurchaseModelOfMicrosoft";

const defaultSelectProduct = null;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectProductListModel({
  open,
  onDialogCloseCB,
  brandSn,
  otherProductItemFilter,
}) {
  const [selectProduct, setSelectProduct] = useState(defaultSelectProduct);
  const [openPDMM, setOpenPDMM] = useState(false); // PDMM = ProductDetailModalOfMicrosoft

  const changeSelectProduct = useCallback(
    (product) => {
      // 再一次檢查是否為年繳
      if (otherProductItemFilter(product)) {
        setSelectProduct(product);
      }
    },
    [setSelectProduct, otherProductItemFilter]
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onDialogCloseCB(defaultSelectProduct)}
        TransitionComponent={Transition}
        aria-describedby="ProductListModel"
        //   PaperComponent={StyledPaper}
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{
          style: {
            height: "370px",
          },
        }}
      >
        <DialogTitle>選擇商品</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <Grid container>
            <Grid item xs={4}>
              訂閱商品：
            </Grid>
            <Grid item xs={8}>
              <ProductListAutoComplete
                brandSn={brandSn}
                onChangeCB={(value) => changeSelectProduct(value)}
                hideDiabled={true}
                otherProductItemFilter={otherProductItemFilter}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Typography>
            <Button onClick={() => setOpenPDMM(true)} color="secondary">
              新增商品
            </Button>
            <Button
              onClick={() => onDialogCloseCB(selectProduct)}
              color="primary"
            >
              確定
            </Button>
            <Button
              onClick={() => onDialogCloseCB(defaultSelectProduct)}
              color="primary"
            >
              取消
            </Button>
          </Typography>
        </DialogActions>
      </Dialog>
      <ProductDetailModalOfMicrosoft
        productDetailSn={0}
        review={false}
        openModal={openPDMM}
        closeModal={(productDetail) => {
          onDialogCloseCB(productDetail, false);
          setOpenPDMM(false);
        }}
        isInsertReviewLaunchProductDetail={true}
        otherProductItemFilter={otherProductItemFilter}
        // productList={productList}
        // brand={company}
      />
    </>
  );
}
