import React, { useState, useEffect, useCallback } from "react";
import styled from 'styled-components';
import MsLogo from './assets/CompanyList/msLogo.png';
import GoogleLogo from './assets/CompanyList/googleLogo.png';
import {
  Dialog,
  DialogActions,
  Slide,
  Paper,
  Grid,
  Button
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// // 商品品牌
// const BrandMicrosoftSn = 1;
// const BrandGoogleSn = 2;

// // CSS 屬性
// const touchShadow = '0px 3px 10px 3px rgba(51, 51, 51, 0.5)';
// const touchTranslate = 'translate(0px, -6px)';
// const touchBackgroundColorOfMs = '#f0ffff';
// const touchBackgroundColorOfGws = '#fffff0';

const DialogTitle = styled.p`
    text-align: center;
    font-weight: bolder;
    font-size: 40px;
    margin-top: 35px;
  `

// const GoogleButton = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-weight: bolder;
//     font-size: 32px;
//     color: #FFC300;
//     width:250px;
//     height:150px;
//     border-radius: 30px;
//     margin: 20px;
//     padding: 9px;
//     text-transform: capitalize;
//     cursor: pointer;
//     border: 1px solid black;
//     transition: all 0.2s ease-in;
//     &:hover {
//       background-color: ${touchBackgroundColorOfGws};
//       box-shadow: ${touchShadow};
//       transform: ${touchTranslate};
//     }
//   `
// const MicroSoftButton = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-weight: bolder;
//     font-size: 32px;
//     color: #329E56;
//     width:250px;
//     height:150px;
//     border-radius: 30px;
//     margin: 20px;
//     padding: 9px;
//     text-transform: capitalize;
//     cursor: pointer;
//     border: 1px solid black;
//     transition: all 0.2s ease-in;
//     &:hover {
//       background-color: ${touchBackgroundColorOfMs};
//       box-shadow: ${touchShadow};
//       transform: ${touchTranslate};
//     }
//   `
// const Icon = styled.img`
//     width: 30px;
//     height: 30px;
//     margin: 0 15px 0 0;
//   `;

const StyledPaper = styled(Paper)`
    border: 1px outset #ddd;
    border-radius: 20px;
    padding: 0px 45px 30px 20px;
  `
const buttonStyle = {
  backgroundColor: "#45b6fe",
  marginLeft: 10,
  marginRight: 10,
  width: "100%"
};

export default function CompanyList(props) {
  const [imageList, setImageList] = useState([MsLogo, GoogleLogo]);
  const open = props.open;
  const closeList = props.closeList;
  const productBrandList = props.productBrandList;

  // Preload Icon
  const preloadImage = useCallback(() => {
    // console.log("preloadImage");
    imageList.forEach((image) => {
      new Image().src = image;
    });
    setImageList(imageList);
  }, [imageList]);

  useEffect(() => {
    preloadImage()
  }, [preloadImage]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeList}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        PaperComponent={StyledPaper}
      >
        <DialogTitle>選擇產品</DialogTitle>
        <DialogActions>
          <Grid container spacing={1}>
            {productBrandList.map((company, index) => {
              return (
                <Grid item>
                  <Button style={buttonStyle} variant="contained" onClick={() => props.chooseCompany(company.Sn)}>{company.Name}</Button>
                </Grid>
              );
            })}
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}