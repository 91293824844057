// react
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import MaterialTable from "material-table";
import { useCookies } from "react-cookie";
// Security Design
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LoginDialogForCheckID from "../common/LoginDialogForCheckID.js";

import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  InputLabel,
  DialogActions,
  Button,
  TextField,
  FormControl,
  NativeSelect,
  IconButton,
} from "@material-ui/core";

// API
import Axios from "axios";

// api url
const updateOrderSubscribeDateUrl = "/api/order/updateOrderSubscribeDateByProductDiscodeSn";
const getDsisLogURL = "/api/product/discode/log/list";
// Security Design
const insertDsisWatcherLogURL = "/api/product/discode/insertDsisWatcherLog";
const getDsisWatcherLogURL = "/api/product/discode/getDsisWatcherLogBySn";


// Const
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";

export default function EditDialog(props) {
  const [cookies] = useCookies(["op_name", "session"]);
  const [modalStatus, setModalStatus] = useState(false);
  const [modifyLog, setModifyLog] = useState([]);
  // const [dsisMgmtLog, setDsisMgmtLog] = useState([]);
  const closeModal = props.closeModal;

  // Security Design
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showAllMemberData, setShowAllMemberData] = useState(false);
  const [dsisWatcherLog, setDsisWatcherLog] = useState([]);

  // Security Design
  useEffect(() => {
    if (showAllMemberData && props.productDiscodeDetail.sn !== undefined && cookies.op_name !== undefined) {
      Axios.post(insertDsisWatcherLogURL, { discodeSn: props.productDiscodeDetail.sn, operatorId: cookies.op_name })
        .then(function (response) {
          console.log(response);
          Axios.post(getDsisWatcherLogURL, { Sn: props.productDiscodeDetail.sn })
            .then(function (response) {
              setDsisWatcherLog(response.data.log != null ? response.data.log : []);
            }).catch(function (err) {
              console.log(err);
            });
        }).catch(function (err) {
          console.log(err);
        });
    }
  }, [showAllMemberData, props.productDiscodeDetail.sn, cookies.op_name]);

  // Security Design
  const convertDataToHiddenCode = (val) => {
    if (val === undefined) {
      return "";
    }
    let strLength = val.length;
    let data = '';
    if (strLength !== 0 && strLength !== undefined && strLength !== null) {
      for (let i = 0; i < strLength; i++) {
        data += '*';
      }
    }
    return data;
  }

  // Security Design
  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  }
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  }
  const handleToggleShowAllMemberData = () => {
    setShowAllMemberData(!showAllMemberData);
  }
  const handleMouseDownAllMemberData = (e) => {
    e.preventDefault();
  }
  const handleClickShowAllMemberData = () => {
    if (showAllMemberData) { // 正常已開啟狀態。
      handleToggleShowAllMemberData();
    } else {  // 未開啟，故要先行登入。
      handleOpenLoginDialog();
    }
  }

  // Modal data
  var [productDiscodeDetail, setProductDiscodeDetail] = useState({
    sn: 0,
    discode: "",
    productName: "",
    productPlanType: "",
    productDertailNo: "",
    productDetailSn: 0,
    discodeName: "",
    amount: 0,
    salePrice: 0.0,
    enable: true,
    duration: 12
  });
  const [productDetailList, setProductDetailList] = useState([]);

  // Security Design
  function watcherLogTable(watcherLog) {
    const columns = [
      {
        title: "時間",
        field: "watchTime",
        render: (rowData) => datetimeStringFormat(rowData.watchTime),
      },
      {
        title: "查看人員",
        field: "operator",
        render: (rowData) => showAllMemberData ? rowData.operator : convertDataToHiddenCode(rowData.operator)
      },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          機敏資料查看紀錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, watcherLog)}</DialogContent>
      </Box>
    );
  }

  function logTable(dsisLog) {
    const columns = [
      {
        title: "時間",
        field: "modifyDate",
        render: (rowData) => datetimeStringFormat(rowData.ModifyTime),
      },
      {
        title: "修改者",
        field: "operator",
        render: (rowData) => {
          // rowData.OperatorSn !== undefined && rowData.OperatorSn.Name !== undefined ? rowData.OperatorSn.Name : convertDataToHiddenCode(rowData.operator)
          if (rowData.OperatorSn !== undefined && rowData.OperatorSn.Name !== undefined) {
            return showAllMemberData ? rowData.OperatorSn.Name : convertDataToHiddenCode(rowData.OperatorSn.Name);
          } else {
            return "";
          }
        }
      },
      {
        title: "欄位",
        field: "field",
        render: (rowData) => {
          if (!fieldMapingList[rowData.Field]) return showAllMemberData ? rowData.Field : convertDataToHiddenCode(rowData.Field);
          return showAllMemberData ? fieldMapingList[rowData.Field] : convertDataToHiddenCode(fieldMapingList[rowData.Field]);
        },
      },
      {
        title: "修改前資料",
        field: "beforeValue",
        render: (rowData) => showAllMemberData ? rowData.BeforeValue : convertDataToHiddenCode(rowData.BeforeValue)
      },
      {
        title: "修改後資料",
        field: "afterValue",
        render: (rowData) => showAllMemberData ? rowData.AfterValue : convertDataToHiddenCode(rowData.AfterValue)
      },
      // { title: "動作", field: "action" },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          優惠代碼更改記錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, dsisLog)}</DialogContent>
      </Box>
    );
  }
  const fieldMapingList = {
    ProductDetailSn: "商品",
    Discode: "優惠代碼",
    DiscodeName: "優惠代碼名稱",
    Amount: "綑綁數量",
    SalePrice: "優惠價格",
    Enable: "代碼是否有效",
    IsCharge: "是否出帳",
    Duration: "優惠持續時間"
  };

  function datetimeStringFormat(time) {
    // time is string
    if (time === "" || time === null) {
      return "";
    } else {
      return format(new Date(time), datetimeFormat);
    }
  }
  function createTable(columns, datas) {
    return (
      <MaterialTable
        columns={columns}
        data={datas}
        options={{
          search: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          padding: "dense",
          sorting: false,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
          }),
        }}
      />
    );
  }

  const handleChangeOrderDetail = (event) => {
    var { name, value, type } = event.target;
    if (type === "select-one") {
      value = parseInt(value);
    }
    if (type === "number") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
    }
    setProductDiscodeDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (props.open) {
      setProductDiscodeDetail(props.productDiscodeDetail);
      setProductDetailList(props.productDetailList);

      Axios.post(getDsisLogURL, { discodeSn: props.productDiscodeDetail.sn }).then(function (modifyLogResponse) {
        if (modifyLogResponse.data !== undefined) {
          setModifyLog(modifyLogResponse.data ? modifyLogResponse.data : [])
        }
      }).catch(function (err) {
        console.log(err);
        alert("撈取優惠代碼操作紀錄後端異常");
        setModifyLog([]);
      });
    }
    setModalStatus(props.open);
  }, [props.open, props.productDiscodeDetail, props.productDetailList]);

  const productDetailSubmit = (e) => {
    e.preventDefault();
    if (window.confirm("確定儲存此筆資料？")) {
      var postData = {};
      postData.Sn = productDiscodeDetail.sn;
      postData.Discode = productDiscodeDetail.discode;
      postData.DiscodeName = productDiscodeDetail.discodeName;
      postData.ProductDetailSn = productDiscodeDetail.productDetailSn;
      postData.Amount = productDiscodeDetail.amount;
      var salePrice = parseFloat(productDiscodeDetail.salePrice);
      postData.SalePrice = isNaN(salePrice) ? 0.0 : salePrice;
      postData.duration = isNaN(parseInt(productDiscodeDetail.duration)) ? 12 : parseInt(productDiscodeDetail.duration);
      postData.OpName = cookies.op_name;
      console.log(postData);
      Axios.post("/api/product/discode/update", postData).then((response) => {
        // console.log(response.data.message);

        // Update the OrderSubscribeDate on all orders 
        Axios.post(updateOrderSubscribeDateUrl, {
          Sn: productDiscodeDetail.sn,
          Duration: isNaN(parseInt(productDiscodeDetail.duration)) ? 12 : parseInt(productDiscodeDetail.duration)
        }).then((response) => {
          // console.log(response);
          alert("已啟用之訂單的退訂日期也皆更新完畢。")
        }).catch((err) => {
          console.log(err);
        })

      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        closeModal();
      });
    }
  };

  const productDetailChange = (event) => {
    if (event.target.value !== "") {
      var sn = parseInt(event.target.value);
      setProductDiscodeDetail((prevState) => ({
        ...prevState,
        productDetailSn: sn,
      }));
    }
  };

  return (
    <Dialog
      open={modalStatus}
      onClose={closeModal}
      maxWidth="md"
      scroll="paper"
    >
      <Box>
        <form onSubmit={productDetailSubmit}>
          <DialogTitle>修改優惠代碼設定：</DialogTitle>
          <DialogContent dividers>
            <TextField
              fullWidth
              margin="dense"
              label="優惠代碼"
              name="discode"
              defaultValue={productDiscodeDetail.discode}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="demo-customized-select-native">
                商品詳細資訊
              </InputLabel>
              <NativeSelect
                value={productDiscodeDetail.productDetailSn}
                onChange={productDetailChange}
                required
              >
                <option></option>
                {productDetailList.map((productDetail, index) => {
                  return (
                    <option
                      key={index}
                      value={productDetail.sn}
                    >
                      {productDetail.name}
                      {productDetail.productPlanType !== "" ? " - " + productDetail.productPlanType : ""}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
            <TextField
              fullWidth
              margin="dense"
              label="優惠代碼名稱"
              name="discodeName"
              defaultValue={productDiscodeDetail.discodeName}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
            <TextField
              fullWidth
              margin="dense"
              label="授權數量"
              name="amount"
              type="number"
              defaultValue={productDiscodeDetail.amount}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
            <TextField
              fullWidth
              margin="dense"
              label="優惠價"
              name="salePrice"
              type="number"
              defaultValue={productDiscodeDetail.salePrice}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
            <TextField
              fullWidth
              margin="dense"
              label="持續期間（月）"
              type="number"
              name="duration"
              defaultValue={productDiscodeDetail.duration}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
          </DialogContent>
          <DialogActions>
            <Typography>
              <Button type="submit" name="save" color="primary">
                儲存
              </Button>
            </Typography>
            <Button onClick={closeModal} color="primary">
              取消
            </Button>
          </DialogActions>
        </form>
        {/* <Typography component="div">
          <DialogTitle>商品種類更改紀錄：</DialogTitle>
          <DialogContent dividers>{logTable(modifyLog)}</DialogContent>
        </Typography> */}
      </Box>
      {logTable(modifyLog)}
      {watcherLogTable(dsisWatcherLog)}
      <LoginDialogForCheckID
        open={openLoginDialog}
        handleToggleShowAllMemberData={handleToggleShowAllMemberData}
        handleCloseLoginDialog={handleCloseLoginDialog}
      >
      </LoginDialogForCheckID>
    </Dialog>
  );
}