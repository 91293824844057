import React, { useState } from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
}));

function App() {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleQuery = async () => {
    // 檢查是否為 UPDATE 或 DELETE 查詢
    if (/^\s*(UPDATE|DELETE)\s+/i.test(query)) {
      const confirmExecution = window.confirm('你確定要執行這個查詢嗎？這可能會改變或刪除資料。');
      if (!confirmExecution) {
        return;
      }
    }

    try {
      const response = await axios.post('/api/sql/query', { sql: query });
      console.log('Response:', response.data); // 加入這行來檢查回應資料
      if (response.data.columns) {
        const columns = response.data.columns.map(col => ({ title: col, field: col }));
        const rows = response.data.result.map(row => {
          const rowData = {};
          response.data.columns.forEach((col, index) => {
            rowData[col] = row[index];
          });
          return rowData;
        });
        setColumns(columns);
        setRows(rows);
        setMessage('');
      } else if (response.data.result) {
        setColumns([]);
        setRows([]);
        setMessage(`已異動資料筆數: ${response.data.result[0][1]}`);
      } else {
        setColumns([]);
        setRows([]);
        setMessage('');
      }
      setError('');
    } catch (err) {
      setError(err.message);
      setMessage('');
    }
  };

  return (
    <Container className={classes.container}>
      <h1>明心見性</h1>
      <textarea
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="一切有為法，如夢幻泡影，如露亦如電，應作如是觀。"
        rows="4"
        cols="50"
      />
      <br />
      <button onClick={handleQuery}>Execute</button>
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}
      <Paper>
        <MaterialTable
          columns={columns}
          data={rows}
          title="Query Results"
          icons={tableIcons}
          options={{
            headerStyle: {
              backgroundColor: "#888",
              color: "#FFF"
            },
            rowStyle: rowData => ({
              backgroundColor: rowData.tableData.id % 2 === 0 ? "#EEE" : "#FFF"
            }),
          }}
        />
      </Paper>
    </Container>
  );
}

export default App;