import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Axios from "axios";

import {
  Button,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Divider
} from "@material-ui/core"

// Image
// import bannerImg from "../assets/OrderStatus/banner.jpg";
import OrderStatus1 from "../assets/OrderStatus/OrderStatus_1.jpg";
import OrderStatus2 from "../assets/OrderStatus/OrderStatus_2.jpg";
import OrderStatus3 from "../assets/OrderStatus/OrderStatus_3.jpg";

// Other Components
import RecordModal from "./RecordModal";

// CSS
import Styled from "styled-components";
const StyledTitle = Styled(Typography)`
  && {
    text-align: center;
    font-weight: 700;
    font-family: Microsoft JhengHei;
    margin: 30px 0px;
    letter-spacing: 0.1em;
    font-style: italic;
    line-height: 1.5;
    word-break: keep-all;
    color: #424242;
  }
`
const StyledCard = Styled(Card)`
  && {
    border-radius: 15px;
    width: 293px;
    border: 1px solid #ddd;
    height: 412.75px;
    box-shadow: 2px 2px 10px #ccc;
    margin: 40px 0px;
    @media (min-width:320px) and (max-width:600px) {
      height: auto;
    }
  }
`
const StyledCardContent = Styled(CardContent)`
  && {
    padding: 0px;
  }
`
const StyledCardMedia = Styled(CardMedia)`
  && {
    padding: 0px;
    object-fit: contain;
  }
`
const StyledGrid = Styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`
const StyledCardTitle = Styled(Typography)`
  && {
    font-size: 1.35rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.04em;
    font-family: Microsoft JhengHei;
    margin: 20px 0px;
  }
`
const StyledCardDescription = Styled(Typography)`
  && {
    text-align: center;
    font-weight: 500;
    font-family: Microsoft JhengHei;
    margin: 10px 0px;
    padding: 0px 12px;
  }
`
const StyledCardActions = Styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 8px;
  }
`
const StyledButton = Styled(Button)`
  && {
    font-size: 0.9rem;
    margin: 10px 0;
    background-color: #28a8ea;
    color: #fff;
    width: 80%;
    text-transform: capitalize;
    font-family: Microsoft JhengHei;
    transition: all .3s ease-in;
    letter-spacing: 2.5px;
    line-height: 1.5;
    padding: 12px;
    word-break: break-all;
    &:hover {
      background-color: #397ea1;
      color: #fff;
    }
  }
`

function ButtonWithDescription(props) {
  return (
    <StyledGrid item xs={12}>
      <StyledButton
        variant="contained"
        href={props.data.buttonLink}
        aria-label={props.data.buttonDescription}
        onClick={props.data.onClick}
      >
        {props.data.buttonName}
      </StyledButton>
    </StyledGrid>
  );
}

function CategoryContent(props) {
  const buttonList = [];
  for (var bwd of props.data.buttons) {
    buttonList.push(<ButtonWithDescription key={bwd.buttonName} data={bwd} />);
  }

  return (
    <StyledGrid
      item
      xs={12} sm={6} md={4}
    >
      <StyledCard>
        <StyledCardContent>
          <StyledCardMedia
            component="img"
            alt="order"
            image={props.data.images}
          />
          <StyledGrid item xs={12}>
            <StyledCardTitle component="div">
              {props.data.name}
            </StyledCardTitle>
          </StyledGrid>
          <StyledGrid item xs={12}>
            {props.data.buttons["0"].buttonDescription === "" ? "" : (<StyledCardDescription>{props.data.buttons["0"].buttonDescription}
            </StyledCardDescription>)}
          </StyledGrid>
        </StyledCardContent>
        <StyledCardActions container>
          {buttonList}
        </StyledCardActions>
      </StyledCard>
    </StyledGrid>
  );
}

export default function OrderStatus() {
  const [recordModalStatus, setRecordModalStatus] = useState(false);
  const [cookies] = useCookies();
  const getOrderListBySessionURL = "/api/order/getOrderListBySession";
  const [orderList, setOrderList] = useState([]);

  const openRecordModal = () => {
    setRecordModalStatus(true);
  };

  const closeRecordModal = () => {
    setRecordModalStatus(false);
  };
  
  const data = [
    {
      name: "訂單查詢",
      images: OrderStatus1,
      buttons: [
        {
          buttonName: "查詢現有訂閱狀態",
          // buttonLink: "http://csp.hinet.net/login.php?t=record",
          buttonLink: "",
          buttonDescription: "",
          onClick: openRecordModal,
        },
        //{
        //  buttonName: "優惠方案開通",
        //  buttonLink: "http://csp.hinet.net/login.php?t=action",
        //  buttonDescription:
        //    "適用企業通路銷售之訂單開通，補填服務所需之用戶資料",
        //},
      ],
    },
    {
      name: "HiNet帳號管理",
      images: OrderStatus2,
      buttons: [
        {
          buttonName: "查詢HiNet帳號",
          buttonLink: "http://service.hinet.net/2004/status.htm",
          buttonDescription: "利用現有HiNet帳號支付，費用合併於電信帳單",
        },
      ],
    },
    {
      name: "HiNet帳號帳務查詢",
      images: OrderStatus3,
      buttons: [
        {
          buttonName: "帳務查詢",
          buttonLink: "http://aaaservice.hinet.net/User/user.html",
          buttonDescription: "",
        },
        {
          buttonName: "3個月內帳務查詢",
          buttonLink: "https://bill.0800080412.com.tw/bill/svalue3.jsp",
          buttonDescription: "",
        },
      ],
    },
  ];

  const categoryContentList = [];
  for (var cc of data) {
    categoryContentList.push(<CategoryContent key={cc.name} data={cc} />);
  }

  useEffect(() => {
    if (typeof cookies.sessionId !== "undefined" || cookies.sessionId !== "" || cookies.sessionId !== null) {
      // check session ID
      console.log("session ID: "+ cookies.sessionId);
      getOrderList(cookies.sessionId);
    }   
  }, [cookies.sessionId]);

  const getOrderList = (sessionId) => {
    Axios.post(getOrderListBySessionURL, { sessionId: sessionId })
      .then((response) => {
        console.log(response.data);
        if (response.data) setOrderList(response.data);
        else setOrderList([]);
      })
      .catch((err) => {
        console.error(err);
        // alert("後端異常");
        setOrderList([]);
      });
  };

  return (
    <Container>
      <StyledTitle variant="h3">客戶專區</StyledTitle>
      <Divider/>
      <Grid container spacing={2}>
        {categoryContentList}
      </Grid>

      <RecordModal
        open={recordModalStatus}
        closeModal={closeRecordModal}
        orderList={orderList}
      // sn={orderSn}
      />
    </Container>
  );
}
