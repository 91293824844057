import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import SearchAndExploration1 from "../assets/ProductOverview/all-20.jpg";
import SearchAndExploration2 from "../assets/ProductOverview/all-21.jpg";
import SearchAndExploration3 from "../assets/ProductOverview/all-22.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

//CSS
const StyledContextGrid = Styled(Grid)`
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 30px;
`
const StyledContextImage = Styled.img`
  max-width: 100%;
  border: 2px solid #28a8ea;
  border-radius: 10px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #28a8ea;
    margin-bottom: 20px;
  }
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 700;
    font-family: Microsoft JhengHei;
    text-align: left;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContainer = Styled(Grid)`
  && {
    margin-bottom: 120px;
  }
`

function renderSubContext(props) {
  const contexts = props;

  return contexts.map(context => (
    <>
      <StyledContextSubTitle color="secondary" variant="h5">
        {context.subTitle}
      </StyledContextSubTitle>
      <StyledContextSubContent paragraph>
        {context.subContext}
      </StyledContextSubContent>
    </>
  ));
}

function renderContext() {
  const contexts = [
    {
      title: "自動顯示所需資訊",
      context: [
        {
          subTitle: "檢視與自身相關的內容",
          subContext:
            "Office Delve 會在 Office 365 各處強調出與您切身相關的內容。配備 Office Graph 的 Delve 可根據您的工作內容和周遭趨勢呈現相關內容。"
        }
      ],
      img: SearchAndExploration1
    },
    {
      title: "找到所需內容",
      context: [
        {
          subTitle: "打破隔閡阻礙",
          subContext:
            "透過「我的工作」和「與我共用」等直覺檢視功能快速取得所需資訊。無需記得文件標題、作者或儲存位置也能輕鬆找到先前看過的文件。"
        }
      ],
      img: SearchAndExploration2
    },
    {
      title: "發掘新內容",
      context: [
        {
          subTitle: "與他人、文件和想法建立連結",
          subContext:
            "Delve 可讓您了解自己和同事與彼此和文件的連結狀況，一覽無遺。輸入姓名就能看到別人的工作內容和其周遭趨勢。Delve 讓您用前所未有的簡單方式與他人建立連結、建構關係和分享資訊。"
        }
      ],
      img: SearchAndExploration3
    }
  ];

  return contexts.map(context => (
    <StyledContainer container>
      <StyledContextGrid item md={5} sm={12}>
        <StyledContextImage src={context.img}></StyledContextImage>
      </StyledContextGrid>
      <StyledContextGrid item md={7} sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextDivider/>
        {renderSubContext(context.context)}
      </StyledContextGrid>
    </StyledContainer>
  ));
}

export default function CenteredGrid(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {renderContext()}
      </Grid>
    </div>
  );
}
