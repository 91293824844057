import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@material-ui/core";
import NumberFormat from "react-number-format";

import Axios from "axios";
import MaterialTable from "material-table";
import ProductDetailModalOfMicrosoft from "./ProductDetailModalOfMicrosoft";
import ProductDetailModalOfGoogle from "./ProductDetailModalOfGoogle";
import ProductDetailModal from "./ProductDetailModal";
import CompanyList from "../CompanyList";

import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useCookies } from "react-cookie";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";

// Refreshing cookies mechanism
import { useHistory } from "react-router-dom";
import { getCookie, handleRefreshLoginCookies, handleExpiredCookies } from "../../common/session"

// Style CSS
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 500,
    //height: '100%'
  },
}));

// 產品廠牌
const BrandInit = 0;
const BrandMicrosoftSn = 1;
const BrandGoogleSn = 2;
const getProductDetailUrl = "/api/product/detail";
const getProductBrandListUrl = "/api/product/brand/list";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ProductDetail() {
  const [cookies] = useCookies(["session", "op_name", "group_id"]);
  const [modalStatus, setModalStatus] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [productList, setProductList] = React.useState([]);
  const [productBrandList, setProductBrandList] = useState([]);
  const [productStatusList, setProductStatusList] = useState({});
  const [productDetailSn, setProductDetailSn] = useState();
  const [companyOptionStatus, setCompanyOptionStatus] = useState(false);
  const [company, setCompany] = useState(BrandInit);
  const [permission, setPermission] = useState(0);

  // Refreshing cookies mechanism
  let history = useHistory() // 取得某頁面route name

  const notPermitted = 1;
  const readOnly = 2;
  // const readWrite = 3;
  // const rwd = 4;
  const debugMode = true;

  // Table Feature
  const tableRef = React.useRef();
  const handleChangePage = (event) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const getProductBrandList = useCallback(() => {
    var postData = {};
    postData["OpName"] = cookies.op_name;
    Axios.post(getProductBrandListUrl, postData).then((response) => {
      setProductBrandList(response.data);
    }).catch((error) => {
      alert("後端異常");
      console.log(error);
    });
  }, [cookies]);

  useEffect(() => {
    var gid = isNaN(parseInt(cookies.group_id)) ? 0 : parseInt(cookies.group_id);
    if (gid !== 0) {
      getGroupPermission(gid);
    }
    if (modalStatus === false) {
      setCompany(localStorage.getItem('filterCompany') !== null ? parseInt(localStorage.getItem('filterCompany')) : 0);
    }
    getProductBrandList();
    setCompanyOptionStatus(false);

  }, [cookies, modalStatus, getProductBrandList]);

  // Refreshing cookies mechanism
  useEffect(() => {
    if (getCookie("session") !== undefined) {// 按下登出按鈕 或是 Session 仍存在的情況下進行換頁。
      handleRefreshLoginCookies()
      if (debugMode) {
        console.log("Call loginCheck api- " + getCookie("session"));
      }
      Axios
        .post("/api/v0/loginCheck", {
          session: getCookie("session"),
        })
        .then(function (response) {
          if (response.data.IsLogin !== true) {
            handleExpiredCookies()
            history.replace("/backoffice/Login")
          }
        })
        .catch(function (error) {
          console.log("OPRouter", error);
        });
    } else {// Session 過期的情況下進行換頁。
      handleExpiredCookies()
      history.replace("/backoffice/Login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalStatus, // 新增、編輯、審核商品種類。
  ])

  // get some List
  // eslint-disable-next-line no-unused-vars
  const getAllListData = () => {
    const getProductList = Axios.post("/api/product/list", { PerPage: 0, OrderBy: "name" });
    const getProductStatusList = Axios.get("/api/product/status/list");

    Axios.all([getProductList, getProductStatusList])
      .then(
        Axios.spread((ProductList, ProductStatusList) => {
          // console.log("ProductList:", ProductList);
          // console.log("ProductStatusList:", ProductStatusList);
          setProductList(ProductList.data.data);
          setProductStatusList(ProductStatusList.data);
        })
      )
      .catch((errors) => {
        alert("後端異常");
      });
  };

  // const getProductBrandList = () => {
  //   Axios
  //     .get(getProductBrandListUrl)
  //     .then((response) => {
  //       setProductBrandList(response.data);
  //     })
  //     .catch((error) => {
  //       alert("後端異常");
  //       console.log(error);
  //     });
  // };

  const getProductBrand = (sn) => {
    console.log("sn:" + sn);
    Axios
      .post(getProductDetailUrl, { sn: sn })
      .then((result) => {
        setCompany(result.data.brandSn);
        console.log("getProductBrand:", result.data.brandSn);
      })
      .catch((error) => {
        setCompany(BrandMicrosoftSn);
        console.log("getProductBrand:" + error);
      });
  };
  const getGroupPermission = (sn) => {
    var postData = {};
    postData["Sn"] = sn;
    Axios.post("/OP/group/getGroupData", postData).then(function (response) {
      var res = response.data.data;
      for (var i in res) {
        if (res[i].name === '商品清單管理') {
          setPermission(res[i].permission);
          break;
        }
      }
    }).catch(function (err) {
      console.log(err);
    });
  };

  // control modal status
  const openModal = () => {
    setModalStatus(true);
  };
  const closeModal = () => {
    setModalStatus(false);

    tableRef.current && tableRef.current.onQueryChange(); // Refreash data
  };

  const openCompanyList = () => {
    setCompanyOptionStatus(true);
  };
  const closeCompanyList = () => {
    setCompanyOptionStatus(false);
  };
  const chooseCompany = (companyName) => {
    setCompany(companyName);
    setModalStatus(true);
  };

  // action of product
  const productAdd = () => {
    setProductDetailSn(0);
    setReviewed(false);
    var brandSn = localStorage.getItem('filterCompany') !== null ? parseInt(localStorage.getItem('filterCompany')) : 0;
    if (brandSn !== 0) {
      chooseCompany(brandSn);
    } else {
      openCompanyList();
    }
  };

  const productEdit = (sn, isUpVisible) => {
    if (isUpVisible) alert("此項目的優惠價與官網的優惠價連動，請小心更改！");

    setProductDetailSn(sn);
    getProductBrand(sn);
    setReviewed(false);
    openModal();
  };

  const productReview = (sn) => {
    setProductDetailSn(sn);
    getProductBrand(sn);
    setReviewed(true);
    openModal();
  };

  const productDelete = (sn, isIpVisible) => {
    if (isIpVisible) {
      alert("不能刪除此項，會影響到使用者介面！")
    } else {
      if (window.confirm("確定刪除此筆資料？")) {
        Axios.post("/api/product/detail/delete", {
          sn: sn,
          OpName: cookies.op_name
        })
          .then(res => {
            alert("刪除成功");
            // window.location.reload(true);
            tableRef.current && tableRef.current.onQueryChange(); // Refreash data
          })
          .catch(error => {
            console.error(error);
            alert("後端異常");
          });
      }
    }
  };

  const changeStatus = (sn, status) => {
    console.log(status);
    if (window.confirm("確定改變此筆資料的狀態？")) {
      Axios.post("/api/product/detail/changeStatus", {
        sn: sn,
        status: status,
        OpName: cookies.op_name
      })
        .then((res) => {
          alert("狀態改變成功");
          // window.location.reload(true);
          tableRef.current && tableRef.current.onQueryChange(); // Refreash data
        })
        .catch((error) => {
          alert("後端異常");
        });
    }
  };

  const renderComponent = (value) => {
    switch (value) {
      case BrandMicrosoftSn:
        return (
          <ProductDetailModalOfMicrosoft
            productDetailSn={productDetailSn}
            openModal={modalStatus}
            closeModal={closeModal}
            review={reviewed}
            productList={productList}
            brand={company}
          />
        )
      case BrandGoogleSn:
        return (
          <ProductDetailModalOfGoogle
            productDetailSn={productDetailSn}
            openModal={modalStatus}
            closeModal={closeModal}
            review={reviewed}
            productList={productList}
            brand={company}
          />
        )
      case 0:
        return <></>
      default:
        return (
          <ProductDetailModal
            productDetailSn={productDetailSn}
            openModal={modalStatus}
            closeModal={closeModal}
            review={reviewed}
            productList={productList}
            brand={company}
          />
        )
    }
  }

  var classes = useStyles();
  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.container}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: 'center',
                minHeight: '100vh'
              }}
            >
              <Grid item style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 200
              }}>
                <Typography style={{ fontSize: 100 }}>
                  您無此權限
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }

  return (
    <div>
      <Box m={2}>
        <MaterialTable
          tableRef={tableRef}
          title="商品清單"
          icons={tableIcons}
          columns={[
            { title: "建立日期", field: "create_time" },
            { title: "小額產品編號", field: "product_id" },
            { title: "商品編號", field: "product_no" },
            { title: "商品名稱", field: "product" },
            { title: "品牌", field: "brandName" },
            { title: "方案", field: "plan" },
            { title: "折扣", field: "discode" },
            {
              title: "定價",
              field: "price",
              type: "numeric",
              render: (rowData) => (
                <NumberFormat
                  value={rowData.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              ),
            },
            {
              title: "成本",
              field: "price_cost",
              type: "numeric",
              render: (rowData) => (
                <NumberFormat
                  value={rowData.price_cost}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              ),
            },
            {
              title: "優惠價",
              field: "price_special",
              type: "numeric",
              render: (rowData) => (
                <NumberFormat
                  value={rowData.price_special}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              ),
            },
            {
              title: "漲價金額",
              field: "price_special",
              type: "numeric",
              render: (rowData) => (
                <NumberFormat
                  value={rowData.mark_up_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              ),
            },
            {
              title: "狀態",
              field: "product_status",
              lookup: productStatusList,
            },
            {
              title: "審核狀態",
              field: "product_reviewed",
              render: (rowData) => (
                <Typography>{rowData.product_reviewed ? "O" : "X"}</Typography>
              ),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let url = "/api/product/detail/list";

              var postData = {};
              postData.perPage = query.pageSize;
              postData.page = query.page;
              postData.search = query.search;
              if (query.orderBy) {
                postData.orderBy = query.orderBy.field;
                postData.orderDirection = query.orderDirection;
              }
              postData.brandSn = localStorage.getItem('filterCompany') !== null ? parseInt(localStorage.getItem('filterCompany')) : 0;
              postData.OpName = cookies.op_name;
              Axios.post(url, postData)
                .then((result) => {
                  resolve({
                    data:
                      result.data.productDetail !== null
                        ? result.data.productDetail
                        : [],
                    page: query.page,
                    totalCount: result.data.total,
                  });
                })
                .catch((result) => {
                  alert("後端異常");
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 100,
                  });
                });
            })
          }
          actions={(permission === readOnly) ?
            [] : [
              {
                icon: AddCircleOutlineIcon,
                tooltip: "新增",
                isFreeAction: true,
                onClick: (event) => productAdd(),
              },
              {
                icon: EditIcon,
                tooltip: "編輯",
                onClick: (event, rowData) =>
                  productEdit(rowData.sn, rowData.is_up_visible),
              },
              {
                icon: DeleteIcon,
                tooltip: "刪除",
                onClick: (event, rowData) => productDelete(rowData.sn, rowData.is_up_visible)
              },
              (rowData) => {
                if (!rowData.product_reviewed) {
                  return {
                    icon: AssignmentTurnedInIcon,
                    tooltip: "審核",
                    onClick: (event, rowData) => productReview(rowData.sn),
                  };
                }
              },
              /*
                （本身商品的狀態）在 DB table product_status 為 -> 下架：１／上架：２
                前端呼叫 product/detail/changeStatus api 執行時會自動轉換為相反值，
                方便於抓資料於前端表格欄位上呈現（商品下架欄位出現上架圖案、商品上架欄位出現下架圖案），才這樣設計。
              */
              (rowData) => {
                if (rowData.product_reviewed && rowData.product_status === 1) {
                  return {
                    icon: PublishIcon,
                    tooltip: "產品上架",
                    onClick: (event, rowData) =>
                      changeStatus(rowData.sn, rowData.product_status),
                  };
                }
              },
              (rowData) => {
                if (rowData.product_reviewed && rowData.product_status === 2) {
                  return {
                    icon: GetAppIcon,
                    tooltip: "產品下架",
                    onClick: (event, rowData) =>
                      changeStatus(rowData.sn, rowData.product_status),
                  };
                }
              },
            ]}
          options={{
            searchText: "",
            pageSize: 30,
            pageSizeOptions: [10, 20, 30, 40, 50],
            padding: "dense",
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#888",
              color: "#FFF",
              textAlign: "center",
            },
            rowStyle: (rowData) => ({
              backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
              color: rowData.is_up_visible ? "#FF44AA" : "",
              textAlign: "center",
            })
          }}
          onChangePage={handleChangePage}
        />
        <CompanyList
          open={companyOptionStatus}
          closeList={closeCompanyList}
          productBrandList={productBrandList}
          chooseCompany={chooseCompany}
        />
        {renderComponent(company)}
      </Box>
    </div>
  );
}
