import React from "react";
import axios from 'axios';
import Qs from "qs";
import { useState, useEffect, forwardRef } from "react";
import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";
import md5 from 'md5';
import { format } from "date-fns";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import Save from "@material-ui/icons/Save";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LoginDialogForCheckID from "../common/LoginDialogForCheckID.js";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  InputLabel,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Menu
} from "@material-ui/core";
import Axios from "axios";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <Save {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const getOpUserGroupBrandRelationURL = "/OP/user/getOpUserGroupBrandRelation";
const editOpUserGroupBrandRelationURL = "/OP/user/editOpUserGroupBrandRelation";

// Security Design
const getOperatorWatcherLogURL = "/OP/user/getOperatorWatcherLogBySn";
const insertOperatorWatcherLogURL = "/OP/user/insertOperatorWatcherLog";

// Log
const getOperatorLogURL = "/OP/user/log/list";
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";

export default function Operator() {
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1)
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }));

  const classes = useStyles();
  const [cookies] = useCookies(["op_name"]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [data, setData] = useState();
  const [groupLists, setGroupList] = useState([]);
  const [operatorWatcherLog, setOperatorWatcherLog] = useState([]);
  const [showAllMemberData, setShowAllMemberData] = useState(false);
  const [operatorLog, setOperatorLog] = useState([]);
  const [mimaAltered, setMimaAltered] = useState(false);

  // Security Design
  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  // Selected operator's info
  const [form, setForm] = useState({
    name: "",
    mima: "",
    group: "",
    groupSn: "",
    account: "",
    state: "",
    cell: "",
    email: "",
    employee_no: "",
    officeManagerEmpNo: "",
    office: "",
    sn: ""
  });

  // Group brand relationship list
  const [groupBrandRelationList, setGroupBrandRelationList] = useState([
    {
      BrandName: "",
      BrandSn: 0,
      IsMemberOfThisBrand: false
    }
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCheckListClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCheckListClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAll();
    getGroupList();
  }, []);

  // Security Design
  const getOperatorWatcherLogList = (sn) => {
    Axios.post(getOperatorWatcherLogURL, { Sn: sn })
      .then(function (response) {
        console.log(response);
        setOperatorWatcherLog(response.data.log != null ? response.data.log : [])
      }).catch(function (err) {
        console.log(err);
      });
  }

  const convertDataToHiddenCode = (val) => {
    let strLength = val.length;
    let data = '';
    if (strLength !== 0 && strLength !== undefined && strLength !== null) {
      for (let i = 0; i < strLength; i++) {
        data += '*';
      }
    }
    return data;
  }

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  }
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  }
  const handleToggleShowAllMemberData = () => {
    setShowAllMemberData(!showAllMemberData);
  }
  const handleMouseDownAllMemberData = (e) => {
    e.preventDefault();
  }
  const handleClickShowAllMemberData = () => {
    if (showAllMemberData) { // 正常已開啟狀態。
      handleToggleShowAllMemberData();
    } else if (showAllMemberData === false && form.sn === 0) { // 新增會員時。
      handleToggleShowAllMemberData();
    } else {  // 未開啟，故要先行登入。
      handleOpenLoginDialog();
    }
  }

  // Handler

  function handleContextChange(event) {
    const { name, value } = event.target;
    //console.log(event.target);

    if (name === "state") {
      setForm(prevState => ({
        ...prevState,
        [name]: event.target.checked
      }));
    } else {
      if (name === "mima") {
        setMimaAltered(true);
      }
      setForm(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  }

  function handleCheckChange(index) {
    if (index !== undefined) {
      console.log(groupBrandRelationList[index]);
      var tmpList = [...groupBrandRelationList];
      tmpList[index].IsMemberOfThisBrand = !tmpList[index].IsMemberOfThisBrand;
      setGroupBrandRelationList(tmpList);
    }
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    //console.log(form);
    setForm({
      name: "",
      mima: "",
      group: "",
      groupSn: "",
      account: "",
      state: "",
      cell: "",
      email: "",
      sn: ""
    });
    setOpen(false);
  }

  function handleEditClose() {
    //console.log(form);
    setEditOpen(false);
    setForm({
      name: "",
      mima: "",
      group: "",
      groupSn: "",
      account: "",
      state: "",
      cell: "",
      email: "",
      sn: ""
    });
  }

  function checkVaild(form) {
    var vaild = true;
    var errorMsg = "";

    if (form.name === "") {
      vaild = false;
      errorMsg = errorMsg + "名稱未填\n";
    }
    if (form.mima === "") {
      vaild = false;
      errorMsg = errorMsg + "密碼未填\n";
    }
    if (form.account === "") {
      vaild = false;
      errorMsg = errorMsg + "帳號未填\n";
    }
    if (form.email === "") {
      vaild = false;
      errorMsg = errorMsg + "信箱未填\n";
    }
    if (form.cell === "") {
      vaild = false;
      errorMsg = errorMsg + "手機未填\n";
    }
    if (form.groupSn === "") {
      vaild = false;
      errorMsg = errorMsg + "所屬群組未填\n";
    }

    return [vaild, errorMsg];
  }
  function groupChange(event) {
    var sn = parseInt(event.target.value);
    //console.log(sn);
    //console.log(sn !== null);
    if (isNaN(sn)) return null;
    var group = groupLists.find(group => {
      if (group.sn === sn) return group;
      return null;
    });
    setForm(prevState => ({
      ...prevState,
      groupSn: group.sn,
      groupName: group.name
    }));
  }

  function getAll() {
    axios
      .post(
        "/OP/user/getAll",
        Qs.stringify({
          test: "test"
        })
      )
      .then(res => {
        const list = res.data;
        //console.log(list);
        setData(list);
        setLoading(false);
        //console.log(data);
        //console.log(loading);
      });
  }
  function getGroupList() {
    axios
      .post(
        "/OP/user/getGroupList",
        Qs.stringify({
          test: "test"
        })
      )
      .then(res => {
        const list = res.data;
        //console.log(list);
        setGroupList(list);
        //console.log(data);
        //console.log(loading);
      });
  }
  function addNewUser() {
    var msg = checkVaild(form);

    // alertForEmpNoEmpty();

    if (msg[0] === true) {
      if (form.employee_no !== undefined && form.employee_no !== "") {
        axios
          .post(
            "/OP/user/addUser",
            {
              name: form.name,
              mima: md5(form.mima),
              groupSn: form.groupSn,
              account: form.account,
              state: form.state,
              cell: form.cell,
              email: form.email,
              employee_no: form.employee_no
            }
          )
          .then(res => {
            alert(res.data.msg);
            getAll();
            handleClose();
          })
          .catch(error => {
            if (error.response) {
              // 當狀態碼不在 vaildateStatus 設定的範圍時進入
              // 有 data / status / headers 參數可用
              alert(error.response.data.msg);
            } else if (error.request) {
              // 發送請求，但沒有接到回應
              // error.request is an instance of XMLHttpRequest in the browser
              // and an instance of http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // 在設定 request 時出錯會進入此
              console.log("Error", error.message);
            }
          });
      } else {
        alert("員工工號為必填!");
      }
    } else {
      alert(msg[1]);
    }
  }

  function editUser() {
    var msg = checkVaild(form);
    console.log(form.state);
    alertForEmpNoEmpty();
    if (msg[0] === true) {
      console.log(form);
      axios
        .post(
          "/OP/user/editUser",
          {
            name: form.name,
            mima: mimaAltered ? md5(form.mima) : form.mima,
            groupSn: form.groupSn,
            account: form.account,
            state: form.state,
            cell: form.cell,
            email: form.email,
            employee_no: form.employee_no,
            officeManagerEmpNo: form.officeManagerEmpNo,
            office: form.office,
            sn: form.sn,
            opName: cookies.op_name
          }
        )
        .then(res => {
          alert(res.data.msg);
          getAll();
          handleEditClose();
          editOpUserGroupBrandRelationList(form.sn);
        })
        .catch(error => {
          if (error.response) {
            // 當狀態碼不在 vaildateStatus 設定的範圍時進入
            // 有 data / status / headers 參數可用
            alert(error.response.data.msg);
          } else if (error.request) {
            // 發送請求，但沒有接到回應
            // error.request is an instance of XMLHttpRequest in the browser
            // and an instance of http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // 在設定 request 時出錯會進入此
            console.log("Error", error.message);
          }
        });
    } else {
      alert(msg[1])
    }
  }

  function deleteUser(rowData) {

    var DeleteSnList = [];

    // for (var i in rowData) {
    //   DeleteSnList.push(rowData[i].sn);
    //   //DeleteSnList[parseInt(i)+1] = parseInt(i)+1;
    // }
    //console.log(DeleteSnList)
    DeleteSnList.push(rowData.sn);

    axios
      .post(
        "/OP/user/deleteUser",
        {
          DeleteSnList: DeleteSnList,
          OpName: cookies.op_name
        }
      )
      .then(res => {
        alert(res.data.msg);
        getAll();
      })
      .catch(error => {
        if (error.response) {
          // 當狀態碼不在 vaildateStatus 設定的範圍時進入
          // 有 data / status / headers 參數可用
          alert(error.response.data.msg);
        } else if (error.request) {
          // 發送請求，但沒有接到回應
          // error.request is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // 在設定 request 時出錯會進入此
          console.log("Error", error.message);
        }
      });

    window.location.reload();
  }

  function getGroupBrandRelationList(operatorSn) {
    var postData = {};
    postData["operatorSn"] = operatorSn;
    Axios.post(getOpUserGroupBrandRelationURL, postData).then(function (res) {
      if (res.data !== undefined) {
        setGroupBrandRelationList(res.data);
      } else {
        alert("撈取所屬廠牌失敗");
        console.log(res);
      }
    }).catch(function (err) {
      console.log(err);
      alert("撈取所屬廠牌失敗");
    });
  }

  function editOpUserGroupBrandRelationList(operatorSn) {
    var postData = {};
    postData["operatorSn"] = operatorSn;
    postData["BrandSnList"] = [];
    for (var i = 0; i < groupBrandRelationList.length; ++i) {
      if (groupBrandRelationList[i].IsMemberOfThisBrand === true) {
        postData["BrandSnList"].push(groupBrandRelationList[i].BrandSn);
      }
    }
    Axios.post(editOpUserGroupBrandRelationURL, postData).then(function (res) {
      console.log(res);
    }).catch(function (err) {
      alert("更新所屬廠牌失敗");
      console.log(err);
    });
  }

  function isAdminGrp() {
    var gid = cookies.group_id === undefined || isNaN(cookies.group_id) ? 0 : parseInt(cookies.group_id);
    if (process.env.REACT_APP_MODE === "production" || process.env.REACT_APP_MODE === "intranet") {
      return gid === 1;
    } else {
      return gid === 1 || gid === 2;
    }
  }

  var groupSnMap = {};

  for (var group in groupLists) {
    var index = parseInt(groupLists[group].sn);

    groupSnMap[index] = groupLists[group];
  }

  function createTable(columns, datas) {
    return (
      <MaterialTable
        columns={columns}
        data={datas}
        options={{
          search: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          padding: "dense",
          sorting: false,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
          }),
        }}
      />
    );
  }

  function watcherLogTable(watcherLog) {
    const columns = [
      {
        title: "時間",
        field: "watchTime",
        render: (rowData) => datetimeStringFormat(rowData.watchTime),
      },
      {
        title: "查看人員",
        field: "operator",
        render: (rowData) => showAllMemberData ? rowData.operator : convertDataToHiddenCode(rowData.operator)
      },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          機敏資料查看紀錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, watcherLog)}</DialogContent>
      </Box>
    );
  }
  function logTable(memberLog) {
    const columns = [
      {
        title: "時間",
        field: "modifyDate",
        render: (rowData) => datetimeStringFormat(rowData.ModifyTime),
      },
      {
        title: "修改者",
        field: "operator",
        render: (rowData) => {
          // rowData.OperatorSn !== undefined && rowData.OperatorSn.Name !== undefined ? rowData.OperatorSn.Name : convertDataToHiddenCode(rowData.operator)
          if (rowData.OperatorSn !== undefined && rowData.OperatorSn.Name !== undefined) {
            return showAllMemberData ? rowData.OperatorSn.Name : convertDataToHiddenCode(rowData.OperatorSn.Name);
          } else {
            return "";
          }
        }
      },
      {
        title: "欄位",
        field: "field",
        render: (rowData) => {
          if (!fieldMapingList[rowData.Field]) return showAllMemberData ? rowData.Field : convertDataToHiddenCode(rowData.Field);
          return showAllMemberData ? fieldMapingList[rowData.Field] : convertDataToHiddenCode(fieldMapingList[rowData.Field]);
        },
      },
      {
        title: "修改前資料",
        field: "beforeValue",
        render: (rowData) => showAllMemberData ? rowData.BeforeValue : convertDataToHiddenCode(rowData.BeforeValue)
      },
      {
        title: "修改後資料",
        field: "afterValue",
        render: (rowData) => showAllMemberData ? rowData.AfterValue : convertDataToHiddenCode(rowData.AfterValue)
      },
      // { title: "動作", field: "action" },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          管理者資料更改記錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, memberLog)}</DialogContent>
      </Box>
    );
  }
  const fieldMapingList = {
    OperatorGroupSn: "群組",
    Name: "名稱",
    Id: "帳號",
    Pwd: "密碼",
    Employee_No: "員工編號",
    Cell: "行動電話",
    Email: "電子郵件",
    Enable: "是否啟用",
    IsDeleted: "是否已刪除"
  };
  function datetimeStringFormat(time) {
    // time is string
    if (time === "" || time === null) {
      return "";
    } else {
      return format(new Date(time), datetimeFormat);
    }
  }

  function alertForEmpNoEmpty() {
    if (form.employee_no === "") {
      alert("依據公司清查帳號規範, 員工工號為必填欄位, 非中華員工則請填入直屬中華窗口員工之工號, 謝謝!");
    }
  }

  return (
    <>
      {isAdminGrp() ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<AddBox></AddBox>}
          onClick={handleClickOpen}
        >
          新增使用者
        </Button>) : null}

      <MaterialTable
        title="帳號管理"
        icons={tableIcons}
        isLoading={loading}
        columns={[
          { title: "名稱", field: "name" },
          { title: "群組名稱", field: "groupName" },
          { title: "帳號", field: "account" },
          { title: "狀態", field: "state", type: "boolean" }
        ]}
        data={data}
        actions={[
          {
            icon: Edit,
            tooltip: "Edit User",
            onClick: (event, rowData) => {
              if (rowData.length > 1) {
                alert("請一次編輯一位使用者");
              } else {
                setEditOpen(true);
                setForm(rowData);
                getGroupBrandRelationList(rowData.sn);
                // console.log(props.memberDetail.sn);
                Axios.post(getOperatorLogURL, { operatorSn: rowData.sn }).then(function (modifyLogResponse) {
                  if (modifyLogResponse.data !== undefined) {
                    setOperatorLog(modifyLogResponse.data ? modifyLogResponse.data : [])
                  }
                }).catch(function (err) {
                  console.log(err);
                  alert("撈取會員資料操作紀錄後端異常");
                  setOperatorLog([]);
                });
                if (showAllMemberData && rowData.sn !== undefined && cookies.op_name !== undefined) {
                  Axios.post(insertOperatorWatcherLogURL, { operatorSn: rowData.sn, operatorId: cookies.op_name })
                    .then(function (response) {
                      console.log(response);
                      getOperatorWatcherLogList(rowData.sn);
                    }).catch(function (err) {
                      console.log(err);
                    });
                }
              }
            }
          },
          {
            icon: DeleteOutline,
            tooltip: "Delete User",
            onClick: (event, rowData) => {
              //console.log(rowData);
              if (isAdminGrp()) {
                deleteUser(rowData);
              } else {
                alert("使用者無此權限, 請聯絡管理者");
              }
            }
          }
        ]}
        options={{
          searchText: "",
          actionsColumnIndex: -1,
          //selection: true,
          paginationType: "stepped",
          showTextRowsSelected: false
        }}
      />
      {/* add dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">新增使用者</DialogTitle>
        <DialogContent>
          <DialogContentText>
            基本資料
          </DialogContentText>
          <TextField
            value={form.name}
            label="使用者名稱"
            name="name"
            required
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Name"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            label="帳號"
            value={form.account}
            name="account"
            required
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="poy_mif123"
            helperText="可使用26個英文字母(a-z)、阿拉伯數字(0-9)，以及 「_」底線符號組合；但英文字母部分必須是小寫，且不能有空白字元，例如：poy_mif123。"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />

          <TextField
            label="密碼"
            required
            value={form.mima}
            name="mima"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="abc1234"
            type="password"
            helperText="密碼長度至少八位，可使用任何英文字母(a-z)及阿拉伯數字(0-9)組合；英文字母部分大小寫不限，但是不能有空白字元，例如：abc1234。"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            label="連絡信箱"
            style={{ margin: 8 }}
            value={form.email}
            name="email"
            type="email"
            onChange={handleContextChange}
            placeholder="csp@com.tw"
            helperText="如登入IP不符合，系統會發認證碼至您信箱，輸入正確認證碼即可登入管理後台"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="standard-full-width"
            label="連絡手機"
            value={form.cell}
            name="cell"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Placeholder"
            helperText="如登入IP不符合，系統會發簡訊認證碼至您手機，輸入正確認證碼即可登入管理後台。"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="standard-full-width"
            label="員工工號"
            value={form.employee_no}
            name="employee_no"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Ex: 123456"
            helperText="非中華員工請協助填入直接中華電信主管工號"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="standard-full-width"
            label="單位主管員工工號"
            value={form.officeManagerEmpNo}
            name="officeManagerEmpNo"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Ex: 123456"
            helperText="非中華員工請協助填入所屬單位之中華電信主管工號"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="standard-full-width"
            label="帳號所屬單位"
            value={form.office}
            name="office"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Ex: 神腦特約 大里中興"
            helperText="非中華員工請協助填入所屬單位之中華電信主管工號"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          {isAdminGrp() ? (
            <TextField
              id="standard-select-currency"
              select
              label=" 所屬權限群組"
              style={{ margin: 8 }}
              fullWidth
              value={form.groupSn}
              onChange={groupChange}
              SelectProps={{
                native: true
              }}
            >
              <option></option>
              {groupLists.map((groupList, index) => {
                return (
                  <option key={index} value={groupList.sn}>
                    {groupList.name}
                  </option>
                );
              })}
            </TextField>) : (
            <TextField
              label=" 所屬權限群組"
              style={{ margin: 8 }}
              disabled
              fullWidth
              value={(groupSnMap[form.groupSn]) !== undefined ? groupSnMap[form.groupSn].name : "此群組不存在"}
            ></TextField>
          )}

          <InputLabel style={{ margin: 8 }}>帳號是否啟用</InputLabel>
          <Checkbox
            // checked={form.state}
            onChange={handleContextChange}
            value={form.state}
            style={{ margin: 8 }}
            name="state"
            color="primary"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={addNewUser} color="primary">
            確定
          </Button>
          <Button onClick={handleClose} color="primary">
            取消
          </Button>
        </DialogActions>
      </Dialog>

      {/* edit dialog */}
      <Dialog
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">編輯使用者</DialogTitle>
        <DialogContent>
          <DialogContentText>
            基本資料
          </DialogContentText>
          <TextField
            value={form.name}
            label="使用者名稱"
            name="name"
            required
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Name"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            label="帳號"
            value={form.account}
            name="account"
            disabled
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="poy_mif123"
            helperText="可使用26個英文字母(a-z)、阿拉伯數字(0-9)，以及 「_」底線符號組合；但英文字母部分必須是小寫，且不能有空白字元，例如：poy_mif123。"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />

          <TextField
            label="密碼"
            required
            value={form.mima}
            name="mima"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="abc1234"
            type="pwd"
            helperText="密碼長度至少八位，可使用任何英文字母(a-z)及阿拉伯數字(0-9)組合；英文字母部分大小寫不限，但是不能有空白字元，例如：abc1234。"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            label="連絡信箱"
            style={{ margin: 8 }}
            value={form.email}
            name="email"
            type="email"
            onChange={handleContextChange}
            placeholder="csp@com.tw"
            helperText="如登入IP不符合，系統會發認證碼至您信箱，輸入正確認證碼即可登入管理後台"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="standard-full-width"
            label="連絡手機"
            value={form.cell}
            name="cell"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Placeholder"
            helperText="如登入IP不符合，系統會發簡訊認證碼至您手機，輸入正確認證碼即可登入管理後台。"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="standard-full-width"
            label="員工工號"
            value={form.employee_no}
            name="employee_no"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Ex: 123456"
            helperText="非中華員工請協助填入直接中華電信主管工號"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="standard-full-width"
            label="單位主管員工工號"
            value={form.officeManagerEmpNo}
            name="officeManagerEmpNo"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Ex: 123456"
            helperText="非中華員工請協助填入所屬單位之中華電信主管工號"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="standard-full-width"
            label="帳號所屬單位"
            value={form.office}
            name="office"
            onChange={handleContextChange}
            style={{ margin: 8 }}
            placeholder="Ex: 神腦特約 大里中興"
            helperText="非中華員工請協助填入所屬單位之中華電信主管工號"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          {isAdminGrp() ? (
            <TextField
              id="standard-select-currency"
              select
              label=" 所屬權限群組"
              style={{ margin: 8 }}
              fullWidth
              value={form.groupSn}
              onChange={groupChange}
              SelectProps={{
                native: true
              }}
            >
              <option></option>
              {groupLists.map((groupList, index) => {
                return (
                  <option key={index} value={groupList.sn}>
                    {groupList.name}
                  </option>
                );
              })}
            </TextField>) : (
            <TextField
              label=" 所屬權限群組"
              style={{ margin: 8 }}
              disabled
              fullWidth
              value={(groupSnMap[form.groupSn]) !== undefined ? groupSnMap[form.groupSn].name : "此群組不存在"}
            ></TextField>
          )}
          <InputLabel style={{ margin: 8 }}>所屬廠牌</InputLabel>
          <Button aria-controls="simple-menu" aria-haspopup="true" variant="outlined" color="primary" onClick={handleCheckListClick}>
            Open Menu
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCheckListClose}
          >
            {groupBrandRelationList.map((groupBrandRelation, index) => {
              return (
                <MenuItem><Checkbox checked={groupBrandRelation.IsMemberOfThisBrand} onChange={() => handleCheckChange(index)}></Checkbox>{groupBrandRelation.BrandName}</MenuItem>
              );
            })}
          </Menu>
          <InputLabel style={{ margin: 8 }}>帳號是否啟用</InputLabel>
          <Checkbox
            disabled={!isAdminGrp()}
            checked={form.state}
            onChange={handleContextChange}
            value={form.state}
            style={{ margin: 8 }}
            name="state"
            color="primary"
          />
          {logTable(operatorLog)}
          {form.sn === 0 ? null : watcherLogTable(operatorWatcherLog)}
          <LoginDialogForCheckID
            open={openLoginDialog}
            handleToggleShowAllMemberData={handleToggleShowAllMemberData}
            handleCloseLoginDialog={handleCloseLoginDialog}
          >
          </LoginDialogForCheckID>
        </DialogContent>

        <DialogActions>
          <Button onClick={editUser} color="primary">
            確定
          </Button>
          <Button onClick={handleEditClose} color="primary">
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
