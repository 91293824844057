import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Meeting1 from "../assets/ProductOverview/all-11.jpg";
import Meeting2 from "../assets/ProductOverview/all-12.jpg";
import Meeting3 from "../assets/ProductOverview/all-13.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

//CSS
const StyledContextGrid = Styled(Grid)`
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 30px;
`
const StyledContextImage = Styled.img`
  max-width: 100%;
  border: 2px solid #28a8ea;
  border-radius: 10px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #28a8ea;
    margin-bottom: 20px;
  }
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 700;
    font-family: Microsoft JhengHei;
    text-align: left;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContainer = Styled(Grid)`
  && {
    margin-bottom: 120px;
  }
`

function renderSubContext(props) {
  const contexts = props;

  return contexts.map(context => (
    <>
      <StyledContextSubTitle color="secondary" variant="h5">
        {context.subTitle}
      </StyledContextSubTitle>
      <StyledContextSubContent paragraph>
        {context.subContext}
      </StyledContextSubContent>
    </>
  ));
}

function renderContext() {
  const contexts = [
    {
      title: "不論身在何處都能線上會面",
      context: [
        {
          subTitle: "開會永不缺席",
          subContext:
            "不論身處何處、使用何種最慣用工作的裝置，都能在線上輕鬆會面聯繫。 省去差旅時間和成本， 如此就能完成更多進度，讓專案保持在預算內。"
        },
        {
          subTitle: "不論身處何處都能會面",
          subContext:
            "在辦公室內、外出或是與客戶或供應商會面時都沒問題。 視訊會議不僅快速輕鬆， 還能有效處理公事。"
        },
        {
          subTitle: "隨時要開會時就能進行",
          subContext:
            "預先或是在行動間安排線上會議。 或者使用 Outlook 迅速加入進行中的會議。"
        }
      ],
      img: Meeting1
    },
    {
      title: "高效率、 高效用 並且智慧。 ",
      context: [
        {
          subTitle: "集合所有人",
          subContext:
            "HD 視訊可將您和多達 250 位使用者一次加入同一個會議中。 如此便能以智慧的方式從多處共用資訊，讓您掌握目標、行程和預算。"
        },
        {
          subTitle: "小組隨時掌握最新資訊",
          subContext:
            "透過單鍵螢幕共用和即時筆記記錄，所有人都能同步瞭解各項須知。"
        },
        {
          subTitle: "每個人對參與者瞭若指掌",
          subContext:
            "顯示所有會議參與者的相片或影片，讓您知道誰在發言、參與者的身分，以及他們所負責的事項。"
        }
      ],
      img: Meeting2
    },
    {
      title: "只需查看誰在線上， 接著連線即可。",
      context: [
        {
          subTitle: "傳送立即訊息",
          subContext:
            "若想要快速詢問問題或迅速傳送資訊，只需快速查看誰在線上、向對方傳送立即訊息，即可快速獲得回覆。"
        },
        {
          subTitle: "或是選擇語音或視訊",
          subContext:
            "強化一對一會議體驗。透過 PC 撥打電話給組織中的其他商務用 Skype 或 Skype 使用者，或其他採用商務用 Skype 或 Skype 之組織的使用者。。"
        },
        {
          subTitle: "一併與 Skype 使用者連線",
          subContext:
            "若要立即與其他使用 Skype 之組織中的同事或人員聯繫，可利用 IM 功能。"
        }
      ],
      img: Meeting3
    }
  ];

  return contexts.map(context => (
    <StyledContainer container>
      <StyledContextGrid item md={5} sm={12}>
        <StyledContextImage src={context.img}></StyledContextImage>
      </StyledContextGrid>
      <StyledContextGrid item md={7} sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextDivider/>
        {renderSubContext(context.context)}
      </StyledContextGrid>
    </StyledContainer>
  ));
}

export default function CenteredGrid(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {renderContext()}
      </Grid>
    </div>
  );
}
