// react
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

// material ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  NativeSelect,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";

import { format } from "date-fns";

// API
import Axios from "axios";

// notification
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";

// common
import { ConvertStr2DatetimeLocal } from "../../common/timeParsing.js";
import LoginDialogForCheckID from "../common/LoginDialogForCheckID.js";

// icon
import Alert from "@mui/material/Alert";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';

import { PageLoadingContext } from "../../context/PageLoadingContext";

const mdSize = 6;
const xsSize = 12;
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
const BrandSnGoogle = 2;

const fieldMapingList = {
  MemberSn: "會員",
  OrderSourceTypeSn: "訂單來源",
  OrderChargeTypeSn: "付款種類",
  OrderStatusSn: "訂單狀態",
  OrderNo: "訂單編號",
  PromotionEmployeeNo: "推廣員工(工號)",
  OperatorNote: "客服備註",
  ProductDetailSn: "商品資料",
  ProductDiscodeSn: "DSIS商品資料",
  DsisNo: "DSIS訂單存連單號碼",
  GoogleSubscriptionId: "Google商品訂閱碼",
  Domain: "網域名稱",
  GoogleCustomerId: "Google ID",
  GoogleCustomerMima: "Google PW",
  OrderDate: "訂單時間",
  OrderStartDate: "商品啟用時間",
  OrderDisSubscribeDate: "退訂日期",
  OrderLastChargeDate: "最後一次成功扣款時間",
  Quantity: "數量",
  AccumulatedPrice: "累計出帳金額",
  MS_SubscribeNo: "微軟商品訂閱碼",
  ContractNoK1: "契約編號 k1 碼",
};

const orderDetailDefault = {
  orderNo: "",
  orderDate: "",
  dsisNo: "",
  orderChargeTypeSn: "",
  orderSourceTypeSn: "",
  dsisCounter: "",
  orderStatusSn: "",
  quantity: "1",
  accumulatedPrice: "",
  orderDisSubscribeDate: "",
  promotionEmployeeNo: "",
  operatorNote: "",
  contractNoK1: "",
  billingCycle: "",
  googleAlternateEmail: "",
  googleSKU: "",
  hd: ""
};

const memberDefault = {
  hn: "",
  name: "",
  contactName: "",
  companyNo: "",
  telMorning: "",
  address: "",
  zip: "",
  email: "",
  cell: "",
};

const registerDefault = {
  gwsSubscriptionId: "",
  gwsId: "",
  orderStartDate: "2020-03-09",
  gwsMima: "",
  domain: "",
};

const chargeDefault = {
  accountingDate: "",
  orderChargeStatusSn: "",
  totalPrice: "",
  aaaTag: "",
};

const productDefault = [
  {
    no: "",
    pid: "",
    name: "",
    plan: "",
    orderServiceStatusSn: "",
    quantity: "",
    price: "",
  },
];

const maintenanceDetailDefault = {
  fiveEightCredit: 0,
  fiveEightCreditPrice: 0,
  nonFiveEightCredit: 0,
  nonFiveEightCreditPrice: 0,
};

// const changeToPlanTypeDefault = {
//   planName: "TRIAL",
//   name: "試用產品(不收費)"
// };

// API
const getOrderURL = "/api/order/getOrderDetail";
const getOrderLogURL = "/api/order/getOrderManagementLogBySn";
const getOrderStatusURL = "/api/order/getOrderStatusList";
const saveOrderURL = "/api/order/modifyOrderDetail";
const sendRegisterMailURL = "/api/mailLog/sendRegisterMail";
const getOutSourceMaintenanceInfoURL = "/api/order/getOutSourceMaintenanceInfo";
const getProductDetailListURL = "/api/product/detail/list/queryProductListBySKU";
const changePlanAPI = "/api/v0/gws/customers/subscriptions/changePlan";
const startPaidServiceAPI = "/api/v0/gws/startPaidService";
const getRenewalTypeListURL = "/api/order/google/getRenewalType";
const changeRenewalSettingsURL = "/api/v0/gws/customers/subscriptions/changeRenewalSettings";
// const getAllMigrationErrorMsgsURL = "/api/order/getAllMigrationErrorMsgs";
const updateOrCancelQCAccountingPriceChangeURL = "/api/order/updateOrCancelQCAccountingPriceChange";
const insertOrderManagementLogURL = "/api/log/insertOrderManagementLog";
const getOrderOtherServiceInfoURL = "/api/order/getOrderOtherServiceInfo";
// const mpnCreateURL = "/api/v0/mpnCreate";
// const mpnCheckDomainByDomainURL = "/api/v0/mpnCheckDomainByDomain";
const getAmEmailListURL = "/api/order/getAmEmailList";

// Security Design
const getOrderWatcherLogURL = "/api/order/getOrderWatcherLogBySn";
const insertOrderWatcherLogURL = "/api/order/insertOrderWatcherLog";

export default function DetailModalOfGoogle(props) {
  const { setShowPageLoading } = useContext(PageLoadingContext);

  const renewalTypeListDefault = [
    {
      sn: 1,
      name: "自動續約年約年繳",
      value: "AUTO_RENEW_YEARLY_PAY"
    }, {
      sn: 2,
      name: "自動續約年約月繳",
      value: "AUTO_RENEW_MONTHLY_PAY"
    }, {
      sn: 3,
      name: "以目前帳號內使用者數量續約年約年繳",
      value: "RENEW_CURRENT_USERS_YEARLY_PAY"
    }, {
      sn: 4,
      name: "以目前帳號內使用者數量續約年約月繳",
      value: "RENEW_CURRENT_USERS_MONTHLY_PAY"
    }, {
      sn: 5,
      name: "改以彈性合約續約",
      value: "SWITCH_TO_PAY_AS_YOU_GO"
    }, {
      sn: 6,
      name: "取消訂閱",
      value: "CANCEL"
    }
  ];

  const isMainChargeOptions = [
    {
      name: "普通一次",
      value: false
    },
    {
      name: "常規補報",
      value: true
    }
  ];

  const [cookies] = useCookies(["op_name", "session"]);
  const { enqueueSnackbar } = useSnackbar();

  const [modalStatus, setModalStatus] = useState(false);
  const [keepModal, setKeepModal] = useState(false);

  // Security Design
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showAllMemberData, setShowAllMemberData] = useState(false);
  const [orderWatcherLog, setOrderWatcherLog] = useState([]);

  // Data
  const [orderSn, setOrderSn] = useState(0);
  const [orderDetail, setOrderDetail] = useState(orderDetailDefault);
  // eslint-disable-next-line no-unused-vars
  const [orderDetailOriginal, setOrderDetailOriginal] = useState(orderDetailDefault);
  const [orderRenewalSn, setOrderRenewalSn] = useState(0);
  const [member, setMember] = useState(memberDefault);
  const [register, setRegister] = useState(registerDefault);
  const [charge, setCharge] = useState(chargeDefault);
  const [product, setProduct] = useState(productDefault);
  const [orderLog, setOrderLog] = useState([]);
  const [maintenanceDetail, setMaintenanceDetail] = useState(
    maintenanceDetailDefault
  );
  const [orderStatusList, setOrderStatusList] = useState({});
  //const [billingCycle, setBillingCycle] = useState("");
  const [domainError, setDomainError] = useState(false);
  // const [operatorNote, setOperatorNote] = useState("");
  const [enterErrorMessageOfDomain, setEnterErrorMessageOfDomain] = useState("Domain 已驗證。");

  // For trial plan
  const [isTrialPlan, setIsTrialPlan] = useState(false);
  // const [changeToPlanType, setChangeToPlanType] = useState(changeToPlanTypeDefault);
  const [productListBySKU, setProductListBySKU] = useState([]);
  const [changeToPlanProductDetailSn, setChangeToPlanProductDetailSn] = useState(0);
  const [renewalTypeList, setRenewalTypeList] = useState(renewalTypeListDefault);
  const [otherServiceInfo, setOtherServiceInfo] = useState([]);
  const [updatingQuantity, setUpdatingQuantity] = useState(
    orderDetail.quantity
  );

  // For operation log
  const [bufferedOrderOtherServiceInfo, setBufferedOrderOtherServiceInfo] = useState([]);
  const [bufferedMaintenanceDetail, setBufferedMaintenanceDetail] = useState(maintenanceDetailDefault);
  const [originalOrderRenewalSn, setOriginalOrderRenewalSn] = useState(0);

  // AM email list
  const [amEmailList, setAmEmailList] = useState([]);
  const [bufferedAmEmailList, setBufferedAmEmailList] = useState([]);

  const closeModal = props.closeModal;

  useEffect(() => {
    if (props.open) {
      // console.log("DetailModal effect");
      setOrderDetail(orderDetailDefault);
      setMember(memberDefault);
      setRegister(registerDefault);
      setCharge(chargeDefault);
      // setProduct(productDefault); // 2023/11/20 微軟「年繳」開放可以改商品方案
      setOrderSn(props.sn);
      getOrder(props.sn);
      // getProductDetailList();
      Axios.get(getRenewalTypeListURL)
        .then((response) => {
          console.table(response.data);
          // setRenewalTypeList(response.data);
          setRenewalTypeList(response.data);
        })
        .catch((err) => {
          console.log("Function getRenewalTypeList() : " + err);
        });
    }
    setModalStatus(props.open);
  }, [props.open/*, getProductDetailList*/, props.sn]);

  // Security Design
  useEffect(() => {
    if (showAllMemberData && props.sn !== undefined && cookies.op_name !== undefined) {
      Axios.post(insertOrderWatcherLogURL, { orderSn: props.sn, operatorId: cookies.op_name })
        .then(function (response) {
          console.log(response);
          Axios.post(getOrderWatcherLogURL, { Sn: props.sn })
            .then(function (response) {
              setOrderWatcherLog(response.data.log != null ? response.data.log : [])
            }).catch(function (err) {
              console.log(err);
            });
        }).catch(function (err) {
          console.log(err);
        });
    }
  }, [showAllMemberData, props.sn, cookies.op_name])

  useEffect(() => {
    setUpdatingQuantity(orderDetail.quantity);
  }, [orderDetail.quantity]);

  const handleChangeOrderDetail = (event) => {
    var { name, value, type } = event.target;
    if (name === "googleRenewalTypeSn") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
    } else {
      if (type === "select-one") {
        value = parseInt(value);
      }
      if (type === "number") {
        value = parseInt(value);
        value = value > 2147483647 ? 2147483647 : value;
        value = value < 1 ? 1 : value;
      }
      if (name === "orderDisSubscribeDate") {
        // convert local datetime to string with timezone
        if (value !== "") {
          value = new Date(value).toISOString();
        }
      }
    }
    setOrderDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeOrderRenewal = (event) => {
    // eslint-disable-next-line no-unused-vars
    var { name, value, type } = event.target;
    value = parseInt(value);
    value = value > 2147483647 ? 2147483647 : value;
    value = value < 1 ? 1 : value;

    setOrderRenewalSn(value);
  };

  const handleChangeMaintenanceDetail = (event) => {
    var { name, value, type } = event.target;
    if (type === "number") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
    }
    setMaintenanceDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeAmEmailList = (i, e) => {
    var { name, value, type } = e.target;
    let newItemValues = [...amEmailList];
    if (type === "number") {
      value = parseInt(value);
      value = isNaN(value) ? 0 : value;
      value = value > 2147483647 ? 2147483647 : value;
    }
    newItemValues[i][name] = value;
    setAmEmailList(newItemValues);
  };

  const handleChangeRegister = (event) => {
    var { name, value, type } = event.target;
    if (type === "select-one") {
      value = parseInt(value);
    }

    // check domain
    const regexDomain = /^[A-Za-z0-9_.\-\b]+$/;
    if (name === "domain") {
      if (!regexDomain.test(value) && value !== "") {
        return;
      }
    }

    if (name === "orderStartDate") {
      if(value !== "") {
        // convert local datetime to string with timezone
        value = new Date(value).toISOString();
      }
    }

    setRegister((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log(register);
  };

  const setKeepModalStatus = (isKeep) => {
    setKeepModal(isKeep);
  };

  // Security Design
  const convertDataToHiddenCode = (val) => {
    let strLength = val.length;
    let data = '';
    if (strLength !== 0 && strLength !== undefined && strLength !== null) {
      for (let i = 0; i < strLength; i++) {
        data += '*';
      }
    }
    return data;
  }
  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  }
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  }
  const handleToggleShowAllMemberData = () => {
    setShowAllMemberData(!showAllMemberData);
  }
  const handleMouseDownAllMemberData = (e) => {
    e.preventDefault();
  }
  const handleClickShowAllMemberData = () => {
    if (showAllMemberData) { // 正常已開啟狀態。
      handleToggleShowAllMemberData();
    } else {  // 未開啟，故要先行登入。
      handleOpenLoginDialog();
    }
  }

  const getOrder = (sn) => {
    // console.log(sn)
    const getOrderList = Axios.post(getOrderURL, {
      Sn: sn,
      productBrandSn: BrandSnGoogle,
    });
    const getOrderLogList = Axios.post(getOrderLogURL, { Sn: sn });
    const getOutSourceMaintenanceInfo = Axios.post(
      getOutSourceMaintenanceInfoURL,
      { Sn: sn }
    );
    const getAmEmailList = Axios.post(getAmEmailListURL, { OrderSn: sn })

    // Security Design
    const getOrderWatcherLogList = Axios.post(getOrderWatcherLogURL, { Sn: sn });

    // Google 的訂單狀態
    const getOrderStatusList = Axios.post(getOrderStatusURL, {
      brandSn: BrandSnGoogle,
    });

    // Get other service one time charging info
    const getOtherServiceInfo = Axios.post(getOrderOtherServiceInfoURL, { Sn: sn });

    Axios.all([
      getOrderList,
      getOrderLogList,
      getOrderWatcherLogList,
      getOutSourceMaintenanceInfo,
      getOrderStatusList,
      getOtherServiceInfo,
      getAmEmailList
    ]).then(
      Axios.spread(
        (
          orderResponse,
          orderLogResponse,
          orderWatcherLogResponse,
          outSourceMaintenanceInfoResponse,
          orderStatusResponse,
          otherServiceInfoResponse,
          getAmEmailListResponse
        ) => {
          var data = orderResponse.data;
          setOrderDetail(data.orderDetail);
          setOrderDetailOriginal(data.orderDetail);
          if (data !== undefined && data.orderDetail !== undefined) {
            console.log(data.orderDetail);
            console.log(data.orderDetail.googleRenewalType);
            setOrderRenewalSn(data.orderDetail.googleRenewalType.Sn);
            setOriginalOrderRenewalSn(data.orderDetail.googleRenewalType.Sn);
            if (data.orderDetail.orderChargeTypeSn === "試用產品(不收費)") {
              setIsTrialPlan(true);
            } else {
              setIsTrialPlan(false);
            }
            var postData = {};
            postData["GoogleSKU"] = data.orderDetail.googleSKU;
            Axios.post(getProductDetailListURL, postData).then(function (productList) {
              setProductListBySKU(productList.data);
            }).catch(function (err) {
              alert(err);
              console.log(err);
            });
          }
          if (data.orderDetail.waitForActivatingHn === true) {
            setKeepModal(true);
          } else {
            setKeepModal(false);
          }
          setMember(data.member);
          setRegister(data.register);
          if (data.charge) {
            setCharge(data.charge);
          } else {
            setCharge(chargeDefault);
          }
          //setBillingCycle(data.orderDetail.billingCycle);

          // 為了用表單呈現，才換成array
          var productList = [];
          productList.push(data.product);
          setProduct(productList);
          setOrderLog(
            orderLogResponse.data.log != null ? orderLogResponse.data.log : []
          );
          setMaintenanceDetail(outSourceMaintenanceInfoResponse.data);
          setBufferedMaintenanceDetail(outSourceMaintenanceInfoResponse.data);
          setOrderWatcherLog(
            orderWatcherLogResponse.data.log != null ? orderWatcherLogResponse.data.log : [],
          )

          setOtherServiceInfo(
            otherServiceInfoResponse.data.list != null ? otherServiceInfoResponse.data.list : [],
          )

          // setBufferedOrderOtherServiceInfo(
          //   otherServiceInfoResponse.data.list != null ? otherServiceInfoResponse.data.list : [],
          // )

          if (getAmEmailListResponse.data.amEmailList != null) {
            var amEmailObjList = [];
            for (
              var i = 0;
              i < getAmEmailListResponse.data.amEmailList.length;
              ++i
            ) {
              var tmpAMEmailObj = {};
              tmpAMEmailObj["amEmail"] =
                getAmEmailListResponse.data.amEmailList[i];
              amEmailObjList.push(tmpAMEmailObj);
            }
            setAmEmailList(amEmailObjList);
            setBufferedAmEmailList(amEmailObjList);
          } else {
            setAmEmailList([]);
            setBufferedAmEmailList([]);
          }

          // Google 的訂單狀態清單
          setOrderStatusList(orderStatusResponse.data.dataList);
        }
      )
    );
  };
  // 有 Google domain api 驗證。
  const gwsCheckDomainByDomain = () => {
    if (register.domain !== "") {
      Axios.get(`/api/v0/gws/customers/${register.domain}`)
        .then((response) => {
          console.log(response);
          setEnterErrorMessageOfDomain("Domain 已被使用。");
          setDomainError(true);
        })
        .catch((error) => {
          console.table(error);
          if (error.response === undefined) {
            setEnterErrorMessageOfDomain("未取得 token 或其他問題。");
            setDomainError(true);
          } else if (error.response.status === 404) {
            setEnterErrorMessageOfDomain("Domain 可使用。");
            setDomainError(false);
          } else {
            setEnterErrorMessageOfDomain("後端發生未知問題。");
            setDomainError(true);
          }
        });
    }
  };

  function getOtherServiceInfoListBeforeEdit() {
    // Newly added
    var beforeChangeLogMsg = "";
    for (var i in bufferedOrderOtherServiceInfo) {
      beforeChangeLogMsg += "名稱:" + bufferedOrderOtherServiceInfo[i].serviceName + ", 價格:" + bufferedOrderOtherServiceInfo[i].servicePrice + "  ";
      if (bufferedOrderOtherServiceInfo[i].isMainCharge !== undefined) {
        beforeChangeLogMsg += "報帳類別:" + (bufferedOrderOtherServiceInfo[i].isMainCharge ? "常規補報" : "普通一次") + "  ";
      }
      if (bufferedOrderOtherServiceInfo[i].chargeDate !== undefined) {
        beforeChangeLogMsg += "報帳時間:" + bufferedOrderOtherServiceInfo[i].chargeDate + "  ";
      }
    }
    console.log(beforeChangeLogMsg);
    return beforeChangeLogMsg;
  }

  function getOtherServiceInfoListAfterEdit() {
    // Newly added
    var changeLogMsg = "";
    for (var i in otherServiceInfo) {
      changeLogMsg += "名稱:" + otherServiceInfo[i].serviceName + ", 價格:" + otherServiceInfo[i].servicePrice + "  ";
      if (otherServiceInfo[i].isMainCharge !== undefined) {
        changeLogMsg += "報帳類別:" + (otherServiceInfo[i].isMainCharge ? "常規補報" : "普通一次") + "  ";
      }
      if (otherServiceInfo[i].chargeDate !== undefined) {
        changeLogMsg += "報帳時間:" + otherServiceInfo[i].chargeDate + "  ";
      }
    }
    console.log(changeLogMsg);
    return changeLogMsg;
  }

  function getAmEmailListBeforeEdit() {
    // Newly added
    var changeLogMsg = "";
    for (var i in bufferedAmEmailList) {
      changeLogMsg += "Email:" + bufferedAmEmailList[i].amEmail + "  ";
    }
    return changeLogMsg;
  }
  function getAmEmailListAfterEdit() {
    // Newly added
    var changeLogMsg = "";
    for (var i in amEmailList) {
      changeLogMsg += "Email:" + amEmailList[i].amEmail + "  ";
    }
    return changeLogMsg;
  }

  function getMaintenanceDetailInfoDiffForChangeLog() {
    // bufferedMaintenanceDetail
    // maintenanceDetail
    var changeLogObj = {};
    changeLogObj["before"] = "";
    changeLogObj["after"] = "";
    if (bufferedMaintenanceDetail.fiveEightCredit !== undefined && maintenanceDetail.fiveEightCredit !== undefined) {
      changeLogObj["before"] += " 5x8 credit: " + bufferedMaintenanceDetail.fiveEightCredit + "點";
      if (bufferedMaintenanceDetail.fiveEightCredit !== maintenanceDetail.fiveEightCredit) {
        changeLogObj["after"] += " 5x8 credit: " + maintenanceDetail.fiveEightCredit + "點";
      }
    }
    if (bufferedMaintenanceDetail.fiveEightCreditPrice !== undefined && maintenanceDetail.fiveEightCreditPrice !== undefined) {
      changeLogObj["before"] += " 5x8 price: " + bufferedMaintenanceDetail.fiveEightCreditPrice + "元";
      if (bufferedMaintenanceDetail.fiveEightCreditPrice !== maintenanceDetail.fiveEightCreditPrice) {
        changeLogObj["after"] += " 5x8 price: " + maintenanceDetail.fiveEightCreditPrice + "元";
      }
    }
    if (bufferedMaintenanceDetail.nonFiveEightCredit !== undefined && maintenanceDetail.nonFiveEightCredit !== undefined) {
      changeLogObj["before"] += " non-5x8 credit: " + bufferedMaintenanceDetail.nonFiveEightCredit + "點";
      if (bufferedMaintenanceDetail.nonFiveEightCredit !== maintenanceDetail.nonFiveEightCredit) {
        changeLogObj["after"] += " non-5x8 credit: " + maintenanceDetail.nonFiveEightCredit + "點";
      }
    }
    if (bufferedMaintenanceDetail.nonFiveEightCreditPrice !== undefined && maintenanceDetail.nonFiveEightCreditPrice !== undefined) {
      changeLogObj["before"] += " non-5x8 price: " + bufferedMaintenanceDetail.nonFiveEightCreditPrice + "元";
      if (bufferedMaintenanceDetail.nonFiveEightCreditPrice !== maintenanceDetail.nonFiveEightCreditPrice) {
        changeLogObj["after"] += " non-5x8 price: " + maintenanceDetail.nonFiveEightCreditPrice + "元";
      }
    }
    return changeLogObj;
  }

  // const productPlanList = [
  //   {
  //     planName: "ANNUAL_MONTHLY_PAY",
  //     name: "年約月繳"
  //   },
  //   {
  //     planName: "ANNUAL_YEARLY_PAY",
  //     name: "年約年繳"
  //   },
  //   {
  //     planName: "FLEXIBLE",
  //     name: "彈性方案"
  //   },
  //   {
  //     planName: "TRIAL",
  //     name: "試用產品(不收費)"
  //   }
  // ]

  // const renderPlanTypeOption = () => {
  //   return (
  //     productPlanList.map((plan, index) => {
  //       return (
  //         <option
  //           key={index}
  //           value={plan.planName}
  //         >
  //           {plan.name}
  //         </option>
  //       )
  //     })
  //   );
  // };

  // const handleSwitchChangingPlanType = (e) => {
  //   const { Name, PlanName } = e.target;
  //   var newPlan = {
  //     planName: PlanName,
  //     name: Name
  //   };
  //   setChangeToPlanType(newPlan);
  // };

  const renderProductOption = () => {
    if (productListBySKU !== undefined && productListBySKU.length !== undefined && productListBySKU.length !== 0) {
      return (
        productListBySKU.map((product, index) => {
          return (
            <option
              key={index}
              value={product.sn}
            >
              {product.product}
              {product.plan !== "" ? " - " + product.plan : ""}
              {product.discode !== "" ? " - " + product.discode : ""}
              {product.price_special !== ""
                ? ", 售價: " + product.price_special + "元"
                : ""}
            </option>
          )
        })
      );
    } else {
      return (<></>);
    }
  };

  const handleSwitchChangingPlanProductOption = (e) => {
    const { value } = e.target;

    setChangeToPlanProductDetailSn(parseInt(value));
  };

  const handleCommitChangePlan = (
    sn = 0,
    productDetailSn = 0,
    quantity = updatingQuantity
  ) => {
    setShowPageLoading(true);
    var postData = {};

    postData["OrderSn"] = sn;
    postData["ProductDetailSn"] = productDetailSn;
    postData["Quantity"] = quantity;

    Axios.post(changePlanAPI, postData)
      .then(function (response) {
        // alert("轉換方案成功");
        enqueueSnackbar("轉換方案成功!", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        getOrder(sn);
        console.log(response.data);
      })
      .catch(function (err) {
        // alert("轉換方案失敗");
        enqueueSnackbar("轉換方案失敗!", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        console.log(err);
      })
      .finally(() => {
        setShowPageLoading(false);
      });
  };

  const handleStartPaidService = () => {
    var postData = {};
    postData["orderSn"] = orderSn;
    console.log(postData);
    Axios.post(startPaidServiceAPI, postData).then(function (res) {
      console.log(res);
      alert("付費方案啟用成功!");
    }).catch(function (err) {
      console.log(err);
      alert("付費方案啟用失敗!");
    });
  };

  const handleConfirmChangeRenewalSettings = () => {
    if (window.confirm("確定要更新到期續約方案?")) {
      var postData = {};
      postData.orderSn = orderSn;
      postData.renewalTypeSn = orderRenewalSn;

      console.log(postData);

      Axios.post(changeRenewalSettingsURL, postData).then(function (res) {
        alert("到期續約方案更換成功");
        console.log(res);
        var originalRenewalValue = "";
        var renewalValue = "";
        // console.log(renewalTypeList[originalOrderRenewalSn - 1].name);
        // console.log(renewalTypeList[orderRenewalSn - 1].name);
        for (var i = 0; i < renewalTypeList.length; ++i) {
          if (renewalTypeList[i].sn === originalOrderRenewalSn) {
            originalRenewalValue = renewalTypeList[i].name;
            if (renewalTypeList[i].sn === orderRenewalSn) {
              renewalValue = renewalTypeList[i].name;
            }
          } else if (renewalTypeList[i].sn === orderRenewalSn) {
            renewalValue = renewalTypeList[i].name;
          }
        }
        insertOrderManagementLog("年度續訂設置", originalRenewalValue, renewalValue);
      }).catch(function (err) {
        var message = "到期續約方案更換失敗!";
        console.log(err);
        if (err.response) {
          if (err.response.data) {
            alert(message + "\n" + err.response.data.error);
          }
          if (err.response.status) {
            console.log(err.response.status);
          }
          if (err.response.headers) {
            console.log(err.response.headers);
          }
        } else {
          alert(message);
        }
      });
    }
  };

  const updateQcPrice = (set = false) => {
    var postData = {};

    var orderNo = orderDetail.orderNo;
    postData["orderNo"] = orderNo;
    postData["set"] = set;
    Axios.post(updateOrCancelQCAccountingPriceChangeURL, postData).then(function (res) {
      console.log(res);
      alert("更新成功!");
      insertOrderManagementLog("更新品管金額", "", (set ? "設定" : "取消") + "更新品管金額");
    }).catch(function (err) {
      console.log(err);
      alert("更新失敗!");
      insertOrderManagementLog("更新品管金額", "", (set ? "設定" : "取消") + "更新品管金額失敗");
    });
  }

  //解釋  getTodayDate函數：取得今天的日期並格式化為YYYY-MM-DD格式，這是日期選擇器的標準格式。
  //TextField組件的InputProps：透過設定inputProps的max屬性為今天的日期，禁用未來的日期。
  //這樣一來，當使用者在日期選擇器中選擇日期時，未來的日期將不可選，且會被反灰顯示。2024/05/27 BY WILLY TSAI
  // 獲取今天的日期
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  function insertOrderManagementLog(field = "", beforeValue = "", afterValue = "") {
    const isSuccess = true;
    var postData = {};
    postData["OpName"] = cookies.op_name;
    postData["OrderNo"] = orderDetail.orderNo;
    postData["Field"] = field;
    postData["BeforeValue"] = beforeValue;
    postData["AfterValue"] = afterValue;

    Axios.post(insertOrderManagementLogURL, postData).then(function (res) {
      console.log(res);
      return isSuccess;
    }).catch(function (err) {
      console.log(err);
      return false;
    });
  };

  const detailFieldList = [
    createLabel("訂單編號:", orderDetail.orderNo),
    createLabel("訂單時間:", datetimeStringFormat(orderDetail.orderDate)),
    createLabel("DSIS單號:", orderDetail.dsisNo),
    createLabel("DSIS優惠代碼:", orderDetail.productDiscode),
    createLabel("收費方式:", orderDetail.orderChargeTypeSn),
    // createLabel("收費方式:", billingCycle !== "" ? billingCycle : orderDetail.orderChargeTypeSn),
    // createLabel("收費方式:",
    //   <FormControl fullWidth>
    //     <NativeSelect
    //       id="demo-customized-select-native"
    //       value={orderDetail.orderChargeTypeSn}
    //       name={"orderChargeTypeSn"}
    //       onChange={handleChangeOrderDetail}
    //       disabled={keepModal}
    //       required
    //     >
    //       <option></option>
    //       {Object.keys(billingCycleList).map((billingCycle, index) => {
    //         return (
    //           <option key={index} value={orderStatusKey} disabled={false}>
    //             {orderStatusList[orderStatusKey]}
    //           </option>
    //         );
    //       })}
    //     </NativeSelect>
    //     <Typography variant="caption" display="block" gutterBottom>
    //       如改為微軟服務啟用時，請記得填寫啟用日期，以確保如期出帳
    // </Typography>
    //   </FormControl>,
    // ),
    createLabel("受理來源:", orderDetail.orderSourceTypeSn),
    // createLabel("訂單狀態:", orderDetail.orderStatusSn),
    createLabel(
      "訂單狀態",
      <FormControl fullWidth>
        <NativeSelect
          id="demo-customized-select-native"
          value={orderDetail.orderStatusSn}
          name={"orderStatusSn"}
          onChange={handleChangeOrderDetail}
          disabled={keepModal}
          required
        >
          <option></option>
          {Object.keys(orderStatusList).map((orderStatusKey, index) => {
            return (
              <option key={index} value={orderStatusKey} disabled={false}>
                {orderStatusList[orderStatusKey]}
              </option>
            );
          })}
        </NativeSelect>
        <Typography variant="caption" display="block" gutterBottom>
          如改為Google服務啟用時，請記得填寫啟用日期，以確保如期出帳
        </Typography>
      </FormControl>
    ),
    // orderDetail.quantity
    createLabel(
      "帳號數量:",
      <div>
        {register.googleSubscriptionId === "" && orderDetail.dsisNo === "" ? (
          <TextField
            fullWidth
            name={"quantity"}
            type="number"
            value={orderDetail.quantity}
            onChange={handleChangeOrderDetail}
            disabled={keepModal}
          />
        ) : (
          orderDetail.quantity
        )}
      </div>
    ),
    createLabel("累計出帳金額:", orderDetail.accumulatedPrice),
    createLabel(
      "推廣員工代號:",
      <TextField
        fullWidth
        name={"promotionEmployeeNo"}
        autoComplete="off"
        value={orderDetail.promotionEmployeeNo}
        onChange={handleChangeOrderDetail}
        disabled={keepModal}
      />
    ),
    createLabel(
      "退訂日期：",
      <TextField
        fullWidth
        type="datetime-local"
        helperText={"空白為下個月自動續訂"}
        name={"orderDisSubscribeDate"}
        value={ConvertStr2DatetimeLocal(orderDetail.orderDisSubscribeDate)}
        disabled={keepModal}
        onChange={handleChangeOrderDetail}
        inputProps={{ step: 1 }} // for datetime to show second
      />
    ),
    createLabel(
      "契約編號 k1 碼：",
      <TextField
        fullWidth
        autoComplete="off"
        name={"contractNoK1"}
        value={orderDetail.contractNoK1}
        onChange={handleChangeOrderDetail}
        disabled={keepModal}
      />
    ),
    createLabel(
      "MKT會員HD碼:",
      <TextField
        fullWidth
        margin="dense"
        // label="MKT會員HD碼"
        name="hd"
        value={orderDetail.hd}
        onChange={handleChangeOrderDetail}
        autoComplete="off"
        required
      />
    ),
    createLabel(
      "備註：",
      <TextField
        fullWidth
        multiline
        rows={5}
        variant="outlined"
        name={"operatorNote"}
        value={orderDetail.operatorNote}
        disabled={keepModal}
        onChange={handleChangeOrderDetail}
      />
    ),
    createLabel(
      "年度續訂設置：",
      <FormControl fullWidth>
        <NativeSelect
          name="googleRenewalTypeSn"
          value={orderRenewalSn}
          onChange={handleChangeOrderRenewal}
          disabled={keepModal}
        >
          <option value=""></option>
          {renewalTypeList.map((renewalInfo, index) => {
            return (
              <option key={index} value={renewalInfo.sn}>
                {renewalInfo.name}
              </option>
            );
          })}
        </NativeSelect>
        <br />
        <Button
          name="submitNewRenewalChoise"
          onClick={() => handleConfirmChangeRenewalSettings()}
          color="primary"
          style={{
            backgroundColor: "#E7EFC6"
          }}
        >確定變更方案</Button>
      </FormControl>
    ),
  ];

  const memberFieldList = [
    createLabel("HN帳號:", member.hn),
    createLabel("公司/客戶名稱:", member.name),
    createLabel("聯絡人姓名:", member.contactName),
    createLabel("統編/證號:", showAllMemberData ? member.companyNo : convertDataToHiddenCode(member.companyNo)),
    createLabel("連絡電話:", showAllMemberData ? member.telMorning : convertDataToHiddenCode(member.telMorning)),
    createLabel("聯絡電話(手機):", showAllMemberData ? member.cell : convertDataToHiddenCode(member.cell)),
    createLabel("帳單地址:", showAllMemberData ? member.address : convertDataToHiddenCode(member.address)),
    createLabel("郵遞區號:", showAllMemberData ? member.zip : convertDataToHiddenCode(member.zip)),
    createLabel("主要Email:", member.email),
    createLabel("備用Email:", orderDetail.googleAlternateEmail),
  ];

  const registerFieldList = [
    createLabel(
      "訂閱識別碼：",
      <Input
        fullWidth
        name={"gwsSubscriptionId"}
        value={register.gwsSubscriptionId}
        disabled={keepModal}
        onChange={handleChangeRegister}
        type={showAllMemberData ? 'text' : 'password'}
      />
    ),
    createLabel(
      "Customer ID：",
      <Input
        fullWidth
        name={"gwsId"}
        value={register.gwsId}
        disabled={keepModal}
        onChange={handleChangeRegister}
        type={showAllMemberData ? 'text' : 'password'}
      />
    ),
    createLabel(
      "使用網域:",
      <TextField
        fullWidth
        name={"domain"}
        value={register.domain}
        onChange={handleChangeRegister}
        disabled={keepModal}
        inputProps={{ maxLength: 100 }}
        required
        type={showAllMemberData ? 'text' : 'password'}
        helperText={enterErrorMessageOfDomain}
        error={domainError}
        onBlur={() => gwsCheckDomainByDomain()}
      />
    ),
    createLabel("首次密碼：", register.gwsMima !== "" ? (showAllMemberData ? register.gwsMima : convertDataToHiddenCode(register.gwsMima)) : "（尚未開通，此欄位會自動填入，請先開通GWS。）"),
    // <Input
    //   fullWidth
    //   name={"gwsMima"}
    //   value={register.gwsMima}
    //   disabled={keepModal}
    //   onChange={handleChangeRegister}
    // />
    createLabel(
      "啟用日期(收費)：",
      <TextField
        fullWidth
        type="datetime-local"
        name={"orderStartDate"}
        helperText={"如需要更動日期，請開通Google後再進行更動"}
        value={ConvertStr2DatetimeLocal(register.orderStartDate)}
        disabled={keepModal}
        onChange={handleChangeRegister}
        inputProps={{ step: 1 }} // for datetime to show second
      />
    ),
  ];
  const chargeFieldList = [
    createLabel("出帳時間:", datetimeStringFormat(charge.accountingDate)),
    createLabel("目前訂單狀態：", charge.orderChargeStatusSn),
    createLabel("總收費價錢：", charge.totalPrice),
    createLabel("AAA-TAG：", charge.aaaTag),
    createLabel("更新品管金額:", <Grid container spacing={2}>
      <Grid item xs={2}>
        <Button
          variant="contained"
          style={{
            color: "#FFFFFF",
            backgroundColor: "#007A33"
          }}
          onClick={() => updateQcPrice(true)}
        >設定</Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#FF0000"
          }}
          onClick={() => updateQcPrice(false)}
        >取消</Button>
      </Grid>
    </Grid >)
  ];

  const additionalMaintenance = [
    createLabel(
      "5X8 維運點數:",
      <TextField
        fullWidth
        type="number"
        name={"fiveEightCredit"}
        value={maintenanceDetail.fiveEightCredit}
        onChange={handleChangeMaintenanceDetail}
        disabled={keepModal}
      />
    ),
    createLabel(
      "單價(新台幣元):",
      <TextField
        fullWidth
        type="number"
        name={"fiveEightCreditPrice"}
        value={maintenanceDetail.fiveEightCreditPrice}
        onChange={handleChangeMaintenanceDetail}
        disabled={keepModal}
      />
    ),
    createLabel(
      "非 5X8 維運點數:",
      <TextField
        fullWidth
        type="number"
        name={"nonFiveEightCredit"}
        value={maintenanceDetail.nonFiveEightCredit}
        onChange={handleChangeMaintenanceDetail}
        disabled={keepModal}
      />
    ),
    createLabel(
      "單價(新台幣元):",
      <TextField
        fullWidth
        type="number"
        name={"nonFiveEightCreditPrice"}
        value={maintenanceDetail.nonFiveEightCreditPrice}
        onChange={handleChangeMaintenanceDetail}
        disabled={keepModal}
      />
    ),
    // createLabel("", ""),
    // createLabel(
    //   "",
    //   <Button
    //     onClick={saveMaintenanceDetail}
    //     color="primary"
    //     style={{ float: "right" }}
    //   >
    //     儲存購買資訊
    //   </Button>
    // ),
  ];

  var trialOrderOperation = [
    createLabel("接續付費方案",
      <FormControl fullWidth>
        <NativeSelect
          id="demo-customized-select-native"
          value={changeToPlanProductDetailSn}
          type="number"
          name={"changePlanProductDetail"}
          onChange={handleSwitchChangingPlanProductOption}
          disabled={keepModal}
          required
        >
          <option value="0"></option>
          {renderProductOption()}
        </NativeSelect>
      </FormControl>
    ),
    createLabel("轉換付費方案",
      <Button
        style={{
          backgroundColor: "#21b6ae"
        }}
        disabled={changeToPlanProductDetailSn === 0} // 沒選擇方案內容不能轉換方案
        onClick={() =>
          handleCommitChangePlan(
            orderSn,
            changeToPlanProductDetailSn,
            updatingQuantity
          )
        }
      >
        轉換
      </Button>
    ),
    createLabel(
      "帳號數量:",
      <TextField
        fullWidth
        type="number"
        value={updatingQuantity}
        onChange={(e) => {
          let { value } = e.target;

          if (value) {
            value = parseInt(value);
            value = value > 2147483647 ? 2147483647 : value;
            value = value < 1 ? 1 : value;

            setUpdatingQuantity(value);
          }
        }}
        disabled={keepModal}
      />
    ),
    // 2023/11/03 隱藏啟用，在方案未更改前不能啟用，避免誤解先註解。
    // 因為前面「轉換付費方案」就會改變成非試用方案，此時就會開啟「啟用」按鍵。
    // createLabel(
    //   "啟用付費方案",
    //   <Button
    //     style={{
    //       backgroundColor: "#21b6ae",
    //     }}
    //     onClick={() => handleStartPaidService()}
    //   >
    //     啟用
    //   </Button>
    // ),
  ];

  var changedTrialOrderOperation = [
    createLabel("啟用付費方案",
      <Button
        style={{
          backgroundColor: "#21b6ae"
        }}
        onClick={() => handleStartPaidService()}
      >
        啟用
      </Button >
    )
  ];

  async function saveMaintenanceDetail() {
    // message
    var message = "";
    try {
      // 5X8 Service Data
      var postData = {};
      postData["Sn"] = orderSn;
      postData["FiveEightCredit"] = maintenanceDetail.fiveEightCredit;
      postData["FiveEightCreditPrice"] = maintenanceDetail.fiveEightCreditPrice;
      postData["NonFiveEightCredit"] = maintenanceDetail.nonFiveEightCredit;
      postData["NonFiveEightCreditPrice"] = maintenanceDetail.nonFiveEightCreditPrice;
      await Axios.post("/api/order/setOutSourceMaintenanceInfo", postData).then((response) => {
        console.log(response);
        message += "維運購買數量已儲存\n";
      }).catch((err) => {
        console.log(err);
        alert("後端異常");
      });

      // Other Service Data
      console.log(otherServiceInfo);
      console.log(bufferedOrderOtherServiceInfo);
      await Axios.post("/api/order/updateOrderOtherServiceInfo", {
        OrderSn: orderSn,
        OtherServiceList: otherServiceInfo
      }).then((response) => {
        console.log(response);
        message += "自訂服務欄位已儲存\n";
        var originalLog = getOtherServiceInfoListBeforeEdit();
        var changeLog = getOtherServiceInfoListAfterEdit();
        if (originalLog !== changeLog) {
          if (insertOrderManagementLog("自訂加購加值服務", originalLog, changeLog)) {
            setBufferedOrderOtherServiceInfo(otherServiceInfo);
          }
        }

        var fiveEightChangeLog = getMaintenanceDetailInfoDiffForChangeLog();
        if (fiveEightChangeLog["before"] !== undefined && fiveEightChangeLog["after"] !== undefined && fiveEightChangeLog["after"] !== "") {
          insertOrderManagementLog("5x8加購加值服務", fiveEightChangeLog["before"], fiveEightChangeLog["after"]);
        }
        setBufferedMaintenanceDetail(maintenanceDetail);
      }).catch((err) => {
        console.log(err);
        alert("後端異常");
      });
      alert(message);
    } catch (error) {
      alert(error);
    }

  }

  async function saveAmEmailList() {
    // message
    var message = "";
    try {
      // AM email list
      var emailList = [];
      for (var i = 0; i < amEmailList.length; ++i) {
        console.log(amEmailList[i]);
        emailList.push(amEmailList[i].amEmail);
      }
      // AM email list Data
      await Axios.post("/api/order/insertAmEmailList", {
        OrderSn: orderSn,
        EmailList: emailList
      }).then((response) => {
        console.log(response);
        message += "AM email 清單已儲存\n";
        var originalLog = getAmEmailListBeforeEdit();
        var changeLog = getAmEmailListAfterEdit();
        if (originalLog !== changeLog) {
          insertOrderManagementLog("AM Email 清單", originalLog, changeLog);
        }
        setBufferedAmEmailList(amEmailList);
      }).catch((err) => {
        console.log(err);
        alert("後端異常");
      });
      alert(message);
    } catch (error) {
      alert(error);
    }
  }

  function productDetailTable(product) {
    const columns = [
      { title: "商品編號", field: "no" },
      { title: "付款PID", field: "pid" },
      { title: "商品名稱", field: "name" },
      { title: "商品方案", field: "plan" },
      { title: "商品折扣", field: "description" },
      // { title: "商品啟用狀態", field: "orderServiceStatusSn" },
      { title: "數量", field: "quantity" },
      { title: "單價", field: "price" },
      {
        title: "合計",
        field: "price",
        render: (rowData) => rowData.quantity * rowData.price,
      },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">購買商品明細</Typography>
        <DialogContent dividers>{createTable(columns, product)}</DialogContent>
      </Box>
    );
  }

  // Security Design
  function watcherLogTable(watcherLog) {
    const columns = [
      {
        title: "時間",
        field: "watchTime",
        render: (rowData) => datetimeStringFormat(rowData.watchTime),
      },
      {
        title: "查看人員",
        field: "operator",
        render: (rowData) => showAllMemberData ? rowData.operator : convertDataToHiddenCode(rowData.operator)
      },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          機敏資料查看紀錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, watcherLog)}</DialogContent>
      </Box>
    );
  }

  function logTable(orderLog) {
    const columns = [
      {
        title: "時間",
        field: "modifyDate",
        render: (rowData) => datetimeStringFormat(rowData.modifyDate),
      },
      {
        title: "修改者",
        field: "operator",
        render: (rowData) =>
          showAllMemberData ? rowData.operator : convertDataToHiddenCode(rowData.operator)
      },
      {
        title: "欄位",
        field: "field",
        render: (rowData) => {
          if (!fieldMapingList[rowData.field]) return showAllMemberData ? rowData.field : convertDataToHiddenCode(rowData.field);
          return showAllMemberData ? fieldMapingList[rowData.field] : convertDataToHiddenCode(fieldMapingList[rowData.field]);
        },
      },
      {
        title: "修改前資料",
        field: "beforeModify",
        render: (rowData) => showAllMemberData ? rowData.beforeModify : convertDataToHiddenCode(rowData.beforeModify)
      },
      {
        title: "修改後資料",
        field: "afterModify",
        render: (rowData) => showAllMemberData ? rowData.afterModify : convertDataToHiddenCode(rowData.afterModify)
      },
      // { title: "動作", field: "action" },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          訂單更改記錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, orderLog)}</DialogContent>
      </Box>
    );
  }

  function createTable(columns, datas) {
    return (
      <MaterialTable
        columns={columns}
        data={datas}
        options={{
          search: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          padding: "dense",
          sorting: false,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
          }),
        }}
      />
    );
  }

  function createLabel(title, text) {
    return (
      <Grid container>
        <Grid item xs={3}>
          <Box m={1}>{title}</Box>
        </Grid>
        <Grid item xs={9}>
          <Box m={1}>{text}</Box>
        </Grid>
      </Grid>
    );
  }

  function datetimeStringFormat(time) {
    // time is string
    if (time === "" || time === null) {
      return "";
    } else {
      return format(new Date(time), datetimeFormat);
    }
  }

  // const handleSetNegativePrice = (isNegative, index) => {
  //   var sign = (isNegative) ? -1 : 1;
  //   let newItemValues = [...otherServiceInfo];
  //   newItemValues[index].isNegative = isNegative;
  //   var tmpPrice = parseInt(newItemValues[index].servicePrice);
  //   tmpPrice = (isNaN(tmpPrice)) ? 0 : sign * Math.abs(tmpPrice);
  //   newItemValues[index].servicePrice = tmpPrice;
  //   setOtherServiceInfo(newItemValues);
  // }

  const handleChangeOtherService = (i, e) => {
    var { name, value, type } = e.target;
    console.log(i, name, value);
    let newItemValues = [...otherServiceInfo];

    if (type === "select-one") {
      if (name === "isMainCharge") {
        value = (value === "true") ? true : false
      } else {
        value = parseInt(value);
      }
    }

    if (type === "number") {
      value = parseInt(value);
      value = isNaN(value) ? 0 : value;
      value = value > 2147483647 ? 2147483647 : value;
    }

    newItemValues[i][name] = value;
    setOtherServiceInfo(newItemValues);
    // console.log(otherServiceInfo);
    // console.log(bufferedOrderOtherServiceInfo);
  };

  // function printStrOfInput(inputStr) {
  //   console.log(inputStr);
  //   return inputStr;
  // }

  function createMaintenanceTable(title, detailFieldList) {
    return (
      <Box m={2}>
        <Typography variant="h6">{title}</Typography>
        <DialogContent dividers>
          <Grid container>
            {additionalMaintenance.map((data, index) => {
              return (
                <Grid item xs={xsSize} md={mdSize} key={index}>
                  {data}
                </Grid>
              );
            })}
          </Grid>
          <br />
          <Grid container>
            {detailFieldList.map((data, index) => (
              <Grid container key={index} alignItems="center">
                <Grid item xs={2}>
                  <Box m={1}>{"自訂名稱 - " + (index + 1) + "："}</Box>
                </Grid>
                <Grid item xs={3}>
                  <Box m={1}>
                    <TextField
                      fullWidth
                      type="text"
                      name={"serviceName"}
                      value={data.serviceName}
                      onChange={e => handleChangeOtherService(index, e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box m={1}>{"型態:"}</Box>
                </Grid>
                <Grid item xs={1}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={data.isMainCharge}
                    name={"isMainCharge"}
                    onChange={e => handleChangeOtherService(index, e)}
                    disabled={keepModal}
                    required
                  >
                    {Object.keys(isMainChargeOptions).map((isMainChargeOptionsKey, index) => {
                      return (
                        <option key={index} value={isMainChargeOptions[index].value} disabled={false}>
                          {isMainChargeOptions[index].name}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </Grid>
                <Grid item xs={2}>
                  <Box m={2}>{"單價(新台幣元)："}</Box>
                </Grid>
                {/* <Grid item xs={1}>
                  <Box m={1}>
                    {
                      (!otherServiceInfo[index].isNegative) ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleSetNegativePrice(true, index)}
                        >正</Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleSetNegativePrice(false, index)}
                        >負</Button>
                      )}
                  </Box>
                </Grid> */}
                <Grid item xs={2}>
                  <Box m={1}>
                    <TextField
                      fullWidth
                      type="number"
                      name={"servicePrice"}
                      value={isNaN(data.servicePrice) ? 0 : data.servicePrice}
                      onChange={e => handleChangeOtherService(index, e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box m={1}>
                    <Tooltip title="Remove a Service" placement="bottom">
                      <IconButton
                        onClick={() => handleRemoveRow(index)}
                        aria-label="Remove a Service"
                      >
                        <DeleteIcon></DeleteIcon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
                <br />
                {data.isMainCharge ? (
                  <>
                    <Grid item xs={5}>
                      <Box m={1}>補報日期（用於紀錄報帳，並非實際執行日期）：</Box>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        // fullWidth
                        type="date"
                        name={"chargeDate"}
                        value={data.chargeDate}
                        onChange={(e) => handleChangeOtherService(index, e)} // Assuming index is not needed here
                        InputProps={{
                          inputProps: {
                            max: getTodayDate(),
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box m={1}>
                        <Tooltip title="範例：若『漏帳那年』或『今年』的啟用日期為「2023/02/19」，則【月繳訂單】請填入「2023/03/01」，而【年繳訂單】請填入「2023/03/15」。" placement="top-end">
                          <Alert severity="info">常規補報日期規則：「『漏帳那年』或『今年』啟用日期的下一個報帳日（月繳：每月1號 / 年繳：每月15號）」。</Alert>
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box m={1}>
                        <Tooltip title="範例：若需切齊授權日期為「2025/04/19」，則需先確認啟用日期是否已填入上一個週期日「2024/04/19」，補報日期請填入「2024/05/15」。" placement="bottom-end">
                          <Alert severity="warning">若為需切齊授權之年繳訂單，請先確認啟用日期是否已填入「切齊授權日期的上一個週期日」，再依照上述的常規補報日期規則填入。</Alert>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            ))}
          </Grid>

          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item>
              <Tooltip title="Add a Service" placement="bottom">
                <Button
                  onClick={handleAddRow}
                  aria-label="Add a Service"
                  variant="outlined"
                  color="secondary"
                >
                  新增自訂欄位
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Save all Services Info" placement="bottom">
                <Button
                  onClick={saveMaintenanceDetail}
                  aria-label="Save all Services Info"
                  variant="outlined"
                  color="primary"
                >
                  儲存購買資訊
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    )
  }

  function createAmEmailListTable(title, detailFieldList) {
    return (
      <Box m={2}>
        <Typography variant="h6">{title}</Typography>
        <DialogContent dividers>
          <Grid container>
            {detailFieldList.map((data, index) => (
              <Grid container key={index} alignItems="center">
                <Grid item xs={2}>
                  <Box m={1}>{"Email - " + (index + 1) + "："}</Box>
                </Grid>
                <Grid item xs={4}>
                  <Box m={1}>
                    <TextField
                      fullWidth
                      type="text"
                      name={"amEmail"}
                      value={data.amEmail}
                      onChange={e => handleChangeAmEmailList(index, e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box m={1}>
                    <Tooltip title="Remove a Service" placement="bottom">
                      <IconButton
                        onClick={() => handleRemoveRowOfEmailList(index)}
                        aria-label="Remove a Service"
                      >
                        <DeleteIcon></DeleteIcon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item>
              <Tooltip title="Add a Service" placement="bottom">
                <Button
                  onClick={handleAddRowOfEmailList}
                  aria-label="Add a Service"
                  variant="outlined"
                  color="secondary"
                >
                  新增 AM Email
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Save all Services Info" placement="bottom">
                <Button
                  onClick={saveAmEmailList}
                  aria-label="Save all Services Info"
                  variant="outlined"
                  color="primary"
                >
                  儲存 AM Email 清單
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    )
  }

  let handleAddRow = () => {
    if (window.confirm("確定新增資料？")) {
      console.log("Come handleAddRow.");
      setOtherServiceInfo([...otherServiceInfo, { sn: -1, serviceName: "", servicePrice: "", isNegative: false, isMainCharge: false, chargeDate: "" }])
    }
  }

  let handleRemoveRow = (i) => {
    if (window.confirm("確定刪除資料？")) {
      console.log("Come handleRemoveRow.");
      let newItemValues = [...otherServiceInfo];
      newItemValues.splice(i, 1);
      setOtherServiceInfo(newItemValues);
    }
  }

  let handleAddRowOfEmailList = () => {
    if (window.confirm("確定新增資料？")) {
      console.log("Handling Add Row Of Email List.");
      setAmEmailList([...amEmailList, { amEmail: "" }])
    }
  }
  let handleRemoveRowOfEmailList = (i) => {
    if (window.confirm("確定刪除資料？")) {
      console.log("Come handleRemoveRow.");
      let newItemValues = [...amEmailList];
      newItemValues.splice(i, 1);
      setAmEmailList(newItemValues);
    }
  }

  return (
    <Dialog
      open={modalStatus}
      // onClose={closeModal}
      maxWidth="lg"
      scroll="paper"
    >
      <Box>
        <form>
          <DialogTitle>訂單資訊</DialogTitle>
          <DialogContent dividers>
            {productDetailTable(product)}
            {dataTable("訂購資料", detailFieldList)}

            {/* {dataTable("訂購人資料", memberFieldList)} */}
            <Box m={2}>
              <Typography variant="h6">訂購人資料
                <IconButton
                  aria-label="toggle personal data visibility"
                  onClick={handleClickShowAllMemberData}
                  onMouseDown={handleMouseDownAllMemberData}
                  edge="end"
                >
                  {showAllMemberData ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Typography>
              <DialogContent dividers>
                <Grid container>
                  {memberFieldList.map((data, index) => {
                    return (
                      <Grid item xs={xsSize} md={mdSize} key={index}>
                        {data}
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
            </Box>

            {/* {dataTable("Google供裝資料", registerFieldList)} */}
            <Box m={2}>
              <Typography variant="h6">GWS 供裝資料
                <IconButton
                  aria-label="toggle personal data visibility"
                  onClick={handleClickShowAllMemberData}
                  onMouseDown={handleMouseDownAllMemberData}
                  edge="end"
                >
                  {showAllMemberData ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Typography>
              <DialogContent dividers>
                <Grid container>
                  {registerFieldList.map((data, index) => {
                    return (
                      <Grid item xs={xsSize} md={mdSize} key={index}>
                        {data}
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
            </Box>
            {createMaintenanceTable("加購加值服務(每週日統一結算出帳)", otherServiceInfo)}
            {/* {dataTable("加購加值服務", additionalMaintenance)} */}
            {createAmEmailListTable("AM Email 清單", amEmailList)}
            {dataTable("付款狀態(最後一次付款狀態)", chargeFieldList)}
            {(isTrialPlan) ? (dataTable("試用轉付費方案", trialOrderOperation)) : (dataTable("試用轉付費方案", changedTrialOrderOperation))}
            {logTable(orderLog)}
            {watcherLogTable(orderWatcherLog)}
          </DialogContent>
          <DialogActions>
            <SnackbarProvider maxSnack={1}>
              <DialogButtons
                orderSn={orderSn}
                getOrder={getOrder}
                orderDetail={orderDetail}
                member={member}
                register={register}
                charge={charge}
                session={cookies.session}
                opname={cookies.op_name}
                productDetailSn={product[0].sn}
                closeModal={() => closeModal(orderDetail.contractNoK1 === "")}
                keepModal={keepModal}
                setKeepModalStatus={setKeepModalStatus}
                domainError={domainError}
                orderRenewalSn={orderRenewalSn}
                renewalTypeList={renewalTypeList}
              />
            </SnackbarProvider>
          </DialogActions>
        </form>
      </Box>

      <LoginDialogForCheckID
        open={openLoginDialog}
        handleToggleShowAllMemberData={handleToggleShowAllMemberData}
        handleCloseLoginDialog={handleCloseLoginDialog}
      >
      </LoginDialogForCheckID>

    </Dialog >
  );
}

function dataTable(title, detailFieldList) {
  return (
    <Box m={2}>
      <Typography variant="h6">{title}</Typography>
      <DialogContent dividers>
        <Grid container>
          {detailFieldList.map((data, index) => {
            return (
              <Grid item xs={xsSize} md={mdSize} key={index}>
                {data}
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
    </Box>
  );
}

function DialogButtons(props) {
  const { enqueueSnackbar } = useSnackbar();
  var postSaveData = {
    sn: props.orderSn,
    productBrandSn: BrandSnGoogle,
    orderDetail: props.orderDetail,
    member: props.member,
    register: props.register,
    charge: props.charge,
    session: props.session,
    OpName: props.opname,
    ProductDetailSn: props.productDetailSn,
  };
  const handleShowMessage = (
    message,
    variant: VariantType,
    isMoment = true
  ) => {
    var autoHideDuration = 5000;
    if (!isMoment) {
      autoHideDuration = 500000;
    }
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: autoHideDuration,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const saveOrder = () => {
    missingK1Warning();
    missingActivateDate();
    // // 避免未填Domain或未過驗證
    // if (props.orderDetail.orderStatusSn !== "9" && props.orderDetail.orderStatusSn !== 9) {
    //   if (props.register.domain === "" || props.domainError) {
    //     alert("更改【使用網域】未過驗證或格式錯誤。");
    //     return false;
    //   }
    // }

    if (window.confirm("確定要儲存資料？")) {
      // Post Data
      props.setKeepModalStatus(true);
      console.log(postSaveData.register.orderStartData);
      Axios.post(saveOrderURL, postSaveData)
        .then((response) => {
          console.log(response);
          handleShowMessage("系統訂單已儲存", "info");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          var postErr = showPostError("系統訂單", error);
          handleShowMessage(`${postErr}`, "error");
          props.setKeepModalStatus(false);
        });
    }
  };

  async function saveOrderAndActivateGWS() {
    missingK1Warning();
    missingActivateDate();
    // 確認是否需要再次開通
    console.log("googleSubscriptionId:" + props.register.googleSubscriptionId);
    if (
      props.register.googleSubscriptionId !== "" &&
      props.register.googleSubscriptionId !== undefined
    ) {
      if (!window.confirm("此商品已有開通紀錄，請再次確認是否需要開通")) {
        return;
      }
    }

    // 避免未填Domain或未過驗證
    if (props.register.domain === "" || props.domainError) {
      alert("更改【使用網域】未過驗證或格式錯誤。");
      return false;
    }

    // 是否確定要儲存資料
    if (!window.confirm("確定儲存資料？")) {
      return;
    }

    props.setKeepModalStatus(true);
    // Save Order Information
    var postErr = "";
    try {
      await Axios.post(saveOrderURL, postSaveData);
      handleShowMessage("系統訂單已儲存，接著開通GWS帳號中...", "info", false);
    } catch (error) {
      postErr = showPostError("系統訂單", error);
      handleShowMessage(`${postErr}`, "error");
      props.setKeepModalStatus(false);
      return;
    }

    // Activate GWS Order

    var message = "";
    try {
      message = "(Google) 檢查網域 & 開通帳號中...";
      handleShowMessage(`${message}...`, "info", false);
      await Axios.post("/api/v0/gws/activate", { orderSn: props.orderSn });
      handleShowMessage(`${message}---Success`, "success");
      window.location.reload();
    } catch (error) {
      postErr = showPostError(message, error);
      handleShowMessage(`${postErr}`, "error");
      props.getOrder(props.orderSn);
      props.setKeepModalStatus(false);
      return;
    }
  }
  /*
    const mpnCheckDomainByDomain = () => {
      var message = "(Google)檢查網域";
      handleShowMessage(`${message}...`, "info", false);
  
      if (props.register.domain !== "") {
        Axios.post("/api/v0/mpnCheckDomainByDomain", {
          domain: props.register.domain,
        })
          .then((response) => {
            handleShowMessage(`${message}---Success`, "success");
          })
          .catch((error) => {
            var errMessage = "";
            if (error.response) {
              if (error.response.data) {
                errMessage = `${message}---Fail`;
              } else {
                errMessage = `${message}---${error.response}`;
              }
            } else if (error.request) {
              errMessage = `${message}---連線異常`;
            } else {
              // 在設定 request 時出錯會進入此
              errMessage = `${message}---error`;
              console.error(error);
            }
            handleShowMessage(`${errMessage}`, "error");
          });
      } else {
        handleShowMessage(`網域此欄位不能空白`, "error");
      }
    };
  */

  async function saveOrderAndSendRegisterMail() {
    missingK1Warning();
    if (!window.confirm("確定儲存資料？")) {
      return;
    }
    props.setKeepModalStatus(true);

    var message = "";
    var postErr = "";
    // Save Order Information
    try {
      message = "系統訂單";
      await Axios.post(saveOrderURL, postSaveData);
      alert("系統訂單已儲存，寄送Google開通信中...");
    } catch (error) {
      postErr = showPostError(message, error);
      handleShowMessage(`${postErr}`, "error");
      props.setKeepModalStatus(false);
      return;
    }

    // Send Register Mail
    try {
      // 寄送Google開通信
      message = "寄送Google開通信";
      handleShowMessage(`${message}...`, "info", false);
      await Axios.post(sendRegisterMailURL, { orderSn: props.orderSn });
      handleShowMessage(`${message}---Success`, "success");
    } catch (error) {
      postErr = showPostError(message, error);
      handleShowMessage(`${postErr}`, "error");
      props.setKeepModalStatus(false);
    }
    props.setKeepModalStatus(false);
  }

  const showPostError = (message, error) => {
    var errMessage = "";
    console.log(error.response);
    if (error.response) {
      if (error.response.data) {
        errMessage = `${error.response.data.message}`;
      } else {
        errMessage = `${error.response}`;
      }
    } else if (error.request) {
      errMessage = `連線異常`;
    } else {
      // 在設定 request 時出錯會進入此
      errMessage = `error`;
      console.error(error);
    }
    return `${message} --- ${errMessage}`;
  };

  function missingK1Warning() {
    if (props.orderDetail.contractNoK1 === "") {
      alert("K1 碼尚未填入, 重要合約資料, 請確認是否需填寫!");
    }
  }

  function missingActivateDate() {
    if (process.env.REACT_APP_ORDER_STATUS_GWS_ACTIVATE_SN !== undefined && typeof (process.env.REACT_APP_ORDER_STATUS_GWS_ACTIVATE_SN) === "string") {
      const activeStatus = parseInt(process.env.REACT_APP_ORDER_STATUS_GWS_ACTIVATE_SN);
      if (props.orderDetail.orderStatusSn === activeStatus && (props.register.orderStartDate === "" || props.register.orderStartDate === undefined || props.register.orderStartDate.includes("0001-01-01"))) {
        alert("重要訊息!!! 此單已啟用但仍未設定啟用日期, 為確保資料正確, 請立即設定啟用日期");
      }
    }
  }

  function missingUnsubscribeDateWithCancelRenewalType() {
    var renewalTypeListSnMap = {};
    if (props.renewalTypeList !== undefined) {
      for (var i = 0; i < props.renewalTypeList.length; ++i) {
        renewalTypeListSnMap[props.renewalTypeList[i].sn] = props.renewalTypeList[i];
      }
    } else {
      alert("到期續約更狀態更新清單載入失敗!");
      return
    }
    // console.log(props.renewalTypeList);
    // console.log(props.orderRenewalSn);
    // console.log(renewalTypeListSnMap[props.orderRenewalSn]);
    // console.log(renewalTypeListSnMap[props.orderRenewalSn].sn);
    if (props.renewalTypeList !== undefined && props.orderRenewalSn !== undefined && renewalTypeListSnMap[props.orderRenewalSn] !== undefined && renewalTypeListSnMap[props.orderRenewalSn].sn !== undefined && renewalTypeListSnMap[props.orderRenewalSn].sn === 6) {
      if (props.orderDetail !== undefined && (props.orderDetail.orderDisSubscribeDate === undefined || props.orderDetail.orderDisSubscribeDate === "")) {
        alert("本單續約狀態為到期後退租且無設定退租日期, 此情形有可能導致誤出帳, 請再次確認");
      }
    }
  }

  return (
    <React.Fragment>
      <Button onClick={saveOrder} color="primary" disabled={props.keepModal}>
        儲存
      </Button>
      <Button
        onClick={() => saveOrderAndSendRegisterMail()}
        color="primary"
        /*只允許這筆訂單是在 GWS 開通的且已取得首次密碼(就是第一次開通)的情況下，才可使用。*/
        disabled={((props.orderDetail.orderStatusSn === 9 && props.register.gwsMima !== "") ? false : true) || props.keepModal}
      >
        儲存並寄送GWS開通信
      </Button>

      {(props.orderDetail.orderStatusSn === 9) ? (<></>) : (
        <>
          <Button
            onClick={() => saveOrderAndActivateGWS()}
            color="primary"
            /*只允許這筆訂單是在 GWS 未開通的且尚未取得首次密碼(就是第一次開通)的情況下，才可以按開通按鈕。*/
            disabled={((props.orderDetail.orderStatusSn === 9 && props.register.gwsMima !== "") ? true : false) || props.keepModal}
          >
            儲存並開通GWS
          </Button>
        </>
      )}
      <Button
        onClick={() => {
          props.closeModal();
          missingK1Warning();
          missingActivateDate();
          missingUnsubscribeDateWithCancelRenewalType();
        }}
        color="primary"
      // disabled={props.keepModal}
      >
        離開
      </Button>
    </React.Fragment>
  );
}
