import React, { forwardRef, useEffect } from "react";

// Table
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import Save from "@material-ui/icons/Save";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DetailsIcon from '@material-ui/icons/Details';

// Restful API
import Axios from "axios";

// Refreshing cookies mechanism
import { useHistory } from "react-router-dom";
import { getCookie, handleRefreshLoginCookies, handleExpiredCookies } from "../common/session"

// Component
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  Button,
  ButtonGroup,
  Paper,
  Typography,
  Grid
} from "@material-ui/core";

// Style CSS
import { makeStyles } from '@material-ui/core/styles';
import { useCookies } from "react-cookie";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    height: '100%',
    width: '110%'
  },
  button: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

// Define table Icons
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Details: forwardRef((props, ref) => (<DetailsIcon {...props} ref={ref} />)),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <Save {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function Logs() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);

  //Dialog parameters
  const [dialogEnable, setDialogEnable] = React.useState(false);
  const [memberLog, setMemberLog] = React.useState({
    Sn: 0,
    Hn: "",
    PostDate: "",
    LoginIp: "",
    LoginDomain: "",
    ErrCode: "",
    AaOneTimePwd: 0,
    RegisterDate: "",
    HinetSn: "",
    IsDeleted: false
  });

  const [cookies] = useCookies(["group_id"]);

  // Refreshing cookies mechanism
  let history = useHistory() // 取得某頁面route name

  const [permission, setPermission] = React.useState(0);

  const notPermitted = 1;
  const readOnly = 2;
  const debugMode = true;

  // Fetch data before render
  useEffect(() => {
    var gid = isNaN(parseInt(cookies.group_id)) ? 0 : parseInt(cookies.group_id);
    if (gid !== 0) {
      getGroupPermission(gid);
    }
    getmemberLogList();
  }, [cookies.group_id]);

  // Refreshing cookies mechanism
  useEffect(() => {
    if(getCookie("session") !== undefined) {// 按下登出按鈕 或是 Session 仍存在的情況下進行換頁。
      handleRefreshLoginCookies()
      if (debugMode) {
        console.log("Call loginCheck api- " + getCookie("session"));
      }
      Axios
        .post("/api/v0/loginCheck", {
          session: getCookie("session"),
        })
        .then(function(response) {
          if (response.data.IsLogin !== true) {
            handleExpiredCookies()
            history.replace("/backoffice/Login")
          }
        })
        .catch(function(error) {
          console.log("OPRouter", error);
        });
    } else {// Session 過期的情況下進行換頁。
      handleExpiredCookies()
      history.replace("/backoffice/Login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    dialogEnable, // 查看紀錄時
  ])

  // Get table data
  function getmemberLogList() {
    Axios.get("/api/memberlog/readLog").then(res => {
      res.data.sort((a, b) => (a.Sn < b.Sn) ? 1 : -1);
      setRows(res.data);
    });
  }

  // Delete log record
  function handleDelete(selected) {
    if (window.confirm("確定刪除資料？")) {
      var DeleteSnList = []
      for (var i in selected) {
        DeleteSnList.push(parseInt(selected[i].Sn));
      }

      if (DeleteSnList.length > 0) {
        Axios.post(
          "/api/memberlog/deleteLog", {
          DeleteSnList: DeleteSnList
        }
        ).then(res => {
          window.location.reload();
        });
      }
    }
  }

  const getGroupPermission = (sn) => {
    var postData = {};
    postData["Sn"] = sn;
    Axios.post("/OP/group/getGroupData", postData).then(function (response) {
      var res = response.data.data;
      for (var i in res) {
        if (res[i].name === '會員管理紀錄') {
          setPermission(res[i].permission);
          break;
        }
      }
    }).catch(function (err) {
      console.log(err);
    });
  };

  // Close modal
  const handleClose = () => {
    setDialogEnable(false);
  };

  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.container}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: 'center',
                minHeight: '100vh'
              }}
            >
              <Grid item style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 200
              }}>
                <Typography style={{ fontSize: 100 }}>
                  您無此權限
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }

  return (
    <Container className={classes.container}>
      <Paper>
        <MaterialTable
          columns={[
            { title: '類別', field: 'Type' },
            //{ title: '用戶帳號', field: 'Id' },
            //{ title: '會員 Hn', field: 'Hn' },
            //{ title: '帳號類型', field: 'AccountType', lookup: {0: "839帳號", 1: "中華電信會員", 2: "HN帳號"} },
            //{ title: 'HINET會員SN', field: 'HinetSn'/*, type: 'numeric'*/ },
            { title: '存取時間', field: 'PostDate' },
            { title: '存取IP', field: 'LoginIp' },
            { title: '存取來源網站	', field: 'LoginDomain' },
          ]}
          data={rows}
          title="會員紀錄管理"
          options={{
            searchText:"",
            search: true,
            actionsColumnIndex: -1,
            selection: false,
            paginationType: "stepped",
            headerStyle: {
              backgroundColor: "#888",
              color: "#FFF"
            },
            rowStyle: (rowData) => ({
              backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
            }),
            showTextRowsSelected: false
          }}
          icons={tableIcons}
          actions={(permission === readOnly) ?
            [] : [
              roData => ({
                icon: DetailsIcon,
                tooltip: "Login Detail",
                onClick: (event, rowData) => {
                  setMemberLog(rowData);
                  setDialogEnable(true);
                  // if(rowData.length > 1) {
                  //   alert("請一次選擇一筆資料");
                  // } else {

                  // }
                }
              }),
              {
                icon: DeleteOutline,
                tooltip: "Delete Record",
                onClick: (event, rowData) => {
                  handleDelete(rowData);
                }
              }
            ]}
        />
      </Paper>
      <Dialog
        open={dialogEnable}
        onClose={handleClose}
        maxWidth="md"
        scroll="paper"
      >
        <Box>
          <form onSubmit={handleClose}>
            <DialogTitle>會員詳細資料： </DialogTitle>
            <DialogContent dividers>
              {/*<TextField
                fullWidth
                margin="dense"
                label="類別"
                name="type"
                value={memberLog.Type}
                diabled={true}
              />*/}

              <TextField
                fullWidth
                margin="dense"
                label="存取時間"
                name="postdate"
                value={memberLog.PostDate}
                disabled={true}
              />

              {/*<TextField
                fullWidth
                margin="dense"
                label="存取來源網站"
                name="logindomain"
                value={memberLog.LoginDomain}
                diabled={true}
              />*/}

              <TextField
                fullWidth
                margin="dense"
                label="存取IP"
                name="loginip"
                value={memberLog.LoginIp}
                disabled={true}
              />

              <TextField
                fullWidth
                margin="dense"
                label="自訂參數(others)"
                name="selfdefinedArgs"
                value={memberLog.Others}
                disabled={true}
              />

              <TextField
                fullWidth
                margin="dense"
                label="帳號類型"
                name="accounttype"
                value={memberLog.Type}
                disabled={true}
              />

              <TextField
                fullWidth
                margin="dense"
                label="錯誤訊息"
                name="errormessage"
                value={memberLog.ErrCode}
                disabled={true}
              />

              {/*<TextField
                fullWidth
                margin="dense"
                label="帳號"
                name="id"
                value={memberLog.Id}
                diabled={true}
              />*/}

              {/*<TextField
                fullWidth
                margin="dense"
                label="HiNet SN"
                name="hinetsn"
                value={memberLog.HinetSn}
                diabled={true}
              />*/}

              {/*<TextField
                fullWidth
                margin="dense"
                label="HN"
                name="hinethn"
                value={memberLog.Hn}
                diabled={true}
              />*/}

              <TextField
                fullWidth
                margin="dense"
                label="AA OTPW"
                name="aaonetimepassword"
                value={memberLog.AaOneTimePwd}
                disabled={true}
              />
            </DialogContent>
            <ButtonGroup style={{
              float: "right",
              padding: 10
            }}>
              <Button /*type="submit"*/ name="save" color="primary" onClick={handleClose}>
                確定
              </Button>
            </ButtonGroup>
          </form>
        </Box>
      </Dialog>
    </Container>
  );
}
