// react
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

// material ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  Link,
  NativeSelect,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";

import { format } from "date-fns";

// API
import Axios from "axios";

// notification
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";

// common
import { ConvertStr2DatetimeLocal } from "../../common/timeParsing.js";
import LoginDialogForCheckID from "../common/LoginDialogForCheckID.js";

// icon
import Alert from "@mui/material/Alert";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
// import { FastRewindOutlined } from "@material-ui/icons";

import SelectProductListModel from "./SelectProductListModel.js";

//ResponsiveDialog
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import getServiceNamefilterOptions from "./serviceNamefilterOptions.js";

const BrandMicrosoftSn = 1;

const mdSize = 6;
const xsSize = 12;
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
const BrandSnMicrosoft = 1;
const orderChargeTypeYear = "年繳";

let productOfferId = ""; // 商品碼，用在驗證欲異動方案使用。

const fieldMapingList = {
  MemberSn: "會員",
  OrderSourceTypeSn: "訂單來源",
  OrderChargeTypeSn: "付款種類",
  OrderStatusSn: "訂單狀態",
  OrderNo: "訂單編號",
  PromotionEmployeeNo: "推廣員工(工號)",
  OperatorNote: "客服備註",
  ProductDetailSn: "商品資料",
  ProductDiscodeSn: "DSIS商品資料",
  DsisNo: "DSIS訂單存連單號碼",
  MsSubscribeNo: "微軟商品訂閱碼",
  Domain: "網域名稱",
  MsId: "Microsoft ID",
  msMima: "Microsoft PW",
  OrderDate: "訂單時間",
  OrderStartDate: "商品啟用時間",
  OrderDisSubscribeDate: "退訂日期",
  OrderLastChargeDate: "最後一次成功扣款時間",
  Quantity: "數量",
  AccumulatedPrice: "累計出帳金額",
  MS_SubscribeNo: "微軟商品訂閱碼",
  ContractNoK1: "契約編號 k1 碼",
};

const orderDetailDefault = {
  orderNo: "",
  orderDate: "",
  dsisNo: "",
  orderChargeTypeSn: "",
  orderSourceTypeSn: "",
  dsisCounter: "",
  orderStatusSn: "",
  quantity: "1",
  accumulatedPrice: "",
  orderDisSubscribeDate: "",
  promotionEmployeeNo: "",
  operatorNote: "",
  contractNoK1: "",
  billingCycle: "",
  isEligibleMigrateNCE: false,
  autoRenewStatus: true,
  hd: "",
  productDetailNo: ""
};

const productDetailDefault = {
  sn: "",
  product_no: "",
  product: "",
  plan: "",
  discode: "",
  price: "",
  priceCost: "",
  priceSpecial: "1",
  msEndDate: "",
  productStatus: ""
}

const memberDefault = {
  hn: "",
  name: "",
  contactLastName: "",
  contactName: "",
  companyNo: "",
  telMorning: "",
  address: "",
  zip: "",
  email: "",
  cell: "",
};

const registerDefault = {
  msSubscribeNo: "",
  msId: "",
  orderStartDate: "2020-03-09",
  msMima: "",
  domain: "",
};

const chargeDefault = {
  accountingDate: "",
  orderChargeStatusSn: "",
  totalPrice: "",
  aaaTag: "",
};

const productDefault = [
  {
    no: "",
    pid: "",
    name: "",
    plan: "",
    orderServiceStatusSn: "",
    quantity: "",
    price: "",
  },
];

const maintenanceDetailDefault = {
  fiveEightCredit: 0,
  fiveEightCreditPrice: 0,
  nonFiveEightCredit: 0,
  nonFiveEightCreditPrice: 0,
};

// const otherServiceInfoDefault = [
//   {
//     sn: "",
//     serviceName: "",
//     servicePrice: 0,
//   },
// ]

// API
const getOrderURL = "/api/order/getOrderDetail";
const getOrderStatusURL = "/api/order/getOrderStatusList";
//const getProductDetailListByNoURL = "/api/product/detail/productDetailListByNo";
const getProductDetailListByAnnualURL = "/api/product/detail/productDetailListByAnnual";
const saveOrderURL = "/api/order/modifyOrderDetail";
const sendRegisterMailURL = "/api/mailLog/sendRegisterMail";
const getOutSourceMaintenanceInfoURL = "/api/order/getOutSourceMaintenanceInfo";
const getOrderOtherServiceInfoURL = "/api/order/getOrderOtherServiceInfo";
// const getAllMigrationErrorMsgsURL = "/api/order/getAllMigrationErrorMsgs";
const updateOrCancelQCAccountingPriceChangeURL =
  "/api/order/updateOrCancelQCAccountingPriceChange";
// const mpnCreateURL = "/api/v0/mpnCreate";
// const mpnCheckDomainByDomainURL = "/api/v0/mpnCheckDomainByDomain";
const getAmEmailListURL = "/api/order/getAmEmailList";

// Security Design
const updateAutoRenewStatusURL = "/api/v0/mpnChangeSubscriptionAutoRenewStatus";
// Log API
const insertOrderManagementLogURL = "/api/log/insertOrderManagementLog";
const getOrderLogURL = "/api/order/getOrderManagementLogBySn";
const getOrderWatcherLogURL = "/api/order/getOrderWatcherLogBySn";
const insertOrderWatcherLogURL = "/api/order/insertOrderWatcherLog";

const mpnSubscriptionMonthly2Year = "/api/v0/mpnSubscriptionMonthly2Year";

export default function DetailModalOfMicrosoft(props) {
  const [cookies] = useCookies(["op_name", "session"]);
  const [modalStatus, setModalStatus] = useState(false);
  const [keepModal, setKeepModal] = useState(false);
  const [openSPM, setOpenSPM] = useState(false); // SPM = SelectProductListModel
  const [canModifyProduct, setCanModifyProduct] = useState(false);
  const [stayModal, setStayModal] = useState(true);
  // const [migrateNCE, setMigrateNCE] = useState(false);

  // Security Design
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showAllMemberData, setShowAllMemberData] = useState(false);
  const [orderWatcherLog, setOrderWatcherLog] = useState([]);

  // Data
  const [orderSn, setOrderSn] = useState(0);
  const [orderDetail, setOrderDetail] = useState(orderDetailDefault);
  const [productDetail, setProductDetail] = useState(productDetailDefault);
  const [orderDetailOriginal, setOrderDetailOriginal] =
    useState(orderDetailDefault);
  const [member, setMember] = useState(memberDefault);
  const [register, setRegister] = useState(registerDefault);
  const [charge, setCharge] = useState(chargeDefault);
  const [product, setProduct] = useState(productDefault);
  const [orderLog, setOrderLog] = useState([]);
  const [maintenanceDetail, setMaintenanceDetail] = useState(
    maintenanceDetailDefault
  );
  const [orderStatusList, setOrderStatusList] = useState({});
  const [productDetailListByAnnual, setProductDetailListByAnnual] = useState([]);

  //const [billingCycle, setBillingCycle] = useState("");
  // const [domainError, setDomainError] = useState(false);
  // const [operatorNote, setOperatorNote] = useState("");
  const [otherServiceInfo, setOtherServiceInfo] = useState([]);
  // const [otherServicePriceIsNegative, setOtherServicePriceIsNegative] = useState(false);
  // const [originRowNumOfOtherService, setOriginRowNumOfOtherService] = useState(0);

  // For operation log
  const [bufferedOrderOtherServiceInfo, setBufferedOrderOtherServiceInfo] =
    useState([]);
  const [bufferedMaintenanceDetail, setBufferedMaintenanceDetail] = useState(
    maintenanceDetailDefault
  );

  // AM email list
  const [amEmailList, setAmEmailList] = useState([]);
  const [bufferedAmEmailList, setBufferedAmEmailList] = useState([]);

  //ResponsiveDialog
  const [openResponsiveDialog, setOpenResponsiveDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleResponsiveDialogClickOpen = () => {
    setOpenResponsiveDialog(true);
  };

  const handleResponsiveDialogClose = () => {
    setOpenResponsiveDialog(false);
  };

  const handleResponsiveDialogCancel = () => {
    setOpenResponsiveDialog(false);
  };

  const handleResponsiveDialogOk = () => {
    //console.log("productDetail.productDetailSn2=", productDetail.productDetailSn)
    //console.log("productDetail.msEndDate2=", productDetail.msEndDate)
    handleProductDetailNo();
    setOpenResponsiveDialog(false);
  };

  const isMainChargeOptions = [
    {
      name: "普通一次",
      value: false,
    },
    {
      name: "常規補報",
      value: true,
    },
  ];

  const closeModal = props.closeModal;

  useEffect(() => {
    if (props.open) {
      // console.log("DetailModal effect");
      setOrderDetail(orderDetailDefault);
      setProductDetail(productDetailDefault);
      setOrderDetailOriginal(orderDetailDefault);
      setMember(memberDefault);
      setRegister(registerDefault);
      setCharge(chargeDefault);
      // setProduct(productDefault); // 2023/11/20 微軟「年繳」開放可以改商品方案
      setOrderSn(props.sn);
      getOrder(props.sn);
    }
    setModalStatus(props.open);
    // eslint-disable-next-line
  }, [/*props.orderStatusList,*/ props.open, props.sn]);

  // Security Design
  useEffect(() => {
    if (
      showAllMemberData &&
      props.sn !== undefined &&
      cookies.op_name !== undefined
    ) {
      Axios.post(insertOrderWatcherLogURL, {
        orderSn: props.sn,
        operatorId: cookies.op_name,
      })
        .then(function (response) {
          Axios.post(getOrderWatcherLogURL, { Sn: props.sn })
            .then(function (response) {
              setOrderWatcherLog(
                response.data.log != null ? response.data.log : []
              );
            })
            .catch(function (err) {
              console.log(err);
            });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [showAllMemberData, props.sn, cookies.op_name]);

  const handleChangeOrderDetail = (event) => {
    var { name, value, type } = event.target;
    console.log(name, value);
    if (type === "select-one") {
      if (name === "autoRenewStatus") {
        value = value === "true" ? true : false;
      } else {
        value = parseInt(value);
      }
    }
    if (type === "number") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
    }
    if (name === "orderDisSubscribeDate") {
      // convert local datetime to string with timezone
      try {
        value = new Date(value).toISOString();
      } catch (e) {
        value = "";
      }
    }
    setOrderDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeMaintenanceDetail = (event) => {
    var { name, value, type } = event.target;
    if (type === "number") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
    }
    setMaintenanceDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeOtherService = (i, e) => {
    var { name, value, type } = e.target;
    let newItemValues = [...otherServiceInfo];

    if (type === "select-one") {
      if (name === "isMainCharge") {
        value = value === "true" ? true : false;
      } else {
        value = parseInt(value);
      }
    }

    if (type === "number") {
      value = parseInt(value);
      value = isNaN(value) ? 0 : value;
      value = value > 2147483647 ? 2147483647 : value;
    }

    newItemValues[i][name] = value;
    setOtherServiceInfo(newItemValues);
  };

  const handleChangeAmEmailList = (i, e) => {
    var { name, value, type } = e.target;
    let newItemValues = [...amEmailList];

    if (type === "number") {
      value = parseInt(value);
      value = isNaN(value) ? 0 : value;
      value = value > 2147483647 ? 2147483647 : value;
    }

    newItemValues[i][name] = value;
    setAmEmailList(newItemValues);
  };

  const handleChangeRegister = (event) => {
    var { name, value, type } = event.target;
    if (type === "select-one") {
      value = parseInt(value);
    }

    // check domain
    const regexDomain = /^[A-Za-z0-9_.\-\b]+$/;
    if (name === "domain") {
      if (!regexDomain.test(value) && value !== "") {
        return;
      }
      if (value.length > 27) return;
    }

    if (name === "orderStartDate") {
      if (value !== "") {
        // convert local datetime to string with timezone
        value = new Date(value).toISOString();
      }
    }

    setRegister((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log(register);
  };

  const updateOrderAutoRenewStatus = (renewStatus) => {
    console.log(renewStatus);
    var tmpOrder = {};
    tmpOrder["Sn"] = orderSn;
    tmpOrder["AutoRenewStatus"] = renewStatus;
    if (tmpOrder !== undefined) {
      Axios.post(updateAutoRenewStatusURL, tmpOrder)
        .then(function (res) {
          alert("自動續約狀態更新成功!");
          insertOrderManagementLog(
            "自動續約選項",
            orderDetailOriginal.autoRenewStatus ? "開啟" : "關閉",
            renewStatus ? "開啟" : "關閉"
          );
          setOrderDetail((prevState) => ({
            ...prevState,
            autoRenewStatus: renewStatus,
          }));
        })
        .catch(function (err) {
          if (err.response) {
            // console.log(err.response.status);
            alert("自動續約狀態更新失敗!" + err.response.data.message);
            insertOrderManagementLog(
              "自動續約選項",
              orderDetailOriginal.autoRenewStatus ? "開啟" : "關閉",
              "更新失敗"
            );
          } else {
            alert("自動續約狀態更新失敗!");
            insertOrderManagementLog(
              "自動續約選項",
              orderDetailOriginal.autoRenewStatus ? "開啟" : "關閉",
              "更新失敗"
            );
          }
          setOrderDetail((prevState) => ({
            ...prevState,
            autoRenewStatus: !renewStatus,
          }));

          console.log(err);
        });
    }
  };

  const handleProductDetailListByAnnual = (event) => {
    var { name, value, type } = event.target;
    console.log(name, value, type);
    if (value !== "") {
      setStayModal(false);
    }
    else {
      setStayModal(true);
    }
    /*if (type === "select-one") {
      //value = parseInt(value);
      setProductDetailListByAnnualKeySn(value);
    } else {
      setProductDetailListByAnnualKeySn(0);
    }*/
    if (name === "msEndDate") {
      // convert local datetime to string with timezone
      try {
        value = new Date(value).toISOString();
      } catch (e) {
        value = "";
      }
    }
    setProductDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProductDetailNo = async () => {
    //console.log("productDetail.productDetailSn2=", productDetail.productDetailSn)
    //console.log("productDetail.msEndDate2=", productDetail.msEndDate)
    //console.log("props.sn1=" + props.sn);
    //var tmpProductDetail = {};
    //tmpProductDetail["ProductNo"] = productDetailNo;
    //if (tmpProductDetail !== undefined) {

    await Axios.post(mpnSubscriptionMonthly2Year, { sn: props.sn, productDetailSn: productDetail.productDetailSn })
      .then(async (response) => {
        alert("MS 訂單取得成功 (1/2)");
        alert(response.data.message);
        await Axios.post(mpnSubscriptionMonthly2Year, { sn: props.sn, productDetailSn: productDetail.productDetailSn })
          .then((response) => {
            alert("CSP 系統訂單取得成功 (2/2)");
            console.log(response);
            // window.location.reload();
          })
          .catch((err) => {
            alert("微軟訂單取得成功但 CSP 系統訂單資訊更新失敗");
            // window.location.reload();
            console.error(err);
          });
      })
      .catch((error) => {
        if (error.response) {
          // console.log(error.response.data.message);
          if (error.response.status === 409) {
            alert(
              "MS 訂單轉換失敗, 訂單尚未準備完成, 請稍帶數分鐘後重試, 謝謝!"
            );
          } else {
            alert("MS 訂單轉換成功\n" + error.response.data.message);
          }
          // console.log(error.response.status);
          // console.log(typeof (error.response.status));
          // console.log(error.response.headers);
        } else {
          alert("MS 訂單轉換失敗, 系統會嘗試將狀態改為待退訂");
        }
        console.log(error);
        // window.location.reload();
      });

    //}
  }

  // Security Design
  const convertDataToHiddenCode = (val) => {
    let strLength = val.length;
    let data = "";
    if (strLength !== 0 && strLength !== undefined && strLength !== null) {
      for (let i = 0; i < strLength; i++) {
        data += "*";
      }
    }
    return data;
  };
  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  };
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  };
  const handleToggleShowAllMemberData = () => {
    setShowAllMemberData(!showAllMemberData);
  };
  const handleMouseDownAllMemberData = (e) => {
    e.preventDefault();
  };
  const handleClickShowAllMemberData = () => {
    if (showAllMemberData) {
      // 正常已開啟狀態。
      handleToggleShowAllMemberData();
    } else {
      // 未開啟，故要先行登入。
      handleOpenLoginDialog();
    }
  };

  // const handleSetNegativePrice = (isNegative, index) => {
  //   var sign = (isNegative) ? -1 : 1;
  //   let newItemValues = [...otherServiceInfo];
  //   newItemValues[index].isNegative = isNegative;
  //   var tmpPrice = parseInt(newItemValues[index].servicePrice);
  //   tmpPrice = (isNaN(tmpPrice)) ? 0 : sign * Math.abs(tmpPrice);
  //   newItemValues[index].servicePrice = tmpPrice;
  //   setOtherServiceInfo(newItemValues);
  // }

  const setKeepModalStatus = (isKeep) => {
    setKeepModal(isKeep);
  };

  const getOrder = (sn) => {
    // console.log(sn)
    //console.log("props.sn=" + props.sn)
    const getOrderList = Axios.post(getOrderURL, {
      Sn: sn,
      productBrandSn: BrandSnMicrosoft,
    });
    const getOrderLogList = Axios.post(getOrderLogURL, { Sn: sn });
    const getOrderWatcherLogList = Axios.post(getOrderWatcherLogURL, {
      Sn: sn,
    });
    const getOutSourceMaintenanceInfo = Axios.post(
      getOutSourceMaintenanceInfoURL,
      { Sn: sn }
    );
    const getOtherServiceInfo = Axios.post(getOrderOtherServiceInfoURL, {
      Sn: sn,
    });
    const getAmEmailList = Axios.post(getAmEmailListURL, { OrderSn: sn });

    // Microsoft 的訂單狀態
    const getOrderStatusList = Axios.post(getOrderStatusURL, {
      brandSn: BrandSnMicrosoft,
    });

    const getProductDetailListByAnnual = Axios.post(getProductDetailListByAnnualURL, {
      orderSn: props.sn,
    });

    Axios.all([
      getOrderList,
      getOrderLogList,
      getOrderWatcherLogList,
      getOutSourceMaintenanceInfo,
      getOrderStatusList,
      getOtherServiceInfo,
      getAmEmailList,
      getProductDetailListByAnnual,
    ]).then(
      Axios.spread(
        (
          orderResponse,
          orderLogResponse,
          orderWatcherLogResponse,
          outSourceMaintenanceInfoResponse,
          orderStatusResponse,
          otherServiceInfoResponse,
          getAmEmailListResponse,
          getProductDetailListByAnnualResponse
        ) => {
          var data = orderResponse.data;
          // setMigrateNCE(data.orderDetail.isEligibleMigrateNCE);
          setOrderDetail(data.orderDetail);

          // setMigrateNCE(data.orderDetail.isEligibleMigrateNCE);
          if (data.orderDetail?.waitForActivatingHn === true) {
            setKeepModal(true);
          } else {
            setKeepModal(false);
          }

          if (data.orderDetail?.orderChargeTypeSn) {
            // 限定年繳才能修改商品
            setCanModifyProduct(
              // true // 測試參數
              data.orderDetail?.orderChargeTypeSn === orderChargeTypeYear
            );
          }

          setMember(data.member);
          setRegister(data.register);
          if (data.charge) {
            setCharge(data.charge);
          } else {
            setCharge(chargeDefault);
          }
          //setBillingCycle(data.orderDetail.billingCycle);

          productOfferId = data.product.msNceOfferId; // 先記錄商品碼當之後過濾條件用
          // 為了用表單呈現，才換成array
          var productList = [];
          productList.push(data.product);
          setProduct(productList);
          setOrderLog(
            orderLogResponse.data.log != null ? orderLogResponse.data.log : []
          );
          setMaintenanceDetail(outSourceMaintenanceInfoResponse.data);
          setBufferedMaintenanceDetail(outSourceMaintenanceInfoResponse.data);
          setOrderWatcherLog(
            orderWatcherLogResponse.data.log != null
              ? orderWatcherLogResponse.data.log
              : []
          );
          setOtherServiceInfo(
            otherServiceInfoResponse.data.list != null
              ? otherServiceInfoResponse.data.list
              : []
          );
          // setBufferedOrderOtherServiceInfo(
          //   otherServiceInfoResponse.data.list != null ? otherServiceInfoResponse.data.list : [],
          // )
          // console.log(otherServiceInfoResponse.data.list);

          // console.log(getAmEmailListResponse.data.amEmailList);
          if (getAmEmailListResponse.data.amEmailList != null) {
            var amEmailObjList = [];
            for (
              var i = 0;
              i < getAmEmailListResponse.data.amEmailList.length;
              ++i
            ) {
              var tmpAMEmailObj = {};
              tmpAMEmailObj["amEmail"] =
                getAmEmailListResponse.data.amEmailList[i];
              amEmailObjList.push(tmpAMEmailObj);
            }
            setAmEmailList(amEmailObjList);
            setBufferedAmEmailList(amEmailObjList);
          } else {
            setAmEmailList([]);
            setBufferedAmEmailList([]);
          }
          // setOriginRowNumOfOtherService(
          //   otherServiceInfoResponse.data.list != null ? otherServiceInfoResponse.data.list.length : 0,
          // )

          // Microsoft 的訂單狀態清單
          // console.table(orderStatusResponse.data.dataList);
          setOrderStatusList(orderStatusResponse.data.dataList);
          setProductDetailListByAnnual(getProductDetailListByAnnualResponse.data);
          //console.log("123");
          //console.table(getProductDetailListByAnnualResponse.data);
          //console.log("456");
        }
      )
    );
  };

  const migrateOrder = (sn) => {
    if (window.confirm("確定將此單遷移至 NCE 訂單?")) {
      var postData = {};
      postData.OrderSn = parseInt(sn);
      postData.LoginSession = cookies.session;
      Axios.post("/api/v0/createMigrationNCE", postData, { timeout: 30000 })
        .then(function (response) {
          alert(response.data.message);
          console.log(response);
        })
        .catch(function (err) {
          if (err.reponse) {
            alert(err.response.data.message);
          }
          console.log(err);
          alert(
            "本單已是 NCE 單或不符合遷移條件\n請洽微軟合作夥伴網站查明細項, 謝謝!"
          );
        });
    }
  };

  const updateQcPrice = (set = false) => {
    var postData = {};

    var orderNo = orderDetail.orderNo;
    postData["orderNo"] = orderNo;
    postData["set"] = set;
    alert("更新成功!");
    Axios.post(updateOrCancelQCAccountingPriceChangeURL, postData)
      .then(function (res) {
        console.log(res);
        insertOrderManagementLog(
          "更新品管金額",
          "",
          (set ? "設定" : "取消") + "更新品管金額"
        );
      })
      .catch(function (err) {
        console.log(err);
        insertOrderManagementLog(
          "更新品管金額",
          "",
          (set ? "設定" : "取消") + "更新品管金額失敗"
        );
        alert("更新失敗!");
      });
  };

  //解釋  getTodayDate函數：取得今天的日期並格式化為YYYY-MM-DD格式，這是日期選擇器的標準格式。
  //TextField組件的InputProps：透過設定inputProps的max屬性為今天的日期，禁用未來的日期。
  //這樣一來，當使用者在日期選擇器中選擇日期時，未來的日期將不可選，且會被反灰顯示。2024/05/27 BY WILLY TSAI
  // 獲取今天的日期
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  function insertOrderManagementLog(
    field = "",
    beforeValue = "",
    afterValue = ""
  ) {
    var postData = {};
    postData["OpName"] = cookies.op_name;
    postData["OrderNo"] = orderDetail.orderNo;
    postData["Field"] = field;
    postData["BeforeValue"] = beforeValue;
    postData["AfterValue"] = afterValue;

    Axios.post(insertOrderManagementLogURL, postData)
      .then(function (res) {
        console.log(res);
        return true;
      })
      .catch(function (err) {
        console.log(err);
        return false;
      });
  }

  function ResponsiveDialog(props) {
    return (
      <React.Fragment>
        <Button variant="outlined" onClick={handleResponsiveDialogClickOpen}>
          Open responsive dialog
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={props.open}
          onClose={handleResponsiveDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"月繳轉年繳提醒通知"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              是否確定要月繳轉年繳?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleResponsiveDialogCancel}>
              取消
            </Button>
            <Button onClick={handleResponsiveDialogOk} autoFocus>
              同意
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  // const mpnCheckDomainByDomain = () => {
  //   if (register.domain !== "") {
  //     Axios.post("/api/v0/mpnCheckDomainByDomain", { domain: register.domain })
  //       .then((response) => {
  //         setDomainError(false);
  //       })
  //       .catch((error) => {
  //         setDomainError(true);
  //       });
  //   }
  // };

  const autoRenewalOptions = [
    {
      name: "關閉",
      value: false,
    },
    {
      name: "開啟",
      value: true,
    },
  ];
  const detailFieldList = [
    createLabel("訂單編號:", orderDetail.orderNo),
    createLabel("訂單時間:", datetimeStringFormat(orderDetail.orderDate)),
    createLabel("DSIS單號:", orderDetail.dsisNo),
    createLabel("DSIS優惠代碼:", orderDetail.productDiscode),
    createLabel("收費方式:", orderDetail.orderChargeTypeSn),
    createLabel("月繳轉年繳:",
      (orderDetail.orderChargeTypeSn === "月繳") ? (
        <Grid container spacing={2}>
          <NativeSelect
            id="demo-customized-select-native"
            value={productDetail.productDetailSn}
            name={"productDetailSn"}
            onChange={handleProductDetailListByAnnual}
            disabled={keepModal}
            required
          >
            <option></option>
            {productDetailListByAnnual === null ? "" : productDetailListByAnnual.map((productDetailListByAnnualKey, index) => {
              return (
                <option key={index} value={productDetailListByAnnualKey.sn} disabled={false}>
                  {productDetailListByAnnualKey.product_no}
                  {productDetailListByAnnualKey.product !== "" ? " - " + productDetailListByAnnualKey.product : ""}
                  {productDetailListByAnnualKey.discode !== "" ? " - " + productDetailListByAnnualKey.discode : ""}
                  {productDetailListByAnnualKey.price_special !== 0 ? " 優惠價: " + productDetailListByAnnualKey.price_special : ""}
                </option>
              );
            })}
          </NativeSelect>
          {/* 微軟到期日期：
          <TextField
            fullWidth
            type="datetime-local"
            helperText={"月繳轉年繳切齊日期"}
            name={"msEndDate"}
            value={ConvertStr2DatetimeLocal(productDetail.msEndDate)}
            disabled={keepModal}
            onChange={handleProductDetailListByAnnual}
            inputProps={{ step: 1 }} // for datetime to show second
          /> */}

          {/* <Grid item xs={6}>
            <TextField
              fullWidth
              name={"productDetailNo"}
              autoComplete="on"
              value={orderDetail.productDetailNo}
              onChange={handleChangeProductDetailNo}
              disabled={keepModal}
            />
          </Grid> */}
          <Grid item xs={2}>
            <Button
              color="primary"
              style={{
                backgroundColor: "#E7EFC6",
              }}
              onClick={() => handleResponsiveDialogClickOpen()}
              disabled={stayModal}
            >
              確定
            </Button>
          </Grid>
        </Grid>) : (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            {orderDetail.orderChargeTypeSn}
          </Grid>
        </Grid>
      )),
    // createLabel("收費方式:", billingCycle !== "" ? billingCycle : orderDetail.orderChargeTypeSn),
    // createLabel("收費方式:",
    //   <FormControl fullWidth>
    //     <NativeSelect
    //       id="demo-customized-select-native"
    //       value={orderDetail.orderChargeTypeSn}
    //       name={"orderChargeTypeSn"}
    //       onChange={handleChangeOrderDetail}
    //       disabled={keepModal}
    //       required
    //     >
    //       <option></option>
    //       {Object.keys(billingCycleList).map((billingCycle, index) => {
    //         return (
    //           <option key={index} value={orderStatusKey} disabled={false}>
    //             {orderStatusList[orderStatusKey]}
    //           </option>
    //         );
    //       })}
    //     </NativeSelect>
    //     <Typography variant="caption" display="block" gutterBottom>
    //       如改為微軟服務啟用時，請記得填寫啟用日期，以確保如期出帳
    // </Typography>
    //   </FormControl>,
    // ),
    createLabel("受理來源:", orderDetail.orderSourceTypeSn),
    // createLabel("訂單狀態:", orderDetail.orderStatusSn),
    createLabel(
      "訂單狀態",
      <FormControl fullWidth>
        <NativeSelect
          id="demo-customized-select-native"
          value={orderDetail.orderStatusSn}
          name={"orderStatusSn"}
          onChange={handleChangeOrderDetail}
          disabled={keepModal}
          required
        >
          <option></option>
          {Object.keys(orderStatusList).map((orderStatusKey, index) => {
            return (
              <option key={index} value={orderStatusKey} disabled={false}>
                {orderStatusList[orderStatusKey]}
              </option>
            );
          })}
        </NativeSelect>
        <Typography variant="caption" display="block" gutterBottom>
          如改為微軟服務啟用時，請記得填寫啟用日期，以確保如期出帳
        </Typography>
      </FormControl>
    ),
    createLabel(
      "自動續約選項:",
      // (orderDetail.autoRenewStatus === true) ? (
      //   <Button
      //     color="primary"
      //     style={{
      //       backgroundColor: "#E7EFC6"
      //     }}
      //     onClick={() => updateOrderAutoRenewStatus(false)}
      //   >
      //     按下以關閉自動續約
      //   </Button>
      // ) : (
      //   <Button
      //     color="primary"
      //     style={{
      //       backgroundColor: "#E7EFC6"
      //     }}
      //     onClick={() => updateOrderAutoRenewStatus(true)}
      //   >
      //     按下以開啟自動續約
      //   </Button>
      // ),
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <NativeSelect
            id="demo-customized-select-native"
            value={orderDetail.autoRenewStatus}
            name={"autoRenewStatus"}
            onChange={handleChangeOrderDetail}
            disabled={keepModal}
            required
          >
            {Object.keys(autoRenewalOptions).map(
              (autoRenewalOptionsKey, index) => {
                return (
                  <option
                    key={index}
                    value={autoRenewalOptions[index].value}
                    disabled={false}
                  >
                    {autoRenewalOptions[index].name}
                  </option>
                );
              }
            )}
          </NativeSelect>
        </Grid>
        <Grid item xs={10}>
          <Button
            color="primary"
            style={{
              backgroundColor: "#E7EFC6",
            }}
            onClick={() =>
              updateOrderAutoRenewStatus(orderDetail.autoRenewStatus)
            }
          >
            確定
          </Button>
        </Grid>
      </Grid>
    ),
    // orderDetail.quantity
    createLabel(
      "帳號數量:",
      <div>
        {register.msSubscribeNo === "" && orderDetail.dsisNo === "" ? (
          <TextField
            fullWidth
            name={"quantity"}
            type="number"
            value={orderDetail.quantity}
            onChange={handleChangeOrderDetail}
            disabled={keepModal}
          />
        ) : (
          orderDetail.quantity
        )}
      </div>
    ),
    createLabel("累計出帳金額:", orderDetail.accumulatedPrice),
    createLabel(
      "推廣員工代號:",
      <TextField
        fullWidth
        name={"promotionEmployeeNo"}
        autoComplete="off"
        value={orderDetail.promotionEmployeeNo}
        onChange={handleChangeOrderDetail}
        disabled={keepModal}
      />
    ),
    createLabel(
      "退訂日期：",
      <TextField
        fullWidth
        type="datetime-local"
        helperText={"空白為下個月自動續訂"}
        name={"orderDisSubscribeDate"}
        value={ConvertStr2DatetimeLocal(orderDetail.orderDisSubscribeDate)}
        disabled={keepModal}
        onChange={handleChangeOrderDetail}
        inputProps={{ step: 1 }} // for datetime to show second
      />
    ),
    createLabel(
      "契約編號 k1 碼：",
      <TextField
        fullWidth
        name={"contractNoK1"}
        autoComplete="off"
        value={orderDetail.contractNoK1}
        onChange={handleChangeOrderDetail}
        disabled={keepModal}
      />
    ),
    // (migrateNCE ? createLabel(
    //   "遷移至NCE訂單",
    //   (migrateNCE) ? (
    //     <Button
    //       color="primary"
    //       style={{
    //         backgroundColor: "#E7EFC6"
    //       }}
    //       onClick={() => migrateOrder(props.sn)}
    //     >遷移</Button>
    //   ) : (
    //     <Typography color="primary">此單無法遷移至NCE</Typography>
    //   ),
    // ) : null),
    // createLabel(
    //   "遷移至NCE訂單",
    //   (migrateNCE) ? (
    //     <Button
    //       color="primary"
    //       style={{
    //         backgroundColor: "#E7EFC6"
    //       }}
    //       onClick={() => migrateOrder(props.sn)}
    //     >遷移</Button>
    //   ) : (
    //     <Typography color="primary">此單無法遷移至NCE</Typography>
    //   ),
    // ),
    createLabel(
      "遷移至NCE訂單",
      <Button
        color="primary"
        style={{
          backgroundColor: "#E7EFC6",
        }}
        onClick={() => migrateOrder(props.sn)}
      >
        遷移
      </Button>
    ),
    createLabel(
      "MKT會員HD碼:",
      <TextField
        fullWidth
        margin="dense"
        // label="MKT會員HD碼"
        name="hd"
        value={orderDetail.hd}
        onChange={handleChangeOrderDetail}
        autoComplete="off"
        required
      />
    ),
    createLabel(
      "備註：",
      <TextField
        fullWidth
        multiline
        rows={5}
        variant="outlined"
        name={"operatorNote"}
        value={orderDetail.operatorNote}
        disabled={keepModal}
        onChange={handleChangeOrderDetail}
      />
    ),
  ];

  const memberFieldList = [
    createLabel("HN帳號:", member.hn),
    createLabel("公司/客戶名稱:", member.name),
    createLabel("聯絡人（姓）:", member.contactLastName),
    createLabel("聯絡人（名）:", member.contactName),
    createLabel(
      "統編/證號:",
      showAllMemberData
        ? member.companyNo
        : convertDataToHiddenCode(member.companyNo)
    ),
    createLabel(
      "連絡電話:",
      showAllMemberData
        ? member.telMorning
        : convertDataToHiddenCode(member.telMorning)
    ),
    createLabel(
      "聯絡電話(手機):",
      showAllMemberData ? member.cell : convertDataToHiddenCode(member.cell)
    ),
    createLabel(
      "帳單地址:",
      showAllMemberData
        ? member.address
        : convertDataToHiddenCode(member.address)
    ),
    createLabel(
      "郵遞區號:",
      showAllMemberData ? member.zip : convertDataToHiddenCode(member.zip)
    ),
    createLabel("聯絡email:", member.email),
  ];

  const registerFieldList = [
    createLabel(
      "訂閱識別碼：",
      <Input
        fullWidth
        name={"msSubscribeNo"}
        value={register.msSubscribeNo}
        disabled={keepModal}
        onChange={handleChangeRegister}
        type={showAllMemberData ? "text" : "password"}
      />
    ),
    createLabel(
      "Microsoft ID：",
      <Input
        fullWidth
        name={"msId"}
        value={register.msId}
        disabled={keepModal}
        onChange={handleChangeRegister}
        type={showAllMemberData ? "text" : "password"}
      />
    ),
    createLabel(
      "使用網域:",
      <Input
        fullWidth
        name={"domain"}
        value={register.domain}
        onChange={handleChangeRegister}
        disabled={keepModal}
        endAdornment={".onmicrosoft.com"}
        type={showAllMemberData ? "text" : "password"}
        required
      // error={domainError}
      // onBlur={() => mpnCheckDomainByDomain()}
      />
    ),
    createLabel(
      "密碼：",
      <Input
        fullWidth
        name={"msMima"}
        value={register.msMima}
        disabled={keepModal}
        onChange={handleChangeRegister}
        type={showAllMemberData ? "text" : "password"}
      />
    ),
    createLabel(
      "啟用日期(收費)：",
      <TextField
        fullWidth
        type="datetime-local"
        name={"orderStartDate"}
        helperText={"如需要更動日期，請開通微軟後再進行更動"}
        value={ConvertStr2DatetimeLocal(register.orderStartDate)}
        disabled={keepModal}
        onChange={handleChangeRegister}
        inputProps={{ step: 1 }} // for datetime to show second
      />
    ),
  ];
  const chargeFieldList = [
    createLabel("出帳時間:", datetimeStringFormat(charge.accountingDate)),
    createLabel("目前訂單狀態：", charge.orderChargeStatusSn),
    createLabel("總收費價錢：", charge.totalPrice),
    createLabel("AAA-TAG：", charge.aaaTag),
    createLabel(
      "更新品管金額:",
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Button
            variant="contained"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#007A33",
            }}
            onClick={() => updateQcPrice(true)}
          >
            設定
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#FF0000",
            }}
            onClick={() => updateQcPrice(false)}
          >
            取消
          </Button>
        </Grid>
      </Grid>
    ),
  ];

  const additionalMaintenance = [
    createLabel(
      "5X8 維運點數:",
      <TextField
        fullWidth
        type="number"
        name={"fiveEightCredit"}
        value={maintenanceDetail.fiveEightCredit}
        onChange={handleChangeMaintenanceDetail}
        disabled={keepModal}
      />
    ),
    createLabel(
      "單價(新台幣元):",
      <TextField
        fullWidth
        type="number"
        name={"fiveEightCreditPrice"}
        value={maintenanceDetail.fiveEightCreditPrice}
        onChange={handleChangeMaintenanceDetail}
        disabled={keepModal}
      />
    ),
    createLabel(
      "非 5X8 維運點數:",
      <TextField
        fullWidth
        type="number"
        name={"nonFiveEightCredit"}
        value={maintenanceDetail.nonFiveEightCredit}
        onChange={handleChangeMaintenanceDetail}
        disabled={keepModal}
      />
    ),
    createLabel(
      "單價(新台幣元):",
      <TextField
        fullWidth
        type="number"
        name={"nonFiveEightCreditPrice"}
        value={maintenanceDetail.nonFiveEightCreditPrice}
        onChange={handleChangeMaintenanceDetail}
        disabled={keepModal}
      />
    ),
  ];

  function getOtherServiceInfoListBeforeEdit() {
    // Newly added
    var changeLogMsg = "";
    for (var i in bufferedOrderOtherServiceInfo) {
      changeLogMsg +=
        "名稱:" +
        bufferedOrderOtherServiceInfo[i].serviceName +
        ", 價格:" +
        bufferedOrderOtherServiceInfo[i].servicePrice +
        "  ";
      if (bufferedOrderOtherServiceInfo[i].isMainCharge !== undefined) {
        changeLogMsg +=
          "報帳類別:" +
          (bufferedOrderOtherServiceInfo[i].isMainCharge
            ? "常規補報"
            : "普通一次") +
          "  ";
      }
      if (bufferedOrderOtherServiceInfo[i].chargeDate !== undefined) {
        changeLogMsg +=
          "報帳時間:" + bufferedOrderOtherServiceInfo[i].chargeDate + "  ";
      }
    }
    return changeLogMsg;
  }

  function getOtherServiceInfoListAfterEdit() {
    // Newly added
    var changeLogMsg = "";
    for (var i in otherServiceInfo) {
      changeLogMsg +=
        "名稱:" +
        otherServiceInfo[i].serviceName +
        ", 價格:" +
        otherServiceInfo[i].servicePrice +
        "  ";
      if (otherServiceInfo[i].isMainCharge !== undefined) {
        changeLogMsg +=
          "報帳類別:" +
          (otherServiceInfo[i].isMainCharge ? "常規補報" : "普通一次") +
          "  ";
      }
      if (otherServiceInfo[i].chargeDate !== undefined) {
        changeLogMsg += "報帳時間:" + otherServiceInfo[i].chargeDate + "  ";
      }
    }
    return changeLogMsg;
  }

  function getAmEmailListBeforeEdit() {
    // Newly added
    var changeLogMsg = "";
    for (var i in bufferedAmEmailList) {
      changeLogMsg += "Email:" + bufferedAmEmailList[i].amEmail + "  ";
    }
    return changeLogMsg;
  }

  function getAmEmailListAfterEdit() {
    // Newly added
    var changeLogMsg = "";
    for (var i in amEmailList) {
      changeLogMsg += "Email:" + amEmailList[i].amEmail + "  ";
    }
    return changeLogMsg;
  }

  function getMaintenanceDetailInfoDiffForChangeLog() {
    // bufferedMaintenanceDetail
    // maintenanceDetail
    var changeLogObj = {};
    changeLogObj["before"] = "";
    changeLogObj["after"] = "";
    if (
      bufferedMaintenanceDetail.fiveEightCredit !== undefined &&
      maintenanceDetail.fiveEightCredit !== undefined
    ) {
      changeLogObj["before"] +=
        " 5x8 credit: " + bufferedMaintenanceDetail.fiveEightCredit + "點";
      if (
        bufferedMaintenanceDetail.fiveEightCredit !==
        maintenanceDetail.fiveEightCredit
      ) {
        changeLogObj["after"] +=
          " 5x8 credit: " + maintenanceDetail.fiveEightCredit + "點";
      }
    }
    if (
      bufferedMaintenanceDetail.fiveEightCreditPrice !== undefined &&
      maintenanceDetail.fiveEightCreditPrice !== undefined
    ) {
      changeLogObj["before"] +=
        " 5x8 price: " + bufferedMaintenanceDetail.fiveEightCreditPrice + "元";
      if (
        bufferedMaintenanceDetail.fiveEightCreditPrice !==
        maintenanceDetail.fiveEightCreditPrice
      ) {
        changeLogObj["after"] +=
          " 5x8 price: " + maintenanceDetail.fiveEightCreditPrice + "元";
      }
    }
    if (
      bufferedMaintenanceDetail.nonFiveEightCredit !== undefined &&
      maintenanceDetail.nonFiveEightCredit !== undefined
    ) {
      changeLogObj["before"] +=
        " non-5x8 credit: " +
        bufferedMaintenanceDetail.nonFiveEightCredit +
        "點";
      if (
        bufferedMaintenanceDetail.nonFiveEightCredit !==
        maintenanceDetail.nonFiveEightCredit
      ) {
        changeLogObj["after"] +=
          " non-5x8 credit: " + maintenanceDetail.nonFiveEightCredit + "點";
      }
    }
    if (
      bufferedMaintenanceDetail.nonFiveEightCreditPrice !== undefined &&
      maintenanceDetail.nonFiveEightCreditPrice !== undefined
    ) {
      changeLogObj["before"] +=
        " non-5x8 price: " +
        bufferedMaintenanceDetail.nonFiveEightCreditPrice +
        "元";
      if (
        bufferedMaintenanceDetail.nonFiveEightCreditPrice !==
        maintenanceDetail.nonFiveEightCreditPrice
      ) {
        changeLogObj["after"] +=
          " non-5x8 price: " + maintenanceDetail.nonFiveEightCreditPrice + "元";
      }
    }
    return changeLogObj;
  }

  async function saveMaintenanceDetail() {
    // message
    var message = "";
    try {
      // 5X8 Service Data
      var postData = {};
      postData["Sn"] = orderSn;
      postData["FiveEightCredit"] = maintenanceDetail.fiveEightCredit;
      postData["FiveEightCreditPrice"] = maintenanceDetail.fiveEightCreditPrice;
      postData["NonFiveEightCredit"] = maintenanceDetail.nonFiveEightCredit;
      postData["NonFiveEightCreditPrice"] =
        maintenanceDetail.nonFiveEightCreditPrice;
      await Axios.post("/api/order/setOutSourceMaintenanceInfo", postData)
        .then((response) => {
          console.log(response);
          message += "維運購買數量已儲存\n";
        })
        .catch((err) => {
          console.log(err);
          alert("後端異常");
        });

      // Other Service Data
      await Axios.post("/api/order/updateOrderOtherServiceInfo", {
        OrderSn: orderSn,
        OtherServiceList: otherServiceInfo,
      })
        .then((response) => {
          console.log(response);
          message += "自訂服務欄位已儲存\n";
          var originalLog = getOtherServiceInfoListBeforeEdit();
          var changeLog = getOtherServiceInfoListAfterEdit();
          if (originalLog !== changeLog) {
            if (
              insertOrderManagementLog(
                "自訂加購加值服務",
                originalLog,
                changeLog
              )
            ) {
              setBufferedOrderOtherServiceInfo(otherServiceInfo);
            }
          }

          var fiveEightChangeLog = getMaintenanceDetailInfoDiffForChangeLog();
          if (
            fiveEightChangeLog["before"] !== undefined &&
            fiveEightChangeLog["after"] !== undefined &&
            fiveEightChangeLog["after"] !== ""
          ) {
            insertOrderManagementLog(
              "5x8加購加值服務",
              fiveEightChangeLog["before"],
              fiveEightChangeLog["after"]
            );
          }
          setBufferedMaintenanceDetail(maintenanceDetail);
        })
        .catch((err) => {
          console.log(err);
          alert("後端異常");
        });
      alert(message);
    } catch (error) {
      alert(error);
    }
  }

  async function saveAmEmailList() {
    // message
    var message = "";
    try {
      // AM email list
      var emailList = [];

      for (var i = 0; i < amEmailList.length; ++i) {
        console.log(amEmailList[i]);
        emailList.push(amEmailList[i].amEmail);
      }

      // AM email list Data
      await Axios.post("/api/order/insertAmEmailList", {
        OrderSn: orderSn,
        EmailList: emailList,
      })
        .then((response) => {
          console.log(response);
          message += "AM email 清單已儲存\n";
          var originalLog = getAmEmailListBeforeEdit();
          var changeLog = getAmEmailListAfterEdit();
          if (originalLog !== changeLog) {
            insertOrderManagementLog("AM Email 清單", originalLog, changeLog);
          }

          setBufferedAmEmailList(amEmailList);
        })
        .catch((err) => {
          console.log(err);
          alert("後端異常");
        });
      alert(message);
    } catch (error) {
      alert(error);
    }
  }

  function productDetailTable(product) {
    const columns = [
      { title: "商品編號", field: "no" },
      { title: "付款PID", field: "pid" },
      { title: "商品名稱", field: "name" },
      {
        title: "商品方案",
        field: "plan",
        render: (rowData) => (
          <>
            {rowData.plan}
            {canModifyProduct && (
              <>
                (
                <Link component="button" onClick={() => setOpenSPM(true)}>
                  按我修改
                </Link>
                )
              </>
            )}
          </>
        ),
      },
      { title: "商品折扣", field: "description" },
      // { title: "商品啟用狀態", field: "orderServiceStatusSn" },
      { title: "數量", field: "quantity" },
      { title: "單價", field: "price" },
      {
        title: "合計",
        field: "price",
        render: (rowData) => rowData.quantity * rowData.price,
      },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">購買商品明細</Typography>
        <DialogContent dividers>{createTable(columns, product)}</DialogContent>
      </Box>
    );
  }

  // Security Design
  function watcherLogTable(watcherLog) {
    const columns = [
      {
        title: "時間",
        field: "watchTime",
        render: (rowData) => datetimeStringFormat(rowData.watchTime),
      },
      {
        title: "查看人員",
        field: "operator",
        render: (rowData) =>
          showAllMemberData
            ? rowData.operator
            : convertDataToHiddenCode(rowData.operator),
      },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          機敏資料查看紀錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>
          {createTable(columns, watcherLog)}
        </DialogContent>
      </Box>
    );
  }

  function logTable(orderLog) {
    const columns = [
      {
        title: "時間",
        field: "modifyDate",
        render: (rowData) => datetimeStringFormat(rowData.modifyDate),
      },
      {
        title: "修改者",
        field: "operator",
        render: (rowData) =>
          showAllMemberData
            ? rowData.operator
            : convertDataToHiddenCode(rowData.operator),
      },
      {
        title: "欄位",
        field: "field",
        render: (rowData) => {
          if (!fieldMapingList[rowData.field])
            return showAllMemberData
              ? rowData.field
              : convertDataToHiddenCode(rowData.field);
          return showAllMemberData
            ? fieldMapingList[rowData.field]
            : convertDataToHiddenCode(fieldMapingList[rowData.field]);
        },
      },
      {
        title: "修改前資料",
        field: "beforeModify",
        render: (rowData) =>
          showAllMemberData
            ? rowData.beforeModify
            : convertDataToHiddenCode(rowData.beforeModify),
      },
      {
        title: "修改後資料",
        field: "afterModify",
        render: (rowData) =>
          showAllMemberData
            ? rowData.afterModify
            : convertDataToHiddenCode(rowData.afterModify),
      },
      // { title: "動作", field: "action" },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          訂單更改記錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, orderLog)}</DialogContent>
      </Box>
    );
  }

  function createTable(columns, datas) {
    return (
      <MaterialTable
        columns={columns}
        data={datas}
        options={{
          search: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          padding: "dense",
          sorting: false,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
          }),
        }}
      />
    );
  }

  function createLabel(title, text) {
    return (
      <Grid container>
        <Grid item xs={3}>
          <Box m={1}>{title}</Box>
        </Grid>
        <Grid item xs={9}>
          <Box m={1}>{text}</Box>
        </Grid>
      </Grid>
    );
  }

  function datetimeStringFormat(time) {
    // time is string
    if (time === "" || time === null) {
      return "";
    } else {
      return format(new Date(time), datetimeFormat);
    }
  }

  function createMaintenanceTable(title, detailFieldList) {
    return (
      <Box m={2}>
        <Typography variant="h6">{title}</Typography>
        <DialogContent dividers>
          <Grid container>
            {additionalMaintenance.map((data, index) => {
              return (
                <Grid item xs={xsSize} md={mdSize} key={index}>
                  {data}
                </Grid>
              );
            })}
          </Grid>
          <br />
          <Grid container>
            {detailFieldList.map((data, index) => (
              <Grid container key={index} alignItems="center">
                <Grid item xs={2}>
                  <Box m={1}>{"自訂名稱 - " + (index + 1) + "："}</Box>
                </Grid>
                <Grid item xs={1}>
                    <NativeSelect
                      // id="demo-customized-select-native"
                      value={""}
                      onChange={(e) =>
                        handleChangeOtherService(index, {
                          target: {
                            name: "serviceName",
                            value: e.target.value,
                            type: "text",
                          },
                        })
                      }
                      disabled={keepModal}
                      required
                    >
                      <option value="" disabled>
                        常用品項
                      </option>
                      <option disabled>========</option>
                    {getServiceNamefilterOptions(BrandMicrosoftSn).map(
                      (filterOption, index) => {
                        return (
                          <option
                            key={`serviceNamefilter-${index}`}
                            value={filterOption}
                            // disabled={false}
                          >
                            {filterOption}
                          </option>
                        );
                      }
                    )}
                    </NativeSelect>
                </Grid>
                <Grid item xs={3}>
                  <Box m={1}>
                    <TextField
                      fullWidth
                      type="text"
                      name={"serviceName"}
                      value={data.serviceName}
                      onChange={(e) => handleChangeOtherService(index, e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box m={1}>{"型態:"}</Box>
                </Grid>
                <Grid item xs={1}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={data.isMainCharge}
                    name={"isMainCharge"}
                    onChange={(e) => handleChangeOtherService(index, e)}
                    disabled={keepModal}
                    required
                  >
                    {Object.keys(isMainChargeOptions).map(
                      (isMainChargeOptionsKey, index) => {
                        return (
                          <option
                            key={index}
                            value={isMainChargeOptions[index].value}
                            disabled={false}
                          >
                            {isMainChargeOptions[index].name}
                          </option>
                        );
                      }
                    )}
                  </NativeSelect>
                </Grid>
                <Grid item xs={2}>
                  <Box m={2}>{"單價(新台幣元)："}</Box>
                </Grid>
                {/* <Grid item xs={1}>
                  <Box m={1}>
                    {
                      (!otherServiceInfo[index].isNegative) ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleSetNegativePrice(true, index)}
                        >正</Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleSetNegativePrice(false, index)}
                        >負</Button>
                      )}
                  </Box>
                </Grid> */}
                <Grid item xs={2}>
                  <Box m={1} style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      fullWidth
                      type="number"
                      name={"servicePrice"}
                      value={isNaN(data.servicePrice) ? 0 : data.servicePrice}
                      onChange={(e) => handleChangeOtherService(index, e)}
                    />
                    <Tooltip title="Remove a Service" placement="bottom">
                      <IconButton
                        onClick={() => handleRemoveRow(index)}
                        aria-label="Remove a Service"
                      >
                        <DeleteIcon></DeleteIcon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
                <br />
                {data.isMainCharge ? (
                  <>
                    <Grid item xs={5}>
                      <Box m={1}>補報日期（用於紀錄報帳，並非實際執行日期）：</Box>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        // fullWidth
                        type="date"
                        name={"chargeDate"}
                        value={data.chargeDate}
                        onChange={(e) => handleChangeOtherService(index, e)} // Assuming index is not needed here
                        InputProps={{
                          inputProps: {
                            max: getTodayDate(),
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box m={1}>
                        <Tooltip title="範例：若『漏帳那年』或『今年』的啟用日期為「2023/02/19」，則【月繳訂單】請填入「2023/03/01」，而【年繳訂單】請填入「2023/03/15」。" placement="top-end">
                          <Alert severity="info">常規補報日期規則：「『漏帳那年』或『今年』啟用日期的下一個報帳日（月繳：每月1號 / 年繳：每月15號）」。</Alert>
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box m={1}>
                        <Tooltip title="範例：若需切齊授權日期為「2025/04/19」，則需先確認啟用日期是否已填入上一個週期日「2024/04/19」，補報日期請填入「2024/05/15」。" placement="bottom-end">
                          <Alert severity="warning">若為需切齊授權之年繳訂單，請先確認啟用日期是否已填入「切齊授權日期的上一個週期日」，再依照上述的常規補報日期規則填入。</Alert>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Tooltip title="Add a Service" placement="bottom">
                <Button
                  onClick={handleAddRow}
                  aria-label="Add a Service"
                  variant="outlined"
                  color="secondary"
                >
                  新增自訂欄位
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Save all Services Info" placement="bottom">
                <Button
                  onClick={saveMaintenanceDetail}
                  aria-label="Save all Services Info"
                  variant="outlined"
                  color="primary"
                >
                  儲存購買資訊
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    );
  }

  function createAmEmailListTable(title, detailFieldList) {
    return (
      <Box m={2}>
        <Typography variant="h6">{title}</Typography>
        <DialogContent dividers>
          <Grid container>
            {detailFieldList.map((data, index) => (
              <Grid container key={index} alignItems="center">
                <Grid item xs={2}>
                  <Box m={1}>{"Email - " + (index + 1) + "："}</Box>
                </Grid>
                <Grid item xs={4}>
                  <Box m={1}>
                    <TextField
                      fullWidth
                      type="text"
                      name={"amEmail"}
                      value={data.amEmail}
                      onChange={(e) => handleChangeAmEmailList(index, e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box m={1}>
                    <Tooltip title="Remove a Service" placement="bottom">
                      <IconButton
                        onClick={() => handleRemoveRowOfEmailList(index)}
                        aria-label="Remove a Service"
                      >
                        <DeleteIcon></DeleteIcon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Tooltip title="Add a Service" placement="bottom">
                <Button
                  onClick={handleAddRowOfEmailList}
                  aria-label="Add a Service"
                  variant="outlined"
                  color="secondary"
                >
                  新增 AM Email
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Save all Services Info" placement="bottom">
                <Button
                  onClick={saveAmEmailList}
                  aria-label="Save all Services Info"
                  variant="outlined"
                  color="primary"
                >
                  儲存 AM Email 清單
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    );
  }

  let handleAddRow = () => {
    if (window.confirm("確定新增資料？")) {
      console.log("Come handleAddRow.");
      setOtherServiceInfo([
        ...otherServiceInfo,
        {
          sn: -1,
          serviceName: "",
          servicePrice: "",
          isNegative: false,
          isMainCharge: false,
          chargeDate: "",
        },
      ]);
    }
  };

  let handleRemoveRow = (i) => {
    if (window.confirm("確定刪除資料？")) {
      console.log("Come handleRemoveRow.");
      let newItemValues = [...otherServiceInfo];
      newItemValues.splice(i, 1);
      setOtherServiceInfo(newItemValues);
    }
  };

  let handleAddRowOfEmailList = () => {
    if (window.confirm("確定新增資料？")) {
      console.log("Handling Add Row Of Email List.");
      setAmEmailList([...amEmailList, { amEmail: "" }]);
    }
  };

  let handleRemoveRowOfEmailList = (i) => {
    if (window.confirm("確定刪除資料？")) {
      console.log("Come handleRemoveRow.");
      let newItemValues = [...amEmailList];
      newItemValues.splice(i, 1);
      setAmEmailList(newItemValues);
    }
  };

  return (
    <Dialog
      className="orderDetailDialog"
      open={modalStatus}
      // onClose={closeModal}
      maxWidth="lg"
      scroll="paper"
      disableScrollLock={false}
    >
      <Box>
        <form>
          <DialogTitle>訂單資訊</DialogTitle>
          <DialogContent dividers>
            {productDetailTable(product)}
            {dataTable("訂購資料", detailFieldList)}
            {/* {dataTable("訂購人資料", memberFieldList)} */}

            <Box m={2}>
              <Typography variant="h6">
                訂購人資料
                <IconButton
                  aria-label="toggle personal data visibility"
                  onClick={handleClickShowAllMemberData}
                  onMouseDown={handleMouseDownAllMemberData}
                  edge="end"
                >
                  {showAllMemberData ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Typography>
              <DialogContent dividers>
                <Grid container>
                  {memberFieldList.map((data, index) => {
                    return (
                      <Grid item xs={xsSize} md={mdSize} key={index}>
                        {data}
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
            </Box>

            {/* {dataTable("微軟供裝資料", registerFieldList)} */}

            <Box m={2}>
              <Typography variant="h6">
                微軟供裝資料
                <IconButton
                  aria-label="toggle personal data visibility"
                  onClick={handleClickShowAllMemberData}
                  onMouseDown={handleMouseDownAllMemberData}
                  edge="end"
                >
                  {showAllMemberData ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Typography>
              <DialogContent dividers>
                <Grid container>
                  {registerFieldList.map((data, index) => {
                    return (
                      <Grid item xs={xsSize} md={mdSize} key={index}>
                        {data}
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
            </Box>

            {createMaintenanceTable(
              "加購加值服務(每週日統一結算出帳)",
              otherServiceInfo
            )}
            {createAmEmailListTable("AM Email 清單", amEmailList)}
            {dataTable("付款狀態(最後一次付款狀態)", chargeFieldList)}
            {logTable(orderLog)}
            {watcherLogTable(orderWatcherLog)}
          </DialogContent>
          <DialogActions>
            <SnackbarProvider maxSnack={1}>
              <DialogButtons
                orderSn={orderSn}
                getOrder={getOrder}
                orderDetail={orderDetail}
                member={member}
                register={register}
                charge={charge}
                session={cookies.session}
                opname={cookies.op_name}
                productDetailSn={product[0].sn}
                closeModal={() => closeModal(orderDetail.contractNoK1 === "")}
                keepModal={keepModal}
                setKeepModalStatus={setKeepModalStatus}
              />
            </SnackbarProvider>
          </DialogActions>
        </form>
      </Box>
      <SelectProductListModel
        open={openSPM}
        brandSn={BrandSnMicrosoft}
        otherProductItemFilter={otherProductItemFilter}
        onDialogCloseCB={(productDetail, fromExistedProductDetail = true) => {
          if (productDetail) {
            let changeProductDetail = {};
            if (fromExistedProductDetail) {
              // 後端 DB 格式
              // 原始訂單 orderHandler.go (data["product"] = make(map[string]interface{}))
              // 商品清單 productDetailHandler.go (data["product_id"] = productDetail.ProductSn.Pid)
              const {
                sn,
                discode,
                plan,
                price_special,
                product,
                product_id,
                product_no,
              } = productDetail;

              changeProductDetail = {
                sn: sn,
                no: product_no,
                pid: product_id,
                name: product,
                description: discode,
                plan: plan,
                price: price_special, // 這邊要顯示的價格是優惠價
              };
            } else {
              changeProductDetail = {
                ...productDetail,
                price: productDetail.priceSpecial, // 這邊要顯示的價格是優惠價
              };
            }

            setProduct((prevProduct) => {
              const prevPd = prevProduct[0];
              return [
                {
                  ...prevPd,
                  ...changeProductDetail,
                },
              ];
            });
          }

          setOpenSPM(false);
        }}
      />
      <LoginDialogForCheckID
        open={openLoginDialog}
        handleToggleShowAllMemberData={handleToggleShowAllMemberData}
        handleCloseLoginDialog={handleCloseLoginDialog}
      ></LoginDialogForCheckID>
      <ResponsiveDialog
        open={openResponsiveDialog}
      >
      </ResponsiveDialog>
    </Dialog>
  );
}

function dataTable(title, detailFieldList) {
  return (
    <Box m={2}>
      <Typography variant="h6">{title}</Typography>
      <DialogContent dividers>
        <Grid container>
          {detailFieldList.map((data, index) => {
            return (
              <Grid item xs={xsSize} md={mdSize} key={index}>
                {data}
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
    </Box>
  );
}

function DialogButtons(props) {
  const { enqueueSnackbar } = useSnackbar();
  var postSaveData = {
    sn: props.orderSn,
    productBrandSn: BrandSnMicrosoft,
    orderDetail: props.orderDetail,
    member: props.member,
    register: props.register,
    charge: props.charge,
    OpName: props.opname,
    ProductDetailSn: props.productDetailSn,
  };
  const handleShowMessage = (
    message,
    variant: VariantType,
    isMoment = true
  ) => {
    var autoHideDuration = 5000;
    if (!isMoment) {
      autoHideDuration = 500000;
    }
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: autoHideDuration,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const saveOrder = () => {
    missingK1Warning();
    missingActivateDate();
    if (window.confirm("確定要儲存資料？")) {
      // Post Data
      props.setKeepModalStatus(true);
      Axios.post(saveOrderURL, postSaveData)
        .then((response) => {
          handleShowMessage("系統訂單已儲存", "info");
          // window.location.reload();
          props.closeModal();
        })
        .catch((error) => {
          console.log(error);
          var postErr = showPostError("系統訂單", error);
          handleShowMessage(`${postErr}`, "error");
          props.setKeepModalStatus(false);
        });
    }
  };

  async function saveOrderAndActivateMs() {
    missingK1Warning();
    missingActivateDate();
    // 確認是否需要再次開通
    if (props.register.msSubscribeNo !== "") {
      if (!window.confirm("此商品已有開通紀錄，請再次確認是否需要開通")) {
        return;
      }
    }

    // 是否確定要儲存資料
    if (!window.confirm("確定儲存資料？")) {
      return;
    }

    props.setKeepModalStatus(true);
    // Save Order Information
    var postErr = "";
    try {
      await Axios.post(saveOrderURL, postSaveData);
      handleShowMessage("系統訂單已儲存，開通微軟帳號中...", "info", false);
    } catch (error) {
      postErr = showPostError("系統訂單", error);
      handleShowMessage(`${postErr}`, "error");
      props.setKeepModalStatus(false);
      return;
    }

    // Activate Microsoft Order
    var message = "";
    try {
      message = "(MS)檢查網域";
      handleShowMessage(`${message}...`, "info", false);
      await Axios.post("/api/v0/mpnCheckDomain", { sn: props.orderSn });
      handleShowMessage(`${message}---Success`, "success");

      message = "(MS)建立客戶";
      handleShowMessage(`${message}...`, "info", false);
      await Axios.post("/api/v0/mpnCreateCustomer", { sn: props.orderSn });
      handleShowMessage(`${message}---Success`, "success");

      message = "(MS)Agree Agreements";
      handleShowMessage(`${message}...`, "info", false);
      await Axios.post("/api/v0/mpnAgreeAgreements", { sn: props.orderSn });
      handleShowMessage(`${message}---Success`, "success");

      message = "(MS)建立訂單";
      handleShowMessage(`${message}...`, "info", false);
      await Axios.post("/api/v0/mpnCreateOrder", { sn: props.orderSn });
      handleShowMessage(`${message}---Success`, "success");

      message = "寄送微軟開通信";
      handleShowMessage(`${message}...`, "info", false);
      Axios.post(sendRegisterMailURL, { orderSn: props.orderSn });
      handleShowMessage(`${message}---Success`, "success");

      message = "設定退訂日期";
      handleShowMessage(`${message}...`, "info", false);
      Axios.post(sendRegisterMailURL, { orderSn: props.orderSn });
      await Axios.post("/api/order/updateOrderSubscribeDateByOrderSn", {
        sn: props.orderSn,
      });

      handleShowMessage(`${message}---Success`, "success");

      // window.location.reload();
      props.closeModal();
    } catch (error) {
      postErr = showPostError(message, error);
      handleShowMessage(`${postErr}`, "error");
      props.getOrder(props.orderSn);
      props.setKeepModalStatus(false);
      return;
    }
  }

  const mpnCheckDomainByDomain = () => {
    var message = "(MS)檢查網域";
    handleShowMessage(`${message}...`, "info", false);

    if (props.register.domain !== "") {
      Axios.post("/api/v0/mpnCheckDomainByDomain", {
        domain: props.register.domain,
      })
        .then((response) => {
          handleShowMessage(`${message}---Success`, "success");
        })
        .catch((error) => {
          var errMessage = "";
          if (error.response) {
            if (error.response.data) {
              errMessage = `${message}---Fail`;
            } else {
              errMessage = `${message}---${error.response}`;
            }
          } else if (error.request) {
            errMessage = `${message}---連線異常`;
          } else {
            // 在設定 request 時出錯會進入此
            errMessage = `${message}---error`;
            console.error(error);
          }
          handleShowMessage(`${errMessage}`, "error");
        });
    } else {
      handleShowMessage(`網域此欄位不能空白`, "error");
    }
  };

  async function saveOrderAndSendRegisterMail() {
    missingK1Warning();
    missingActivateDate();
    if (!window.confirm("確定儲存資料？")) {
      return;
    }
    props.setKeepModalStatus(true);

    var message = "";
    var postErr = "";
    // Save Order Information
    try {
      message = "系統訂單";
      await Axios.post(saveOrderURL, postSaveData);
      alert("系統訂單已儲存，寄送微軟開通信中...");
    } catch (error) {
      postErr = showPostError(message, error);
      handleShowMessage(`${postErr}`, "error");
      props.setKeepModalStatus(false);
      return;
    }

    // Send Register Mail
    try {
      // 寄送微軟開通信
      message = "寄送微軟開通信";
      handleShowMessage(`${message}...`, "info", false);
      await Axios.post(sendRegisterMailURL, { orderSn: props.orderSn });
      handleShowMessage(`${message}---Success`, "success");
    } catch (error) {
      postErr = showPostError(message, error);
      handleShowMessage(`${postErr}`, "error");
      props.setKeepModalStatus(false);
    }
    props.setKeepModalStatus(false);
  }

  const showPostError = (message, error) => {
    var errMessage = "";
    if (error.response) {
      if (error.response.data) {
        errMessage = `${error.response.data.message}`;
      } else {
        errMessage = `${error.response}`;
      }
    } else if (error.request) {
      errMessage = `連線異常`;
    } else {
      // 在設定 request 時出錯會進入此
      errMessage = `error`;
      console.error(error);
    }
    return `${message}---${errMessage}`;
  };

  function missingK1Warning() {
    if (props.orderDetail.contractNoK1 === "") {
      alert("K1 碼尚未填入, 重要合約資料, 請確認是否需填寫!");
    }
  }

  function missingActivateDate() {
    if (
      process.env.REACT_APP_ORDER_STATUS_ACTIVATE_SN !== undefined &&
      typeof process.env.REACT_APP_ORDER_STATUS_ACTIVATE_SN === "string"
    ) {
      const activeStatus = parseInt(
        process.env.REACT_APP_ORDER_STATUS_ACTIVATE_SN
      );
      if (
        props.orderDetail.orderStatusSn === activeStatus &&
        (props.orderDetail.orderStartDate === "" ||
          props.orderDetail.orderStartDate === undefined)
      ) {
        alert(
          "重要訊息!!! 此單已啟用但仍未設定啟用日期, 為確保資料正確, 請立即設定啟用日期"
        );
      }
    }
  }

  return (
    <React.Fragment>
      <Button
        onClick={mpnCheckDomainByDomain}
        color="primary"
        disabled={props.keepModal}
      >
        檢查網域
      </Button>
      <Button onClick={saveOrder} color="primary" disabled={props.keepModal}>
        儲存
      </Button>
      <Button
        onClick={() => saveOrderAndSendRegisterMail()}
        color="primary"
        disabled={props.keepModal}
      >
        儲存並寄送微軟開通信
      </Button>
      <Button
        onClick={() => saveOrderAndActivateMs()}
        color="primary"
        disabled={props.keepModal}
      >
        儲存並微軟開通
      </Button>
      <Button
        onClick={() => {
          missingK1Warning();
          missingActivateDate();
          props.closeModal(props.orderDetail.contractNoK1 === "");
        }}
        color="primary"
      //disabled={props.keepModal}
      >
        離開
      </Button>
    </React.Fragment>
  );
}

const otherProductItemFilter = (product) => {
  const { charge_type_sn, msNceOfferId } = product;
  let passFilter = true;

  // ChargeTypeYear 小額商品類型-年繳出帳
  // var ChargeTypeYear = ChargeType{Sn: 2, Value: "年繳出帳"} // 小額商品類型-年繳出帳
  if (charge_type_sn !== 2) {
    passFilter = false;
    // 限制相同商品碼的商品
  } else if (msNceOfferId && msNceOfferId !== productOfferId) {
    passFilter = false;
  }

  return passFilter;
};
