import React, { useState } from "react";

// core
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";

// icons
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// router
import { Link } from "react-router-dom";

export default function OPDrawer(props) {
  const [openDrawerList, setOpenDrawerList] = useState(false);

  const drawerList = [];
  for (var page of props.pages) {
    if (page.subPages) {
      // has subpages
      const subPageList = [];
      for (var subPage of page.subPages) {
        subPageList.push(
          <Link key={subPage.toString()} to={subPage.path}>
            <ListItem key={subPage.toString()} button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={subPage.name} />
            </ListItem>
          </Link>
        );
      }

      drawerList.push(
        <ListItem
          key={page.subPages.toString()}
          button
          onClick={(e) => setOpenDrawerList((prevState) => !prevState)}
        >
          <ListItemIcon>{page.icon}</ListItemIcon>
          <ListItemText primary={page.name} />
          {openDrawerList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      );

      drawerList.push(
        <Collapse
          key={page.subPages.toString()}
          in={openDrawerList}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {subPageList}
          </List>
        </Collapse>
      );
    } else {
      drawerList.push(
        <Link key={page.name} to={page.path}>
          <ListItem button>
            <ListItemText primary={page.name} />
          </ListItem>
        </Link>
      );
    }
  }

  return (
    <Drawer
      variant="temporary"
      open={props.drawerIsOpen}
      onClick={props.handleDrawerClose}
    >
      <div role="presentation">{drawerList}</div>
    </Drawer>
  );
}
