// react
import React, { useState, useEffect } from "react";

import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  InputLabel,
  DialogActions,
  Button,
  TextField,
  FormControl,
  NativeSelect,
} from "@material-ui/core";

// API
import Axios from "axios";

export default function CreateNewDiscode(props) {
  const [modalStatus, setModalStatus] = useState(false);
  const closeModal = props.closeModal;

  // Modal data
  var [productDiscodeDetail, setProductDiscodeDetail] = useState({
    sn: 0,
    discode: "",
    productName: "",
    productPlanType: "",
    productDertailNo: "",
    productDetailSn: 0,
    discodeName: "",
    amount: 0,
    salePrice: 0.0,
    enable: true,
    duration: 12
  });
  const [productDetailList, setProductDetailList] = useState([]);

  const handleChangeOrderDetail = (event) => {
    var { name, value, type } = event.target;
    if (type === "select-one") {
      value = parseInt(value);
    }
    if (type === "number") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
    }
    setProductDiscodeDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (props.open) {
      setProductDetailList(props.productDetailList);
    }
    setModalStatus(props.open);
  }, [props.open, props.productDetailList]);

  const productDetailSubmit = (e) => {
    e.preventDefault();
    if (window.confirm("確定新增此筆資料？")) {
      var postData = {};
      postData.Discode = productDiscodeDetail.discode;
      postData.DiscodeName = productDiscodeDetail.discodeName;
      postData.ProductDetailSn = productDiscodeDetail.productDetailSn;
      var amount = parseInt(productDiscodeDetail.amount);
      postData.Amount = isNaN(amount) ? 0 : amount;
      var salePrice = parseFloat(productDiscodeDetail.salePrice);
      postData.SalePrice = isNaN(salePrice) ? 0.0 : salePrice;
      postData.Enable = productDiscodeDetail.enable;
      postData.IsCharge = 0;
      postData.duration = isNaN(parseInt(productDiscodeDetail.duration)) ? 12 : parseInt(productDiscodeDetail.duration);
      // console.log(postData);
      Axios.post("/api/product/discode/create", postData).then((response) => {
        // console.log(response.data.message);
        // window.location.reload();
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        closeModal();
      });
    }
  };

  const productDetailChange = (event) => {
    if (event.target.value !== "") {
      var sn = parseInt(event.target.value);
      setProductDiscodeDetail((prevState) => ({
        ...prevState,
        productDetailSn: sn,
      }));
    }
  };

  const handleDiscodeEnableChange = (event) => {
    if (event.target.value !== "") {
      var enable = parseInt(event.target.value);
      setProductDiscodeDetail((prevState) => ({
        ...prevState,
        enable: enable,
      }));
    }
  };

  return (
    <Dialog
      open={modalStatus}
      onClose={closeModal}
      maxWidth="md"
      scroll="paper"
    >
      <Box>
        <form onSubmit={productDetailSubmit}>
          <DialogTitle>新增優惠代碼：</DialogTitle>
          <DialogContent dividers>
            <TextField
              fullWidth
              margin="dense"
              label="優惠代碼"
              name="discode"
              defaultValue={productDiscodeDetail.discode}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="demo-customized-select-native">
                商品詳細資訊
              </InputLabel>
              <NativeSelect
                value={productDiscodeDetail.productDetailSn}
                onChange={productDetailChange}
                required
              >
                <option></option>
                {productDetailList.map((productDetail, index) => {
                  return (
                    <option
                      key={index}
                      value={productDetail.sn}
                    >
                      {productDetail.name}
                      {productDetail.productPlanType !== "" ? " - " + productDetail.productPlanType : ""}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
            <TextField
              fullWidth
              margin="dense"
              label="優惠代碼名稱"
              name="discodeName"
              defaultValue={productDiscodeDetail.discodeName}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
            <TextField
              fullWidth
              margin="dense"
              label="授權數量"
              type="number"
              name="amount"
              defaultValue={productDiscodeDetail.amount}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
            <TextField
              fullWidth
              margin="dense"
              label="優惠價"
              type="number"
              name="salePrice"
              defaultValue={productDiscodeDetail.salePrice}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="demo-customized-select-native">
                優惠代碼是否有效
              </InputLabel>
              <NativeSelect
                value={productDiscodeDetail.enable}
                onChange={handleDiscodeEnableChange}
                required
              >
                <option></option>
                <option key={0} value={1}>是</option>
                <option key={1} value={0}>否</option>
              </NativeSelect>
            </FormControl>
            <TextField
              fullWidth
              margin="dense"
              label="持續期間（月）"
              type="number"
              name="duration"
              defaultValue={productDiscodeDetail.duration}
              onChange={handleChangeOrderDetail}
              autoComplete="off"
              required
            />
          </DialogContent>
          <DialogActions>
            <Typography>
              <Button type="submit" name="save" color="primary">
                儲存
              </Button>
            </Typography>
            <Button onClick={closeModal} color="primary">
              取消
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}