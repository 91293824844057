import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Styled from 'styled-components';
import DoneIcon from "../assets/ProductOverview/Product_Safety_Check.png";

//CSS
const StyledContextSubGrid = Styled(Grid)`
  display: grid;
  text-align: left;
  justify-content: left;
  align-items: center;
  padding: 0 30px 0 30px;
`
const StyledContextTitle = Styled(Typography)`
  && {
    color: #28a8ea;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    margin-top: 15px;
  }    
`
const StyledContextContent = Styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContextDivider = Styled(Divider)`
  && {
    background-color: #f50057;
    margin-bottom: 15px;
  }
`
const StyledContextSubTitle = Styled(Typography)`
  && {
    font-weight: 700;
    font-family: Microsoft JhengHei;
    text-align: left;
  }   
`
const StyledContextSubContent = Styled(Typography)`
  && {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 5px;
    font-family: Microsoft JhengHei;
    text-align: left;
  }    
`
const StyledContextGrid = Styled(Grid)`
  && {
    margin: 20px 0 40px 0;
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 30px 0 30px;
  }
`
const StyledDoneIcon = Styled.img`
  max-width: 20px;
  height: auto;
  margin-right: 10px;
`

export default function PrivacyDesign(props) {
  function renderTitle() {
    const context = {
      title: "著重隱私權的設計",
      context:
        "當您將資料託付給 Office 365 後，您仍舊是該資料的唯一擁有者：您對於儲存在 Office 365 中的資料保有所有權利、權益以及利益。 我們謹遵原則，絕不將您的資料用於廣告，或是用於為您提供雲端生產力服務以外的任何用途。"
    };
    return (
      <StyledContextGrid container sm={12}>
        <StyledContextTitle paragraph variant="h4">
          {context.title}
        </StyledContextTitle>
        <StyledContextContent paragraph>{context.context}</StyledContextContent>
      </StyledContextGrid>
    );
  }
  function renderList(props) {
    const contexts = props;

    return contexts.map(context => (
      <>
        <Typography paragraph>
          <StyledDoneIcon src={DoneIcon}/>
          {context.subContext}
        </Typography>
      </>
    ));
  }

  function renderContext() {
    const contexts = [
      {
        title: "資料擁有權及其含意",
        context: [
          {
            subContext: "您是資料的擁有人；Microsoft 是資料的保管人或處理人。"
          },
          {
            subContext:
              "這是您的資料，如果您選擇要停用服務， 也能將資料一併帶走。"
          },
          {
            subContext: "我們不會掘取您的資料做為廣告用途。"
          }
        ]
      },
      {
        title: "做為資料處理者",
        context: [
          {
            subContext: "您的資料 只會用於提供您所付費的服務。"
          },
          {
            subContext:
              "我們會透過透明化報告，定期揭露我們所收到的 執法要求報告數。"
          },
          {
            subContext:
              "若政府單位向我們要求存取客戶資料，我們會盡可能轉發通知給客戶，並且透過司法途徑解除任何禁止我們向客戶告知政府要求取得客戶資料的禁令。"
          }
        ]
      },
      {
        title: "隱私權控制",
        context: [
          {
            subContext:
              "隱私權控制讓您設定組織中有誰能存取資料，以及能存取哪些資料。"
          },
          {
            subContext:
              "設計元素能防止將您的資料與其他使用 Office 365 的組織資料混在一起。"
          },
          {
            subContext:
              "全面稽核及監督，防止管理人員以未經授權的方式存取您的資料。"
          }
        ]
      }
    ];

    return contexts.map(context => (
      <Grid container>
        <StyledContextSubGrid item sm={12}>
          <StyledContextSubTitle color="secondary" variant="h5">
            {context.title}
          </StyledContextSubTitle>
          <StyledContextDivider/>
          <StyledContextSubContent paragraph>
            {renderList(context.context)}
          </StyledContextSubContent>
        </StyledContextSubGrid>
      </Grid>
    ));
  }

  return (
    <div>
      <Grid container spacing={3}>
        {renderTitle()}
        {renderContext()}
      </Grid>
    </div>
  );
}
