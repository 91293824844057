// core
import React, { useState } from "react";
// import Container from "@material-ui/core/Container";

// router
import OPRouter from "./Router.js";
import { useRouteMatch } from "react-router-dom";

// components
import OPHeader from "./Header.js";
import OPDrawer from "./Drawer.js";
import PageLoading from "../common/PageLoading";
import { PageLoadingProvider } from "../context/PageLoadingContext";

import { SnackbarProvider } from "notistack";

export default function OPIndex(props) {
  var postData = {};
  postData["brandSn"] =
    localStorage.getItem("filterCompany") !== null
      ? parseInt(localStorage.getItem("filterCompany"))
      : 0;
  console.log(postData["brandSn"])
  let match = useRouteMatch();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const pages1 = [
    { name: "商品種類", path: match.path + "ProductBasic" },
    { name: "商品清單", path: match.path + "ProductList" },
    { name: "優惠代碼管理", path: match.path + "DiscodeManagement" },
    { name: "異常訂單管理", path: match.path + "DsisProblematicOrders" },
    { name: "訂單", path: match.path + "Orders" },
    { name: "版本用量查詢", path: match.path + "UsingManage" },
    { name: "會員管理", path: match.path + "Members" },
    { name: "會員管理記錄", path: match.path + "MemberLogs" },
    { name: "信件寄送記錄", path: match.path + "MailLogs" },
    { name: "簡訊寄送記錄", path: match.path + "SmsLogs" },
    { name: "公告編輯", path: match.path + "Announcement" },
    { name: "管理者", path: match.path + "Operators" },
    { name: "編輯發送信件", path: match.path + "EditableMail" },
  ];
  const pages2 = [
    { name: "商品種類", path: match.path + "ProductBasic" },
    { name: "商品清單", path: match.path + "ProductList" },
    { name: "優惠代碼管理", path: match.path + "DiscodeManagement" },
    { name: "異常訂單管理", path: match.path + "DsisProblematicOrders" },
    { name: "訂單", path: match.path + "Orders" },
    { name: "會員管理", path: match.path + "Members" },
    { name: "會員管理記錄", path: match.path + "MemberLogs" },
    { name: "信件寄送記錄", path: match.path + "MailLogs" },
    { name: "簡訊寄送記錄", path: match.path + "SmsLogs" },
    { name: "公告編輯", path: match.path + "Announcement" },
    { name: "管理者", path: match.path + "Operators" },
    { name: "編輯發送信件", path: match.path + "EditableMail" },
  ];

  let pages = pages1

  postData["brandSn"] === 1 || postData["brandSn"] === 0 ? pages = pages1 : pages = pages2


  const additionDevPages = [
    // { name: "", path: "" },
    { name: "訂單管理記錄", path: match.path + "OrderLogs" },
    { name: "商品管理記錄", path: match.path + "ProductLogs" },
    { name: "帳號管理記錄", path: match.path + "OperatorLogs" },
    //{ name: "優惠代碼", path: match.path + "CouponCodes" },
    { name: "微軟", path: match.path + "MPN" },
  ];

  function getPages() {
    if (process.env.REACT_APP_MODE === "development") {
      additionDevPages.forEach((page) => {
        pages.push(page);
      });
    }
    return pages;
  }

  return (
    <PageLoadingProvider>
      <OPHeader handleDrawerOpen={(e) => setDrawerIsOpen(true)} />

      <OPDrawer
        pages={getPages()}
        drawerIsOpen={drawerIsOpen}
        handleDrawerClose={(e) => setDrawerIsOpen(false)}
      />

      {/* <Container> */}
      <SnackbarProvider maxSnack={2}>
        <OPRouter />
      </SnackbarProvider>
      {/* </Container> */}
      <PageLoading />
    </PageLoadingProvider>
  );
}
