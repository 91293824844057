// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, forwardRef, useCallback } from "react";

import MaterialTable from "material-table";

// icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";

import Axios from "axios";

// Component
import { Box } from "@material-ui/core";
import { useCookies } from "react-cookie";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";

import EditDialog from "./EditDialog";
import CreateNewDiscode from "./CreateNewDiscode";

// Style CSS
import { makeStyles } from '@material-ui/core/styles';

 // Refreshing cookies mechanism
 import { useHistory } from "react-router-dom";
 import { getCookie, handleRefreshLoginCookies, handleExpiredCookies } from "../../common/session"

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 500,
    //height: '100%'
  },
}));

// API
const getProductDiscodeList = "/api/product/discode/list";
const deleteProductDiscodeAPI = "/api/product/discode/delete";
const getProductDetailListAPI = "/api/product/detail/detailForDiscode/list";

const tableIcons = {
  // CRUD
  Add: forwardRef((props, ref) => (
    <AddCircleOutlineIcon {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  // page
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  // search
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
};

export default function DiscodeManagement(props) {
  // Table row data
  const [rows, setRows] = React.useState([]);

  const [cookies] = useCookies(["op_name", "group_id"]);
  const [permission, setPermission] = React.useState(0);

  // Refreshing cookies mechanism
  let history = useHistory() // 取得某頁面route name

  const notPermitted = 1;
  const readOnly = 2;
  const debugMode = true;
  // const rwd = 4;

  function deleteProductDiscode(sn) {
    if (window.confirm("確定刪除此優惠代碼？")) {
      var postData = {};
      postData.Sn = sn;
      Axios.post(deleteProductDiscodeAPI, postData).then((response) => {
        // console.log(response.data);
        // window.location.reload();
        refleshData(cookies)
      }).catch((err) => {
        console.log(err);
        alert("後端異常");
      });
    }
  }

  // Dialog enable
  const [EditDialogEnable, setEditDialEnable] = React.useState(false);
  const [CreateNewDiscodeEnable, setCreateNewDiscodeEnable] = React.useState(false);

  const openEditDialog = () => {
    setEditDialEnable(true);
  };

  const closeEditDialog = () => {
    setEditDialEnable(false);
    refleshData(cookies)
  };

  const openEditCreateNewDiscode = () => {
    setCreateNewDiscodeEnable(true);
  };

  const closeEditCreateNewDiscode = () => {
    setCreateNewDiscodeEnable(false);
    refleshData(cookies)
  };

  // Dialog props data
  var [productDiscodeDetail, setProductDiscodeDetail] = React.useState({
    sn: 0,
    discode: "",
    productName: "",
    productPlanType: "",
    productDertailNo: "",
    productDetailSn: 0,
    discodeName: "",
    amount: 0,
    salePrice: 0,
    enable: true,
    duration: 12
  });
  const [productDetailList, setProductDetailList] = React.useState([]);

  // Define table components
  const TableColumns = [
    { title: "優惠代碼", field: "discode" },
    { title: "商品名稱", field: "productName" },
    { title: "商品方案類型", field: "productPlanType" },
    { title: "商品序號", field: "productDetailNo" },
    { title: "優惠代碼名稱", field: "discodeName" },
    { title: "綑綁數量", field: "amount", align: "right" },
    { title: "優惠價格", field: "salePrice", align: "right" },
    {
      title: "代碼是否有效", field: "enable", render: (rowData) => {
        return (rowData.enable) ? "是" : "否";
      },
    },
  ];

  // Define table actions
  const actions = (permission === readOnly) ?
    [] : [
      {
        icon: tableIcons.Add,
        tooltip: "Add",
        isFreeAction: true, // this flag will show action at top-right
        onClick: (event) => {
          //handleCreateNewMember();
          openEditCreateNewDiscode();
        },
      },
      {
        icon: tableIcons.Edit,
        tooltip: "Edit",
        onClick: (event, rowData) => {
          openEditDialog(rowData.sn);
          setProductDiscodeDetail(rowData);
        },
      },
      {
        icon: tableIcons.Delete,
        tooltip: "Delete",
        onClick: (event, rowData) => {
          deleteProductDiscode(rowData.sn);
        },
      },
    ];

  const refleshData = useCallback((cks) => {
      var gid = isNaN(parseInt(cks.group_id)) ? 0 : parseInt(cks.group_id);
      if (gid !== 0) {
        getGroupPermission(gid);
      }
      var postData = {};
      postData.brandSn = localStorage.getItem('filterCompany') !== null ? parseInt(localStorage.getItem('filterCompany')) : 0;
      postData.OpName = cks.op_name;
      // 優惠代碼
      const getProductDiscodeListReq = Axios.post(getProductDiscodeList, postData);
  
      // 商品詳細資訊
      const getProductDetailListReq = Axios.post(getProductDetailListAPI, postData);
  
      Axios.all([getProductDiscodeListReq, getProductDetailListReq])
        .then(
          Axios.spread((productDiscodeListResponse, productDetailListResponse) => {
            setRows(productDiscodeListResponse.data.data);
            setProductDetailList(productDetailListResponse.data.data);
          })
        )
        .catch((errors) => {
          alert("後端異常");
        });
    },[])

  // Table data operations
  useEffect(() => {
    refleshData(cookies);
  }, [cookies, refleshData]);

  // Refreshing cookies mechanism
  useEffect(() => {
    if(getCookie("session") !== undefined) {// 按下登出按鈕 或是 Session 仍存在的情況下進行換頁。
      handleRefreshLoginCookies()
      if (debugMode) {
        console.log("Call loginCheck api- " + getCookie("session"));
      }
      Axios
        .post("/api/v0/loginCheck", {
          session: getCookie("session"),
        })
        .then(function(response) {
          if (response.data.IsLogin !== true) {
            handleExpiredCookies()
            history.replace("/backoffice/Login")
          }
        })
        .catch(function(error) {
          console.log("OPRouter", error);
        });
    } else {// Session 過期的情況下進行換頁。
      handleExpiredCookies()
      history.replace("/backoffice/Login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    CreateNewDiscodeEnable, // 新增 Dsis 優惠代碼管理
    EditDialogEnable, // 編輯 Dsis 優惠代碼管理
  ])

  const getGroupPermission = (sn) => {
    var postData = {};
    postData["Sn"] = sn;
    Axios.post("/OP/group/getGroupData", postData).then(function (response) {
      var res = response.data.data;
      for (var i in res) {
        if (res[i].name === '優惠代碼') {
          setPermission(res[i].permission);
          break;
        }
      }
    }).catch(function (err) {
      console.log(err);
    });
  };
  var classes = useStyles();
  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.container}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: 'center',
                minHeight: '100vh'
              }}
            >
              <Grid item style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 200
              }}>
                <Typography style={{ fontSize: 100 }}>
                  您無此權限
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }

  return (
    <Box m={2}>
      <MaterialTable
        title="Dsis 優惠代碼管理"
        columns={TableColumns}
        data={rows}
        options={{
          searchText: "",
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 500],
          actionsColumnIndex: -1,
          showTextRowsSelected: true,
          headerStyle: {
            backgroundColor: "#888",
            color: "#FFF"
          },
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2
              ? "#FFFFFF"
              : "#EEEEEE"
          })
        }}
        icons={tableIcons}
        actions={actions}
      />
      <EditDialog
        open={EditDialogEnable}
        closeModal={closeEditDialog}
        productDiscodeDetail={productDiscodeDetail}
        productDetailList={productDetailList}
      />
      <CreateNewDiscode
        open={CreateNewDiscodeEnable}
        closeModal={closeEditCreateNewDiscode}
        productDetailList={productDetailList}
      ></CreateNewDiscode>
    </Box>
  );
}
