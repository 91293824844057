import React from "react";

// expansionPanel
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Box,
  Typography,
} from "@material-ui/core";

// CSS
import Styled from "styled-components"
const StyledAccordion = Styled(Accordion)`
  && {
    border-radius: 30px;
    margin: 10px 0px;
  }
  &&:before {
    background-color: white;
  }
`
const StyledAccordionSummary = Styled(AccordionSummary)`
  && {
    background-color: #28a8ea;
    border-radius: 30px;
  }
  & .MuiAccordionSummary-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const StyledAccordionDetails = Styled(AccordionDetails)`
  && {
    border-radius: 30px;
    padding: 16px 40px 20px;
  }
`
const StyledTitle = Styled(Typography)`
  && {
    font-size: 0.9rem;
    font-weight: 600;
    font-family: Microsoft JhengHei;
    color: white;
    letter-spacing: 0.063em;
    border-radius: 30px;
  }
`
const StyledContent = Styled(Typography)`
  && {
    font-size: 0.8rem;
    font-weight: 500;
    font-family: Microsoft JhengHei;
    color: black;
    letter-spacing: 0.083em;
    border-radius: 30px;
    word-break: break-all;
  }
`
const StyledHeader = Styled(Typography)`
  && {
    font-size: 2.6rem;
    font-weight: 700;
    font-family: Microsoft JhengHei;
    color: #424242;
    letter-spacing: 0.1em;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0px;
  }
`
const StyledBox = Styled(Box)`
  && {
    margin: 20px 0px 80px 0px;
    padding: 0px;
  }
`

const state = {
  questionItems: [
    {
      question: "該如何購買中華電信微軟雲服務?",
      ans:
        "這次推出的服務是針對中小企業所使用的軟體服務，客戶您可於線上直接以(公司/負責人名義)申請使用，另外，您還能以按月租或年租的方式付費。每期(月/年)透過中華電信帳單付款。"
    },
    {
      question: "請問中華電信微軟雲服務是否僅提供給中華電信客戶?",
      ans:
        "中華電信為企業所打造的雲端服務，不論是否為中華的現有客戶皆可申請，並提供24小時客服(0800-080-365)，讓企業隨時隨地皆可享受立即的服務。"
    },
    {
      question: "中華電信微軟雲服務與那些服務整合?",
      ans:
        "微軟雲服務包含了Office 365及其它微軟雲端服務(如 Intune、Azure、 Exchange、Lync、SharePoint、OneDrive)等服務，未來會陸續提供，另外與中華電信其它企客產品結合，如企業上網、中小企業商務辦公包共同搭售，讓企業自由搭配，彈性的租用方式，讓企業節省成本，並享有軟體隨時更新的優勢。"
    },
    {
      question:
        "在中華電信微軟雲銷售的Office 365僅限於企業用戶? 家庭用戶或政府機關可以購買嗎?",
      ans:
        "在中華電信銷售的Office 365主要是針對企業商用客戶。在功能上多了管理者權限便於帳號管理，可以讓公司組織管理帳號與協同合作的彈性。建議個人或家庭如不須管理功能，可另至微軟的OFFICE官網購買。<br>政府公部門，另有專案定價，請就近洽詢各營運處專案經理或洽企業客服(0800-080-365)。"
    },
    // {
    //   question:
    //     '"微軟雲服務"首月免月租費，這優惠如何計算? 年缴的用戶也有此優惠?',
    //   ans:
    //     "線上申購之月繳客戶首月免費，自次月同一日才會開始計費，線上年繳已享有9折優惠則不重複提供優惠。<br>" +
    //     "有關出帳時間點之電信帳單計費週期，詳細說明請參考:<a href='http://www.cht.com.tw/tune_cycle/' target='_blank'>http://www.cht.com.tw/tune_cycle/</a>"
    // },
    {
      question:
        "什麼是 Office 365?Office 365 與 Microsoft Office 兩者之間有何差異?",
      ans:
        "<span class='tB'>Office 365：</span> 「Office 365」是指包含 Office 應用程式外加其他生產力服務之存取權限的訂閱方案，這些項目必須透過網際網路 (雲端服務) 加以啟用，例如針對企業所提供的商務用 Skype Web 會議與 Exchange Online 代管式電子郵件，以及針對家庭所提供的 OneDrive 額外線上儲存空間與 Skype 通訊。<br>" +
        "許多 Office 365 方案也包含最新的桌上型電腦版 Office 應用程式，可供使用者安裝至多台電腦與裝置。 完整安裝的應用程式包括：Word、Excel、PowerPoint、OneNote、Outlook、Publisher 和 Access (Publisher 與 Access 僅提供 PC 版本)。您可以將它們安裝在多個裝置上，包括 PC、Mac、Android 平板、Android 手機、iPad 及 iPhone。 只要您擁有包含桌上型電腦版 Office 的有效 Office 365 訂閱，即可隨時享有最新版本的應用程式。<br>" +
        "僅包含線上版 Office 365 的方案，對於特定企業的需求亦是實惠，而且它們相容於桌上型電腦版本的 Office 2010、Office 2007 (功能稍微受限)、Mac 版 Office 2011，以及 Mac 版 Office 2008。<br>" +
        "所有 Office 365 訂閱方案皆按月或按年收費。<span class='tB'>Microsoft Office：</span> 是指用在文書處理、辦公室生產力軟體的名稱。 Office 套件可以一次買斷，套件內包含 Word、Excel 和 PowerPoint 等應用程式，但只能安裝在一部 PC 或 Mac 上。 這些應用程式並不會自動更新；若要取得最新版本，可在新版本推出後再次購買本產品。 目前提供買斷的最新版本有 Windows 版 Office 2016和 Mac 版 Office 2016。 這些套件則不會隨附Office 365 中的雲端服務。"
    },
    {
      question: "Office 365 是否能夠與我的 Office 版本共同運作? ",
      ans:
        "Office 365 在與最新版本的 Office、Office 2016 以及 Mac 版 Office 2016 搭配使用時，能夠發揮最佳效能。Office 365 能與 Office 2007 搭配使用，只是功能些許受限。如果您擁有的是更早以前的 Office 版本，您將需要更新為較新版本的 Office 才能使用 Office 365；或者您可以訂閱其中一項包含最新版 Office 的 Office 365 方案。訂閱後，除了能取得最新版 Office 外，也能取得自動更新，讓您的 Office 永保最新狀態。"
    },
    {
      question: "是否需要網路連線才能使用 Office 365?",
      ans:
        "您需要網路連線才能安裝並啟用所有 Office 365 方案，並管理您的訂閱帳戶。網際網路連線另外也將用於存取 Office 365 雲端生產力服務，包括電子郵件、會議、IT 管理，以及其他服務。<br>" +
        "許多 Office 365 方案也包括桌上型電腦版 Office，例如 Office 365 商務進階版及 Office 365 企業版 E3。桌上型電腦版本的 Office 應用程式能夠離線作業，並且確保下次連線至網際網路時，您的所有工作將會自動同步處理，完全不用擔心文件是否為最新狀態。桌上型電腦版本的 Office 也會在您連線至網際網路時自動取得更新與升級，讓您一直都有最新工具來處理工作。"
    },
    {
      question: "什麼是雲端? ",
      ans:
        "「雲端」是指託管於組織外部的 Web 型運算服務。使用雲端型服務時，IT 基礎架構是位於您的物業之外 (外部部署)，而且由第三方 (託管) 單位來維護，而非位於您所維護的住家或公司 (內部部署) 的伺服器上。使用 Office 365 時，像是資訊儲存、運算及軟體都會位於由 Microsoft 擁有的伺服器上，需進行遠端管理。日常使用的服務都是雲端的一部分：無論是 Web 電子郵件、網路銀行，還是線上相片儲存，均是如此。因為這種基礎結構位於線上或「雲端」，因此只要 PC、平板、智慧型手機或其他裝置能夠連上網際網路，您幾乎隨時隨地都能加以存取。"
    },
    {
      question: "Office 提供哪些行動應用程式? ",
      ans:
        "Office 提供下列應用程式：<br>" +
        "<ul>" +
        "<li><span class='tB'>Office for Android™</span>：以適用於觸控裝置的 Word、Excel 和 PowerPoint 應用程式來檢視、建立和編輯文件。您可以信賴 Office for Android，因為它能讓您的內容和格式設定維持不變，當您返回個人電腦或 Mac 上使用文件時，文件的外觀仍舊完美。當您與他人在 Word 中共同合作時，可新增註解或追蹤變更。檢閱和更新 Excel 試算表，並輕鬆新增公式或圖表。在 PowerPoint 簡報中進行變更，並可無線投影在大螢幕上。<br>您可以使用 Office for Android 免費檢視文件。可編輯和建立文件的有效 Office 365 訂閱如下：Office 365 家用版、Office 365 個人版、Office 365 大專院校版、Office 365 商務版、Office 365 商務進階版、Office 365 企業版 E3 和 E4、Office 365 企業版 E3 和 E4 (政府定價方案)、Office 365 政府版 E3 和 E4、Office 365 學生用教育版 E3 和 E4、Office 365 教職員用教育版 E3 和 E4，以及 Office 365 專業增強版。</li>" +
        "<li><span class='tB'>iPad® 版 Office</span>：提供可觸控的 Word、Excel 和 PowerPoint 應用程式，可用來檢視、建立及編輯文件。您可以信賴 iPad 版 Office，因為它能讓您的內容和格式設定維持不變，當您返回個人電腦或 Mac 上使用文件時，文件的外觀仍舊完美。當您與他人在 Word 中共同合作時，可新增註解或追蹤變更。檢閱和更新 Excel 試算表，並輕鬆新增公式或圖表。在 PowerPoint 簡報中進行變更，並可無線投影在大螢幕上。<br>使用 iPad 版 Office 可免費檢視文件。可編輯和建立文件的有效 Office 365 訂閱如下：Office 365 家用版、Office 365 個人版、Office 365 大專院校版、Office 365 商務版、Office 365 商務進階版、Office 365 企業版 E3 和 E4、Office 365 企業版 E3 和 E4 (政府定價方案)、Office 365 政府版 E3 和 E4、Office 365 學生用教育版 E3 和 E4、Office 365 教職員用教育版 E3 和 E4，以及 Office 365 專業增強版。</li>" +
        "<li><span class='tB'>Office Mobile</span>：Windows Phone 8 已預先安裝 Office Mobile，且適用於特定型號的 iPhone® 和 Android 手機。Office Mobile 提供優異的 Office 內容檢視與行動內容編輯功能。而 Office Mobile 一律會保持內容與格式的完整，讓您在回到 PC 或 Mac 工作後，文件也不會出現任何紕漏。iPhone 與 Android 手機適用的 Office Mobile 可免費用於非商業用途，只要透過手機即可到線上商店下載使用。<br>若要編輯或建立文件，或者存取儲存在內部部署的 SharePoint 和商務用 OneDrive 中的文件，則需擁有下列符合資格的 Office 365 方案：Office 365 商務進階版、Office 365 企業版、Office 365 企業版 E3 和 E4 (政府定價)、Office 365 政府版 E3 和 E4、Office 365 學生用教育版 E3 和 E4、Office 365 教職員用教育版 E3 和 E4、Office 365 專業增強版和 Office 365 大專院校版。</li>" +
        "<li><span class='tB'>Outlook Mobile</span>：在 Windows Phone 中預先安裝 Outlook，可用來存取 Outlook.com、Office 365 或 Exchange 電子郵件。 </li>" +
        "<li><span class='tB'>OneNote</span>：您可以使用行動 OneNote 應用程式，隨時隨地存取您的筆記本。此應用程式可用於 Windows Phone 8、iPhone、iPad 及 Android 手機。</li>" +
        "<li><span class='tB'>OWA</span>：如果訂閱的 Office 365 方案包含 Exchange Online，則可使用 OWA 應用程式排序、掃描及回覆電子郵件，永遠不怕進度落後。此應用程式可用於 iPhone 與 iPad。</li>" +
        "<li><span class='tB'>Lync Mobile</span>：如果貴組織使用商務用 Skype 或是最新版 Lync，您就可以透過行動 Lync App 或商務用 Skype 行動 App，以行動裝置與他人保持連繫。此應用程式可用於 Windows Phone 8、iPhone、iPad 及 Android 手機。</li>" +
        "<li><span class='tB'>SharePoint Newsfeed</span>：如果您的組織使用最新版本的 SharePoint，您就可以從行動裝置使用 SharePoint Newsfeed 應用程式來存取新聞摘要。此應用程式可用於 Windows Phone 8、iPhone 及 iPad。</li>" +
        "</ul>"
    },
    {
      question: "Office 365 可支援多少名使用者?",
      ans:
        "Office 365 具備高度擴充性，無論是單人企業或是擁有成千上萬名使用者的公司，Office 365 均可提供支援：<br>Office 365 基本商務版、Office 365商務版、Office 365商務進階版適合 1 至 300 名使用者的公司。<br>Office 365 企業版方案則適合規模從僅有1名使用者到3000名使用者，如超過3000名使用者的組織，請洽客服0800-080-365專人購買。"
    },
    {
      question: "該如何退租中華電信微軟雲服務?",
      ans:
        '您可以將以下資訊(公司名稱、統一編號、公司電話、連絡人) 寄至以下信箱(<a href="mailto:o365service@chtcsp.onmicrosoft.com">o365service@chtcsp.onmicrosoft.com</a>)，客服人員將為您進行退租處理，退租完成後將回覆EMAIL給您。 '
    },
    {
      question: "請問退租中華電信微軟雲服務後，帳號及資料會保留多久?",
      ans:
        "用戶退租後帳號及資料將保留(一個月)，超過一個月將進行刪除。用戶在退租申請生效後30天內，仍可存取與備份檔案，停用期間如用戶繳款可請系統再開通帳號，超過60天如用戶仍未繳費，系統會自動將此帳戶刪除，用戶將無法進入與存取資料。"
    }
  ],
  fastItems: [
    {
      question: "在訂購Office 365 之後，如何進行所需的安裝? ",
      ans:
        "訂購完成後，將收到微軟雲啟用通知，請依所附的管理者帳號與密碼，登入啟用網址並設定使用者與指派授權，啟用網址<a href='https://portal.office.com/' target='_blank'>https://portal.office.com/</a><br>完成組織內各使用者的授權指派後，各個使用者逕行登入啟用網址<a href='https://portal.office.com/' target='_blank'>https://portal.office.com/</a>，即可看到下載安裝Office的提示功能與引導。"
    },
    {
      question: "如果我已有自訂的網域名稱該怎麼辦? ",
      ans:
        "您也可以將自己擁有的網域名稱新增至 Office 365，來建立以網域為主的電子郵件地址。請以管理者權限登入Office系統管理進行網域設定。<a href='https://support.office.com/zh-tw/article/%E5%B0%87%E6%82%A8%E7%9A%84%E4%BD%BF%E7%94%A8%E8%80%85%E8%88%87%E7%B6%B2%E5%9F%9F%E6%96%B0%E5%A2%9E%E8%87%B3-Office-365-6383f56d-3d09-4dcb-9b41-b5f5a5efd611?ui=zh-TW&rs=zh-TW&ad=TW' target='_blank'>使用者與網域新增至 Office 365</a>。"
    },
    {
      question: "我的試用期資訊是否會轉移至我的 Office 365 訂閱? ",
      ans:
        "是的。若以免費試用期間建立的使用者帳戶購買 Office 365 授權，這些使用者帳戶的資訊和設定將會完整保留。免費試用一旦到期，您將無法繼續免費使用 Office 365。您可以在 30 天內購買 Office 365，30 天後若未購買將會清除您的帳戶資訊。您試用期的帳戶資訊一旦清除即無法再取得。"
    },
    {
      question: "如何設定我的 Office 365 服務?",
      ans:
        "Office 365設定會逐步引導您進行設定。僅須執行幾個快速步驟便可完成 Office 365 註冊、設定您的組織網域名稱並新增使用者帳戶、安裝 Office 以及將您現有的電子郵件移至 Office 365。"
    }
  ],
  priceItems: [
    {
      question: "如果我有帳號設定或Office 365後台的問題應該請教誰",
      ans:
        "公司在申租Office 365後，會有一個權限管理人負責帳號的開通與權限設定，如果您有帳號使用的問題， 可以先洽詢貴公司管理人，或是經由貴公司的權限管理人直接和中華電信客服聯繫。"
    },
    {
      question:
        "如果哪一天停止訂閱'微軟雲服務'，本來公司或個人在線上的資料應該怎麼辦",
      ans:
        "停止訂閱「微軟雲服務」後，您將有一個月的期間可以備份線上的所有資料。"
    },
    {
      question: "在中華電信微軟雲服務，我可以採用哪些付款方式? ",
      ans:
        "您可以使用HiNet寬頻的電信帳單付款，而您的訂閱金額將會顯示在您企業的電信帳單中。無論是 Office 365 企業版、Office 365 商務版或獨立方案，您都可以選擇收取發票並依自選服務規定按月或按年付費。<br>如果您的微軟雲服務Office 365費用欲單獨開立付款通知單與原有電信帳單分開，可透過業務經理或通知客服信箱(<a href='mailto:o365service@chtcsp.onmicrosoft.com'>o365service@chtcsp.onmicrosoft.com</a>)，客服人員將為您設定處理。"
    },
    {
      question: "按月收費、按年收費，以及按年份計的履約承諾付款方式有何差異? ",
      ans:
        "為提供彈性的付款方式，我們提供不同的付款選項。<br>Office 365 商務版、商務基本版及商務進階版方案提供按月份，或按年份計的履約承諾付款方式。<br>" +
        "<ul>" +
        "<li><span class='tB'>月繳制</span>：按月支付，可以隨時取消。</li>" +
        "<li><span class='tB'>年約月繳制</span>：年約優惠價但按月支付，最少需一年期。 </li>" +
        "<li><span class='tB'>年繳制</span>：可在註冊時選擇一次付清整年費用。享有年繳折扣。最短租期為1年，租期未滿1年以1年計收。如於未滿最短租期 終止租用或違反規定經本服務終止租用者，客戶已繳納費用概不退還。每期到期後以E-Mail方式通知，並自動按年繳制計收，如不欲繼續採年繳時，請於年繳到期前辦理異動手續。</li>" +
        "</ul>"
    },
    {
      question: "企業是否可享有大量授權的折扣優惠",
      ans:
        "是的，想要購買大量授權的 Office 365 企業客戶(通常為 50+ 份授權)，微軟雲服務提供享有折扣優惠。可透過業務經理或通知客服信箱(<a href='mailto:o365service@chtcsp.onmicrosoft.com'>o365service@chtcsp.onmicrosoft.com</a>)，將派專人與貴公司聯繫。"
    },
    {
      question: "我是否可以改用其他 Office 365 方案? ",
      ans:
        "是的。如果您需要升級，可以讓帳戶轉換到其他方案。例如，您可以從 Office 365 商務版方案升級成 Office 365 企業版方案、基本版升級為商務進階版等。可透過業務經理或通知客服信箱(<a href='mailto:o365service@chtcsp.onmicrosoft.com'>o365service@chtcsp.onmicrosoft.com</a>)進行升級。"
    },
    {
      question: "我可以混搭使用不同的 Office 365 方案嗎? ",
      ans:
        "是的，您可以混搭使用不同的 Office 365 方案。但請留意各方案層級可能會有一些授權限制。Office 365 商務版、商務基本版與商務進階版方案皆限定 300 位使用者，至於企業版方案則並未限定使用者人數。例如，您可針對單一租用戶購買 300 個商務進階版基座、300 個商務基本版基座與 500 個企業版 E3 基座。 "
    },
    {
      question: "我是否可以隨時取消訂閱? ",
      ans:
        "您可以隨時取消訂閱。但仍依照貴公司與微軟雲服務所約定的產品與訂閱類型，在合約結束前取消訂閱可能需要支付違約金。倘若訂閱的是非優惠方案的月繳制，則隨時取消合約皆無需支付違約金。倘若訂閱的是按年份計的履約承諾且採用按月付費的方式，則提前取消可能需要支付一些違約金。倘若訂閱的是按年份計的履約承諾且採用事先付清的方式，在完整的合約期限結束前取消訂閱恕無法退款。電子郵件聯絡方式：<a href='mailto:o365service@chtcsp.onmicrosoft.com'>o365service@chtcsp.onmicrosoft.com</a>"
    },
    {
      question: "若取消訂閱，資料會怎麼樣? ",
      ans:
        "您的資料為您個人所擁有。如果您決定要取消您的 Office 365 訂閱，您可以下載您的資料 (例如電子郵件與小組網站上的文件) 並儲存至其他位置。取消訂閱前，應先儲存您的資料。在您取消訂閱後的30天內，您的系統管理員仍可透過功能受限的帳戶取得與您的 Office 365 帳戶相關的資料。 "
    },
    {
      question: "我要如何變更我的密碼? ",
      ans:
        "<a href='http://go.microsoft.com/fwlink/p/?LinkID=506798' target='_blank'>到此處</a> 以變更您的密碼。"
    },
    {
      question:
        "我執行的作業系統是 Windows Vista 或 Windows XP。我是否會受到任何限制? ",
      ans:
        "使用Office 365的作業系統至少需要為 Windows 7 Service Pack 1或Mac：Mac OS X 10.10。如果您執行 Windows Vista 或 Windows XP，將無法下載或安裝 Office 365。"
    },
    {
      question: "我需要額外協助來設定 Office 365。我可以向誰求助? ",
      ans:
        "提供微軟推薦並認可的系統資訊公司，可協助您相關設定，或由舊系統及舊版本轉換至 Office 365 或為您的企業開發自訂解決方案。可透過業務經理或通知客服信箱(<a href='mailto:o365service@chtcsp.onmicrosoft.com'>o365service@chtcsp.onmicrosoft.com</a>)將派專人與貴公司聯繫。 "
    }
  ]
};

export default function OnlineQA() {
  return (
    <Container>
      {/* 常見問題 */}
      {itemModel("常見問題", state.questionItems)}

      {/* 快速入門 */}
      {itemModel("快速入門", state.fastItems)}

      {/* 支援與訂價 */}
      {itemModel("支援與訂價", state.priceItems)}
    </Container>
  );
}

function itemModel(text, items) {
  return (
    <Container>
      <StyledHeader>{text}</StyledHeader>
      <StyledBox>
          {items.map(function (item, i) {
            return (
              <StyledAccordion key={i} square={true}>
                <StyledAccordionSummary>
                  <StyledTitle>{item.question}</StyledTitle>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <StyledContent
                    variant="body2"
                    component={"span"}
                    dangerouslySetInnerHTML={{ __html: item.ans }}
                  ></StyledContent>
                </StyledAccordionDetails>
              </StyledAccordion>
            );
          })}
      </StyledBox>
    </Container>
  );
}
