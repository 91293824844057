import React, { useEffect, useCallback } from "react";
import MaterialTable from "material-table";
import { format } from "date-fns";

import { forwardRef } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { getCookie, handleRefreshLoginCookies, handleExpiredCookies } from "../../common/session";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";
import { Container, Grid, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { Box } from "@material-ui/core";
import { useState } from "react";
// import { useCookies } from "react-cookie";
import Axios from "axios";
import NumberFormat from "react-number-format";
import CompanyList from "../CompanyList.js";
import ProductFormOfMicrosoft from "./ProductOfMicrosoft.js";
import ProductFormOfGoogle from "./ProductOfGoogle.js";
import Product from "./Product.js";

// Style CSS
import { makeStyles } from "@material-ui/core/styles";

const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
const getProductListUrl = "/api/product/list";
const getPlanTypeListUrl = "/api/product/planType/list";
const getTermDurationListUrl = "/api/product/termDuration/list";
const getPlanTypeListOfGWSUrl = "/api/product/planType/listOfGWS";
const getProductUrl = "/api/product";
const getProductBrandListUrl = getProductUrl + "/brand/list";
const BrandInit = 0;
const BrandMicrosoftSn = 1;
const BrandGoogleSn = 2;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 500,
    //height: '100%'
  },
}));

export default function ProductBasic(props) {
  const [modalStatus, setModalStatus] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [productSn, setProductSn] = useState();
  const [planTypeList, setPlanTypeList] = useState([]);
  const [productBrandList, setProductBrandList] = useState([]);
  const [termDurationList, setTermDurationList] = useState([]);
  const [cookies] = useCookies(["op_name", "group_id"]);
  const [permission, setPermission] = useState(0);
  const [planTypeListOfGWS, setPlanTypeListOfGWS] = useState([]);
  const [companyOptionStatus, setCompanyOptionStatus] = useState(false);
  const [company, setCompany] = useState(BrandInit);

  // Refreshing cookies mechanism
  let history = useHistory() // 取得某頁面route name

  // Table Feature
  const tableRef = React.useRef();
  const handleChangePage = (event) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const notPermitted = 1;
  const readOnly = 2;
  const debugMode = true;
  // const readWrite = 3;
  // const rwd = 4;

  const getProductBrandList = useCallback(() => {
    var postData = {};
    postData["OpName"] = cookies.op_name;
    Axios.post(getProductBrandListUrl, postData)
      .then((response) => {
        setProductBrandList(response.data);
      })
      .catch((error) => {
        alert("後端異常");
        console.log(error);
      });
  }, [cookies]);

  useEffect(() => {
    // getPlanTypeList();
    getTermDurationList();
    var gid = isNaN(parseInt(cookies.group_id))
      ? 0
      : parseInt(cookies.group_id);
    if (gid !== 0) {
      getGroupPermission(gid);
    }
    getPlanTypeList();
    getProductBrandList();
    getPlanTypeListOfGWS();
    if (modalStatus === false) {
      setCompany(
        localStorage.getItem("filterCompany") !== null
          ? parseInt(localStorage.getItem("filterCompany"))
          : 0
      );
    }
    setCompanyOptionStatus(false);
  }, [modalStatus, cookies, getProductBrandList]);

  // Refreshing cookies mechanism
  useEffect(() => {
    if (getCookie("session") !== undefined) {// 按下登出按鈕 或是 Session 仍存在的情況下進行換頁。
      handleRefreshLoginCookies()
      if (debugMode) {
        console.log("Call loginCheck api- " + getCookie("session"));
      }
      Axios
        .post("/api/v0/loginCheck", {
          session: getCookie("session"),
        })
        .then(function (response) {
          if (response.data.IsLogin !== true) {
            handleExpiredCookies()
            history.replace("/backoffice/Login")
          }
        })
        .catch(function (error) {
          console.log("OPRouter", error);
        });
    } else {// Session 過期的情況下進行換頁。
      handleExpiredCookies()
      history.replace("/backoffice/Login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalStatus, // 新增、編輯、審核商品種類。
  ])

  const getPlanTypeList = () => {
    Axios.get(getPlanTypeListUrl)
      .then((response) => {
        setPlanTypeList(response.data);
      })
      .catch((error) => {
        alert("後端異常");
        console.log(error);
      });
  };

  const getPlanTypeListOfGWS = () => {
    Axios.get(getPlanTypeListOfGWSUrl)
      .then((response) => {
        setPlanTypeListOfGWS(response.data);
      })
      .catch((error) => {
        alert("後端異常");
        console.log(error);
      });
  };
  const getTermDurationList = () => {
    Axios.get(getTermDurationListUrl)
      .then((response) => {
        setTermDurationList(response.data);
      })
      .catch((error) => {
        alert("後端異常");
        console.log(error);
      });
  };

  const getGroupPermission = (sn) => {
    var postData = {};
    postData["Sn"] = sn;
    Axios.post("/OP/group/getGroupData", postData)
      .then(function (response) {
        var res = response.data.data;
        for (var i in res) {
          if (res[i].name === "商品種類管理") {
            setPermission(res[i].permission);
            break;
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  // const openModal = () => {
  //   setModalStatus(true);
  // };
  const closeModal = () => {
    setModalStatus(false);

    tableRef.current && tableRef.current.onQueryChange(); // Refreash data
  };
  // const productAdd = () => {
  //   setProductSn(0);
  //   setReviewed(false);
  //   openModal();
  // };

  // const updateMSID = () => {
  //   alert("請等待5~10秒")
  //   Axios
  //     .get("/api/v0/mpnGetOfferList")
  //     .then(function (response) {
  //       alert("成功更新")
  //       window.location.reload();
  //     })
  //     .catch(function (error) {
  //       alert("更新失敗");
  //     });
  // };

  const updateMSNCEID = () => {
    alert("更新需時數分鐘, 請稍後");
    Axios.get("/api/v0/updateProductOfferId")
      .then(function (response) {
        alert("成功更新");
        // window.location.reload();
        tableRef.current && tableRef.current.onQueryChange(); // Refreash data
      })
      .catch(function (error) {
        alert("更新失敗");
      });
  };

  const getProductBrand = (sn) => {
    console.log("sn:" + sn);
    Axios.post(getProductUrl, { sn: sn })
      .then((result) => {
        setCompany(result.data.brandSn);
        console.log("getProductBrand:" + result.data.brandSn);
      })
      .catch((error) => {
        setCompany(BrandMicrosoftSn);
        console.log("getProductBrand:" + error);
      });
  };

  // const openModal = () => {
  //     setModalStatus(true);
  // };
  // const closeModal = () => {
  //   setModalStatus(false);
  // };

  const openCompanyList = () => {
    setCompanyOptionStatus(true);
  };
  const closeCompanyList = () => {
    setCompanyOptionStatus(false);
  };

  const chooseCompany = (companyName) => {
    setCompany(companyName);
    setModalStatus(true);
  };

  const productAdd = () => {
    setProductSn(0);
    setReviewed(false);
    var brandSn =
      localStorage.getItem("filterCompany") !== null
        ? parseInt(localStorage.getItem("filterCompany"))
        : 0;
    if (brandSn !== 0) {
      chooseCompany(brandSn);
    } else {
      openCompanyList();
    }
  };
  const columns = [
    {
      title: "商品名稱",
      field: "name",
      cellStyle: {
        width: "30%",
      },
    },
    { title: "品牌", field: "brandName" },
    { title: "商品方案", field: "plan" },
    { title: "Pid", field: "pid" },
    {
      title: "定價",
      field: "price",
      type: "numeric",
      render: (rowData) => (
        <NumberFormat
          value={rowData.price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$ "}
        />
      ),
    },
    {
      title: "成本",
      field: "priceCost",
      type: "numeric",
      render: (rowData) => (
        <NumberFormat
          value={rowData.priceCost}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$ "}
        />
      ),
    },
    {
      title: "微軟商品碼狀態",
      field: "msOfferId",
      render: (rowData) => (rowData.msOfferId !== "" ? "O" : "X"),
    },
    {
      title: "審核",
      field: "reviewed",
      render: (rowData) => (rowData.reviewed ? "O" : "X"),
    },
    {
      title: "NCE",
      field: "withNceOffer",
      render: (rowData) => (rowData.withNceOffer ? "O" : "X"),
    },
    {
      title: "建檔時間",
      field: "reviewed",
      render: (rowData) => format(new Date(rowData.createDate), datetimeFormat),
    },
  ];

  const actions =
    permission === readOnly
      ? []
      : [
        {
          icon: AddCircleOutlineIcon,
          tooltip: "新增",
          isFreeAction: true,
          onClick: (event) => productAdd(),
        },
        {
          icon: UpdateIcon,
          tooltip: "更新微軟ID",
          isFreeAction: true,
          onClick: (event) => updateMSID(),
        },
        {
          icon: UpdateIcon,
          tooltip: "更新 NCE Offer ID",
          isFreeAction: true,
          onClick: (event) => updateMSNCEID(),
        },
        {
          icon: EditIcon,
          tooltip: "編輯",
          onClick: (event, rowData) => productEdit(rowData.sn),
        },
        (rowData) => ({
          icon: DeleteIcon,
          tooltip: "刪除",
          onClick: (event, rowData) => productDelete(rowData.sn),
        }),
        (rowData) => ({
          icon: AssignmentTurnedInIcon,
          tooltip: "審核",
          onClick: (event, rowData) => productReview(rowData.sn),
        }),
      ];

  const updateMSID = () => {
    alert("請等待更新完成");
    Axios.get("/api/v0/mpnGetOfferList")
      .then(function (response) {
        alert("成功更新");
        // window.location.reload();
        tableRef.current && tableRef.current.onQueryChange(); // Refreash data
      })
      .catch(function (error) {
        alert("更新失敗");
      });
  };

  const productEdit = (sn) => {
    setProductSn(sn);
    getProductBrand(sn);
    setReviewed(false);
    setModalStatus(true);
  };

  const productReview = (sn) => {
    setProductSn(sn);
    getProductBrand(sn);
    setReviewed(true);
    setModalStatus(true);
  };

  const productDelete = (sn) => {
    if (window.confirm("確定刪除此筆資料？")) {
      Axios.post("/api/product/delete", {
        sn: sn,
        OpName: cookies.op_name,
      })
        .then((res) => {
          alert("刪除成功");
          // window.location.reload();
          tableRef.current && tableRef.current.onQueryChange(); // Refreash data
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  // function createLabel(title, data, mdSize = 6) {
  //   return (
  //     <Grid item xs={12} md={mdSize}>
  //       <Grid container>
  //         <Grid item xs={4}>
  //           <Box m={1}>{title}</Box>
  //         </Grid>
  //         <Grid item xs={8}>
  //           <Box m={1}>{data}</Box>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  const renderComponent = (value) => {
    switch (value) {
      case BrandMicrosoftSn:
        return (
          <ProductFormOfMicrosoft
            open={modalStatus}
            closeModal={closeModal}
            review={reviewed}
            sn={productSn}
            planTypeList={planTypeList}
            termDurationList={termDurationList}
            brand={company}
          />
        );
      case BrandGoogleSn:
        return (
          <ProductFormOfGoogle
            open={modalStatus}
            closeModal={closeModal}
            review={reviewed}
            planTypeListOfGWS={planTypeListOfGWS}
            sn={productSn}
            brand={company}
          />
        );
      case 0:
        return <></>;
      default:
        return (
          <Product
            open={modalStatus}
            closeModal={closeModal}
            review={reviewed}
            sn={productSn}
            brand={company}
          />
        );
    }
  };

  const getData = (query) =>
    new Promise((resolve, reject) => {
      var postData = {};
      postData.perPage = query.pageSize;
      postData.page = query.page;
      postData.searchText = query.search;
      if (query.orderBy) {
        postData.orderBy = query.orderBy.field;
        postData.orderDirection = query.orderDirection;
      }
      postData.brandSn =
        localStorage.getItem("filterCompany") !== null
          ? parseInt(localStorage.getItem("filterCompany"))
          : 0;
      postData.OpName = cookies.op_name;

      Axios.post(getProductListUrl, postData)
        .then((result) => {
          resolve({
            data: result.data.data !== null ? result.data.data : [],
            page: query.page,
            totalCount: result.data.total,
          });
        })
        .catch((result) => {
          alert("後端異常");
          resolve({ data: [], page: 0, totalCount: 0 });
        });
    });
  var classes = useStyles();
  if (permission === notPermitted) {
    return (
      <Box>
        <div>
          <Container className={classes.container}>
            <Grid
              spacing={1}
              direction="row"
              style={{
                textAlign: "center",
                minHeight: "100vh",
              }}
            >
              <Grid
                item
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 200,
                }}
              >
                <Typography style={{ fontSize: 100 }}>您無此權限</Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    );
  }

  return (
    <Box m={2}>
      <MaterialTable
        tableRef={tableRef}
        title="商品種類"
        icons={tableIcons}
        columns={columns}
        data={getData}
        actions={actions}
        onChangePage={handleChangePage}
        options={{
          searchText: "",
          pageSize: 20,
          pageSizeOptions: [10, 20, 30, 40, 50],
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#888",
            color: "#FFF",
            textAlign: "center",
          },
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
            textAlign: "center",
          }),
        }}
      />
      <CompanyList
        open={companyOptionStatus}
        closeList={closeCompanyList}
        productBrandList={productBrandList}
        chooseCompany={chooseCompany}
      />

      {renderComponent(company)}
    </Box>
  );
}
