import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";

import {
  TextField,
  Grid,
  Button,
  Divider,
  Dialog,
  DialogTitle,
} from "@material-ui/core";

import Styled from "styled-components"
// CSS
const StyledDialogTitle = Styled(DialogTitle)`
  && {
    display: flex;
    justify-content: center;
  }
`
const StyledDialog = Styled(Dialog)`
  & .MuiDialog-paper {
    padding: 10px 30px 20px 30px;
    border-radius: 10px;
    width: 22vw;
    min-width: 200px;
  }
`
const StyledTextField = Styled(TextField)`
  && {
    width: 100%;
  }
  & .MuiFilledInput-input {
    text-align: center;
    border-radius: 30px;
  }
  & .MuiFilledInput-root {
    width: 100%;
    border-radius: 30px;
    letter-spacing: 0.2rem;
  }
  & .MuiInputLabel-formControl {
    position: relative;
  }
  & .MuiFormLabel-root {
    text-align: center;
    transform-origin: center;
    font-size: 1.15rem;
  }
  & .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(0px, 23px) scale(0.65);
  }
  & .MuiInputLabel-filled {
    transform: translate(0px, 38px) scale(1);
  }
  & .MuiFormHelperText-root {
    text-align: center;
    margin-top: 5px;
  }
`
const StyledOTPBtn = Styled(Button)`
  && {
    background-color: #28a8ea;
    color: #fff;
    width: 95%;
    border-radius: 30px;
    font-size: 0.7rem;
    padding:10px;
  }
  &&:hover {
    background-color: #1794d5;
  }
`
const StyledCancelBtn = Styled(Button)`
  && {
    background-color: #f75c5c;
    color: #fff;
    width: 95%;
    border-radius: 30px;
    font-size: 0.84rem;
  }
  &&:hover {
    background-color: #d54b4b;
  }
`
const StyledGrid = Styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }
`

export default function NoOTPLoginModal(props) {
  const history = useHistory();

  // No Hn login OTP modal status
  const [openOTPInputModal, setOpenOTPInputModal] = React.useState(false);
  const [otpCell, setOTPCell] = React.useState("");
  const [inputOTP, setInputOTP] = React.useState("");
  const [otpValid, setOTPValid] = React.useState(false);
  const [confirmOTP, setConfirmOTP] = React.useState(false);
  const [isSendingMessage, setIsSendingMessage] = React.useState(false);

  // Function for setting up values for hiB2B modal
  const defaultTimerSecond = -1;
  const TimerStartSecond = 300;
  const setOTPCellHandler = props.setOTPCellHandler;
  const openHiB2BModal = props.openHiB2BModal;
  const [OTPTimerID, setOTPTimerID] = useState(null);
  const [OTPSeconds, setOTPSeconds] = useState(defaultTimerSecond);

  useEffect(() => {
    if (props.open) {
      setOpenOTPInputModal(props.open);
    }
  }, [props.open]);

  // 用於畫面上 OTP驗證碼的輸入倒數計時器
  useEffect(() => {
    if (OTPSeconds > 0) { // 正常倒數過程，並更新畫面上的倒數秒數
      var timer = window.setTimeout(() => setOTPSeconds(OTPSeconds-1), 1000);
      setOTPTimerID(timer); // 盯著此時的 timer ID ，以備隨時利用 timer ID 來暫停倒數。
    } else if (OTPSeconds === 0) { // 倒數結束
      alert("OTP 驗證碼超過 300 秒未輸入，即將重新導入此頁面...");
      window.location.reload();
    }
  },[OTPSeconds]);

  const closeOTPLogin = () => {
    setOpenOTPInputModal(false);
  };

  // 使用於需要 開始OTP驗證碼倒數
  const OTPTimerStart = () => {
    setOTPSeconds(TimerStartSecond);
  }
  // 使用於需要 重設OTP驗證碼倒數
  const OTPTimerReset = () => {
    if (OTPTimerID) {
      window.clearTimeout(OTPTimerID); // 移除 OTPTimerID 計時器
      setOTPTimerID(null); // 將 OTPTimerID 重設為 null
      setOTPSeconds(defaultTimerSecond); // Reset to Init second(-1)
    }
  }

  function handleOTPCellChange(event) {
    // eslint-disable-next-line no-unused-vars
    const { name, value } = event.target;
    setOTPCell((prevState) => value);
  }

  function handleOTPChange(event) {
    // eslint-disable-next-line no-unused-vars
    const { name, value } = event.target;
    setInputOTP((prevState) => value);
  }

  function issueOTPtoCell() {
    if (otpCell === "") {
      alert("手機號碼為必填欄位!");
      return;
    }
    if (!isSendingMessage) {
      setOTPValid(true);
      setIsSendingMessage(true);

      // Set otp cellphone number in main page
      setOTPCellHandler(otpCell);

      // POST otp data for sending text message
      Axios.post("/api/sms/noHnWriteSmsLogData", {
        Cell: otpCell.trim(),
      })
        .then((res) => {
          if (res.data["responseStatus"] === true) {
            setConfirmOTP(true);
            setTimeout(function() {
              setOTPValid(false);
            }, 5 * 60 * 1000);
            OTPTimerStart(); // 有正常的 response，OTP驗證碼 計時器才開始。
            if (process.env.REACT_APP_MODE === "development") {
              alert(res.data["responseMsg"]);
            } else {
              alert("簡訊 OTP 認證碼已發送, 請於 300 秒之內輸入!");
            }
          } else {
            if (res.data["responseMsg"] === "NoHnPurchaseInValid") {
              alert(
                "此手機號碼已執行過無 HN 購買, " +
                  "且已取得正式 HN 碼, " +
                  "請改以有 HN 碼方式進行購物, " +
                  "或改以其他手機號碼進行無 HN 購買, " +
                  "謝謝您的配合!",
              );
            } else {
              alert("OTP 簡訊發送失敗, 請重送 OTP 申請!");
            }
          }
          setIsSendingMessage(false);
        })
        .catch((err) => {
          alert("網路錯誤, 請重送 OTP 申請!");
          setIsSendingMessage(false);
        });
    }
  }

  function createButtonGroup() {
    return (
      <Grid container>
        <StyledGrid item xs={12} md={6}>
          <StyledCancelBtn
            color="primary"
            onClick={() => {
              history.push({ pathname: "BuyNow" });
            }}
          >
            取消購買
          </StyledCancelBtn>
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
        {!confirmOTP ? (
          <StyledOTPBtn onClick={issueOTPtoCell} color="primary">
            發送 OTP 認證碼
          </StyledOTPBtn>
        ) : (
          <StyledOTPBtn onClick={confirmOTPLogin} color="primary">
            確認 OTP 認證碼
          </StyledOTPBtn>
        )}
        </StyledGrid>
      </Grid>
    );
  }

  function otpAuthSuccess(InputOTPCorrect) {
    if (otpValid && InputOTPCorrect) {
      closeOTPLogin();
      openHiB2BModal();
    } else {
      if (!otpValid) {
        alert("超過 300 秒未正確輸入, OTP 已失效, 請重發申請!");
      } else {
        alert("輸入之 OTP 錯誤, 請重發申請!");
      }
      setConfirmOTP(false);
    }
  }

  function confirmOTPLogin() {
    Axios.post("/api/sms/noHnOTPAuthentication", {
      Cell: otpCell,
      OTP: inputOTP.trim(),
    })
      .then((res) => {
        otpAuthSuccess(res.data["authResult"]);
        OTPTimerReset(); // 網路異常就 Reset 計時器。
      })
      .catch(() => {
        alert("後端異常, 請稍後重新送出 OTP 驗證!");
        OTPTimerReset(); // 網路異常就 Reset 計時器。
      });
  }

  return (
    <StyledDialog
      open={openOTPInputModal}
      maxWidth="lg"
      scroll="paper"
      backdrop="static"
    >
      <StyledDialogTitle>手機 OTP 登入</StyledDialogTitle>
      <Divider/>
      <Grid item xs={12}>
        <StyledTextField
          variant="filled"
          label="手機號碼"
          name="OTPCell"
          required
          onChange={handleOTPCellChange}
          InputProps={{ disableUnderline: true }}
        ></StyledTextField>
      </Grid>
        {confirmOTP && (
          <Grid container xs={12}>
            <StyledTextField
              variant="filled"
              label="OTP 認證碼"
              name="OTP"
              required
              onChange={handleOTPChange}
              InputProps={{ disableUnderline: true }}
              helperText={`剩下 ${OTPSeconds} 秒`}
            ></StyledTextField>
          </Grid>
        )}
      {createButtonGroup()}
    </StyledDialog>
  );
}
