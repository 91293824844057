import React from "react";

import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import Save from "@material-ui/icons/Save";
import ViewColumn from "@material-ui/icons/ViewColumn";

import axios from "axios";
import Qs from "qs";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import GroupAddModel from "./GroupAddModal";
import GroupEditModel from "./GroupEditModal";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <Save {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function Group() {
  const [data, setData] = useState();
  const [addModalStatus, setAddModalStatus] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [editPermissions, setEditPermissions] = useState([]);
  const [enablePermissions, setEnablePermissions] = useState([]);
  const [selectedSn, setSelectedSn] = useState(1);
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies();
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1)
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }));

  useEffect(() => {
    getAll();
    getAllPermission();
  }, []);

  const classes = useStyles();

  // FIXME
  // const openModal = () => {
  //   setAddModalStatus(true);
  // };
  const closeModal = () => {
    setAddModalStatus(false);
    setEditModalStatus(false);
    getAll();

  };

  const openEditModal = (sn = 0) => {
    setSelectedSn(sn);
    setEditModalStatus(true);
  };
  const closeEditModal = () => {
    setEditModalStatus(false);
    getAll();
  }

  function getAll() {
    axios
      .post(
        "/OP/group/getAllgroup",
        Qs.stringify({
          test: "test"
        })
      )
      .then(res => {
        const list = res.data.data;
        //console.log(list);
        setData(list);
        setLoading(false);
        //console.log(data);
        //console.log(loading);
      });
  }
  function getAllPermission() {
    axios
      .post(
        "/OP/function/getAllPermission",
        Qs.stringify({
          test: "test"
        })
      )
      .then(res => {
        // var list = res.data.editPermissions;
        //console.log(res.data.editPermissions);
        //console.log(res.data.enablePermissions);
        setEditPermissions(res.data.editPermissions);
        setEnablePermissions(res.data.enablePermissions);
      })
      .catch(error => {
        if (error.response) {
          // 當狀態碼不在 validateStatus 設定的範圍時進入
          // 有 data / status / headers 參數可用
          alert(error.response.data.msg);
        } else if (error.request) {
          // 發送請求，但沒有接到回應
          // error.request is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // 在設定 request 時出錯會進入此
          console.log("Error", error.message);
        }
      });
  }


  function deleteGroup(rowData) {

    //console.log(rowData)
    var DeleteSnList = []

    // for(var i in rowData) {
    //   DeleteSnList.push(rowData[i].sn);
    //   //DeleteSnList[parseInt(i)+1] = parseInt(i)+1;
    // }
    //console.log(DeleteSnList)
    DeleteSnList.push(rowData.sn);

    axios
      .post(
        "/OP/group/deleteGroup",
        {
          DeleteSnList: DeleteSnList
        }
      )
      .then(res => {
        alert(res.data.msg);
        getAll();
      })
      .catch(error => {
        if (error.response) {
          // 當狀態碼不在 validateStatus 設定的範圍時進入
          // 有 data / status / headers 參數可用
          alert(error.response.data.msg);
        } else if (error.request) {
          // 發送請求，但沒有接到回應
          // error.request is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // 在設定 request 時出錯會進入此
          console.log("Error", error.message);
        }
      });
  }
  function isAdminGrp() {
    var gid = cookies.group_id === undefined || isNaN(cookies.group_id) ? 0 : parseInt(cookies.group_id);
    if (process.env.REACT_APP_MODE === "production" || process.env.REACT_APP_MODE === "intranet") {
      return gid === 1;
    } else {
      return gid === 1 || gid === 2;
    }
  }
  return (
    <>

      <Button // FIXME
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<AddBox></AddBox>}
        // onClick={openModal}
        disabled={true}
      >
        新增群組
      </Button>
      <MaterialTable
        title="群組管理"
        icons={tableIcons}
        isLoading={loading}
        columns={[
          { title: "群組名稱", field: "name" },
          { title: "說明", field: "description" },
          { title: "狀態", field: "state", type: "boolean" }
        ]}
        data={data}
        actions={[
          {
            icon: Edit,
            tooltip: "Edit User",
            // onClick: (event, rowData) => {
            //   if (rowData.length > 1) {
            //     alert("請一次編輯一位使用者");
            //   } else {
            //     //console.log(rowData[0]);
            //     setSelectedSn(rowData.sn);
            //     setEditModalStatus(true);
            //   }
            // }
            onClick: (event, rowData) => openEditModal(rowData.sn)
          },
          {
            icon: DeleteOutline,
            tooltip: "Delete Group",
            onClick: (event, rowData) => {
              //console.log(rowData);
              // deleteGroup(rowData);
              if (isAdminGrp()) {
                alert("無法刪除最高權限管理者群組!");
              } else {
                deleteGroup(rowData);
              }
            }
          }
        ]}
        options={{
          searchText: "",
          actionsColumnIndex: -1,
          paginationType: "stepped",
          showTextRowsSelected: false
        }}
      ></MaterialTable>
      <GroupAddModel
        modalStatus={addModalStatus}
        editPermissions={editPermissions}
        enablePermissions={enablePermissions}
        closeModal={closeEditModal}
      />
      <GroupEditModel
        modalStatus={editModalStatus}
        editPermissions={editPermissions}
        enablePermissions={enablePermissions}
        sn={selectedSn}
        closeModal={closeModal}
      />
    </>
  );
}
