import React, { useState, useEffect, useCallback } from "react";
import { format } from "date-fns";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  DialogContent,
  FormControl,
  NativeSelect
} from "@material-ui/core";
import Axios from "axios";
import MaterialTable from "material-table";
import { useCookies } from "react-cookie";

const BrandGoogleSn = 2;
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";
const productDefault = {
  sn: 0,
  name: "",
  brandSn: BrandGoogleSn,
  planTypeSn: "",
  pid: "",
  price: 0,
  priceCost: 0,
  gOfferName: "",
  gOfferId: "",
  gOfferRenewDate: "",
  isUnlistedProduct: false
};

const productFieldList = {
  Name: "商品名稱",
  PlanTypeSn: "方案類型",
  Pid: "小額付款商品編號",
  Price: "定價",
  PriceCost: "成本",
  Reviewed: "審核狀態",
  GoogleOfferName: "Google 商品名稱(英文)",
  GoogleOfferId: "Google 商品碼"
};

const productInfo = [
  {
    'skuId': '1010020027',
    'skuName': 'Google Workspace Business Starter'
  },
  {
    'skuId': '1010020028',
    'skuName': 'Google Workspace Business Standard'
  },
  {
    'skuId': '1010020025',
    'skuName': 'Google Workspace Business Plus'
  },
  {
    'skuId': '1010060003',
    'skuName': 'Google Workspace Enterprise Essentials'
  },
  {
    'skuId': '1010020026',
    'skuName': 'Google Workspace Enterprise Standard'
  },
  {
    'skuId': '1010020020',
    'skuName': 'Google Workspace Enterprise Plus (formerly G Suite Enterprise)'
  },
  {
    'skuId': '1010060001',
    'skuName': 'Google Workspace Essentials (formerly G Suite Essentials)'
  },
  {
    'skuId': '1010020030',
    'skuName': 'Google Workspace Frontline'
  }
];

function logTable(data) {
  const columns = [
    {
      title: "時間",
      field: "modifyTime",
      render: (rowData) => format(new Date(rowData.modifyTime), datetimeFormat)
    }, {
      title: "帳號",
      field: "operatorName"
    }, {
      title: "欄位",
      field: "field",
      lookup: productFieldList
    }, {
      title: "修改前資料",
      field: "beforeValue"
    }, {
      title: "修改後資料",
      field: "afterValue"
    }, {
      title: "IP位址",
      field: "modifyIP"
    }
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      options={{
        search: false,
        paging: false,
        showTitle: false,
        toolbar: false,
        padding: "dense",
        sorting: false,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.tableData.id % 2
            ? "#FFFFFF"
            : "#EEEEEE"
        })
      }} />
  );
}

function createLabel(title, data, mdSize = 6) {
  return (
    <Grid item="item" xs={12} md={mdSize}>
      <Grid container="container">
        <Grid item="item" xs={4}>
          <Box m={1}>{title}</Box>
        </Grid>
        <Grid item="item" xs={8}>
          <Box m={1}>{data}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

function datetimeStringFormat(time) {
  console.log(time);
  // time is string
  if (time === "" || time === null || time === undefined) {
    return "";
  } else {
    return format(new Date(time), datetimeFormat);
  }
}

export default function ProductFormOfGoogle(props) {
  const [cookies] = useCookies(["session"]);
  const [modalStatus, setModalStatus] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [product, setProduct] = useState(productDefault);
  const [productSn, setProductSn] = useState(0);
  const [modifyLog, setModifyLog] = useState([]);
  const [planTypeList, setPlanTypeList] = useState([]);
  // const [product.isUnlistedProduct, setIsUnListedProduct] = useState(false);

  const closeModal = props.closeModal;

  const getProduct = useCallback((sn) => {
    setProductSn(sn);
    if (sn && sn !== 0) {
      const getProduct = Axios.post("/api/product", { sn: sn });
      const getModifyLog = Axios.post("/api/product/log/list", { sn: sn }); //FIXME

      Axios
        .all([getProduct, getModifyLog])
        .then(Axios.spread((productResponse, modifyLogResponse) => {
          console.log(productResponse.data);
          setProduct(productResponse.data);
          setModifyLog(
            modifyLogResponse.data
              ? modifyLogResponse.data
              : []
          );
          if (productResponse.data !== undefined && productResponse.data.isUnlistedProduct !== undefined) {
            if (productResponse.data.isUnlistedProduct === false) {
              handleAutoInputSKUName(productResponse.data.gOfferId);
            }
          }
        }))
        .catch(() => {
          alert("後端異常");
          setProduct(productDefault);
          setModifyLog([]);
        });
    } else {
      setProduct(productDefault);
      setModifyLog([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (props.open) {
      setReviewed(props.review);
      setPlanTypeList(props.planTypeListOfGWS);
      getProduct(props.sn);
    }
    setModalStatus(props.open);

  }, [props.open, props.planTypeListOfGWS, props.review, props.sn, getProduct]);

  const handleAutoInputSKUName = (value) => {
    console.log(product.gOfferName);
    let SKUName = "";
    for (let i = 0; i < productInfo.length; i++) {
      if (value === productInfo[i].skuId) {
        SKUName = productInfo[i].skuName;
        break;
      } else {
        SKUName = "";
      }
    }
    setProduct((prevState) => ({
      ...prevState,
      gOfferName: SKUName
    }));
  }

  const setIsUnListedProductHandler = () => {
    setProduct((prevState) => ({
      ...prevState,
      gOfferName: "",
      gOfferId: "",
      isUnlistedProduct: !product.isUnlistedProduct
    }));
    // setIsUnListedProduct(!product.isUnlistedProduct);
  };

  const handleChange = (event) => {
    var {
      name,
      value,
      type
    } = event.target;
    if (type === "number") {
      value = value > 2147483647
        ? 2147483647
        : parseFloat(value);
      value = value < 0
        ? 0
        : parseFloat(value);
    } else {
      if (name === "planTypeSn") {
        value = parseInt(value);
        value = value > 2147483647 ? 2147483647 : value;
        value = value < 1 ? 1 : value;
      } else if (name === "gOfferId") {
        if (!product.isUnlistedProduct) {
          handleAutoInputSKUName(value);
        } else {
          setProduct((prevState) => ({
            ...prevState,
            gOfferId: value
          }));
        }
        // } else if (name === "gOfferName") {
        //   setProduct((prevState) => ({
        //     ...prevState,
        //     gOfferName: value
        //   }));
      }
    }

    console.log("name:" + name);
    console.log("type:" + type);
    console.log("value:" + value);

    setProduct((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const productReview = () => {
    if (window.confirm("確定審核此筆資料？")) {
      Axios
        .post("/api/product/review", {
          sn: product.sn,
          OpName: cookies.op_name,
        })
        .then((res) => {
          alert("審核成功");
          closeModal();
          window
            .location
            .reload();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const productSubmit = (e) => {
    e.preventDefault();
    if (window.confirm("確定儲存此筆資料？")) {
      Axios
        .post("/api/product/insertOrUpdate", {
          product: product,
          OpName: cookies.op_name,
        })
        .then((res) => {
          alert("儲存成功");
          closeModal(); // Refreash data after closeModal
          // window.location.reload();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const productDataList = [
    createLabel(
      "商品名稱",
      <TextField
        fullWidth="fullWidth"
        margin="dense"
        placeholder="（必填欄位 / Required Field）"
        value={product.name}
        onChange={handleChange}
        autoComplete="off"
        disabled={reviewed}
        name="name"
        required="required" />
    ),
    createLabel(
      "方案類型",
      <FormControl fullWidth>
        <NativeSelect
          name="planTypeSn"
          value={product.planTypeSn}
          disabled={reviewed}
          onChange={handleChange}
          required
        >
          <option value=""></option>
          {planTypeList.map((planType, index) => {
            return (
              <option key={index} value={planType.Sn}>
                {planType.Name}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>

      // <TextField
      //     fullWidth="fullWidth"
      //     margin="dense"
      //     value={productPlanType.Name || product.planTypeSn}
      //     onChange={handleChange}
      //     disabled={reviewed}
      //     name="planTypeSn"
      //     InputProps={{
      //       readOnly: true,
      //     }}/>
    ),
    createLabel(
      "PID",
      <TextField
        fullWidth="fullWidth"
        margin="dense"
        placeholder="（必填欄位 / Required Field）"
        value={product.pid || ""}
        disabled={reviewed}
        name="pid"
        onChange={handleChange}
        autoComplete="off"
        required="required" />
    ),
    createLabel(
      "定價",
      <TextField
        fullWidth="fullWidth"
        margin="dense"
        placeholder="（必填欄位 / Required Field）"
        value={product.price || ""}
        disabled={reviewed}
        name="price"
        type="number"
        max="2,147,483,647"
        onChange={handleChange}
        autoComplete="off"
        required="required" />
    ),
    createLabel(
      "成本",
      <TextField
        fullWidth="fullWidth"
        margin="dense"
        placeholder="（必填欄位 / Required Field）"
        value={product.priceCost || ""}
        disabled={reviewed}
        name="priceCost"
        type="number"
        onChange={handleChange}
        autoComplete="off"
        required="required" />
    )
  ];

  var gOfferDataList = [
    createLabel("切換新增商品模式:",
      !product.isUnlistedProduct ? (
        <Button
          style={{
            backgroundColor: "#21b6ae"
          }}
          onClick={setIsUnListedProductHandler}
        >
          轉換成輸入未列出之商品
        </Button>
      ) : (
        <Button
          style={{
            backgroundColor: "#21b6ae"
          }}
          onClick={setIsUnListedProductHandler}
        >
          轉換成新建一般商品
        </Button>
      )
    ),
    // Padding
    createLabel("", <></>),
    createLabel(
      "Google 實際商品名稱",
      <TextField
        fullWidth="fullWidth"
        margin="dense"
        placeholder={product.isUnlistedProduct ? "請輸入商品名稱" : "（自動填入）"}
        value={product.gOfferName/* || ""*/}
        onChange={handleChange}
        disabled={!product.isUnlistedProduct}
        name="gOfferName" />
    ),
    createLabel(
      "Google 商品碼",
      <TextField
        fullWidth="fullWidth"
        margin="dense"
        placeholder={product.isUnlistedProduct ? "（選填欄位 / Optional Field）" : "（必填欄位 / Required Field）"}
        helperText={<p>需要與 Google 所提供商品的 SKU ID 相同，可參考 <a href="https://developers.google.com/admin-sdk/reseller/v1/how-tos/products" target="_blank" rel="noreferrer noopener">這裡</a>。</p>}
        value={product.gOfferId || ""}
        onChange={handleChange}
        autoComplete="off"
        name="gOfferId"
        disabled={reviewed}
        required={!product.isUnlistedProduct} />
      // required
    ),
    createLabel("Google 商品更新時間", datetimeStringFormat(product.gOfferRenewDate))
  ];

  return (
    <Dialog open={modalStatus} onClose={closeModal} maxWidth="lg" scroll="paper">
      <Box>
        <form onSubmit={productSubmit}>
          <DialogTitle>Google 商品重要資訊設定：</DialogTitle>
          <DialogContent dividers="dividers">
            <Grid container="container">
              {
                productDataList.map((item) => {
                  return item;
                })
              }
            </Grid>
          </DialogContent>
          <DialogTitle>Google 相關設定：</DialogTitle>
          <DialogContent dividers="dividers">
            <Grid container="container">
              {
                gOfferDataList.map((item) => {
                  return item;
                })
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Typography hidden={!reviewed}>
              <Button onClick={productReview} name="review" color="primary">
                審核
              </Button>
            </Typography>
            <Typography hidden={reviewed}>
              <Button type="submit" name="save" color="primary">
                儲存
              </Button>
            </Typography>
            <Button onClick={closeModal} color="primary">
              取消
            </Button>
          </DialogActions>
          <Typography component="div" hidden={productSn === 0}>
            <DialogTitle>商品種類更改紀錄：</DialogTitle>
            <DialogContent dividers="dividers">{logTable(modifyLog)}</DialogContent>
          </Typography>
        </form>
      </Box>
    </Dialog>
  )
}
