// eslint-disable-next-line no-unused-vars
import React, { useEffect, forwardRef } from "react";
import { useCookies } from "react-cookie";

import MaterialTable from "material-table";
import { format } from "date-fns";

// icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import BuildIcon from "@material-ui/icons/Build";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";

import Axios from "axios";

// Component
import { Box } from "@material-ui/core";

// Formats
const datetimeFormat = "yyyy/MM/dd HH:mm:ss";

// API
const deleteProblematicOrderAPI = "/api/order/deleteDsisProblematicOrder";
const resolveProblematicOrderAPI = "/api/order/rerunDsisProblematicOrder";

const tableIcons = {
  // CRUD
  Add: forwardRef((props, ref) => (
    <AddCircleOutlineIcon {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Resolve: forwardRef((props, ref) => <BuildIcon {...props} ref={ref} />),
  // page
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  // search
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
};

export default function DsisProblematicOrders(props) {
  // Table row data
  const [rows, setRows] = React.useState([]);
  const [cookies] = useCookies(["op_name"]);

  // Define table components
  const TableColumns = [
    { title: "會員名稱", field: "memberName" },
    { title: "HN帳號", field: "hn" },
    { title: "訂單編號", field: "orderNo" },
    { title: "訂單來源", field: "orderSourceType" },
    { title: "推廣員工", field: "promotionEmployeeNo" },
    { title: "DSIS訂單碼", field: "dsisNo" },
    { title: "優惠/錯誤代碼", field: "discode" },
    { title: "商品名稱", field: "productName" },
    { title: "品牌", field: "brandName" },
    {
      title: "訂單時間",
      field: "orderDate",
      render: (rowData) => {
        if (rowData.orderDate) {
          return format(new Date(rowData.orderDate), datetimeFormat);
        }
        return null;
      },
    },
    { title: "訂單異常訊息", field: "errorLog" },
    {
      title: "Dsis訂單操作",
      field: "dsisOperation",
      render: (rowData) => {
        return rowData.dsisOperation === "D" ? "刪除訂單" : "新增訂單";
      },
    },
  ];

  const resolveOrder = () => {
    if (window.confirm("確定重新執行異常訂單匯入?")) {
      var postData = {};
      Axios.post(resolveProblematicOrderAPI, postData)
        .then((response) => {
          alert("異常訂單匯入完成");
          // window.location.reload();
          getData(cookies.op_name);
        })
        .catch((err) => {
          alert("後端異常");
        });
    }
  };

  const orderDelete = (sn = 0) => {
    //alert("請確定微軟網站已退訂");
    if (window.confirm("確定刪除前是否已確定正確處理此訂單?")) {
      var postData = {};
      postData.Sn = sn;
      postData.opName = cookies.op_name;
      Axios.post(deleteProblematicOrderAPI, postData)
        .then((response) => {
          alert(response.data.message);
          // window.location.reload();
          getData(cookies.op_name);
        })
        .catch((err) => {
          alert("異常訂單刪除失敗");
        });
    }
  };

  // Define table actions
  const actions = [
    {
      icon: tableIcons.Resolve,
      tooltip: "Resolve",
      isFreeAction: true,
      onClick: (event, rowData) => {
        resolveOrder();
      },
    },
    {
      icon: tableIcons.Delete,
      tooltip: "Delete",
      onClick: (event, rowData) => {
        // deleteProductDiscode(rowData.sn);
        orderDelete(rowData.sn);
      },
    },
  ];

  useEffect(() => {
    getData(cookies.op_name);
  }, [cookies.op_name]);

  const getData = (op_name) => {
    var postData = {};
    postData["brandSn"] =
      localStorage.getItem("filterCompany") !== null
        ? parseInt(localStorage.getItem("filterCompany"))
        : 0;
    postData["OpName"] = op_name;

    Axios.post("/api/order/getDsisProblematicOrderList", postData)
      .then((response) => {
        setRows(response.data.data?? []);
      })
      .catch((err) => {
        alert("後端異常");
        console.log(err);
      });
  };

  return (
    <Box m={2}>
      <MaterialTable
        title="異常訂單管理"
        columns={TableColumns}
        data={rows}
        options={{
          searchText: "",
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 500],
          actionsColumnIndex: -1,
          showTextRowsSelected: true,
          headerStyle: {
            backgroundColor: "#888",
            color: "#FFF",
          },
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
          }),
        }}
        icons={tableIcons}
        actions={actions}
      />
      {/* <EditDialog
        open={EditDialogEnable}
        closeModal={closeEditDialog}
        productDiscodeDetail={productDiscodeDetail}
        productDetailList={productDetailList}
      />
      <CreateNewDiscode
        open={CreateNewDiscodeEnable}
        closeModal={closeEditCreateNewDiscode}
        productDetailList={productDetailList}
      ></CreateNewDiscode> */}
    </Box>
  );
}
