import React from "react";
import {useState, useEffect} from "react";

import Introduction from "./Introduction.js";
import BuiltinSecurity from "./BuiltinSecurity";
import PrivacyDesign from "./PrivacyDesign";
import Law from "./Law";
import TransparentOperation from "./TransparentOperation";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid'
import Button from "@material-ui/core/Button";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

// Dialog option
const init = -1;
const builtinSecurity = 0;
const privacyDesign = 1;
const law = 2;
const transparentOperation = 3;

export default function SafetyAndPrivacy() {
  const [open, setOpen] = useState(false);
  const [dialogOption, setDialogOption] = useState(init);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
    handleSetOption(init);
  };
  const handleSetOption = (value = 0) => {
    setDialogOption(value);
  }

  useEffect(() => {
    if( (open === false) && (dialogOption !== init) ){
      setOpen(true);
    } else {
      setOpen(false);
      handleSetOption(init);
    }
  }, [open, dialogOption]);

  const renderSecurityDetail = (value) => {
    switch (value) {
      case builtinSecurity:
        return (
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText>
                <Grid container spacing={3}>
                  <BuiltinSecurity></BuiltinSecurity>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Button 
                  variant="outlined"
                  onClick={handleClose}
                  size="large"
                  startIcon={<ArrowBackOutlinedIcon/>}
                > BACK </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )
      case privacyDesign:
        return (
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText>
                <Grid container spacing={3}>
                  <PrivacyDesign></PrivacyDesign>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Button 
                  variant="outlined"
                  onClick={handleClose}
                  size="large"
                  startIcon={<ArrowBackOutlinedIcon/>}
                > BACK </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )
      case law:
        return (
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText>
                <Grid container spacing={3}>
                  <Law></Law>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Button 
                  variant="outlined"
                  onClick={handleClose}
                  size="large"
                  startIcon={<ArrowBackOutlinedIcon/>}
                > BACK </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )
      case transparentOperation:
        return (
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText>
                <Grid container spacing={3}>
                <TransparentOperation></TransparentOperation>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Button 
                  variant="outlined"
                  onClick={handleClose}
                  size="large"
                  startIcon={<ArrowBackOutlinedIcon/>}
                > BACK </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )
      default:
        return <></>
    }
  }
  return (
    <>
      <Introduction
        handleClose={handleClose}
        handleSetOption={handleSetOption}
      />
      {renderSecurityDetail(dialogOption)}
    </>
  );
}
