import React from "react";
import Banner from "./Banner.js";
import Tabs from "./Tabs.js";

import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Styled from "styled-components";

const StyledFab = Styled(Fab)`
  && {
    position: fixed;
    right: 64px;
    bottom: 64px;
    background-color: #28a8ea;
    color: #fff;
  }
  &&:hover {
    background-color: #005683;
    color: #fff;
  }
`
const goTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

export default function ProductOverview() {
  return (
    <div>
      <Banner />
      <Tabs />
      <StyledFab onClick={goTop}>
        <ArrowUpwardIcon></ArrowUpwardIcon>
      </StyledFab>
    </div>
  );
}
