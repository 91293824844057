// eslint-disable-next-line no-unused-vars
import React from "react";
//import Axios from "axios";
import { Link } from "react-router-dom";

import {
  Container,
  Grid,
  Button,
  Typography,
  Paper
} from "@material-ui/core";

// Style CSS
import Styled from "styled-components"

// CSS
const StyledHint = Styled(Typography)`
&& {
  color: red;
  font-family: Microsoft JhengHei;
}
`
const StyledGrid = Styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-itmes: center;
    margin: 20px 0px;
  }
`
const StyledPaper = Styled(Paper)`
  && {
    width: 80vw;
    padding: 15px 30px;
    max-width: 620px;
    border-radius: 13px;
  }
`
const StyledTitle = Styled(Typography)`
  && {
    color: black;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.0938em;
    font-family: Microsoft JhengHei;
  }
`
const StyledContent = Styled(Typography)`
  && {
    color: red;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.0938em;
    font-family: Microsoft JhengHei;
    word-wrap: break-word;
  }
`
const StyledBackBtn = Styled(Button)`
  && {
    background-color: #28a8ea;
    font-weight: bolder;
    line-height: 1.5;
    padding: 12px;
    letter-spacing: 0.0938em;
    font-size: 0.975rem;
    font-family: Microsoft JhengHei;
    color: #fff;
    width: 30vw;
    max-width: 180px;
  }
  &&:hover {
    background-color: #1077ad;
  }
`
const StyledSystemInfo = Styled(Typography)`
&& {
  color: black;
  font-family: Microsoft JhengHei;
  font-weight: 600;
}
`
const StyledContainerOrderTitle = Styled(Grid)`
  && {
    background-color: #ddd;
    border-radius: 13px;
    margin: 10px 0px;
  }
`


export default function Purchase(props) {
  let data = (props.location.state) ? props.location.state : {
    planName: "",
    plan: "",
    price: "",
    TelMorning: "",
    Zip: "",
    Name: "",
    ContactName: "",
    EMail: "",
    Address: "",
    Domain: "",
    Quantity: "",
    PromoEmpno: "",
    OperatorNote: "",
    PaySuccess: true,
    OrderDate: "",
    OrderNo: "",
    productDescription:""
  };

  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });

  //console.log(data);

  let planName = localStorage.getItem("planName");   // Plan name
  let plan = localStorage.getItem("plan");  // Pay per month or per year
  let price = localStorage.getItem("price"); // Price of plan
  let TelMorning = localStorage.getItem("TelMorning");
  let Zip = localStorage.getItem("Zip");
  let Name = localStorage.getItem("Name");
  let ContactLastName = localStorage.getItem("ContactLastName");
  let ContactName = localStorage.getItem("ContactName");
  let EMail = localStorage.getItem("EMail");
  let Address = localStorage.getItem("Address");
  let Domain = localStorage.getItem("Domain");
  let Quantity = localStorage.getItem("Quantity");
  let PromoEmpno = localStorage.getItem("PromoEmpno");
  let OperatorNote = localStorage.getItem("OperatorNote");
  let PaySuccess = localStorage.getItem("PaySuccess");
  let OrderDate = localStorage.getItem("OrderDate");
  let OrderNo = localStorage.getItem("OrderNo");
  let productDescription = localStorage.getItem("productDescription");

  // Renew arguments and save it in local storage
  if (props.location.state) {
    PaySuccess = data.PaySuccess;
    if (PaySuccess) {
      console.log(props.location.state);
      
      planName = data.planName;
      plan = data.plan;
      price = data.price;
      TelMorning = data.TelMorning;
      Zip = data.Zip;
      Name = data.Name;
      ContactLastName = data.ContactLastName;
      ContactName = data.ContactName;
      EMail = data.EMail;
      Address = data.Address;
      Domain = data.Domain;
      Quantity = data.Quantity;
      PromoEmpno = data.PromoEmpno;
      OperatorNote = data.OperatorNote;
      OrderDate = data.OrderDate;
      OrderNo = data.OrderNo;
      productDescription = data.productDescription;
      

      localStorage.setItem('planName', planName);
      localStorage.setItem('plan', plan);
      localStorage.setItem('price', price);
      localStorage.setItem("TelMorning", TelMorning);
      localStorage.setItem("Zip", Zip);
      localStorage.setItem("Name", Name);
      localStorage.setItem("ContactLastName", ContactLastName);
      localStorage.setItem("ContactName", ContactName);
      localStorage.setItem("EMail", EMail);
      localStorage.setItem("Address", Address);
      localStorage.setItem("Domain", Domain);
      localStorage.setItem("Quantity", Quantity);
      localStorage.setItem("PromoEmpno", PromoEmpno);
      localStorage.setItem("OperatorNote", OperatorNote);
      localStorage.setItem("OrderDate", OrderDate);
      localStorage.setItem("OrderNo", OrderNo);
      localStorage.setItem("productDescription", productDescription);
    }
    localStorage.setItem("PaySuccess", PaySuccess);
    clearLocalStorage();
  }

  // Clear localStorage before close window
  function clearLocalStorage() {
    // If cheat mode is on
    var cheatMode = false;
    if (localStorage.getItem("cheatMode") !== null) {
      cheatMode = true;
    }
    localStorage.clear();
    if (cheatMode) {
      localStorage.setItem('cheatMode', true);
    }
  }

  return (
    <Container>
      <Grid container spacing={4}>
        {PaySuccess && (
          <>
            <StyledGrid item xs={12}>
              <StyledHint variant="h3">結帳成功，感謝您的訂閱</StyledHint>
            </StyledGrid>

            {/* <Grid container>
              <StyledGrid item xs={12}>
                <StyledSystemInfo variant="h4">{"商品名稱：Office 365 " + planName + "(" + plan + ")"}</StyledSystemInfo>
              </StyledGrid>
            </Grid> */}
            {/* <Grid container>
              <Grid item xs={12}>
                <Typography>適合需要使用最新桌上型電腦版 Office，並隨時隨地收發電子郵件、分享檔案及進行線上會議的企業 (使用者人數 1 至 300 名)。</Typography>
              </Grid>
            </Grid> */}


            <StyledGrid item xs={12}>
              <StyledPaper elevation={15}>

                <StyledContainerOrderTitle container>
                  <StyledGrid item xs={12}>
                    <StyledSystemInfo variant="h4">訂單資訊</StyledSystemInfo>
                  </StyledGrid>
                </StyledContainerOrderTitle>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>商品名稱：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{planName + " (" + plan + ")"}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>商品說明：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{productDescription}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>消費金額：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{price.length === 4 ? `NT$ ${price.substring(0, 1)},${price.substring(1, price.length)} / ${plan[0]}` : `NT$ ${price} / ${plan[0]}`}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>使用者授權數：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{`${Quantity} 人`}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>訂閱日期：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{OrderDate}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>訂單編號：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{OrderNo}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>公司/客戶名稱：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{Name}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>連絡電話：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{TelMorning}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>地址：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{Address}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>主要網域名稱：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{Domain}</StyledContent>
                  </Grid>
                </StyledGrid>

                <StyledGrid container>
                  <Grid item xs={6}>
                    <StyledTitle>電子郵件地址：</StyledTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledContent>{EMail}</StyledContent>
                  </Grid>
                </StyledGrid>

              </StyledPaper>
            </StyledGrid>
          </>
        )}
        {!PaySuccess && (
          <>
            <StyledGrid item xs={12}>
              <StyledHint variant="h3">系統忙線中，請靜待管理人員聯絡。</StyledHint>
            </StyledGrid>

            <Grid container>
              <StyledGrid item xs={12}>
                <StyledSystemInfo variant="h4">{"商品名稱：" + planName + "(" + plan + ")"}</StyledSystemInfo>
              </StyledGrid>
            </Grid>

            <Grid container>
              <StyledGrid item xs={12}>
                <StyledSystemInfo variant="h5">系統忙碌中, 此筆訂單已作廢</StyledSystemInfo>
              </StyledGrid>
            </Grid>

            <Grid container>
              <StyledGrid item xs={12}>
                <StyledSystemInfo variant="h5">造成您的不便敬請見諒, 感謝您的配合！</StyledSystemInfo>
              </StyledGrid>
            </Grid>
          </>
        )}

          <StyledGrid item xs={12}>
            <Link 
              to={{ pathname: "BuyNow" }} 
              style={{ textDecoration: 'none' }}
            >
              <StyledBackBtn 
                variant="contained"
                onClick={clearLocalStorage}
              >
                返回方案
              </StyledBackBtn>
            </Link>
          </StyledGrid>

      </Grid>
    </Container>
  );
}

