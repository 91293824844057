import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { format } from "date-fns";

// material ui
import MaterialTable from "material-table";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
} from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// common
import Axios from "axios";
import LoginDialogForCheckID from "../common/LoginDialogForCheckID.js";

// Security Design
const getMemberWatcherLogURL = "/api/member/getMemberWatcherLogBySn";
const insertMemberWatcherLogURL = "/api/member/insertMemberWatcherLog";

// Log
const getMemberLogURL = "/api/member/log/list";

const datetimeFormat = "yyyy/MM/dd HH:mm:ss";

export default function EditDialog(props) {
  const [cookies] = useCookies(["op_name"]);
  const [hib2bData, setHib2bData] = React.useState({});
  const [isApplyingHn, setIsApplyingHn] = useState(false);
  const [memberLog, setMemberLog] = useState([]);

  // Security Design
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showAllMemberData, setShowAllMemberData] = useState(false);
  const [memberWatcherLog, setMemberWatcherLog] = useState([]);
  const [newHnIsAvailable, setNewHnIsAvailable] = useState(true);

  useEffect(() => {
    // console.log("Member Sn:" + props.memberDetail.sn);
    if (
      props.dialogEnable &&
      props.memberDetail.sn !== undefined &&
      props.memberDetail.sn !== 0
    ) {
      getMemberWatcherLogList(props.memberDetail.sn);
    }
    if (
      props.memberDetail.hib2bsn !== undefined &&
      props.memberDetail.hib2bsn !== 0
    ) {
      Axios.post("/api/v0/getHib2bBySn", {
        hib2bsn: props.memberDetail.hib2bsn,
      }).then((res) => {
        setHib2bData(res.data.hib2b);
      });
    }
    Axios.post(getMemberLogURL, { memberSn: props.memberDetail.sn }).then(function (modifyLogResponse) {
      if (modifyLogResponse.data !== undefined) {
        setMemberLog(modifyLogResponse.data ? modifyLogResponse.data : [])
      }
    }).catch(function (err) {
      console.log(err);
      alert("撈取會員資料操作紀錄後端異常");
      setMemberLog([]);
    });
  }, [props.dialogEnable, props.memberDetail.hib2bsn, props.memberDetail.sn]);

  // Security Design
  useEffect(() => {
    // console.log(props.memberDetail.sn);
    if (
      showAllMemberData &&
      props.memberDetail.sn !== undefined &&
      cookies.op_name !== undefined
    ) {
      Axios.post(insertMemberWatcherLogURL, {
        memberSn: props.memberDetail.sn,
        operatorId: cookies.op_name,
      })
        .then(function (response) {
          console.log(response);
          getMemberWatcherLogList(props.memberDetail.sn);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [showAllMemberData, props.memberDetail.sn, cookies.op_name]);

  const getMemberWatcherLogList = (sn) => {
    Axios.post(getMemberWatcherLogURL, { Sn: sn })
      .then(function (response) {
        console.log(response);
        setMemberWatcherLog(response.data.log != null ? response.data.log : []);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const convertDataToHiddenCode = (val) => {
    let strLength = val.length;
    let data = "";
    if (strLength !== 0 && strLength !== undefined && strLength !== null) {
      for (let i = 0; i < strLength; i++) {
        data += "*";
      }
    }
    return data;
  };

  const enableApplyingHn = () => {
    setIsApplyingHn(true);
  };
  const disableApplyingHn = () => {
    setIsApplyingHn(false);
  };
  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  };
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  };
  const handleToggleShowAllMemberData = () => {
    setShowAllMemberData(!showAllMemberData);
  };
  const handleMouseDownAllMemberData = (e) => {
    e.preventDefault();
  };
  const handleClickShowAllMemberData = () => {
    if (showAllMemberData) {
      // 正常已開啟狀態。
      handleToggleShowAllMemberData();
    } else if (showAllMemberData === false && props.memberDetail.sn === 0) {
      // 新增會員時。
      handleToggleShowAllMemberData();
    } else {
      // 未開啟，故要先行登入。
      handleOpenLoginDialog();
    }
  };
  const handleCloseEditDialog = () => {
    props.handleClose();
    if (showAllMemberData) {
      handleToggleShowAllMemberData();
    }
  };

  function createTable(columns, datas) {
    return (
      <MaterialTable
        columns={columns}
        data={datas}
        options={{
          search: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          padding: "dense",
          sorting: false,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#FFFFFF" : "#EEEEEE",
          }),
        }}
      />
    );
  }
  function watcherLogTable(watcherLog) {
    const columns = [
      {
        title: "時間",
        field: "watchTime",
        render: (rowData) => datetimeStringFormat(rowData.watchTime),
      },
      {
        title: "查看人員",
        field: "operator",
        render: (rowData) =>
          showAllMemberData
            ? rowData.operator
            : convertDataToHiddenCode(rowData.operator),
      },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          機敏資料查看紀錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>
          {createTable(columns, watcherLog)}
        </DialogContent>
      </Box>
    );
  }
  function logTable(memberLog) {
    const columns = [
      {
        title: "時間",
        field: "modifyDate",
        render: (rowData) => datetimeStringFormat(rowData.ModifyTime),
      },
      {
        title: "修改者",
        field: "operator",
        render: (rowData) => {
          // rowData.OperatorSn !== undefined && rowData.OperatorSn.Name !== undefined ? rowData.OperatorSn.Name : convertDataToHiddenCode(rowData.operator)
          if (rowData.OperatorSn !== undefined && rowData.OperatorSn.Name !== undefined) {
            return showAllMemberData ? rowData.OperatorSn.Name : convertDataToHiddenCode(rowData.OperatorSn.Name);
          } else {
            return "";
          }
        }
      },
      {
        title: "欄位",
        field: "field",
        render: (rowData) => {
          if (!fieldMapingList[rowData.Field]) return showAllMemberData ? rowData.Field : convertDataToHiddenCode(rowData.Field);
          return showAllMemberData ? fieldMapingList[rowData.Field] : convertDataToHiddenCode(fieldMapingList[rowData.Field]);
        },
      },
      {
        title: "修改前資料",
        field: "beforeValue",
        render: (rowData) => showAllMemberData ? rowData.BeforeValue : convertDataToHiddenCode(rowData.BeforeValue)
      },
      {
        title: "修改後資料",
        field: "afterValue",
        render: (rowData) => showAllMemberData ? rowData.AfterValue : convertDataToHiddenCode(rowData.AfterValue)
      },
      // { title: "動作", field: "action" },
    ];
    return (
      <Box m={2}>
        <Typography variant="h6">
          會員資料更改記錄
          <IconButton
            aria-label="toggle personal data visibility"
            onClick={handleClickShowAllMemberData}
            onMouseDown={handleMouseDownAllMemberData}
            edge="end"
          >
            {showAllMemberData ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Typography>
        <DialogContent dividers>{createTable(columns, memberLog)}</DialogContent>
      </Box>
    );
  }
  const fieldMapingList = {
    Hn: "Hinet會員HN",
    CompanyNo: "公司統編",
    IdNo: "身份證字號",
    TelMorning: "聯絡電話",
    Cell: "行動電話",
    Zip: "郵遞區號",
    RegisterStatus: "註冊狀態",
    RegisterDate: "註冊日期",
    LastDate: "上次登入日期",
    Name: "會員姓名",
    ContactName: "聯絡人（名）",
    ContactLastName: "聯絡人（姓）",
    EMail: "Email",
    City: "城市",
    Address: "聯絡住址",
    ContractNoK1: "K1碼",
    IsDeleted: "是否已刪除"
  };
  function datetimeStringFormat(time) {
    // time is string
    if (time === "" || time === null) {
      return "";
    } else {
      return format(new Date(time), datetimeFormat);
    }
  }

  // Check if zip code is valid
  const [zipValid, setZipValid] = React.useState(true);

  //function createTextField(label, name, value, onChange, required) {
  //  return (
  //    <TextField
  //      fullWidth
  //      margin="dense"
  //      key={label}
  //      label={label}
  //      name={name}
  //      value={value || ""}
  //      onChange={onChange}
  //      required={required}
  //    />
  //  );
  //}

  function showHib2bTextField() {
    //let textFieldEntries = [
    //  createTextField("代表人", "rpt", hib2bData.rpt, handleHib2bChange, true),
    //  createTextField(
    //    "代表人證號",
    //    "rptid",
    //    hib2bData.rptid,
    //    handleHib2bChange,
    //    true,
    //  ),
    //  createTextField(
    //    "收據用戶名稱",
    //    "rcp_cname",
    //    hib2bData.rcp_cname,
    //    handleHib2bChange,
    //    true,
    //  ),
    //  createTextField(
    //    "收據證號",
    //    "rcp_custmno",
    //    hib2bData.rcp_custmno,
    //    handleHib2bChange,
    //    true,
    //  ),
    //  createTextField(
    //    "傳真號碼",
    //    "usrfax",
    //    hib2bData.usrfax,
    //    handleHib2bChange,
    //    true,
    //  ),
    //];

    if (isApplyingHn === true || props.memberDetail.hib2bsn !== 0) {
      // note: using { variable || '' } is to prevent overlap of value and label
      return (
        <React.Fragment>
          <TextField
            fullWidth
            margin="dense"
            label="代表人"
            name="rpt"
            value={hib2bData.rpt || ""}
            onChange={handleHib2bChange}
            autoComplete="off"
            required
          />
          <TextField
            fullWidth
            margin="dense"
            label="代表人證號"
            type={showAllMemberData ? "text" : "password"}
            name="rptid"
            value={hib2bData.rptid || ""}
            onChange={handleHib2bChange}
            autoComplete="off"
            required
          />
          <TextField
            fullWidth
            margin="dense"
            label="收據用戶名稱"
            name="rcp_cname"
            value={hib2bData.rcp_cname || ""}
            onChange={handleHib2bChange}
            autoComplete="off"
            required
          />
          <TextField
            fullWidth
            margin="dense"
            label="收據證號"
            type={showAllMemberData ? "text" : "password"}
            name="rcp_custmno"
            value={hib2bData.rcp_custmno || ""}
            onChange={handleHib2bChange}
            autoComplete="off"
            required
          />
          <TextField
            fullWidth
            margin="dense"
            label="傳真號碼"
            type={showAllMemberData ? "text" : "password"}
            name="usrfax"
            value={hib2bData.usrfax || ""}
            onChange={handleHib2bChange}
            autoComplete="off"
          />
        </React.Fragment>
      );
    }
  }

  function handleOrderChangeZip(event) {
    const { name, value } = event.target;
    const isZipValid = /[0-9]{5}/.test(value);
    setZipValid(isZipValid);
    props.setMemberDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    props.setMemberDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleHib2bChange(event) {
    const { name, value } = event.target;
    setHib2bData((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  }

  function hasHib2b() {
    if (
      props.memberDetail.hib2bsn !== undefined &&
      props.memberDetail.hib2bsn !== 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  const checkMemberData = (errMsg) => {
    if (
      props.memberDetail.name !== undefined &&
      props.memberDetail.name.length > 50
    ) {
      errMsg += " - 會員名稱請少於 50 個字!";
    }
    if (
      !zipValid ||
      props.memberDetail.zip === undefined ||
      !/[0-9]{5}/.test(props.memberDetail.zip)
    ) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 郵遞區號請輸入五碼!";
    }
    if (props.memberDetail.hn === undefined || props.memberDetail.hn === "") {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - Hinet會員HN為必填欄位!";
    }
    if (
      props.memberDetail.companyno === undefined ||
      props.memberDetail.companyno === ""
    ) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 公司統編為必填欄位!";
    }
    if (
      props.memberDetail.cell === undefined ||
      props.memberDetail.cell === ""
    ) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 行動電話為必填欄位!";
    }
    if (
      props.memberDetail.name === undefined ||
      props.memberDetail.name === ""
    ) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 會員姓名為必填欄位!";
    }
    if (
      props.memberDetail.contactlastname === undefined ||
      props.memberDetail.contactlastname === ""
    ) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 聯絡人（姓）為必填欄位!";
    }
    if (
      props.memberDetail.contactname === undefined ||
      props.memberDetail.contactname === ""
    ) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 聯絡人（名）為必填欄位!";
    }
    if (
      props.memberDetail.email === undefined ||
      props.memberDetail.email === ""
    ) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - Email 為必填欄位!";
    }
    if (
      props.memberDetail.city === undefined ||
      props.memberDetail.city === ""
    ) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 城市為必填欄位!";
    }
    if (
      props.memberDetail.address === undefined ||
      props.memberDetail.address === ""
    ) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 聯絡住址為必填欄位!";
    }

    if (newHnIsAvailable === false) {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 此 HN 已註冊!";
    }

    return errMsg;
  };

  const alterMember = () => {
    Axios.post("/api/member/alterMember", {
      sn: props.memberDetail.sn,
      hn: props.memberDetail.hn.trim(),
      idno: props.memberDetail.idno ? props.memberDetail.idno.trim() : "",
      companyno: props.memberDetail.companyno.trim(),
      telmorning: props.memberDetail.telmorning
        ? props.memberDetail.telmorning.trim()
        : "",
      cell: props.memberDetail.cell.trim(),
      zip: props.memberDetail.zip.trim(),
      //registerdate: props.memberDetail.registerdate.trim(),
      //lastdate: props.memberDetail.lastdate.trim(),
      name: props.memberDetail.name.trim(),
      contactlastname: props.memberDetail.contactlastname.trim(),
      contactname: props.memberDetail.contactname.trim(),
      email: props.memberDetail.email.trim(),
      city: props.memberDetail.city.trim(),
      address: props.memberDetail.address.trim(),
      opName: cookies.op_name
      //isdeleted: props.memberDetail.isdeleted,
    });
  };

  const checkHib2bData = (errMsg) => {
    if (hib2bData.rpt === undefined || hib2bData.rpt === "") {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 代表人為必填欄位!";
    }
    if (hib2bData.rptid === undefined || hib2bData.rptid === "") {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 代表人證號為必填欄位!";
    }
    if (hib2bData.rcp_cname === undefined || hib2bData.rcp_cname === "") {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 收據用戶名稱為必填欄位!";
    }
    if (hib2bData.rcp_custmno === undefined || hib2bData.rcp_custmno === "") {
      errMsg += errMsg !== "" ? "\n" : "";
      errMsg += " - 收據證號為必填欄位!";
    }

    return errMsg;
  };

  const alterHib2b = () => {
    Axios.post("/api/v0/hib2bInfoCreateForMember", {
      membersn: props.memberDetail.sn,
      Rpt: hib2bData.rpt,
      RptId: hib2bData.rptid,
      RcpCname: hib2bData.rcp_cname,
      RcpCustmno: hib2bData.rcp_custmno,
      usrfax: hib2bData.usrfax,
    });
  };

  async function confirmAddNewMember() {
    let errMsg = "";
    errMsg = checkMemberData(errMsg);
    if (
      props.memberDetail.hib2bsn !== undefined &&
      props.memberDetail.hib2bsn !== 0
    ) {
      errMsg = checkHib2bData(errMsg);
    }

    if (errMsg !== "") {
      errMsg = "以下欄位請修正:\n" + errMsg;
      alert(errMsg);
      return;
    }

    if (window.confirm("確定儲存變更資料？")) {
      try {
        await alterMember();
        if (
          props.memberDetail.hib2bsn !== undefined &&
          props.memberDetail.hib2bsn !== 0
        ) {
          await alterHib2b();
        }
        // window.location.reload();
        handleCloseEditDialog();
      } catch (error) {
        alert(error.response.data.message);
      }
    }
    //props.handleClose();
  }

  async function applyHN() {
    let errMsg = "";
    errMsg = checkMemberData(errMsg);
    errMsg = checkHib2bData(errMsg);

    if (errMsg !== "") {
      errMsg = "以下欄位請修正:\n" + errMsg;
      alert(errMsg);
      return;
    }

    if (window.confirm("確定申請HN？")) {
      try {
        await alterMember();
        await alterHib2b();
        await Axios.post("/api/v0/hib2bApply", {
          memberSn: props.memberDetail.sn,
        });
        // window.location.reload();
        handleCloseEditDialog();
      } catch (error) {
        alert(error.response.data.message);
      }
    }
  }

  const checkHNIsAvailable = () => {
    if (props.isCreateNewMember === true) {
      var postData = {};
      postData["Hn"] = props.memberDetail.hn;
      Axios.post("/api/member/getMemberByHn", postData)
        .then(function (res) {
          if (
            res !== undefined &&
            res.data !== undefined &&
            res.data.rowNumber !== 0
          ) {
            setNewHnIsAvailable(false);
          } else {
            setNewHnIsAvailable(true);
          }
        })
        .catch(function (err) {
          console.log(err);
          setNewHnIsAvailable(true);
        });
    } else {
      setNewHnIsAvailable(true);
    }
  };

  return (
    <Dialog
      open={props.dialogEnable}
      // onClose={props.handleClose}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogTitle>
        會員資料編輯
        <IconButton
          aria-label="toggle cellphone visibility"
          onClick={handleClickShowAllMemberData}
          onMouseDown={handleMouseDownAllMemberData}
          edge="end"
          size="medium"
        >
          {showAllMemberData ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {showHib2bTextField()}
        <TextField
          fullWidth
          margin="dense"
          label="Hinet會員HN"
          name="hn"
          value={props.memberDetail.hn}
          onBlur={checkHNIsAvailable}
          error={!newHnIsAvailable}
          onChange={handleChange}
          autoComplete="off"
          required
        />
        {/* <TextField
          fullWidth
          margin="dense"
          label="MKT會員HD碼"
          name="hd"
          value={props.memberDetail.hd}
          onChange={handleChange}
          autoComplete="off"
          required
        /> */}
        <TextField
          fullWidth
          margin="dense"
          label="身份證字號"
          name="idno"
          type={showAllMemberData ? "text" : "password"}
          value={props.memberDetail.idno}
          onChange={handleChange}
          autoComplete="off"
        />

        <TextField
          fullWidth
          margin="dense"
          label={hasHib2b() ? "客戶證號" : "公司統編"}
          type={showAllMemberData ? "text" : "password"}
          name="companyno"
          value={props.memberDetail.companyno}
          onChange={handleChange}
          autoComplete="off"
          required
        />

        <TextField
          fullWidth
          margin="dense"
          label={hasHib2b() ? "聯絡人電話" : "聯絡電話"}
          name="telmorning"
          value={props.memberDetail.telmorning}
          onChange={handleChange}
          autoComplete="off"
          type={showAllMemberData ? "text" : "password"}
        />

        <TextField
          fullWidth
          margin="dense"
          label={hasHib2b() ? "手機" : "行動電話"}
          name="cell"
          value={props.memberDetail.cell}
          onChange={handleChange}
          autoComplete="off"
          type={showAllMemberData ? "text" : "password"}
          required
        />

        <TextField
          fullWidth
          margin="dense"
          label={hasHib2b() ? "帳寄郵遞區號" : "郵遞區號"}
          name="zip"
          error={!zipValid}
          value={props.memberDetail.zip}
          onChange={handleOrderChangeZip}
          autoComplete="off"
          type={showAllMemberData ? "text" : "password"}
          required
        />

        <TextField
          fullWidth
          margin="dense"
          label={hasHib2b() ? "公司/客戶名稱" : "會員姓名"}
          name="name"
          value={props.memberDetail.name}
          onChange={handleChange}
          autoComplete="off"
          required
        />

        <TextField
          fullWidth
          margin="dense"
          label="聯絡人（姓）"
          name="contactlastname"
          value={props.memberDetail.contactlastname}
          onChange={handleChange}
          autoComplete="off"
          required
        />

        <TextField
          fullWidth
          margin="dense"
          label="聯絡人（名）"
          name="contactname"
          value={props.memberDetail.contactname}
          onChange={handleChange}
          autoComplete="off"
          required
        />

        <TextField
          fullWidth
          margin="dense"
          label={hasHib2b() ? "聯絡人Email" : "Email"}
          name="email"
          value={props.memberDetail.email}
          onChange={handleChange}
          autoComplete="off"
          required
        />

        <TextField
          fullWidth
          margin="dense"
          label={hasHib2b() ? "帳單寄送城市" : "城市"}
          name="city"
          value={props.memberDetail.city}
          onChange={handleChange}
          autoComplete="off"
          type={showAllMemberData ? "text" : "password"}
          required
        />

        <TextField
          fullWidth
          margin="dense"
          label={hasHib2b() ? "帳單寄送地址" : "聯絡住址"}
          name="address"
          value={props.memberDetail.address}
          onChange={handleChange}
          autoComplete="off"
          type={showAllMemberData ? "text" : "password"}
          required
        />
        {props.memberDetail.sn === 0 ? null : watcherLogTable(memberWatcherLog)}
      </DialogContent>
      <DialogActions>
        {(isApplyingHn === false && props.memberDetail.hib2bsn === 0) ? (
          <Button onClick={enableApplyingHn} color="primary">申請HN</Button>
        ) : (<>
          <Button onClick={applyHN} name="save" color="primary">
            儲存+申請HN
          </Button>
          <Button onClick={disableApplyingHn} color="primary">取消申請模式</Button>
        </>)}
        <Button onClick={confirmAddNewMember} name="save" color="primary">
          儲存
        </Button>
        <Button onClick={handleCloseEditDialog} color="primary">
          取消
        </Button>
      </DialogActions>
      {logTable(memberLog)}
      <LoginDialogForCheckID
        open={openLoginDialog}
        handleToggleShowAllMemberData={handleToggleShowAllMemberData}
        handleCloseLoginDialog={handleCloseLoginDialog}
      ></LoginDialogForCheckID>
    </Dialog>
  );
}
