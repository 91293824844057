import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fakeAuthContext } from "../Router";
import { handleInitLoginCookies } from "../common/session";
// Customed component
import { getCookie } from "../common/session";
import FilterCompanyList from "./FilterCompanyList";

// APIs
const getProductBrandListAPI = "/api/product/brand/list";
const getOpUserGroupBrandRelationURL = "/OP/user/getOpUserGroupBrandRelation";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  versionText: {
    color: "darkgreen",
    textAlign: "center",
    fontSize: "16px",
    marginTop: theme.spacing(2),
  },
  bulletinBoard: {
    backgroundColor: "#f8f9fa",
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    borderRadius: 10,
    border: "1px solid #ddd",
    textAlign: "center",
    cursor: "pointer",
  },
}));

export default function Login(props) {
  const fakeAuth1 = React.useContext(fakeAuthContext);
  const classes = useStyles();
  const history = useHistory();
  // eslint-disable-next-line

  const ckOpName = getCookie("op_name");
  const authorization = axios.defaults.headers.common["X-RenZheng"];

  const [id, setId] = useState("");
  const [pwd, setPwd] = useState("");
  const [otp, setOtp] = useState("");

  const [filterCompanyOptionStatus, setFilterCompanyOptionStatus] =
    useState(false);
  const [productBrandList, setProductBrandList] = useState([]);
  const [version, setVersionContent] = useState(""); // Show version number
  const [bulletinDetails, setBulletinDetails] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    axios.post("/api/bulletin/bulletin")
      .then(response => {
        setBulletinDetails(response.data);
      })
      .catch(error => {
        console.error("Error fetching bulletin details:", error);
      });
  }, []);

  useEffect(() => {
    axios.post("/api/bulletin/version")
      .then(response => {
        setVersionContent(response.data);
      })
      .catch(error => {
        console.error("Error fetching version no:", error);
      });
  }, []);

  // State operator
  const closeFilterCompanyList = () => {
    setFilterCompanyOptionStatus(false);
  };

  // Data handler
  const chooseFilterCompany = (brandSn) => {
    // getData(brandSn);
    localStorage.setItem("filterCompany", brandSn);
  };

  const handleBulletinClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Functions
  const redirect = () => {
    console.log("準備轉至 /backoffice/Orders");
    axios.post("/OP/user/getUserById", {
      "OperatorId": id
    }).then(function (req) {
      if (req.data !== undefined) {
        console.log(req.data);
        var errMsg = "";
        if (req.data["Employee_No"] === "") {
          errMsg += "員工編號為空\n";
        }
        if (req.data["Email"].includes("cht.com.tw") === false) {
          if (req.data["OfficeManagerEmpNo"] === "") {
            errMsg += "單位主管員工編號為空\n";
          }
          if (req.data["Office"] === "") {
            errMsg += "所屬單位為空\n";
          }
        }
        if (errMsg !== "") {
          errMsg = "依據公司規範, 需於\"管理者\"內自身的資訊內填入帳號資訊, 以下資訊請盡速填寫:\n" + errMsg;
					alert(errMsg);
        }
      }
      history.replace("/backoffice/Orders");
    }).catch(function (err) {
      console.log(err);
      history.replace("/backoffice/Orders");
    });
  };

  // Load initial data with useEffect
  useEffect(() => {
    if (ckOpName && authorization) {
      var postData = {};
      postData["OpName"] = ckOpName;
      axios
        .post(getProductBrandListAPI, postData)
        .then(function (res) {
          if (res.data !== undefined) {
            // console.log(res);
            setProductBrandList(res.data);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [ckOpName, authorization]);

  function login() {
    axios
      .post("/api/v0/login", {
        id: id,
        pwd: md5(pwd),
        otp: otp,
      })
      .then(function (response) {
        handleInitLoginCookies(
          response.data.Session,
          response.data.Name,
          response.data.GroupSn
        );
        fakeAuth1.isAuthenticated = true;
        axios
          .post(getOpUserGroupBrandRelationURL, {
            operatorId: id,
          })
          .then(function (res) {
            if (res.data !== undefined) {
              var brandCount = 0;
              var brandSn = 0;
              for (var i = 0; i < res.data.length; ++i) {
                if (res.data.IsMemberOfThisBrand !== undefined) {
                  if (res.data.IsMemberOfThisBrand === true) {
                    brandCount += 1;
                    brandSn = res.data[i]["BrandSn"];
                  }
                }
              }
              if (brandCount === 1) {
                chooseFilterCompany(brandSn);
                redirect();
              } else {
                setFilterCompanyOptionStatus(true);
              }
            } else {
              setFilterCompanyOptionStatus(true);
            }
          })
          .catch(function (err) {
            console.log(err);
            alert("資料庫異常, 登入所有廠牌");
            chooseFilterCompany(0);
            redirect();
          });
      })
      .catch(function (error) {
        //console.log(error);
        //console.log(error.response);
        alert(error.response.data);
      });
  }

  function getOtp() {
    axios
      .post("/api/v0/getOtp", {
        id: id,
        pwd: md5(pwd),
      })
      .then(function (response) {
        alert("OTP 已發送,請在5分鐘內登入");
      })
      .catch(function (error) {
        //console.log(error);
        //console.log(error.response);
        alert(error.response.data);
      });
  }

  function showAdminLogin() {
    if (process.env.REACT_APP_MODE === "development") {
      // cheat login API
      function loginAdmin() {
        axios
          .post("/api/v0/cheatLogin", {})
          .then(function (response) {
            handleInitLoginCookies(
              response.data.Session,
              response.data.Name,
              response.data.GroupSn
            ).then(() => {
              fakeAuth1.isAuthenticated = true;
              setId("admincms");

              axios
                .post(getOpUserGroupBrandRelationURL, {
                  operatorId: response.data.Name,
                })
                .then(function (res) {
                  // console.log(response.data.Name);
                  if (res.data !== undefined) {
                    var brandCount = 0;
                    var brandSn = 0;
                    for (var i = 0; i < res.data.length; ++i) {
                      // console.log(res.data[i]["IsMemberOfThisBrand"]);
                      if (res.data[i]["IsMemberOfThisBrand"] !== undefined) {
                        if (res.data[i]["IsMemberOfThisBrand"] === true) {
                          brandCount += 1;
                          brandSn = res.data[i]["BrandSn"];
                        }
                      }
                    }
                    // console.log(brandCount);
                    if (brandCount === 1) {
                      chooseFilterCompany(brandSn);
                      redirect();
                    } else {
                      setFilterCompanyOptionStatus(true);
                    }
                  } else {
                    setFilterCompanyOptionStatus(true);
                  }
                })
                .catch(function (err) {
                  console.log(err);
                  alert("資料庫異常, 登入所有廠牌");
                  chooseFilterCompany(0);
                  redirect();
                });

              // Move this to this end of brand choosing
              // history.replace("/backoffice");
            });
          })
          .catch(function (error) {
            //console.log(error);
            //console.log(error.response);
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
              alert(error.response.data);
            }
          });
      }

      // return cheat login button
      return (
        <Grid container spacing={3}>
          <Grid item xs>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={loginAdmin}
            >
              Admin
            </Button>            
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.versionText} variant="body2" color="textSecondary" align="center">
              Version: {version}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return <React.Fragment />;
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>CSP</Avatar>
        <Typography component="h1" variant="h5">
          Login ({process.env.REACT_APP_MODE})
        </Typography>
        <div className={classes.bulletinBoard} onClick={handleBulletinClick}>
          <Typography variant="h6">{"公告: 當月更新(詳細請點擊)！"}</Typography>
        </div>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>公告詳細更新內容</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {bulletinDetails.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              關閉
            </Button>
          </DialogActions>
        </Dialog>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="管理者帳號"
            name="id"
            id="email"
            autoComplete="off"
            autoFocus
            value={id}
            onChange={(event) => {
              setId(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="管理者密碼"
            name="password"
            type="password"
            id="password"
            autoComplete="off"
            value={pwd}
            onChange={(event) => {
              setPwd(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="OTP"
            name="otp"
            id="otp"
            autoComplete="off"
            value={otp}
            onChange={(event) => {
              setOtp(event.target.value);
            }}
          />
          <Grid container spacing={3}>
            <Grid item xs>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={login}
              >
                登入系統
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.submit}
                onClick={getOtp}
              >
                發送 OTP
              </Button>
            </Grid>
          </Grid>
          {showAdminLogin()}
        </form>
      </div>
      <FilterCompanyList
        open={filterCompanyOptionStatus}
        closeList={closeFilterCompanyList}
        chooseFilterCompany={chooseFilterCompany}
        companyList={productBrandList}
        redirect={redirect}
      />
    </Container>
  );
}
