import React from "react";
import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";

import bannerImg from "../assets/ProductOverview/banner.jpg";
import { Link } from "react-router-dom";

import styled from 'styled-components';

const StyledLink = styled(Link)`
  && {
    text-decoration: none;
  }
  @media(max-width: 600px){
    display: none;
  }
`
const StyledContent = styled.div`
  background-image: url(${bannerImg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  max-width: 100%;
  height: auto;
  padding-top: 13.55%;
  padding-bottom: 13.55%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  overflow: hidden;
  border-radius: 8px 8px 0 0;

  .text{
    width: 50%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(34, 49, 63, .8);
    padding: 10px 0;
    transform: rotateX(-90deg); 
    transform-origin: 50% 50%;
    transition: 0.5s;
    opacity: 0;

    p{
      font: normal 36px/1.5 Arial, Helvetica, "微軟正黑體";
      color: white;
    }
  }

  &:hover{
    opacity: 0.8;
    .text{
      transform: rotateX(0deg);
      opacity: 1;
    }
  }

`

export default function card() {
  return (
    <Card>
        <StyledLink to="Buynow">
            <StyledContent>
              <div class="text">
                  <p>查看方案與價格</p>
              </div>
            </StyledContent>
        </StyledLink>
    </Card>
  );
}
